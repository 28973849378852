const jssStyles = theme => ({
  container: {
    position: 'absolute',
    cursor: 'text'
  },
  input: {
    position: 'absolute',
    background: 'transparent',
    border: 'none',
    width: '100%',
    margin: 0,
    padding: 0,
    '&::placeholder': {
      opacity: 1
    }
  },
  closeIcon: {
    color: 'rgb(129, 144, 165)',
    cursor: 'pointer',
    position: 'absolute',
    right: 4,
    top: '50%',
    zIndex: theme.zIndex.layerElevated
  }
})

export default jssStyles
