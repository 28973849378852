/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import {
  withStyles,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'

import { log, isProductionInstance } from 'utils'
import themes from 'config/themes'
import { fetchProjectPage, fetchLinkedProjects } from 'actions/pageActions'
import {
  initProjectState,
  updateActiveViews,
  updateLiveState,
} from 'actions/projectStateActions'

import GUIControls from 'components/GUIControls'
import ViewContainer from 'components/ViewContainer'

import styles from './LivePageStyles'

const RouteParams = {
  ProjectId: 'projectId',
  EditPage: 'edit',
}

let projectTheme = {}

const LivePage = ({ classes, page, projectState, routeParams, actions }) => {
  const isGUIEnabled =
    get(routeParams, RouteParams.EditPage) === RouteParams.EditPage

  useEffect(() => {
    const projectId = get(routeParams, RouteParams.ProjectId)

    if (!projectId) return

    actions.initProjectState(projectId)
    actions.fetchProjectPage(projectId)

    projectTheme = createMuiTheme(themes[projectId])
  }, [])

  useEffect(() => {
    actions.fetchLinkedProjects()
  }, [page])

  const { project, linkedProjects } = page

  if (isEmpty(project)) return null

  log('project', project)
  log('linkedProjects', linkedProjects)
  log('projectState', projectState)

  const updateState = async ({ stateValues, nodeId, linkedProjectId }) => {
    if (!isArray(stateValues)) {
      actions.updateLiveState(stateValues, nodeId, linkedProjectId)
      return
    }

    for (const stateValue of stateValues) {
      await actions.updateLiveState(stateValue, nodeId, linkedProjectId)
    }
  }

  const handleHotspotChange = (viewId, linkedProjectId = null) => {
    if (!viewId) return
    actions.updateActiveViews(viewId, linkedProjectId)
  }

  const handleProjectStateChange = async (value, nodeId, stateProps = {}) => {
    const { nodeProps, linkedProjectId, updateWithValue } = stateProps

    if (updateWithValue) {
      actions.updateLiveState(value)
      return
    }

    if (value && value.stateValues) {
      await updateState({
        nodeId,
        linkedProjectId,
        stateValues: value.stateValues,
      })
      return
    }

    const stateNodeProps = nodeId
      ? get(projectState, `data.${nodeId}.props`)
      : nodeProps

    if (!stateNodeProps || !stateNodeProps.onStateChange) return
    const stateValues = stateNodeProps.onStateChange(value)

    if (stateValues) {
      await updateState({ nodeId, linkedProjectId, stateValues })
    }
  }

  const Component = (
    <div className={classes.container}>
      {isGUIEnabled && (
        <GUIControls
          projectState={projectState}
          onProjectStateChange={handleProjectStateChange}
        />
      )}
      <ViewContainer
        views={project.views}
        linkedProjects={linkedProjects}
        projectId={project.id}
        projectState={projectState}
        onHotspotChange={handleHotspotChange}
        onProjectStateChange={handleProjectStateChange}
      />
    </div>
  )

  if (projectTheme) {
    return <ThemeProvider theme={projectTheme}>{Component}</ThemeProvider>
  }

  return Component
}

LivePage.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  projectState: PropTypes.object,
  routeParams: PropTypes.object,
  history: PropTypes.object,
  actions: PropTypes.shape({
    fetchProjectPage: PropTypes.func.isRequired,
    initProjectState: PropTypes.func.isRequired,
    updateActiveViews: PropTypes.func.isRequired,
    updateLiveState: PropTypes.func.isRequired,
    fetchLinkedProjects: PropTypes.func.isRequired,
  }),
}

const mapStateToProps = (state, ownProps) => {
  const { page, projectState } = state
  return {
    page,
    projectState,
    routeParams: ownProps.match.params,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        fetchProjectPage,
        initProjectState,
        updateActiveViews,
        updateLiveState,
        fetchLinkedProjects,
      },
      dispatch
    ),
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(LivePage)
