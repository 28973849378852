import get from 'lodash/get'
import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FDINX7UJYA412')

const Properties = {
  ExpandedItem: 'expandedItem',
}

const toggleExpandedValue = state => ({
  property: Properties.ExpandedItem,
  value: !get(state, `liveState.${Properties.ExpandedItem}`),
})

export const data = {
  '41-3': {
    eventProps: {
      onClick: state => toggleExpandedValue(state),
    },
  },
  '41-4': {
    eventProps: {
      onClick: state => toggleExpandedValue(state),
    },
  },
}

export default ProjectId
