import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AnimateSharedLayout } from 'framer-motion'

import Content from './Content'
import CompactCard from './CompactCard'
import ExpandedCard from './ExpandedCard'

const DateButton = ({ classes, day, disabled, onExpand, onCollapse }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const collapseDate = () => {
    setIsExpanded(false)
    onCollapse()
  }

  const expandDate = () => {
    setIsExpanded(true)
    onExpand()
  }

  return (
    <div className={classes.cardContainer}>
      <AnimateSharedLayout>
        {isExpanded ? (
          <ExpandedCard classes={classes} onCollapse={collapseDate} day={day}>
            <Content classes={classes} day={day} disabled={disabled} />
          </ExpandedCard>
        ) : (
          <CompactCard
            classes={classes}
            onExpand={expandDate}
            disabled={disabled}
            day={day}
          >
            <Content classes={classes} day={day} disabled={disabled} />
          </CompactCard>
        )}
      </AnimateSharedLayout>
    </div>
  )
}

DateButton.propTypes = {
  classes: PropTypes.object.isRequired,
  day: PropTypes.number,
  disabled: PropTypes.bool,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
}

export default DateButton
