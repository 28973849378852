import { getId } from 'utils'

import mapValues from 'lodash/mapValues'
import zIndex from '@material-ui/core/styles/zIndex'

const ProjectIds = [
  'FHFIT3R6B712',
  'FHFIT3R6B74416',
  'FHFIT3R6B780432',
  'FHFIT3R6B71342043',
  'FHFIT3R6B71634185',
  'FHFIT3R6B71811793', // MatchupBox
  'FHFIT3R6B72060', // FloatingBox
  'FHFIT3R6B72380', // ColorCommentaryBox
]

const maxLayers = 2000
const elevatedZIndices = mapValues(zIndex, z => z + maxLayers)

const black = '#161c2e'
const white = '#FFF'
const red = '#D2003C'

const dark = '#161B2E'
const gray = '#6F7786'
const lightGray = '#DCDCDC'
const extralightGray = '#F1F1F1'
const midGray = '#A0A0A0'
const darkGray = '#6F7786'
const cloudGray = '#C4C4C4'
const green = '#00842D'

const fontFamilies = {
  overpassLight: 'Overpass-Light',
  overpassRegular: 'Overpass-Regular',
  overpassSemiBold: 'Overpass-SemiBold',
  overpassExtraBold: 'Overpass-ExtraBold',
  overpassBold: 'Overpass-Bold',
  overpassBlack: 'Overpass-Black',
}

const theme = {
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: red,
    },
    black,
    white,
    red,
    white40: 'rgba(255,255,255,0.4)',
    dark,
    gray,
    lightGray,
    extralightGray,
    midGray,
    darkGray,
    cloudGray,
    warnings: {
      success: green,
      error: red,
    },
  },
  typography: {
    fontFamilies: {
      overpassLight: 'Overpass-Light',
      overpassRegular: 'Overpass-Regular',
      overpassSemiBold: 'Overpass-SemiBold',
      overpassExtraBold: 'Overpass-ExtraBold',
      overpassBold: 'Overpass-Bold',
      overpassBlack: 'Overpass-Black',
    },
    fontFamily: [fontFamilies.overpassRegular].join(','),
    h1: {
      fontFamily: fontFamilies.overpassBlack,
      fontSize: 36,
      lineHeight: '48px',
    },
    h2: {
      fontFamily: fontFamilies.overpassBold,
      fontSize: 32,
      lineHeight: '40px',
      letterSpacing: 1,
    },
    h3: {
      fontFamily: fontFamilies.overpassBlack,
      fontSize: 24,
      lineHeight: '32px',
    },
    h4: {
      fontFamily: fontFamilies.overpassBlack,
      fontSize: 20,
      lineHeight: '24px',
    },
    h5: {
      fontFamily: fontFamilies.overpassBlack,
      fontSize: 18,
      lineHeight: '20px',
    },
    h6: {
      fontFamily: fontFamilies.overpassSemiBold,
      fontSize: 18,
      lineHeight: '20px',
    },
    h7: {
      fontFamily: fontFamilies.overpassBlack,
      fontSize: 16,
      lineHeight: '20px',
    },
    h8: {
      fontFamily: fontFamilies.overpassBlack,
      fontSize: 14,
      lineHeight: '16px',
    },
    h9: {
      fontFamily: fontFamilies.overpassSemiBold,
      fontSize: 14,
      lineHeight: '20px',
    },
    body1: {
      fontFamily: fontFamilies.overpassRegular,
    },
    body2: {
      fontFamily: fontFamilies.overpassRegular,
      fontSize: 14,
    },
    caption: {
      fontFamily: fontFamilies.overpassSemiBold,
    },
    overline: {
      fontFamily: fontFamilies.overpassBold,
    },
    s6: {
      fontSize: 16,
      lineHeight: '24px',
      fontFamily: fontFamilies.overpassRegular,
      color: darkGray,
    },
    s8: {
      fontSize: 14,
      lineHeight: '20px',
      fontFamily: fontFamilies.overpassRegular,
      color: darkGray,
    },
    s9: {
      fontSize: 13,
      lineHeight: '8px',
      fontFamily: fontFamilies.overpassRegular,
      color: darkGray,
    },
    l4: {
      fontSize: 14,
      lineHeight: '20px',
      fontFamily: fontFamilies.overpassSemiBold,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      multiline: {
        height: '100%',
      },
      inputMultiline: {
        height: '100% !important',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },
    MuiInput: {
      input: {
        fontFamily: fontFamilies.overpassLight,
      },
    },
    MuiTextField: {
      root: {
        fontSize: 36,
      },
    },
    MuiAutocomplete: {
      inputFocused: {
        border: 'none',
        outline: 'none',
      },
    },
    MuiChip: {
      root: {
        fontSize: 36,
        height: 56,
        borderRadius: 36,
        padding: '0 6px',
      },
      sizeSmall: {
        fontSize: 16,
        height: 32,
        borderRadius: 16,
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: fontFamilies.overpassRegular,
        fontSize: 14,
        paddingLeft: 40,
      },
    },
    MuiFab: {
      extended: {
        boxShadow: 'none',
        backgroundColor: black,
        fontSize: 18,
        color: white,
        width: '100%',
        height: 48,
        textTransform: 'capitalize',
      },
    },
  },
  zIndex: {
    layer: maxLayers + 1,
    layerElevated: maxLayers + 2,
    GUI: maxLayers + 3,
    ...elevatedZIndices,
  },
}

export default ProjectIds.reduce(
  (acc, curr) => ({
    ...acc,
    [getId(curr)]: theme,
  }),
  {}
)
