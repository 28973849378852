const jssStyles = theme => ({
  container: {
    position: 'absolute',
  },
  navContainer: {
    backgroundColor: 'white',
    position: 'fixed',
    zIndex: theme.layerElevatedL,
  },
  content: {
    position: 'absolute',
  },
  item: {
    visibility: 'hidden',
    opacity: 0,
    zIndex: -1,
  },
  active: {
    display: 'block',
    visibility: 'visible',
    opacity: 1,
    zIndex: 10,
  },
})

export default jssStyles
