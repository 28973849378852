import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import find from 'lodash/find'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './ToggleItemStyles'

const ToggleItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  idExercise,
  ...props
}) => {
  const [activeState, setActiveState] = useState(false)

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  const findNodeByName = name => find(values(node.nodes), n => n.name === name)

  const defaultNode = findNodeByName('default__item')
  const activeNode =
    findNodeByName('active__item') || findNodeByName('color__item')

  const defaultToggleClassNames = classNames({
    [classes.toggleIcon]: true,
    [classes.showIcon]: !activeState
  })

  const activeToggleClassNames = classNames({
    [classes.toggleIcon]: true,
    [classes.showIcon]: activeState
  })

  const toggleActiveState = () => {
    const newActiveState = !activeState
    setActiveState(newActiveState)

    const nodeId = get(projectState, 'data.globalNodeIds.Favorites')

    const newFavorites = {
      ...(get(projectState, 'liveState.favorites') || {}),
      [idExercise]: newActiveState
    }

    onProjectStateChange && onProjectStateChange(newFavorites, nodeId)
  }

  return (
    <div
      className={classes.container}
      style={containerStyles}
      onClick={toggleActiveState}
    >
      <>
        <div className={defaultToggleClassNames}>
          <Node
            node={defaultNode}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...props}
          />
        </div>
        <div className={activeToggleClassNames}>
          <Node
            node={activeNode}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...props}
          />
        </div>
      </>
    </div>
  )
}

ToggleItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  idExercise: PropTypes.string
}

export default withStyles(styles)(ToggleItem)
