const jssStyles = theme => ({
  container: {
    position: 'absolute',
    display: 'flex',

    '& img': {
      userDrag: 'none',
      pointerEvents: 'none',
    },
  },
  image: {
    height: 'auto',
    width: '100%',
    pointerEvents: 'none',
  },
  backgroundImage: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
})

export default jssStyles
