const jssStyles = theme => ({
  container: {
    position: 'absolute',
  },
  tabPanel: {
    position: 'relative',
  },
  boxSample: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  tabPanelContainer: {
    position: 'relative',
    height: '100%',

    '& > *': {
      height: '100%',
    },
  },
})

export default jssStyles
