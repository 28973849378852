import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { motion } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import useNodeProps from 'hooks/useNodeProps'
import { VoidNode } from 'constants/general'

import TextNode from './nodes/TextNode'
import ImageNode from './nodes/ImageNode'
import HotspotNode from './nodes/HotspotNode'
import RectangleNode from './nodes/RectangleNode'
import ComponentNode from './nodes/ComponentNode'

import styles from './NodeStyles'

const Node = ({
  classes,
  node,
  projectState,
  actions,
  onHotspotChange,
  onProjectStateChange: onProjectState,
  style,
  motionProps,
  scaleRatio,
  viewRect,
  ...props
}) => {
  if (node.name === VoidNode) {
    return null
  }

  const {
    node: processedNodeAndFrame,
    nodeProps,
    nodeStyles,
    nodeMotionProps,
    onProjectStateChange,
  } = useNodeProps(node, projectState, onProjectState, style, {
    scaleRatio,
    viewRect,
    motionProps,
  })

  const handleProjectStateChange = (value, nodeId = null, stateProps = {}) =>
    onProjectStateChange &&
    onProjectStateChange(value, nodeId || node.id, {
      ...stateProps,
      nodeProps,
    })

  const handleHotspotChange = () => {
    // TODO: Add animation in the transition
    const viewId = get(node, 'value.destinationViewID')

    onHotspotChange && onHotspotChange(viewId)
  }

  if (node.type === 'component') {
    return (
      <ComponentNode
        node={processedNodeAndFrame}
        projectState={projectState}
        onHotspotChange={onHotspotChange}
        onProjectStateChange={handleProjectStateChange}
        style={nodeStyles}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        {...props}
        {...nodeProps}
      />
    )
  } else if (node.type === 'text') {
    return (
      <TextNode
        node={processedNodeAndFrame}
        projectState={projectState}
        onProjectStateChange={handleProjectStateChange}
        style={nodeStyles}
        {...props}
        {...nodeProps}
      />
    )
  } else if (node.type === 'image') {
    return (
      <ImageNode
        node={processedNodeAndFrame}
        projectState={projectState}
        onProjectStateChange={handleProjectStateChange}
        style={nodeStyles}
        {...props}
        {...nodeProps}
      />
    )
  } else if (node.type === 'hotspot') {
    return (
      <HotspotNode
        node={processedNodeAndFrame}
        projectState={projectState}
        onChange={handleHotspotChange}
        onProjectStateChange={handleProjectStateChange}
        style={nodeStyles}
        {...props}
        {...nodeProps}
      />
    )
  } else if (
    (node.type === 'shape' && node.shapeType === 'rectangle') ||
    node.shapeType === 'oval' ||
    node.shapeType === 'line'
  ) {
    return (
      <RectangleNode
        node={processedNodeAndFrame}
        projectState={projectState}
        onProjectStateChange={handleProjectStateChange}
        style={nodeStyles}
        {...props}
        {...nodeProps}
      />
    )
  } else if (nodeMotionProps) {
    return (
      <motion.div {...nodeMotionProps} {...props}>
        {nodeResult}
      </motion.div>
    )
  }

  return null
}

Node.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  motionProps: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
}

export default withStyles(styles)(Node)
