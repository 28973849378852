const jssStyles = theme => ({
  container: {},
  gameContainer: {
    marginBottom: 16,
    '& > *': {
      width: '100%',
    },
  },
})

export default jssStyles
