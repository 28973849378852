const jssStyles = {
  ui: {
    root: {
      width: 42,
      height: 26,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: 'white',
        '& + $track': {
          backgroundColor: '#069aff',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#069aff',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: '1px solid #a2a2a2',
      backgroundColor: '#a2a2a2',
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
  },
}

export default jssStyles
