const AnimationTransition = 'ease .2s'

const jssStyles = theme => ({
  container: {
    position: 'absolute',
    background: '#fff',
    border: '1px solid rgb(239, 239, 239)',
    borderRadius: 8,
    boxShadow: 'rgb(242, 242, 242) 0px 2px 4px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    transition: `height ${AnimationTransition}`,
    '& img': {
      transition: `transform ${AnimationTransition}`
    }
  },
  content: {
    '& > *': {
      marginBottom: 6
    }
  },
  expanded: {
    '& img': {
      transform: 'translate(10px, 66px) scale(1.9)'
    }
  },
  imageContainer: {
    position: 'absolute'
    // transform: 'translateY(76px) scale(1.5)'
  },
  tag: {
    position: 'relative',
    borderRadius: 9,
    background: 'yellow',
    width: 'fit-content',
    padding: '2px 8px'
  },
  hotspot: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})

export default jssStyles
