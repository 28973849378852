import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import storiesConfig from 'config/stories'

import GoogleAuthPage from 'pages/GoogleAuthPage'

import styles from './StoriesPageStyles'

const RouteParams = {
  ProjectName: 'projectName',
}

const StoriesPage = ({ classes, routeParams, location }) => {
  const [project, setProject] = useState(null)
  const [path, setPath] = useState('')

  useEffect(() => {
    const projectName = get(routeParams, RouteParams.ProjectName)
    setProject(storiesConfig[projectName])

    const urlParams = new URLSearchParams(location.search)
    const id = urlParams.get('id')
    if (!id) return
    setPath(`/?path=/story/${id}`)
  }, [])

  if (!project) return null

  return (
    <GoogleAuthPage validateDomain={project.domain}>
      <iframe
        className={classes.iframe}
        title="Story Page"
        src={`${project.url}${path}`}
      />
    </GoogleAuthPage>
  )
}

StoriesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  routeParams: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.shape({
    loginWithGoogle: PropTypes.func.isRequired,
  }),
}

const mapStateToProps = (state, ownProps) => ({
  routeParams: ownProps.match.params,
})

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withStyles(styles)
)(StoriesPage)
