import React, { useState, useEffect } from 'react'
import camelCase from 'lodash/camelCase'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './CapacityCardStyles'

const CapacityCard = ({ classes, title, imageSrc, style, onCardClick }) => {
  const [id, setId] = useState(null)

  useEffect(() => {
    setId(camelCase(title))
  }, [title])

  return (
    <div
      className={classes.container}
      style={style}
      onClick={() => onCardClick && onCardClick(id)}
    >
      <div className={classes.imageContainer}>
        <img src={imageSrc} alt={title} />
      </div>
      <div className={classes.labelContainer}>{title}</div>
    </div>
  )
}

CapacityCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  style: PropTypes.object,
  onCardClick: PropTypes.func,
}

export default withStyles(styles)(CapacityCard)
