import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import get from 'lodash/get'
import values from 'lodash/values'
import { withTheme, withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './StickyUnitStyles'

const StickyUnit = ({
  classes,
  theme,
  node: nodeContainer,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  ...props
}) => {
  const containerStyles = {
    ...nodeContainer.style,
    ...processNodeFrame(nodeContainer, scaleRatio, viewRect),
    zIndex: theme.zIndex.layerElevated
  }

  const scrollState = get(projectState, 'liveState.scrollState')

  return (
    <div className={classes.container} style={containerStyles}>
      {values(nodeContainer.nodes).map(childNode => {
        const stickyUnitClassNames = classNames({
          [classes.stickyUnit]: true,
          [classes.active]:
            (!scrollState && childNode.name === 'Ads') ||
            scrollState === childNode.name
        })

        return (
          <div key={childNode.id} className={stickyUnitClassNames}>
            <Node
              node={childNode}
              scaleRatio={scaleRatio}
              viewRect={viewRect}
              {...props}
            />
          </div>
        )
      })}
    </div>
  )
}

StickyUnit.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default compose(withTheme, withStyles(styles))(StickyUnit)
