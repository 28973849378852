const jssStyles = theme => ({
  container: {
    position: 'absolute',
    borderRadius: '100%',
    overflow: 'hidden'
  },
  toggleIcon: {
    opacity: 0,
    zIndex: 5
  },
  showIcon: {
    opacity: 1,
    zIndex: 10
  }
})

export default jssStyles
