import firebase, { provider } from 'api/firebase'

import { INIT_USER } from 'constants/actionTypes'

export function initUser(user) {
  return async dispatch => {
    if (!user) return

    dispatch({
      type: INIT_USER,
      user,
    })
  }
}

export function signOutUser() {
  return async dispatch => {
    await firebase.auth().signOut()

    dispatch({
      type: INIT_USER,
      user: null,
    })
  }
}

export function loginWithGoogle() {
  return async dispatch => {
    firebase
      .auth()
      .getRedirectResult()
      .then(({ user }) => dispatch(initUser(user)))

    // Start a sign in process for an unauthenticated user.
    provider.addScope('profile')
    provider.addScope('email')
    firebase.auth().signInWithRedirect(provider)
  }
}
