import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import find from 'lodash/find'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './ShoppingItemsStyles'

const ShoppingItems = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  style,
  ...props
}) => {
  const findNodeByName = (name, optNode) =>
    find(values((optNode || node).nodes), ch => ch.name === name)

  const renderBasicNodeByName = (name, basicNode, value, otherNodeProps) => {
    const nameNode = findNodeByName(name, basicNode)

    if (!nameNode) return

    const nodeValueInjected = {
      ...nameNode
    }

    if (value) nodeValueInjected.value = value

    return (
      <Node
        node={nodeValueInjected}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        {...props}
        {...otherNodeProps}
      />
    )
  }

  const shoppingCart = get(projectState, 'liveState.shoppingCart')
  const itemNode = findNodeByName('item')

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(shoppingCart).map(product => {
        return (
          <div className={classes.item} key={product.label}>
            {renderBasicNodeByName('title', itemNode, product.label)}
            {renderBasicNodeByName(
              'description',
              itemNode,
              product.description
            )}
            {renderBasicNodeByName('price', itemNode, `$${product.price}`)}
            {renderBasicNodeByName(
              'totalprice',
              itemNode,
              `$${product.price * product.amount}`
            )}
            {renderBasicNodeByName(
              'nitems',
              itemNode,
              `${product.amount} items`
            )}
            {renderBasicNodeByName('tile', itemNode, null, {
              url: product.imageUrl
            })}
          </div>
        )
      })}
    </div>
  )
}

ShoppingItems.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(ShoppingItems)
