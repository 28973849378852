import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import theme from 'styles/muiTheme'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import {
  getId,
  fetchFeedState,
  getGameItemDynamicY,
  getExerciseData,
  getGameDataFeedComponentProps,
  getGameIdFromDataFeedState,
  getCardNameFromDataFeedState,
} from '../utils'
import { StyleMixins, Properties } from '../constants'

import GameExerciseProjectId from './GameExercise'

const ProjectId = getId('650E2A0A94A')

const Actions = {
  OpenExerciseView: [
    {
      property: LiveStateProperties.ForceViewScrollTo,
      value: {
        viewId: ProjectId,
        value: 0,
      },
    },
    {
      property: LiveStateProperties.DisableScroll,
      value: true,
    },
    {
      property: projectStatesTypes.ENABLE_LINKED_PROJECT,
      value: GameExerciseProjectId,
    },
  ],
}

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[B] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.ExercisesData,
          value: async state => getExerciseData(state),
        },
      ],
    },
  },
  '611F6AD2-1540-4772-AB51-01C28A08E1BE': {
    // `hero__image`
    conditionalProps: {
      url: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'image',
          'attachment'
        ),
    },
  },
  '9D721DD3-8CF3-4CAA-A2BE-A0147C2F1180': {
    // `HELLO` title
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
      textTransform: 'uppercase',
    },
  },
  'B885F1D0-ECEF-4A1C-93FD-5239952DB18B': {
    // `KWAMIN` subtitle
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
      textTransform: 'uppercase',
    },
  },
  '4B02C9BD-162C-4AD7-93DE-43FFCCEFA3C9': {
    // `fav-chip__item` component
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: true,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
      ],
    },
    styleProps: state => {
      const { liveState } = state

      const isFavorited = Object.keys(liveState).find(
        key => key.includes(Properties.Favorited) && liveState[key]
      )

      return {
        display: isFavorited ? 'block' : 'none',
      }
    },
  },
  '2C91CCC8-EE88-4CE9-A56F-D4607FE94EDD': {
    // `fav-modal__item`
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: false,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
  },
  '44EC9034-761B-41A6-9476-0D4A8AAE3575': {
    // `program__item` initial state
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
  '04F4D4DD-635C-44BC-BF78-0D6571CCF8C8': {
    // `program__item` title
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '3D5E09CA-C836-4335-94F4-824F95A79E03': {
    // `program__item` subtitle
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  'FCA15671-EE66-4E41-8A46-5EF20632339F': {
    // `program__item--checked` message
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'successMessage',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '94F4ABE6-1665-4A37-8EF0-700313EA95EC': {
    // ProgramView `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '42650F84-0756-4DA0-8983-B7831E00BC49': {
    // ProgramView `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '798088F7-4692-402C-84CA-373B027A2F75': {
    // ProgramView `START button`
    styleProps: {
      zIndex: 50,
    },
  },
  '1E3C94A3-0752-4211-87BA-A69A1DE7E0E2': {
    // ProgramView `START button HOTSPOT`
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '70831A12-564A-4C57-A771-F90BA0569C0D': {
    // ProgramView `close  icon HOTSPOT`
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '02076177-C3E4-4C77-A75E-5AB28D08E416': {
    // First motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[0].text') ||
        'This method suggests meditation helps us bounce back from stress. Give it a try.',
    },
  },
  '34432ABA-DE3A-46AE-B943-10B2646D768B': {
    // Second motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[1].text') ||
        'You think around 70,000 thoughts a day! Reframing the unhelpful ones can lower stress. Try it out.',
    },
  },
  'CCA2AD9C-89C1-4BBE-9110-7302FC15A130': {
    // Third motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[2].text') ||
        'You think around 70,000 thoughts a day! Reframing the unhelpful ones can lower stress. Try it out.',
    },
  },
  '57587E67-E671-49B0-A922-98069264AA83': {
    // `games-list__item` in Fav Modal
    componentProps: {
      gamesData: state => {
        const { liveState } = state

        const exercisesData = get(liveState, Properties.ExercisesData)

        const favs = Object.keys(liveState).filter(
          key => key.includes(Properties.Favorited) && liveState[key]
        )

        const favorites = favs.map(f => f.split(Properties.Favorited)[1])

        if (!favorites || !exercisesData) return null

        return favorites.reduce((acc, currId) => {
          const exercise = exercisesData.find(e => e.id === currId)

          if (!exercise) return acc

          acc.push(exercise)

          return acc
        }, [])
      },
      onChange: state => ({ e, id, value }) => {
        if (e === Properties.GameCardEvents.FavChange) {
          return {
            property: `${Properties.Favorited}${id}`,
            value,
          }
        }

        if (e === Properties.GameCardEvents.CardClicked) {
          return [
            {
              property: 'isFavModalActive',
              value: false,
            },
            {
              property: LiveStateProperties.ForceViewScrollTo,
              value: {
                viewId: ProjectId,
                value: 0,
              },
            },
            {
              property: LiveStateProperties.DisableScroll,
              value: true,
            },
            {
              property: Properties.ActiveGameId,
              value: id,
            },
            {
              property: projectStatesTypes.ENABLE_LINKED_PROJECT,
              value: GameExerciseProjectId,
            },
          ]
        }
      },
    },
  },
  '724E1537-C86D-44B3-ACE6-C04B5031D3B3': {
    // `game__item` Unchecked First card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`
      )
        ? 'none'
        : '',
    }),
  },
  '8D1B787A-8FD5-4EBA-B0BC-F7FB7C81965E': {
    // `game__item` Checked First card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`
      )
        ? ''
        : 'none',
    }),
  },
  '8479F5C1-4D18-401E-9315-ACD3B50CE64C': {
    // `game-card__item` First Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'firstCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  '23A4D5C5-2C7C-41D5-A9B4-8E737D0A596B': {
    // `game__item--checked` First Card Text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'firstCardName'),
    },
  },
  '7F2EF0C7-0493-4599-ADBB-9247F2216971': {
    // `game__item` Unchecked Second card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(
          state,
          'secondCardName'
        )}Checked`
      )
        ? 'none'
        : '',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [`${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`],
        204
      ),
    }),
  },
  'BF415948-A0B6-4BCC-AE6E-84265C7CC525': {
    // `game__item` Checked First card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(
          state,
          'secondCardName'
        )}Checked`
      )
        ? ''
        : 'none',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [`${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`],
        199
      ),
    }),
  },
  'D3CA7713-E411-4EF5-9DD6-72F8D34D7EA9': {
    // `game-card__item` Second Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'secondCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  '624679C8-EC35-4C10-91FD-9AEDF24A17A7': {
    // `game__item--checked` Second Card Text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'secondCardName'),
    },
  },
  '1ACFB3DA-F9F0-4C4E-946F-0B65A8D0627C': {
    // `game__item` Unchecked Third card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`
      )
        ? 'none'
        : '',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
        ],
        398
      ),
    }),
  },
  '447BD046-8296-4DDC-BF76-98CA25F5049D': {
    // `game__item` Checked Third card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`
      )
        ? ''
        : 'none',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
        ],
        396
      ),
    }),
  },
  '42CDC7AC-D883-48F0-89EB-0549952ED482': {
    // `game-card__item` Third Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'thirdCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  'A41A4F6E-6ABC-4369-82D3-909626AE28A2': {
    // `game__item--checked` Third Card Text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'thirdCardName'),
    },
  },
  '97A12B08-6AE0-4151-9050-8EE72D212E5E': {
    // Game Exercise Linked View
    styleProps: {
      zIndex: theme.zIndex.layerElevatedL,
    },
  },
  '5B828C7D-94FC-4C47-819B-CF70BCC93BBD': {
    // `content__item` 1
    frameProps: state => ({
      y: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 520
        : 630,
      height: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        1430 //  1354 + 76 (Home Feed Height)
      ),
    }),
  },
  '01561422-D140-43B3-B7D1-3E2196C02DDC': {
    // `content__item` 2
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        554
      ),
    }),
  },
  '36162D7E-62D7-47EB-8811-F5F80ADB062C': {
    // `house-tree` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fhouse-tree.svg?alt=media',
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
}

export default ProjectId
