import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const DefaultContextValues = {
  getData: () => {},
  setData: () => {},
}

export const ProjectStateContext = createContext(DefaultContextValues)

const ProjectStateContextProvider = ({ children }) => {
  const [currentData, setCurrentData] = useState({})

  const getData = key => currentData[key]

  const setData = ({ key, value }) =>
    setCurrentData({
      ...currentData,
      [key]: value,
    })

  return (
    <ProjectStateContext.Provider
      value={{
        getData,
        setData,
      }}
    >
      {children}
    </ProjectStateContext.Provider>
  )
}

ProjectStateContextProvider.propTypes = {
  children: PropTypes.object,
}

export default ProjectStateContextProvider
