const jssStyles = theme => ({
  container: {
    position: 'absolute',
    zIndex: theme.zIndex.layerElevated,
    transform: 'translateZ(0)',
  },
  icon: {
    position: 'absolute',
  },
})

export default jssStyles
