import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import get from 'lodash/get'
import values from 'lodash/values'
import { withTheme, withStyles } from '@material-ui/core/styles'

import { processNodeFrame, formatNumberWithComma } from 'utils'

import Node from 'components/Node'

import styles from './PointsChipItemStyles'

const NodeNames = {
  Number: 'number'
}

const PointsChipItem = ({
  classes,
  theme,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  onChange,
  ...props
}) => {
  const renderNumber = node => {
    const points = get(projectState, 'liveState.points')

    const processedNode = {
      ...node,
      value: points ? formatNumberWithComma(points) : node.value
    }

    return (
      <div key={node.id}>
        <Node
          node={processedNode}
          className={classes.icon}
          viewRect={viewRect}
          scaleRatio={scaleRatio}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          {...props}
        />
      </div>
    )
  }

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect, true),
    zIndex: theme.zIndex.layerElevated
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(containerNode.nodes).map(node => {
        if (node.name === NodeNames.Number) return renderNumber(node)

        return (
          <Node
            key={node.id}
            node={node}
            viewRect={viewRect}
            scaleRatio={scaleRatio}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...props}
          />
        )
      })}
    </div>
  )
}

PointsChipItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  onChange: PropTypes.func
}

export default compose(withTheme, withStyles(styles))(PointsChipItem)
