import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { checkIsDesktopDevice } from 'utils'

import View from 'components/View'

const ScaleContainer = ({ view, ...props }) => {
  const [scaleRatio, setScaleRatio] = useState(null)
  const [windowRect, setWindowRect] = useState(null)
  const [isDesktopExperience, setIsDesktopExperience] = useState(null)

  const isDesktopDevice = useMemo(() => checkIsDesktopDevice(), [scaleRatio])

  const isExperienceNotSupported = () => !isDesktopDevice && isDesktopExperience

  const calcScaleRatio = () => {
    const isDesktop = checkIsDesktopDevice()
    const projectTypeExperience = get(props, 'projectState.data.typeExperience')
    const isDesktopExp =
      projectTypeExperience !== 'mobile' && view.frame.width > view.frame.height

    const containerWidth =
      (isDesktop && isDesktopExp) || !isDesktop
        ? window.innerWidth
        : view.frame.width

    setScaleRatio(containerWidth / view.frame.width)
    setIsDesktopExperience(isDesktopExp)

    setTimeout(() => {
      setWindowRect({
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      })
    })
  }

  useEffect(() => {
    calcScaleRatio()
    window.addEventListener('resize', calcScaleRatio)

    return () => {
      window.removeEventListener('resize', calcScaleRatio)
    }
  }, [])

  if (!windowRect) return null

  if (isExperienceNotSupported()) {
    // TODO: Create PAGE for not supported experience
    return null
  }

  return (
    <View
      scaleRatio={scaleRatio}
      view={view}
      id={view.id}
      windowRect={windowRect}
      isDesktopDevice={isDesktopDevice}
      isDesktopExperience={isDesktopExperience}
      {...props}
    />
  )
}

ScaleContainer.propTypes = {
  view: PropTypes.object,
}

export default ScaleContainer
