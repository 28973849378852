import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { useSpring, motion, useTransform } from 'framer-motion'

import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, findNodeByName } from 'utils'

import Node from 'components/Node'

import styles from './ForegroundBackgroundScrollStyles'

const NodeNames = {
  FramerScrollItem: 'framer-scroll__item',
  ScrollItem: 'scroll__item',
  HeaderItem: 'header__item',
  ContentItem: 'content__item',
  BackgroundItem: 'background__item',
  ForegroundItem: 'foreground__item',
}

const ScrollContainerStyles = {
  overflow: 'hidden',
  transform: 'translateZ(0)',
  cursor: 'grab',
}

const ForegroundBackgroundScroll = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  style,
  ...otherProps
}) => {
  const [contentNode, setContentNode] = useState(null)
  const [scrollNodeItem, setScrollNodeItem] = useState(null)
  const [backgroundNodeItem, setBackgroundNodeItem] = useState(null)
  const [foregroundNodeItem, setForegroundNodeItem] = useState(null)

  const ease = [0.6, 0.05, -0.01, 0.99]
  const y = useSpring(0, { stiffness: 300, damping: 200, ease })
  const scaleForeground = useTransform(y, [-200, 0, 200], [0.25, 1, 1.25])
  const scaleBackground = useTransform(y, [-200, 0, 200], [1, 1, 1.25])
  const heightBackground = useTransform(y, [-200, 0, 200], [100, 475, 475])

  useEffect(() => {
    const framerScrollItem = findNodeByName(
      containerNode,
      NodeNames.FramerScrollItem
    )

    const scrollItem = findNodeByName(framerScrollItem, NodeNames.ScrollItem)

    const headerItem = findNodeByName(scrollItem, NodeNames.HeaderItem)
    const contentItem = findNodeByName(scrollItem, NodeNames.ContentItem)
    const backgroundItem = findNodeByName(headerItem, NodeNames.BackgroundItem)
    const foregroundItem = findNodeByName(headerItem, NodeNames.ForegroundItem)

    setContentNode(contentItem)
    setScrollNodeItem(scrollItem)
    setBackgroundNodeItem(backgroundItem)
    setForegroundNodeItem(foregroundItem)
  }, [])

  useEffect(() => {
    y.onChange(() => {
      // console.log(y.get())
    })
  }, [y])

  if (!scrollNodeItem) return null

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    ...style,
    ...ScrollContainerStyles,
  }

  const foregroundStyles = {
    ...processNodeFrame(foregroundNodeItem, scaleRatio, viewRect),
    top: 0,
    left: 0,
    y,
    scale: scaleForeground,
    transformOrigin: 'center',
  }

  const backgroundStyles = {
    ...processNodeFrame(backgroundNodeItem, scaleRatio, viewRect),
    backgroundColor: backgroundNodeItem.style.backgroundColor,
    overflow: 'hidden',
    scale: scaleBackground,
    height: heightBackground,
  }

  return (
    <>
      <motion.div className={classes.container} style={backgroundStyles}>
        <motion.div className={classes.container} style={foregroundStyles}>
          <Node
            node={foregroundNodeItem}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...otherProps}
          />
        </motion.div>
      </motion.div>

      <motion.div
        className={classes.container}
        style={containerStyles}
        whileTap={{ cursor: 'grabbing' }}
      >
        <motion.div
          style={{
            width: containerStyles.width,
            height: scrollNodeItem.frame.height,
          }}
          y={y}
          drag="y"
          dragConstraints={{
            top: -scrollNodeItem.frame.height + containerStyles.height,
            bottom: 0,
          }}
        >
          <Node
            node={contentNode}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...otherProps}
          />
        </motion.div>
      </motion.div>
    </>
  )
}

ForegroundBackgroundScroll.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(ForegroundBackgroundScroll)
