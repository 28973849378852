import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import raf from 'raf'
import { withStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './SearchInputStyles'

const CloseIconSize = 17

const _ = {
  rafHandle: null
}

const SearchInput = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange,
  ...props
}) => {
  // TODO: Figure out why `inputRef` is null
  const inputRef = React.createRef()
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(null)

  useEffect(() => {
    _.rafHandle = raf(() => {
      onProjectStateChange && onProjectStateChange(inputValue)
    })
  }, [inputValue])

  const focus = () => inputRef.current.focus()

  const handleTextChange = e => setInputValue(e.target.value)

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  const closeIconStyles = {
    fontSize: CloseIconSize * scaleRatio,
    marginTop: -(CloseIconSize / 2) * scaleRatio,
    height: CloseIconSize * scaleRatio
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(node.nodes).map(childNode => {
        if (childNode.type === 'text') {
          const inputStyles = {
            ...childNode.style,
            ...processNodeFrame(childNode, scaleRatio, viewRect)
          }

          return (
            <input
              onChange={handleTextChange}
              key={childNode.id}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setTimeout(() => setIsFocused(false), 200)}
              className={classes.input}
              type="text"
              placeholder={childNode.value}
              style={inputStyles}
              ref={inputRef}
              value={inputValue}
              spellCheck="false"
            />
          )
        }

        return (
          <Node
            key={childNode.id}
            node={childNode}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            {...props}
          />
        )
      })}
      {isFocused && (
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => setInputValue('')}
          style={closeIconStyles}
        />
      )}
    </div>
  )
}

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(SearchInput)
