import React from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import ToriiMagicMotion from '../ToriiMagicMotion'

import styles from './FlipCardsStyles'

const FlipCards = ({ classes, node: containerNode, style, ...otherProps }) => (
  <div className={classes.container} style={style}>
    {values(containerNode.nodes).map(node => (
      <ToriiMagicMotion key={node.id} node={node} {...otherProps} />
    ))}
  </div>
)

FlipCards.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object,
  style: PropTypes.object,
}

export default withStyles(styles)(FlipCards)
