export default {
  api: {
    host: 'https://torii-live-dev.appspot.com'
  },
  firebase: {
    apiKey: 'AIzaSyDmKGRHC-z5CGKw4gaWqGZfHItWPMa37N0',
    authDomain: 'torii-live-dev.firebaseapp.com',
    databaseURL: 'https://torii-live-dev.firebaseio.com',
    projectId: 'torii-live-dev',
    storageBucket: 'torii-live-dev.appspot.com',
    messagingSenderId: '510918745685'
  },
  analytics: {
    id: 'UA-138992764-1',
    options: { titleCase: false }
  },
  localStorage: {
    reduxStateKey: 'DEV_toriiReduxState'
  }
}
