/* eslint-disable no-param-reassign */
import head from 'lodash/head'
import isArray from 'lodash/isArray'

const AirtableKey = 'keyzOeuOJqkn3oxcX'

const sanitizeJson = data => {
  if (!data || !data.records) return null

  return data.records.reduce((acc, curr) => {
    const dataField = Object.keys(curr.fields).reduce((accField, fieldKey) => {
      const fieldValue = curr.fields[fieldKey]

      if (typeof fieldValue === 'string') {
        accField[fieldKey] = fieldValue
      } else if (isArray(fieldValue)) {
        const f = head(fieldValue)
        if (f.type.includes('image/')) {
          accField[fieldKey] = f.url
        }
      }

      return accField
    }, {})

    acc.push(dataField)

    return acc
  }, [])
}

class AirtableAPI {
  fetch = async (tableKey, tableName, viewName) => {
    let url = `https://api.airtable.com/v0/${tableKey}/${tableName}`

    if (viewName) url += `?view=${viewName}`

    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization: `Bearer ${AirtableKey}`,
      },
    })

    const responseJson = await response.json()

    return sanitizeJson(responseJson)
  }
}

export default new AirtableAPI()
