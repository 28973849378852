import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FDINX7UJYA13')

export const data = {
  [ProjectId]: {
    '2-2': {
      componentProps: {
        onChange: state => ({ dragging }) => ({
          property: LiveStateProperties.DisableScroll,
          value: dragging,
        }),
      },
    },
  },
}

export default ProjectId
