import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B74416')

const CardProps = {
  story: {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta aliquet est eget condimentum. Suspendisse condimentum',
    imageSrc: '',
    tag: 'tag',
    user: {
      username: 'Waynes_Colts',
      avatarSrc:
        'https://s3-us-west-2.amazonaws.com/figma-alpha-api/img/a791/fcec/2e03a1086552a3cd3b85586210d15f8c',
    },
  },
}

const ExpandScrollMs = 300

const handleExpansion = (state, nodeId) => [
  {
    property: LiveStateProperties.DisableScroll,
    value: true,
  },
  {
    property: `${LiveStateProperties.MagicExpandedItem}_${nodeId}`,
    value: true,
  },
  {
    property: LiveStateProperties.ForceViewScrollTo,
    value: {
      viewId: state.projectId,
      scrollProps: {
        scrollTo: 0,
        duration: ExpandScrollMs * 2,
      },
    },
  },
]

const handleContraction = (state, nodeId) => [
  {
    property: LiveStateProperties.DisableScroll,
    value: false,
  },
  {
    property: `${LiveStateProperties.MagicExpandedItem}_${nodeId}`,
    value: false,
  },
]

const MagicNodeIds = {
  First: '44-28',
}

export const data = {
  [ProjectId]: {
    fontFamilies: ['Roboto', 'Overpass-Regular'],
    props: {
      onInitialState: [
        {
          property: LiveStateProperties.MagicExpandedItem,
          value: false,
        },
      ],
    },
  },
  '44-53': {
    // 1st story card
    eventProps: {
      onClick: state => handleExpansion(state, MagicNodeIds.First),
    },
  },
  '44-22': {
    // 2nd story card
    componentProps: {
      ...CardProps,
    },
  },
  '44-18': {
    // 3rd story card
    componentProps: {
      ...CardProps,
      story: {
        ...CardProps.story,
        description: null,
      },
    },
  },
  '46-3': {
    eventProps: {
      onClick: state => handleContraction(state, MagicNodeIds.First),
    },
  },
  '45-36': {
    // Item 1 - Mask for image
    styleProps: {
      overflow: 'hidden',
    },
  },
}

export default ProjectId
