import get from 'lodash/get'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import { isProductionInstance } from 'utils'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { fetchSectionsData } from './utils'
import { StyleMixins } from './constants'

const ProjectId = `CFE65EFA94A${isProductionInstance() ? '0' : ''}`

export const data = {
  '48E86E2A-B9E4-4878-BF92-A5E654E49EAD': {
    // Title
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  'FA9E0048-28D4-4975-99BC-2F4042923000': {
    // Description
    styleProps: {
      overflow: 'hidden',
    },
  },
  '78D3F18B-DA5D-4503-8C4A-C9FB8EC473C6': {
    // Image
    props: {
      isBackgroundImage: true,
    },
  },
  'DBA438A6-19ED-4280-ABF0-F1D6B8A638BA': {
    // Hotspot back to `Capacity Tabs`
    props: {
      onStateChange: () => [
        {
          property: projectStatesTypes.ACTIVE_VIEW_ID,
          value: '1F37D8BD94A',
        },
      ],
    },
  },
  '5BFC6800-1F4E-4173-9CFD-75ED2BECF8AB': {
    // Section repeater list
    dataProps: {
      data: async state => {
        const sectionsData = await fetchSectionsData(
          '5BFC6800-1F4E-4173-9CFD-75ED2BECF8AB',
          '64DE2893-5DDB-4CBA-82FA-0ED0DE49034F'
        )

        const tagLabel = get(state, 'liveState.capacityData.tagLabel')

        if (!tagLabel) return sectionsData

        const { data: sectionData, nestedData } = sectionsData

        const newSectionsData = []

        const newNestedData = nestedData.data.reduce((acc, curr, index) => {
          const sectionGames = filter(curr, d => d.tagLabel === tagLabel)
          if (isEmpty(sectionGames)) {
            return acc
          }

          acc.push(sectionGames)
          newSectionsData.push(sectionData[index])

          return acc
        }, [])

        return {
          ...sectionsData,
          data: newSectionsData,
          nestedData: {
            ...sectionsData.nestedData,
            data: newNestedData,
          },
        }
      },
    },
  },
}

export default ProjectId
