const fixedContainer = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  objectFit: 'cover'
}

const jssStyles = theme => ({
  container: {
    position: 'absolute',
    borderRadius: 14,
    overflow: 'hidden'
  },
  camera: {
    ...fixedContainer
  },
  cameraView: {
    ...fixedContainer
    // ...mirrorImage
  },
  cameraSensor: {
    ...fixedContainer
    // ...mirrorImage
  }
})

export default jssStyles
