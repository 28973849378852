const jssStyles = theme => ({
  container: {},
  tabPanel: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
})

export const UI = {
  tabs: theme => ({
    root: {
      paddingTop: 28,
    },
    indicator: {
      bottom: 1,
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > div': {
        height: 3,
        width: '100%',
        backgroundColor: theme.palette.primaryColor,
      },
    },
  }),
  tab: theme => ({
    root: {
      fontFamily: 'SairaCondensed-Regular',
      color: '#4A4A4A',
      fontSize: 18,
      letterSpacing: 0,
      lineHeight: '20px',
      textTransform: 'none',
      minWidth: 72,
      marginRight: 24,

      '& .MuiTouchRipple-child': {
        backgroundColor: theme.palette.primaryColor,
      },

      '&:hover': {
        // color: '#40a9ff',
        // opacity: 1,
      },
      '&$selected': {
        // color: '#1890ff',
      },
      '&:focus': {
        // color: '#40a9ff',
      },
    },
  }),
}

export default jssStyles
