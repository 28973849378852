import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId } from '../utils'
import { Properties } from '../constants'

const ProjectId = getId('FT4IODZ5FB160')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: state => [
        {
          property: 'disableScroll',
          value: true,
        },
        {
          property: Properties.OnboardingLibraryStep,
          value: 0,
        },
        {
          property: 'libraryDataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[B] Library 2.0',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
      ],
    },
  },
  '17-12': {
    // First item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )
      return {
        display: onboardingStep === 0 ? 'block' : 'none',
      }
    },
  },
  '17-21': {
    // Second item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )
      return {
        display: onboardingStep === 1 ? 'block' : 'none',
      }
    },
  },
  '17-27': {
    // Third item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )

      return {
        display: onboardingStep === 2 ? 'block' : 'none',
      }
    },
  },
  '17-11': {
    // First message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: 1,
        },
      ],
    },
  },
  '17-20': {
    // Second message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: 2,
        },
      ],
    },
  },
  '17-26': {
    // Third message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: null,
        },
        {
          property: 'disableScroll',
          value: false,
        },
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: ProjectId,
        },
      ],
    },
  },
  '17-9': {
    // First body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[0].text') ||
        'Welcome to the Library! Here you can explore trainings, breathing, meditations, and videos all in one place.',
    },
  },
  '17-18': {
    // Second body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[1].text') ||
        '“Topics” are collections of activities designed to help you reach a certain goal. Check them out here.',
    },
  },
  '17-24': {
    // Third body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[2].text') ||
        'Want to train something specific? You can filter the Library by capacities here.',
    },
  },
}

export default ProjectId
