const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  disabled: {
    display: 'none'
  },
  stack: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'translateZ(0)'
  },
  topStack: {
    backgroundColor: 'white',
    borderRadius: 8,
    zIndex: 10
  },
  bottomStack: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: -1
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%'
  },
  icon: {
    position: 'relative',
    color: '#fff',
    height: 46,
    width: 46,
    marginLeft: 12
  }
})

export default jssStyles
