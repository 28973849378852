const jssStyles = theme => ({
  container: {
    display: 'flex',

    '& > *:nth-child(2)': {
      display: 'none',
    },
  },
  activeContainer: {
    '& > *:nth-child(2)': {
      display: 'block',
    },
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    height: 42,
    background: '#fff',
    border: '1px solid rgb(238, 238, 238)',
    borderRadius: 8,
    position: 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      backgroundImage:
        'url(https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F581EEC9F94A%2FCC0CD93B-616B-4F2B-9D68-93A200ADB936%402x.png?alt=media)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      width: 17,
      height: 18,
      top: 12,
      left: 8,
    },
  },
  input: {
    color: '#A2A2A2',
    fontFamily: 'Saira-Regular',
    fontSize: 17,
    lineHeight: '26px',
    margin: 0,
    padding: 0,
    background: 'transparent',
    border: 'none',
    marginLeft: 32,
    width: 'calc(100% - 32px)',
  },
  cancelButton: {
    fontFamily: 'Saira-Regular',
    background: 'transparent',
    border: 'none',
    color: '#0276B4',
    width: 60,
    fontSize: 17,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  hide: {
    display: 'none',
  },
})

export default jssStyles
