import moment from 'moment'

import { isProductionInstance, getOrdinal } from 'utils'

import AirtableAPI from 'api/AirtableAPI'

export const ProjectIds = ['E415185E94A', 'E1AD94F294A']

const data = {
  typeExperience: 'mobile',

  [`E415185E94A${isProductionInstance() ? '0' : ''}`]: {
    props: {
      onInitialState: [
        {
          property: 'exercisesData',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appk3ANW42XtTXtor',
              'Table 1',
              'Grid view'
            )

            const todayDay = moment().format('D')
            const date = `${moment().format('on dddd, MMMM ')}${getOrdinal(
              todayDay
            )}`

            return airtableData.map(d => ({
              ...d,
              date,
            }))
          },
        },
      ],
    },
  },
}

export default ProjectIds.reduce((acc, curr) => {
  const projectId = `${curr}${isProductionInstance() ? '0' : ''}`
  acc[projectId] = data
  return acc
}, {})
