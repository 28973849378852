import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import { LiveStateProperties } from 'constants/general'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId, fetchFeedState, getGameDataFeedComponentProps } from '../utils'
import { StyleMixins, Properties } from '../constants'

import HomeFeed2ProjectId from './HomeFeed2'

const ProjectId = getId('AC72B18A94A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[A] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.OnboardingHomeFeed,
          value: 1,
        },
      ],
    },
  },
  '420C81F9-1BA7-4C4F-A14F-24259F89C057': {
    // First `Onboarding` item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: onboardingStep && onboardingStep === 1 ? 'block' : 'none',
      }
    },
  },
  '60EF0E63-0703-4DE6-953B-021D3273F08A': {
    // First `Next button` HOTSPOT in Onboarding dialog
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingHomeFeed,
          value: 2,
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: [ProjectId, HomeFeed2ProjectId],
            scrollProps: {
              scrollTo: 400,
              duration: 400,
            },
          },
        },
        {
          property: 'openCardItem',
          value: true,
        },
      ],
    },
  },
  '3638F9D4-B584-428B-BD44-20CF97C01EAD': {
    // Card component
    componentProps: {
      ...getGameDataFeedComponentProps('firstCardName'),
      open: async state => get(state, 'liveState.openCardItem'),
    },
  },
  'C82D5220-D1D7-4144-8B7C-873E52A9FD9C': {
    // First Onboarding Program Card `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '9B81099F-4664-40BC-ACCF-99FF05A3A213': {
    // First Onboarding Program Card `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '4FA353B3-685B-4938-831F-03B285C3827B': {
    // Second `Onboarding` item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: onboardingStep && onboardingStep === 2 ? 'block' : 'none',
      }
    },
  },
  'A6EAD8F1-F66C-4467-82E8-BFDC008CA961': {
    // Second `Done` HOTSPOT in Onboarding dialog
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingHomeFeed,
          value: null,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: ProjectId,
        },
      ],
    },
  },
  'E062F289-6A94-47DF-A2DC-B14AA0F4DA4B': {
    // First body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Onboarding[0].text') ||
        'We created a personalized program based on your assessment. Learn more here.',
    },
  },
  '5D6D8483-4C31-4B0C-BBC1-BE9FD6543833': {
    // Second body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Onboarding[1].text') ||
        'Swipe to see the science behind your assigned practices or save them to your favorites.',
    },
  },
}

export default ProjectId
