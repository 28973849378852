import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, findNodeByName, sortNodesByZIndex } from 'utils'

import Node from 'components/Node'

import styles from './TabsItemStyles'

const TabsItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange,
  ...props
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [tabHeaderItems, setTabHeaderItems] = useState(null)
  const [tabContentItems, setTabContentItems] = useState(null)
  const [tabHeaderSelection, setTabHeaderSelection] = useState(null)

  useEffect(() => {
    // Setting header item values
    const headerItemNode = findNodeByName(node, 'header__item')
    const headerSelectionNode = findNodeByName(headerItemNode, 'selection')
    const headerTabNodes = sortNodesByZIndex(
      findNodeByName(headerItemNode, 'item')
    )
    setTabHeaderItems(headerTabNodes)
    setTabHeaderSelection(headerSelectionNode)

    // Setting content item values
    const contentItemNode = findNodeByName(node, 'content__item')
    setTabContentItems(sortNodesByZIndex(contentItemNode))
  }, [])

  const renderHeader = () => {
    if (!tabHeaderItems) return

    const itemNodeProcessed = processNodeFrame(
      tabHeaderItems[tabIndex],
      scaleRatio,
      viewRect
    )
    const selectionNodeProcessed = processNodeFrame(
      tabHeaderSelection,
      scaleRatio,
      viewRect
    )

    const offsetWidth = 30 * scaleRatio

    const width = itemNodeProcessed.width + offsetWidth
    const x = itemNodeProcessed.left + itemNodeProcessed.width / 2 - width / 2

    // Rendering selection node
    const selectionStyles = {
      ...tabHeaderSelection.style,
      transform: `translateX(${x}px)`,
      width,
      top: selectionNodeProcessed.top,
      height: selectionNodeProcessed.height,
    }

    const headerSelectionNode = (
      <div className={classes.selection} style={selectionStyles} />
    )

    // Rendering item nodes
    const headerItemsNodes = tabHeaderItems.map((item, itemIndex) => (
      <div
        key={item.id}
        className={classes.tab}
        onClick={() => setTabIndex(itemIndex)}
      >
        <Node
          node={item}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          onProjectStateChange={onProjectStateChange}
          {...props}
        />
      </div>
    ))

    const headerItemNode = findNodeByName(node, 'header__item')

    headerItemNode.frame.x = 0

    const headerStyles = {
      ...headerItemNode.style,
      ...processNodeFrame(headerItemNode, scaleRatio, viewRect, true),
    }

    return (
      <div className={classes.header} style={headerStyles}>
        {headerItemsNodes}
        {headerSelectionNode}
      </div>
    )
  }

  const renderContent = () => {
    if (!tabContentItems) return null

    const contentItemNode = findNodeByName(node, 'content__item')

    const contentStyles = {
      ...contentItemNode.style,
      ...processNodeFrame(contentItemNode, scaleRatio, viewRect),
    }

    return (
      <div className={classes.container} style={contentStyles}>
        {tabContentItems.map((item, itemIndex) => {
          const contentClassNames = classNames({
            [classes.content]: true,
            [classes.active]: tabIndex === itemIndex,
          })

          return (
            <div key={item.id} className={contentClassNames}>
              <Node
                node={item}
                scaleRatio={scaleRatio}
                viewRect={viewRect}
                onProjectStateChange={onProjectStateChange}
                {...props}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect),
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {renderHeader()}
      {renderContent()}
    </div>
  )
}

TabsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
}

export default withStyles(styles)(TabsItem)
