import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash/get'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, findNodeByName } from 'utils'

import Node from 'components/Node'

import styles from './DaysItemStyles'

const NodeNames = {
  Day: 'day',
  Selector: 'selector',
}

const DaysItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  projectState,
  onProjectStateChange,
  viewRect,
  ...props
}) => {
  const { currentDate, todayDate } =
    get(projectState, 'liveState.programPoints') || {}

  const getDayFromDate = date => moment(date).format('D')

  const renderNode = node => (
    <Node
      key={node.id}
      node={node}
      scaleRatio={scaleRatio}
      viewRect={viewRect}
      {...props}
    />
  )

  const handleDayClick = dayValue => {
    if (dayValue > 6) return
    onProjectStateChange && onProjectStateChange(`03/0${dayValue}/20`)
  }

  const renderDayNode = node => {
    if (!currentDate || !todayDate) return null

    const currentDay = getDayFromDate(currentDate)
    const todayDay = getDayFromDate(todayDate)

    const isCurrentDay = node.value === currentDay

    const dayFrame = processNodeFrame(node, scaleRatio, viewRect)

    const dayStyles = {
      ...dayFrame,
    }

    const processedNode = { ...node, frame: { ...node.frame, x: 0, y: 0 } }

    if (node.value <= todayDay || isCurrentDay) {
      processedNode.style.color = '#272727'
    }

    const dayClassNames = classNames({
      [classes.day]: true,
      [classes.currentDay]: isCurrentDay,
    })

    return (
      <div
        className={dayClassNames}
        style={dayStyles}
        key={node.id}
        onClick={() => handleDayClick(node.value)}
      >
        {renderNode(processedNode)}
      </div>
    )
  }

  const containerFrame = processNodeFrame(containerNode, scaleRatio, viewRect)

  const containerStyles = {
    ...containerNode.style,
    ...containerFrame,
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(containerNode.nodes).map(node => {
        if (NodeNames.Day !== node.name) return null
        return renderDayNode(node)
      })}
    </div>
  )
}

DaysItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  viewEvents: PropTypes.object,
}

export default withStyles(styles)(DaysItem)
