import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './StackStyles'

const Stack = ({ classes, items, style, itemStyle, onItemClick }) => (
  <div className={classes.container} style={style}>
    {items &&
      items.map((item, itemIdx) => (
        <div
          key={itemIdx}
          style={itemStyle}
          onClick={() => onItemClick && onItemClick(itemIdx)}
        >
          {item}
        </div>
      ))}
  </div>
)

Stack.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  itemStyle: PropTypes.object,
  onItemClick: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(Stack)
