import get from 'lodash/get'
import values from 'lodash/values'
import camelCase from 'lodash/camelCase'
import AirtableAPI from 'api/AirtableAPI'
import { isProductionInstance } from 'utils'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId } from './utils'
import { GlobalIds, Colors, Properties } from './constants'

const ProjectId = `16741A1794A${isProductionInstance() ? '0' : ''}`

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: Properties.ExercisesData,
          value: async state => {
            const exercisesData = get(
              state,
              `liveState.${Properties.ExercisesData}`
            )

            if (exercisesData) return exercisesData

            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              'Exercises',
              'Grid view'
            )
            return airtableData.map(
              ({ title, subtitle, description, image, tagLabel }) => ({
                id: camelCase(title),
                title,
                subtitle,
                description,
                image,
                tagLabel,
              })
            )
          },
        },
        {
          property: Properties.Points,
          value: 3400,
        },
        {
          property: Properties.Palette,
          value: {
            primary: Colors.primary,
            secondary: Colors.secondary,
          },
        },
      ],
    },
    styleProps: {
      overflowX: 'hidden',
    },
    guiControls: [
      {
        prop: 'name',
        label: 'Name',
        value: 'Kwamin',
        type: 'string',
        liveStateProp: 'userName',
      },
      {
        prop: 'color',
        label: 'Primary color',
        type: 'color',
        liveStateProp: 'palette.primary',
        value: Colors.primary,
      },
      {
        prop: 'scolor',
        label: 'Secondary color',
        type: 'color',
        liveStateProp: 'palette.secondary',
        value: Colors.secondary,
      },
    ],
  },
  A1CB322794A: {
    // `Personalized program` view
    styleProps: state => ({
      backgroundColor: get(state, 'liveState.palette.primary'),
    }),
  },
  E0AE8DB394A: {
    // `Practice Exercise` view
    styleProps: {
      overflow: 'hidden',
    },
  },
  [GlobalIds.Favorites]: {
    // `Thought Tamer` `favorite` button
    props: {
      onStateChange: value => ({
        property: 'favorites',
        value,
      }),
    },
  },
  '0E7D46B1-113D-488F-BFB4-12AE4C7931B2': {
    // `Favorite` chip
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: true,
        },
        {
          property: 'disableScroll',
          value: true,
        },
      ],
    },
    styleProps: state => ({
      display: values(get(state, 'liveState.favorites')).find(v => !!v)
        ? 'block'
        : 'none',
    }),
  },
  '53DF0A2A-2A86-4119-A29E-69CF60317D09': {
    // `Favorites Modal`
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: false,
        },
        {
          property: 'disableScroll',
          value: false,
        },
      ],
    },
  },
  'D5F711A6-EE84-491F-A455-669EB170524C': {
    // `Favorites Modal` repeater
    dataProps: {
      data: async state => {
        const exercisesData = get(state, 'liveState.exercisesData')
        const favorites = get(state, 'liveState.favorites')

        if (!favorites || !exercisesData) return null

        return {
          data: Object.keys(favorites).reduce((acc, currId) => {
            const value = favorites[currId]

            const exercise = exercisesData.find(e => e.id === currId)

            if (!value || !exercise) return acc

            acc.push(exercise)

            return acc
          }, []),
          repeaterId: 'D5F711A6-EE84-491F-A455-669EB170524C',
        }
      },
    },
  },
  'E26C8F50-4C33-45D3-BCE2-93ADC45316A5': {
    // Main Name `Kwamin`
    conditionalProps: {
      value: state => get(state, 'liveState.userName'),
    },
    styleProps: {
      textTransform: 'uppercase',
    },
  },
  'C2F6C2F5-2137-41A6-BBDA-C07B51EE4E2F': {
    // `Kwamin` in `Personalized program`
    conditionalProps: {
      value: state =>
        get(state, 'liveState.userName')
          ? `${get(state, 'liveState.userName').toLowerCase()}'s`
          : "Kwamin's",
    },
    styleProps: {
      textTransform: 'capitalize',
    },
  },
  'FF47F871-8FF3-41E7-A0D6-D59E715135EB': {
    // `mountain` image in `Personalized program`
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fmountain-light.svg?alt=media',
    },
  },
  'DACB2CB9-E795-4C85-AB35-4D08ADF69499': {
    // `Personalized Program` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fprogram-light.svg?alt=media',
    },
  },
  'C7C0593C-9168-42D6-908C-9112AE3A8E46': {
    // `Personalized Program` OKAY button
    styleProps: state => ({
      color: get(state, 'liveState.palette.primary'),
    }),
  },
  'EA059596-FBFD-488A-AF77-E7D8DEA5EEB3': {
    // `Personalize Program` CLOSE button
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '49719912-1EEF-46BE-BBFB-BF6E9875D736': {
    // `Short Meditation` CLOSE Hotspot
    props: {
      onStateChange: () => [
        {
          property: Properties.ShortMeditationChecked,
          value: true,
        },
      ],
    },
  },
  '3F36769B-CC5A-41B1-AC9C-9A0A4C7E54C2': {
    // `Short Meditation` CLOSE Button
    props: {
      onStateChange: () => [
        {
          property: Properties.ShortMeditationChecked,
          value: true,
        },
        {
          property: projectStatesTypes.ACTIVE_VIEW_ID,
          value: getId('16741A1794A'),
        },
      ],
    },
  },
  '2AE38871-0E2A-4A34-9B19-00856F372939': {
    // `Short Meditation` Card Item
    props: {
      onStateChange: () => [
        {
          property: projectStatesTypes.ACTIVE_VIEW_ID,
          value: getId('E0AE8DB394A'),
        },
      ],
    },
    conditionalProps: {
      animateOnInit: () => true,
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShortMeditationChecked}`)
        ? 'none'
        : '',
    }),
  },
  '105CC59F-E219-4F31-BC53-00EB7C5FB981': {
    // Main Hotspot for `Short Meditation` Card Item
    styleProps: {
      display: 'none',
    },
  },
  '2ED10FCC-D11D-44E8-B6BD-6EDD03458C9D': {
    // Science Hotspot for `Short Meditation` Card Item
    frameProps: {
      x: 242,
    },
  },
  '615D0069-31F7-45F5-BBC6-31BC604FED04': {
    // Text `Mr Rogers`
    styleProps: state => ({
      color: get(state, 'liveState.palette.primary'),
    }),
  },
  '95950A1F-C37D-4B11-8462-65E676DCC146': {
    // Card Item: `Short Meditation`
    styleProps: state => ({
      backgroundColor: get(state, 'liveState.palette.primary'),
    }),
  },
  'D4222D78-C179-479E-9142-C620F7968275': {
    // `Personalized Program` CLOSE button
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '5D5C6775-1D51-4C28-98B9-4B4F50CD6F26': {
    // `Short Meditation` tag `Stress Control`
    styleProps: state => ({
      color: get(state, 'liveState.palette.primary'),
    }),
  },
  '8BA0DD45-C96E-463E-A8EC-F9B424A82F80': {
    // `Short Meditation` bottom `lab` icon
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Flab.svg?alt=media',
    },
  },
  '443F3F78-D400-4430-8BC2-A8A012840E2A': {
    // `Short Meditation` `favorite` icon
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Ffavorite.svg?alt=media',
    },
  },
  '6D94A688-6597-4C38-B64E-3D4DD2C8C483': {
    // Card Item: `Thought Tamer`
    styleProps: state => ({
      backgroundColor: get(state, 'liveState.palette.primary'),
    }),
  },
  'E0A757C0-2753-4A9F-A662-867E45EC6AE6': {
    // `Thought Tamer` tag `Stress Control`
    styleProps: state => ({
      color: get(state, 'liveState.palette.primary'),
    }),
  },
  '5F97D411-CB53-4939-A854-21CF532B76FB': {
    // `Thought Tamer` bottom `lab` icon. Content 1
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Flab.svg?alt=media',
    },
  },
  '1CC7D969-03AD-4E8A-8732-1046DC94C402': {
    // `Thought Tamer` `favorite` icon. Content 1
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Ffavorite.svg?alt=media',
    },
  },
  '0C533657-7D70-46E5-A5B1-AC8DCAD5F1DB': {
    // `Personalized Program` container
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
  '94FD494B-E164-46E8-ADB6-9E2E57BCB69C': {
    // Checked `Learn about your program`
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? ''
        : 'none',
    }),
  },
  'AC75131D-C9A4-4724-9872-9CD5611EF1F0': {
    // `Personalized Program` background
    styleProps: state => ({
      backgroundColor: get(state, 'liveState.palette.secondary'),
    }),
  },
  'F4AA2800-057B-4674-8237-8E30D67F3E9F': {
    // `Personalized Program` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fprogram.svg?alt=media',
    },
  },
  '2C967987-6599-41EE-9D8E-7AC3BE60D287': {
    // Check icon for `Learn About Your Program`
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fcheck.svg?alt=media',
    },
  },
  '7AD1DA5D-7483-47D2-8849-2AEEC01891BA': {
    // `Learn About Your Program` checked item
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? ''
        : 'none',
    }),
  },
  '88D71D71-ECA1-43BA-B201-7047431560B5': {
    // `Short Meditation` checked item
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShortMeditationChecked}`)
        ? ''
        : 'none',
    }),
    frameProps: state => {
      const isPersonalizedProgramChecked = get(
        state,
        `liveState.${Properties.PersonalizedProgramChecked}`
      )
      const isShortMeditationChecked = get(
        state,
        `liveState.${Properties.ShortMeditationChecked}`
      )

      const frameProcessed = {}

      if (!isPersonalizedProgramChecked && isShortMeditationChecked) {
        frameProcessed.y = 120
      }

      return frameProcessed
    },
  },
  '11D23274-2B83-47E3-89EC-5B082B8BE086': {
    // Check icon for `Short Meditation`
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fcheck.svg?alt=media',
    },
  },
  'BF5501BF-0E50-4DD9-A10D-3B8E6212E60A': {
    // Journey background image
    conditionalProps: {
      url: state =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fjourney.svg?alt=media',
    },
    props: {
      isBackgroundImage: true,
    },
    frameProps: {
      x: -119,
    },
  },
  '3404C5B8-09D6-49F3-840D-04A55C134929': {
    // Journey `bag` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fbag.svg?alt=media',
    },
  },
  'B9545D0A-DBB1-4FE2-8929-DCD778E9703E': {
    // Journey `mountain tree` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fmountain-tree.svg?alt=media',
    },
    styleProps: {
      zIndex: 50,
    },
  },
  '20E2694B-EBEF-4257-9ED4-51D17E9B2996': {
    // Journey `mountain` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fmountain.svg?alt=media',
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
  '1057FABD-5706-4309-987A-970B46363DEB': {
    // Journey `path 1` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fpath-2.svg?alt=media',
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShortMeditationChecked}`)
        ? 'none'
        : '',
    }),
  },
  'AA46AB18-27CF-49D2-9DFF-13BD17BB9F6A': {
    // Journey `path 3` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fpath-3.svg?alt=media',
    },
  },
  'E889E982-1954-47D9-9EE4-3BA804615AD4': {
    // Journey `path 4` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fpath-4.svg?alt=media',
    },
  },
  '122AAB96-7C93-40C4-805B-4694F63A4B89': {
    // Journey `house mountain` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fhouse-mountain.svg?alt=media',
    },
    frameProps: {
      x: -119,
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShortMeditationChecked}`)
        ? 'none'
        : '',
    }),
  },
  'E0568C64-BA69-4649-9F5A-EF0687FABE74': {
    // `Dynamic bottom item`
    frameProps: state => {
      const isPersonalizedProgramChecked = get(
        state,
        `liveState.${Properties.PersonalizedProgramChecked}`
      )
      const isShortMeditationChecked = get(
        state,
        `liveState.${Properties.ShortMeditationChecked}`
      )

      const frameProcessed = {}

      if (!isPersonalizedProgramChecked && isShortMeditationChecked) {
        frameProcessed.y = 640
      } else if (isPersonalizedProgramChecked && isShortMeditationChecked) {
        frameProcessed.y = 600
      }

      return frameProcessed
    },
  },
  '3BAD25FC-1E3F-4DEA-97F7-57719A0812C4': {
    // Research text
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShortMeditationChecked}`)
        ? 'none'
        : '',
    }),
  },
  'AF765BE3-278C-4866-91E3-A1619F24DC26': {
    // Journey `leaf` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fleaf.svg?alt=media',
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShortMeditationChecked}`)
        ? 'none'
        : '',
    }),
  },
  'C05F3C9A-9FED-473B-94BE-133267547913': {
    // Journey `play` image 1
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fplay.svg?alt=media',
    },
  },
  'FA551964-13E0-459C-9D7F-28686CC22AF6': {
    // Journey `play` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fplay.svg?alt=media',
    },
  },
  'B297D16D-BE59-43CA-B755-A6B5B20D4126': {
    // Journey `clouds` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fclouds.svg?alt=media',
    },
    props: {
      isBackgroundImage: true,
    },
  },
  '40EE838A-6010-4F47-8870-0B7183E4406F': {
    // Journey `book` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fbook.svg?alt=media',
    },
  },
  '491D10EC-CF15-4A85-9CFA-A2131459F947': {
    // Journey `book` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fbook.svg?alt=media',
    },
  },
  'F166DA2B-8EC9-44DE-9D88-CCE51790C2AE': {
    // Journey `trees` image
    conditionalProps: {
      svgUrl: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Ftrees.svg?alt=media',
    },
    frameProps: {
      x: 30,
    },
  },
  '96EBD7A9-001F-4D48-A519-66C173DDF364': {
    // `Short Meditation` Exercise Item
    props: {
      onStateChange: () => [
        {
          property: projectStatesTypes.ACTIVE_VIEW_ID,
          value: getId('4529087694A'),
        },
      ],
    },
  },
  '764278E8-8FCD-44CE-BE03-FEE13E34448C': {
    // Hotspot on `Success view` to Home (OKAY)
    props: {
      onStateChange: stateValue => [
        {
          property: Properties.Points,
          value: () => {
            const pointsValue = get(
              stateValue,
              `liveState.${Properties.Points}`
            )
            return Number(pointsValue) + 3000
          },
        },
        {
          property: Properties.ShortMeditationChecked,
          value: true,
        },
      ],
    },
  },
}

export default ProjectId
