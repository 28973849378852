import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ExpandingNode from './ExpandingNode'

import styles from './ExpandingCardStyles'

const ExpandingCard = ({ classes, style }) => {
  const nodes = [
    {
      cardTitle: '1',
      backgroundColor: '#0998fe',
    },
    {
      cardTitle: '2',
      backgroundColor: '#6833fe',
    },
    {
      cardTitle: '3',
      backgroundColor: '#e4a706',
    },
  ]

  return (
    <div className={classes.container}>
      {nodes.map(({ cardTitle, backgroundColor }) => (
        <ExpandingNode
          key={cardTitle}
          cardTitle={cardTitle}
          backgroundColor={backgroundColor}
        />
      ))}
    </div>
  )
}

ExpandingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default withStyles(styles)(ExpandingCard)
