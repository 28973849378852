import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import head from 'lodash/head'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, findNodeByName } from 'utils'

import Node from 'components/Node'

import styles from './DataItemStyles'

const TimerStart = {
  minutes: 3,
  seconds: 60
}

let IntervalId = null

const TimerNode = ({ node, scaleRatio, viewRect, style, ...props }) => {
  const [timer, setTimer] = useState('03:00')
  const [countDownStart] = useState(new Date().getTime())

  function handlerTimer() {
    const timeleft = countDownStart - new Date().getTime()
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeleft % (1000 * 60)) / 1000)

    const remainingMins = TimerStart.minutes + minutes
    const remainingSecs = TimerStart.seconds + seconds

    if (!remainingMins && !remainingSecs) {
      setTimer('00:00')
      clearInterval(IntervalId)
      return
    }

    const remainingMinsString = `0${remainingMins}`
    const remainingSecsString =
      remainingSecs < 10 ? `0${remainingSecs}` : remainingSecs

    setTimer(`${remainingMinsString}:${remainingSecsString}`)
  }

  useEffect(() => {
    IntervalId = setInterval(handlerTimer, 1000)
    return () => {
      clearInterval(IntervalId)
    }
  }, [])

  const processedNode = {
    ...node,
    value: timer,
    forceReload: true
  }

  return (
    <Node
      node={processedNode}
      scaleRatio={scaleRatio}
      viewRect={viewRect}
      style={style}
      {...props}
    />
  )
}

TimerNode.propTypes = {
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  style: PropTypes.object
}

const DataItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  dataProps,
  style,
  ...props
}) => {
  const exercisesData = head(get(projectState, 'liveState.exercisesData'))

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    height: 'fit-content'
  }

  const nodeStyle = {
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
    top: 0,
    left: 0
  }

  const renderNode = node => {
    return (
      <Node
        node={node}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        style={nodeStyle}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        {...props}
      />
    )
  }

  const renderNameNode = () => {
    const nameNode = findNodeByName(containerNode, 'name')

    const nameStyles = {
      ...nameNode.style,
      lineHeight: `${nameNode.style.lineHeight}px`
    }

    const quantityNodeStyle = {
      ...nameNode.style,
      lineHeight: `${nameNode.style.lineHeight}px`,
      color: '#eb5a4c'
    }

    return (
      <div className={classes.nameContainer}>
        <div style={nameStyles}>
          {exercisesData.name}
          <span style={quantityNodeStyle}>&nbsp;x&nbsp;1</span>
        </div>
      </div>
    )
  }

  const renderRestaurantNode = () => {
    const restaurantNode = findNodeByName(containerNode, 'restaurant')

    const processedNode = {
      ...restaurantNode,
      value: exercisesData.restaurant
    }

    return renderNode(processedNode)
  }

  const renderDateNode = () => {
    const dateNode = findNodeByName(containerNode, 'date')

    const processedNode = {
      ...dateNode,
      value: exercisesData.date
    }

    return renderNode(processedNode)
  }

  const renderTimerNode = () => {
    const timerNode = findNodeByName(containerNode, 'timer')
    return (
      <TimerNode
        node={timerNode}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        style={nodeStyle}
        {...props}
      />
    )
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {renderNameNode()}

      {renderRestaurantNode()}

      {renderDateNode()}

      {renderTimerNode()}
    </div>
  )
}

DataItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  dataProps: PropTypes.object,
  style: PropTypes.object
}

export default withStyles(styles)(DataItem)
