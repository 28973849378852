import React, { useState, useEffect, createRef, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import values from 'lodash/values'
import isArray from 'lodash/isArray'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, checkIsSmartDevice } from 'utils'
import { LiveStateProperties } from 'constants/general'
import PanScrollProvider from 'common/PanScrollProvider'
import { setScrollValues } from 'actions/viewActions'
import { ProjectStateContext } from 'contexts/ProjectStateContextProvider'

import Node from 'components/Node'

import styles from './ViewStyles'

const _ = {
  viewRect: {
    top: null,
    left: null,
    width: null,
    height: null,
  },
}

const View = ({
  classes,
  view,
  scaleRatio,
  windowRect,
  projectState,
  isDesktopDevice,
  isDesktopExperience,
  isLinkedProject,
  onHotspotChange,
  onProjectStateChange,
  actions,
  ...props
}) => {
  const viewRef = createRef()

  const [isSmartDevice, setIsSmartDevice] = useState(null)
  const [forceScrollTo, setForceScrollTo] = useState(null)

  const projectStateCtx = useContext(ProjectStateContext)
  const viewProps = get(projectState, `data.${view.id}.props`)
  const styleProps = get(projectState, `data.${view.id}.styleProps`)
  const eventProps = get(projectState, `data.${view.id}.eventProps`)
  const disableScroll = get(
    projectState,
    `liveState.${LiveStateProperties.DisableScroll}`
  )

  const calcScrollView = e => {
    const {
      target: { scrollTop, scrollHeight, offsetTop, offsetLeft, offsetWidth },
    } = e

    actions.setScrollValues({
      scrollTop,
      scrollHeight,
      offsetWidth,
      offsetTop,
      offsetLeft,
    })

    projectStateCtx.setData({
      key: LiveStateProperties.ViewScrollPosition,
      value: scrollTop,
    })

    // TODO(cristobalchao@): Expand the events for view, right now only accepts `onScroll`
    eventProps &&
      Object.keys(eventProps).forEach(nodePropKey => {
        if (nodePropKey !== 'onScroll') return

        const value = eventProps[nodePropKey](scrollTop)
        onProjectStateChange &&
          onProjectStateChange(value, null, {
            updateWithValue: true,
          })
      })
  }

  useEffect(() => {
    const viewsInitialized =
      get(projectState, `liveState.${LiveStateProperties.PropsInitialized}`) ||
      {}

    const onInitialState = get(viewProps, 'onInitialState')

    if (!onInitialState || viewsInitialized[view.id]) return

    const onInitialStateProps =
      typeof onInitialState === 'function'
        ? onInitialState(projectState)
        : onInitialState

    if (!onInitialStateProps) return null

    onProjectStateChange &&
      onProjectStateChange({
        stateValues: onInitialStateProps.concat({
          property: LiveStateProperties.PropsInitialized,
          value: {
            ...viewsInitialized,
            [view.id]: true,
          },
        }),
      })
  }, [])

  useEffect(() => {
    if (!viewRef.current) return null

    viewRef.current.addEventListener('scroll', calcScrollView)

    return () => {
      viewRef.current &&
        viewRef.current.removeEventListener('scroll', calcScrollView)
    }
  }, [])

  useEffect(() => {
    actions.setScrollValues({
      offsetWidth: _.viewRect.width,
      offsetTop: _.viewRect.top,
      offsetLeft: _.viewRect.left,
    })
  }, [windowRect])

  useEffect(() => {
    const forceViewScrollTo = get(
      projectState,
      `liveState.${LiveStateProperties.ForceViewScrollTo}`
    )
    if (!forceViewScrollTo) return

    const { viewId, value, scrollProps } = forceViewScrollTo

    // Enable multiple view scrolling
    const viewIdArray = isArray(viewId) ? viewId : [viewId]

    if (!viewIdArray.includes(view.id)) return

    const scrollToValue = isNil(value) ? scrollProps : value

    setForceScrollTo(scrollToValue)

    onProjectStateChange &&
      onProjectStateChange({
        stateValues: {
          property: LiveStateProperties.ForceViewScrollTo,
          value: null,
        },
      })
  }, [projectState])

  useEffect(() => {
    setIsSmartDevice(checkIsSmartDevice())
  }, [isDesktopDevice])

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.mobileContainer]: !isDesktopExperience,
    [classes.noScroll]: disableScroll,
    [classes.linkedContainer]: isLinkedProject,
  })

  const processedFrame = processNodeFrame(view, scaleRatio)

  let top = null
  let left = null
  let width = null
  let height = null

  if (isDesktopDevice) {
    top = !isLinkedProject
      ? windowRect.height / 2 - processedFrame.height / 2
      : 0
    left = !isLinkedProject
      ? windowRect.width / 2 - processedFrame.width / 2
      : 0
    width = processedFrame.width
    height = processedFrame.height
  } else if (isSmartDevice) {
    top = 0
    left = 0
    width = windowRect.width
    height = Math.min(view.frame.height, windowRect.height)
  } else {
    // Chrome Tools with Device
    top = 0
    left = 0
    width = windowRect.width
    height = windowRect.height
  }

  _.viewRect = {
    ...processedFrame,
    width,
    height,
    top,
    left,

    artboardFrame: {
      ...view.frame,
    },
    windowRect: {
      ...windowRect,
    },
  }

  const processedStyleProps =
    styleProps && typeof styleProps === 'function'
      ? styleProps(projectState)
      : styleProps

  const rootStyles = {
    ...view.style,
    ..._.viewRect,
    ...processedStyleProps,
  }

  const isMobileExperienceInDesktop = isDesktopDevice && !isDesktopExperience

  return (
    <div onScroll={calcScrollView} ref={viewRef}>
      <PanScrollProvider
        disabled={!isMobileExperienceInDesktop || disableScroll}
        forceScrollTo={forceScrollTo}
        onUpdateForceScrollTo={() => setForceScrollTo(null)}
      >
        {({ ref }) => (
          <div className={containerClassNames} style={rootStyles} ref={ref}>
            {values(view.nodes).map(node => (
              <Node
                key={node.id}
                node={node}
                scaleRatio={scaleRatio}
                viewRect={_.viewRect}
                projectState={projectState}
                onHotspotChange={onHotspotChange}
                onProjectStateChange={onProjectStateChange}
                isDesktopDevice={isDesktopDevice}
                {...props}
              />
            ))}
          </div>
        )}
      </PanScrollProvider>
    </div>
  )
}

View.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  scaleRatio: PropTypes.number,
  windowRect: PropTypes.object,
  projectState: PropTypes.object,
  isDesktopDevice: PropTypes.bool,
  isDesktopExperience: PropTypes.bool,
  onHotspotChange: PropTypes.func,
  isLinkedProject: PropTypes.bool,
  onProjectStateChange: PropTypes.func,
  actions: PropTypes.shape({
    setScrollValues: PropTypes.func.isRequired,
  }),
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setScrollValues,
      },
      dispatch
    ),
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(View)
