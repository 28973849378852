import update from 'immutability-helper'

import {
  INIT_PROJECT_STATE,
  UPDATE_LIVE_STATE,
  UPDATE_ACTIVE_VIEWS,
  UPDATE_DATA_STYLE_PROPS,
  UPDATE_ACTIVE_LINKED_VIEW,
} from 'constants/actionTypes'

import {
  ENABLE_LINKED_PROJECT,
  DISABLE_LINKED_PROJECT,
} from 'constants/projectStatesTypes'

import initialState from './initialState'

export default (state = initialState.projectState, action) => {
  switch (action.type) {
    case INIT_PROJECT_STATE: {
      return update(state, {
        $set: {
          ...initialState.projectState,
          ...action.payload,
        },
      })
    }
    case UPDATE_ACTIVE_VIEWS: {
      return update(state, {
        prevActiveViews: {
          $set: {
            ...state.activeViews,
          },
        },
        activeViews: {
          $set: {
            ...state.activeViews,
            ...action.payload,
          },
        },
      })
    }
    case UPDATE_ACTIVE_LINKED_VIEW: {
      const { linkedActiveViewId } = action
      const prevLinkedViewId = linkedActiveViewId
        ? state.activeLinkedViewId
        : null
      return update(state, {
        prevLinkedViewId: {
          $set: prevLinkedViewId,
        },
        activeLinkedViewId: {
          $set: linkedActiveViewId,
        },
      })
    }
    case UPDATE_LIVE_STATE: {
      return update(state, {
        liveState: {
          $set: {
            ...state.liveState,
            ...action.payload,
          },
        },
      })
    }
    case UPDATE_DATA_STYLE_PROPS: {
      return update(state, {
        data: {
          [action.nodeId]: {
            styleProps: {
              $set: {
                ...action.styleProps,
              },
            },
          },
        },
      })
    }
    case ENABLE_LINKED_PROJECT: {
      return update(state, {
        activeLinkedProjects: {
          $set: Array.from(
            new Set([...state.activeLinkedProjects, action.payload])
          ),
        },
      })
    }
    case DISABLE_LINKED_PROJECT: {
      const activeLinkedProjectsSet = new Set(state.activeLinkedProjects)
      activeLinkedProjectsSet.delete(action.payload)
      return update(state, {
        activeLinkedProjects: {
          $set: Array.from(activeLinkedProjectsSet),
        },
      })
    }

    default:
      return state
  }
}
