import Program2Id, { data as Program2Data } from './Program2'
import HomeFeed2Id, { data as HomeFeed2Data } from './HomeFeed2'
import AssessPageId, { data as AssessPageData } from './AssessPage'
import LibraryPage2Id, { data as LibraryPage2Data } from './LibraryPage2'
import GameExerciseId, { data as GameExerciseData } from './GameExercise'
import OnboardingHomeFeed2Id, {
  data as OnboardingHomeFeed2Data,
} from './OnboardingHomeFeed2'
import OnboardingLibraryId, {
  data as OnboardingLibraryData,
} from './OnboardingLibrary'
import LibraryCapacityDetailsId, {
  data as LibraryCapacityDetailsData,
} from './LibraryCapacityDetails'
import TotalBrainExperienceId, {
  data as TotalBrainExperienceData,
} from './TotalBrainExperience'

const ProjectIds = [
  Program2Id,
  HomeFeed2Id,
  AssessPageId,
  LibraryPage2Id,
  GameExerciseId,
  OnboardingLibraryId,
  OnboardingHomeFeed2Id,
  LibraryCapacityDetailsId,
  TotalBrainExperienceId,
]

const data = {
  ...Program2Data,
  ...HomeFeed2Data,
  ...AssessPageData,
  ...LibraryPage2Data,
  ...GameExerciseData,
  ...OnboardingLibraryData,
  ...OnboardingHomeFeed2Data,
  ...LibraryCapacityDetailsData,
  ...TotalBrainExperienceData,
}

export default {
  ProjectIds,
  data,
}
