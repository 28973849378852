import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import theme from 'styles/muiTheme'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import {
  getId,
  fetchFeedState,
  getGameItemDynamicY,
  getExerciseData,
  getGameDataFeedComponentProps,
  getGameIdFromDataFeedState,
  getCardNameFromDataFeedState,
} from '../utils'
import { StyleMixins, Properties } from '../constants'

const ProjectId = getId('627141BE94A')

const Actions = {
  OpenExerciseView: [
    {
      property: LiveStateProperties.ForceViewScrollTo,
      value: {
        viewId: ProjectId,
        value: 0,
      },
    },
    {
      property: LiveStateProperties.DisableScroll,
      value: true,
    },
    {
      property: projectStatesTypes.ENABLE_LINKED_PROJECT,
      value: getId('1548C3BC94A'),
    },
  ],
}

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[A] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.ExercisesData,
          value: async state => getExerciseData(state),
        },
      ],
    },
  },
  'A5AE8305-13A0-4F10-86C4-E5DD2B8CA95C': {
    // `hero` image
    conditionalProps: {
      url: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'image',
          'attachment'
        ) ||
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fhero.svg?alt=media',
    },
  },
  'D67C73FC-9FDF-43D5-836C-CE408E80ABD5': {
    // Welcome hero `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
      textTransform: 'uppercase',
    },
  },
  '0A31BD94-DDA5-4BD0-86BC-1BEAFC8BACC9': {
    // WelcomeHero `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
      textTransform: 'uppercase',
    },
  },
  'D2895772-9945-4DAA-81D5-B4393F8F679F': {
    // `FavChip` chip
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: true,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
      ],
    },
    styleProps: state => {
      const { liveState } = state

      const isFavorited = Object.keys(liveState).find(
        key => key.includes(Properties.Favorited) && liveState[key]
      )

      return {
        display: isFavorited ? 'block' : 'none',
      }
    },
  },
  'F7ADBE84-D93A-4D47-8700-3B2B04AF42AE': {
    // `Favorites Modal`
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: false,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
  },
  'AC649F51-C7FA-4092-8869-C4B18983A1B4': {
    // `Fav Modal` close icon
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: false,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fmodal-close.svg?alt=media',
    },
  },
  '04AD1E8F-4D3D-4758-8992-E51EF283CC03': {
    // Program Card
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
  '9DBFA8D1-01BF-4871-A972-32908EA34ECB': {
    // Program Card `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '7A3B99E9-4E3C-4414-BC00-B1DF2845309F': {
    // Program Card `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '31F5FCBD-1A5B-431A-A94F-A02B2C9B5FDD': {
    // Program Card `successMessage`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'successMessage',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '282B9632-B34A-4245-81C6-032DB77C2A9E': {
    // ProgramView `mountain` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fmountain.svg?alt=media',
    },
  },
  '1FE21F41-A2CE-401A-8F64-ED9927604FB3': {
    // ProgramView `lighting` icon
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Flightning-icon.svg?alt=media',
    },
  },
  'C3BA4501-4121-4F4F-843B-677F5805E7A8': {
    // ProgramView `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '54BC0456-6B92-420A-B8B4-1F3D757C441F': {
    // ProgramView `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  'EFD2A238-E096-40FB-8260-F9BCC8C0EB63': {
    // ProgramView `headlineTitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'headlineTitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '9A7CCC19-E2B3-487D-B1AB-D27FE80FC4BF': {
    // ProgramView `headlineDescription`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'headlineDescription',
          'text'
        ),
    },
  },
  '38728263-185F-4E24-A149-41F79F6225AA': {
    // ProgramView `firstSectionTitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'firstSectionTitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '732514D0-89E7-4352-84C7-362647004F33': {
    // ProgramView `firstSectionDescription`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'firstSectionDescription',
          'text'
        ),
    },
  },
  '46929B14-0D4C-4848-B30E-0B003BF3275A': {
    // ProgramView `secondSectionTitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'secondSectionTitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '869072C0-3786-4662-B4AB-C483E31699D0': {
    // ProgramView `secondSectionDescription`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'secondSectionDescription',
          'text'
        ),
    },
  },
  '07225FF7-40E2-4081-95AF-306E740728CE': {
    // ProgramView `OKAY button`
    styleProps: {
      zIndex: 50,
    },
  },
  '0B2A6FED-B87E-4010-9000-ADED673AD521': {
    // `Personalized Program` OKAY button HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '3834EEB7-772A-458D-BECD-219338CAF820': {
    // `Program close` icon HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '8A3BCE99-835B-4E1B-8DA5-2446884C30F6': {
    // `Program close` icon
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fprogram-close.svg?alt=media',
    },
  },
  '82B35ECB-A4B7-4520-BC22-E3331869AACC': {
    // First motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[0].text') ||
        'This method suggests meditation helps us bounce back from stress. Give it a try.',
    },
  },
  'A74A233F-BB7B-45B0-84E8-D88C081606AF': {
    // Second motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[1].text') ||
        'You think around 70,000 thoughts a day! Reframing the unhelpful ones can lower stress. Try it out.',
    },
  },
  '7847473A-B335-412C-A6BF-9D7FE27C2DC0': {
    // Third motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[2].text') ||
        'You think around 70,000 thoughts a day! Reframing the unhelpful ones can lower stress. Try it out.',
    },
  },
  'FC848096-6757-4F2E-B2CA-EB18CF548982': {
    // `Games List` in Fav Modal
    componentProps: {
      gamesData: state => {
        const { liveState } = state

        const exercisesData = get(liveState, Properties.ExercisesData)

        const favs = Object.keys(liveState).filter(
          key => key.includes(Properties.Favorited) && liveState[key]
        )

        const favorites = favs.map(f => f.split(Properties.Favorited)[1])

        if (!favorites || !exercisesData) return null

        return favorites.reduce((acc, currId) => {
          const exercise = exercisesData.find(e => e.id === currId)

          if (!exercise) return acc

          acc.push(exercise)

          return acc
        }, [])
      },
      onChange: state => ({ e, id, value }) => {
        if (e === Properties.GameCardEvents.FavChange) {
          return {
            property: `${Properties.Favorited}${id}`,
            value,
          }
        }

        if (e === Properties.GameCardEvents.CardClicked) {
          return [
            {
              property: 'isFavModalActive',
              value: false,
            },
            {
              property: LiveStateProperties.ForceViewScrollTo,
              value: {
                viewId: ProjectId,
                value: 0,
              },
            },
            {
              property: LiveStateProperties.DisableScroll,
              value: true,
            },
            {
              property: Properties.ActiveGameId,
              value: id,
            },
            {
              property: projectStatesTypes.ENABLE_LINKED_PROJECT,
              value: getId('1548C3BC94A'),
            },
          ]
        }
      },
    },
  },
  'EED8FD14-34D1-4EAC-9F3E-F0610AE06B37': {
    // `game__item` First Card Unchecked container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`
      )
        ? 'none'
        : '',
    }),
  },
  '09DBB243-8EDF-41EB-BA81-0D2E4F38D806': {
    // `game__item` First Card Checked container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`
      )
        ? ''
        : 'none',
    }),
  },
  'E8748D67-3882-4F8E-AC34-3B8336253A24': {
    // `game-card__item` First Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'firstCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  'D3AF594D-7A82-4AA6-A761-C1059DD8AC88': {
    // `game__item--checked` First Card Text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'firstCardName'),
    },
  },
  '340B5F8B-89C9-4F46-9CCD-420DB84714D9': {
    // `game__item` Second Card Unchecked container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(
          state,
          'secondCardName'
        )}Checked`
      )
        ? 'none'
        : '',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [`${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`],
        204
      ),
    }),
  },
  'C5CCA7FD-EC44-4A75-B0B0-287C5A524815': {
    // `game__item` Second Card Checked container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(
          state,
          'secondCardName'
        )}Checked`
      )
        ? ''
        : 'none',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [`${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`],
        199
      ),
    }),
  },
  '3DE70BAE-40E5-4819-B672-E7A6BD637C9F': {
    // `game-card__item` Second Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'secondCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  'B6ADBF64-9B10-4641-A527-0D4672117F68': {
    // `game__item--checked` Second Card text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'secondCardName'),
    },
  },
  '1AE884B7-D778-4FCC-84DD-F0AC5CDB4277': {
    // `game__item` Third Unchecked card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`
      )
        ? 'none'
        : '',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
        ],
        398
      ),
    }),
  },
  '617C603B-D699-4424-842D-F41B4C986DF8': {
    // `game__item` Third Checked card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`
      )
        ? ''
        : 'none',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
        ],
        396
      ),
    }),
  },
  'A808AE8E-BA85-4C44-9CE1-099148B71F98': {
    // `game-card__item` Third Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'thirdCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  '886626E4-186D-4E77-A5D8-8FE348F31284': {
    // `game__item--checked` Third Card text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'thirdCardName'),
    },
  },
  '5EA5067A-B991-4440-A2AA-CC082B69ED09': {
    // Game Exercise Linked View
    styleProps: {
      zIndex: theme.zIndex.layerElevatedL,
    },
  },
  'D050F2AE-1C28-4B60-B6F7-2E1F982EDEF4': {
    // `Content` Home Feed
    frameProps: state => ({
      y: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 520
        : 630,
      height: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        1430 //  1354 + 76 (Home Feed Height)
      ),
    }),
  },
  '524DFBF6-C432-4E7B-8BE7-8070315E4320': {
    // `Content 2` Home Feed
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        554
      ),
    }),
  },
  '68D84F8E-AC55-4003-A17F-E369C63AB5A9': {
    // `Happy Seeker Card` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fhappy-seeker.svg?alt=media',
    },
  },
  '52DDD26C-7C38-418E-9251-36ADC70C3628': {
    // `Short Meditation` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fshort-meditation.svg?alt=media',
    },
  },
  '90162568-12E0-4CB9-AA02-C590F29E5D3A': {
    // `house tree` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fhouse-tree.svg?alt=media',
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
  '713CFAC4-82F4-4C7C-BCF9-9CAB4D2A5120': {
    // `path 1`
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fpath-1.svg?alt=media',
    },
  },
  '2935D118-FD41-4C77-B8DA-7785A6167C17': {
    // `path 2`
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fpath-1.svg?alt=media',
    },
    styleProps: {
      transform: 'rotate(270deg)',
    },
  },
  'FA259C46-C8BF-43FD-A12D-578D1B805281': {
    // `path 3`
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fpath-1.svg?alt=media',
    },
  },
  '58C84A37-5AD7-4394-A869-F1735D6202AD': {
    // `rocks` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Frocks.svg?alt=media',
    },
  },
  'AEDA4CB1-10ED-4771-AFB4-8878E247C760': {
    // `books` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fbooks.svg?alt=media',
    },
  },
  '536917A1-21B2-48F0-AD8E-BEFF55B648E5': {
    // `books` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fbooks.svg?alt=media',
    },
  },
  'F4ED10FD-57D2-444F-AA70-E10498BD81DE': {
    // `trees` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Ftrees.svg?alt=media',
    },
  },
}

export default ProjectId
