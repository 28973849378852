import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import ProgressiveImage from 'common/ProgressiveImage'

import styles from './ImageNodeStyles'

const ImageNode = ({
  classes,
  node,
  value,
  url,
  style,
  isBackgroundImage,
  onClick,
  ...props
}) => {
  const { id, name, style: nodeStyle, url: nodeUrl, layoutId } = node

  const rootStyles = {
    ...nodeStyle,
    ...node.processedFrame,
    ...style,
  }

  const imageNode = {
    ...node,
    id: value || id,
  }

  const imageUrl = url || nodeUrl

  const Image = isBackgroundImage ? (
    <div
      className={classes.backgroundImage}
      style={{ backgroundImage: `url("${imageUrl}")` }}
    />
  ) : (
    <img className={classes.image} src={imageUrl} alt={name} />
  )

  const otherProps = {}

  if (layoutId) {
    otherProps.layoutId = layoutId
  }

  return (
    <motion.div
      className={classes.container}
      style={rootStyles}
      onClick={onClick}
      {...node?.nodeRef}
      {...otherProps}
    >
      {!imageUrl && <ProgressiveImage node={imageNode} {...props} />}
      {imageUrl && Image}
    </motion.div>
  )
}

ImageNode.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  value: PropTypes.string,
  url: PropTypes.string,
  isBackgroundImage: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

export default withStyles(styles)(ImageNode)
