import random from 'lodash/random'
import { getRandomProgress } from './utils'

export const GlobalIds = {
  Favorites: 'FAVORITES',
}

export const StyleMixins = {
  oneLine: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}

export const Colors = {
  primary: '#008fff',
  secondary: '#74d3ff',
}

export const Properties = {
  Points: 'points',
  Palette: 'palette',
  ExercisesData: 'exercisesData',
  PersonalizedProgramChecked: 'personalizedProgramChecked',
  HappySeekerChecked: 'happySeekerChecked',
  ShortMeditationChecked: 'shortMeditationChecked',
  BubbleTopiaChecked: 'bubbleTopiaChecked',
  ActiveGameId: 'activeGameId',
  ProgramPoints: 'programPoints',
  ShowPracticeFreq: 'showPracticeFreq',
  BottomNavItemsData: 'bottomNavItemsData',
  OnboardingHomeFeed: 'onboardingHomeFeed',
  OnboardingLibraryStep: 'onboardingLibraryStep',
  ActiveCapacityDetails: 'activeCapacityDetails',
  Favorited: 'favorited',
  GameCardEvents: {
    LabClicked: 'lab_clicked',
    CardClicked: 'card_clicked',
    FavChange: 'fav_change',
  },
}

export const TodayPointsDate = '03/06/20'

const getRandomPoints = total => {
  const incrementOffset = 50
  const arr = Array.from(
    { length: total / incrementOffset },
    (v, k) => (k + 1) * incrementOffset
  )
  return arr[random(0, arr.length - 1)]
}

function getRandomProgressPoints(total, lengthArray) {
  const offsetDiff = 1.25
  const progressPoints = []
  let currentPoints = 0
  for (let i = 0; i < lengthArray; i += 1) {
    currentPoints += getRandomPoints(
      Math.round(total / (lengthArray - offsetDiff)),
      total
    )
    progressPoints.push(Math.min(currentPoints, total))
  }
  return progressPoints
}

export const DayPointsCategories = [
  {
    id: 'stressControl',
    label: 'Stress Control',
    color: '#089AFF',
  },
  {
    id: 'memory',
    label: 'Memory',
    color: '#FCB82F',
  },
]

const DayPointsData = [
  {
    points: 1500,
    label: 'Break Stress',
    category: 'stressControl',
    totalPoints: 1800,
    lastDayPoints: 1200,
    practices: 15,
  },
  {
    points: 1000,
    label: 'Short Meditation',
    category: 'stressControl',
    totalPoints: 1600,
    lastDayPoints: 900,
    practices: 10,
  },
  {
    points: 0,
    label: 'Victory Breath',
    category: 'stressControl',
    totalPoints: 1200,
    lastDayPoints: 0,
    practices: 5,
  },
  {
    points: 600,
    label: 'Think Memory',
    category: 'memory',
    totalPoints: 1000,
    lastDayPoints: 500,
    practices: 10,
  },
  {
    points: 700,
    label: 'Power Nap (5 minutes)',
    category: 'memory',
    totalPoints: 1000,
    lastDayPoints: 600,
    practices: 7,
  },
  {
    points: 300,
    label: 'Focus Breath',
    category: 'memory',
    totalPoints: 900,
    lastDayPoints: 200,
    practices: 8,
  },
]

const NDays = 5

const PointsPerDay = [
  getRandomProgressPoints(DayPointsData[0].lastDayPoints, NDays), // Break Stress
  getRandomProgressPoints(DayPointsData[1].lastDayPoints, NDays), // Short Meditation
  getRandomProgressPoints(DayPointsData[2].lastDayPoints, NDays), // Victory Breath
  getRandomProgressPoints(DayPointsData[3].lastDayPoints, NDays), // Think Memory
  getRandomProgressPoints(DayPointsData[4].lastDayPoints, NDays), // Power Nap
  getRandomProgressPoints(DayPointsData[5].lastDayPoints, NDays), // Focus Breath
]

export const DayPoints = {
  '03/02/20': {
    goal: 3000,
    data: [
      {
        ...DayPointsData[0],
        progress: getRandomProgress(),
        points: PointsPerDay[0][0],
      },
      {
        ...DayPointsData[1],
        progress: getRandomProgress(),
        points: PointsPerDay[1][0],
      },
      {
        ...DayPointsData[2],
        points: PointsPerDay[2][0],
      },
      {
        ...DayPointsData[3],
        progress: getRandomProgress(),
        points: PointsPerDay[3][0],
      },
      {
        ...DayPointsData[4],
        progress: getRandomProgress(),
        points: PointsPerDay[4][0],
      },
      {
        ...DayPointsData[5],
        progress: getRandomProgress(),
        points: PointsPerDay[5][0],
      },
    ],
  },
  '03/03/20': {
    goal: 3000,
    data: [
      {
        ...DayPointsData[0],
        progress: getRandomProgress(),
        points: PointsPerDay[0][1],
      },
      {
        ...DayPointsData[1],
        progress: getRandomProgress(),
        points: PointsPerDay[1][1],
      },
      {
        ...DayPointsData[2],
        points: PointsPerDay[2][1],
      },
      {
        ...DayPointsData[3],
        progress: getRandomProgress(),
        points: PointsPerDay[3][1],
      },
      {
        ...DayPointsData[4],
        progress: getRandomProgress(),
        points: PointsPerDay[4][1],
      },
      {
        ...DayPointsData[5],
        progress: getRandomProgress(),
        points: PointsPerDay[5][1],
      },
    ],
  },
  '03/04/20': {
    goal: 3000,
    data: [
      {
        ...DayPointsData[0],
        progress: getRandomProgress(),
        points: PointsPerDay[0][2],
      },
      {
        ...DayPointsData[1],
        progress: getRandomProgress(),
        points: PointsPerDay[1][2],
      },
      {
        ...DayPointsData[2],
        points: PointsPerDay[2][2],
      },
      {
        ...DayPointsData[3],
        progress: getRandomProgress(),
        points: PointsPerDay[3][2],
      },
      {
        ...DayPointsData[4],
        progress: getRandomProgress(),
        points: PointsPerDay[4][2],
      },
      {
        ...DayPointsData[5],
        progress: getRandomProgress(),
        points: PointsPerDay[5][2],
      },
    ],
  },
  '03/05/20': {
    goal: 3000,
    data: [
      {
        ...DayPointsData[0],
        progress: getRandomProgress(),
        points: PointsPerDay[0][3],
      },
      {
        ...DayPointsData[1],
        progress: getRandomProgress(),
        points: PointsPerDay[1][3],
      },
      {
        ...DayPointsData[2],
        points: PointsPerDay[2][3],
      },
      {
        ...DayPointsData[3],
        progress: getRandomProgress(),
        points: PointsPerDay[3][3],
      },
      {
        ...DayPointsData[4],
        progress: getRandomProgress(),
        points: PointsPerDay[4][3],
      },
      {
        ...DayPointsData[5],
        progress: getRandomProgress(),
        points: PointsPerDay[5][3],
      },
    ],
  },
  [TodayPointsDate]: {
    goal: 3000,
    data: [
      {
        ...DayPointsData[0],
        progress: getRandomProgress(),
        points: DayPointsData[0].lastDayPoints,
      },
      {
        ...DayPointsData[1],
        progress: getRandomProgress(),
        points: DayPointsData[1].lastDayPoints,
      },
      {
        ...DayPointsData[2],
      },
      {
        ...DayPointsData[3],
        progress: getRandomProgress(),
        points: DayPointsData[3].lastDayPoints,
      },
      {
        ...DayPointsData[4],
        progress: getRandomProgress(),
        points: DayPointsData[4].lastDayPoints,
      },
      {
        ...DayPointsData[5],
        progress: getRandomProgress(),
        points: DayPointsData[5].lastDayPoints,
      },
    ],
  },
}
