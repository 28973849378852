const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  input: {
    position: 'absolute',
    background: 'transparent',
    border: 'none',
    width: '100%',
    margin: 0,
    padding: 0,
    '&::placeholder': {
      opacity: 1
    }
  }
})

export default jssStyles
