import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'
import { withStyles } from '@material-ui/core/styles'

import {
  processNodeFrame,
  findNodeByName,
  processNodeStyleProps,
  sortNodesByZIndex,
} from 'utils'
import Node from 'components/Node'
import { Properties } from '@totalbrain-project/constants'

import styles from './BottomNavStyles'

const NodeNames = {
  NavItemContainer: 'bottom-navigation__item',
  NavToggleItem: 'toggle__item',
  toggleItemActive: 'item--active',
  ContentItem: 'content__item',
}

const BottomNav = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  ...props
}) => {
  const [navIndex, setNavIndex] = useState(0)
  const [contentItems, setContentItems] = useState(null)

  useEffect(() => {
    const contentItemNode = findNodeByName(containerNode, NodeNames.ContentItem)
    setContentItems(sortNodesByZIndex(contentItemNode))
  }, [])

  useEffect(() => {
    const items = get(
      projectState,
      `liveState.${Properties.BottomNavItemsData}`
    )
    const activeId =
      get(projectState, 'liveState.bottomNavActiveId') || get(items, '[0].id')

    const activeIndex = findIndex(items, i => i.id === activeId)

    setNavIndex(activeIndex !== -1 ? activeIndex : 0)
  }, [projectState])

  const renderContent = () => {
    if (!contentItems) return null

    const contentItemNode = findNodeByName(containerNode, NodeNames.ContentItem)

    const contentStyles = {
      ...contentItemNode.style,
      ...processNodeFrame(contentItemNode, scaleRatio, viewRect),
      height: 'auto',
      ...processNodeStyleProps(projectState, contentItemNode),
    }

    return (
      <div className={classes.container} style={contentStyles}>
        {contentItems.map((item, itemIndex) => {
          const isActive = navIndex === itemIndex

          if (!isActive) return null

          const contentClassNames = classNames({
            [classes.content]: true,
            [classes.active]: isActive,
          })

          return (
            <div key={item.id} className={contentClassNames}>
              <Node
                node={item}
                scaleRatio={scaleRatio}
                viewRect={viewRect}
                projectState={projectState}
                onProjectStateChange={onProjectStateChange}
                {...props}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const renderBottomNavigation = () => {
    const bottomNavNode = findNodeByName(
      containerNode,
      NodeNames.NavItemContainer
    )

    return (
      <Node
        node={bottomNavNode}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        {...props}
      />
    )
  }

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    height: 'auto',
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {renderContent()}
      {renderBottomNavigation()}
    </div>
  )
}

BottomNav.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
}

export default withStyles(styles)(BottomNav)
