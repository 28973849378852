import React, { useState, useEffect } from 'react'
import get from 'lodash/get'
import { ErrorBoundary } from 'react-error-boundary'

import useNodeProps from 'hooks/useNodeProps'

const withWrapper = WrappedComponent => ({
  node,
  viewRect,
  scaleRatio,
  projectState,
  onProjectStateChange,
  style,
  ...props
}) => {
  const [componentProps, setComponentProps] = useState(null)

  const { node: processedNodeAndFrame } = useNodeProps(
    node,
    projectState,
    onProjectStateChange,
    style,
    {
      scaleRatio,
      viewRect,
    }
  )

  useEffect(() => {
    const componentPropsData =
      node && get(projectState, `data.${node.id}.componentProps`)

    const cProps =
      componentPropsData &&
      Object.keys(componentPropsData).reduce((acc, currKey) => {
        const value = componentPropsData[currKey]
        let newValue = value

        if (typeof value === 'function') {
          const funcValue = value(projectState)

          if (typeof funcValue === 'function') {
            newValue = (args = null) => {
              const result = funcValue(args)
              onProjectStateChange &&
                onProjectStateChange(result, null, { updateWithValue: true })
            }
          } else {
            newValue = funcValue
          }
        }

        return {
          ...acc,
          [currKey]: newValue,
        }
      }, {})

    setComponentProps(cProps)
  }, [projectState])

  const wrapperStyles = node && {
    position: 'absolute',
    ...processedNodeAndFrame.processedFrame,
    ...processedNodeAndFrame.style,
    ...style,
    ...(get(componentProps, 'style') || {}),
  }

  if (!componentProps) return null

  return (
    <ErrorBoundary FallbackComponent={<div />}>
      <WrappedComponent
        node={node}
        viewRect={viewRect}
        scaleRatio={scaleRatio}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        {...props}
        {...componentProps}
        {...node?.nodeRef}
        style={wrapperStyles}
      />
    </ErrorBoundary>
  )
}

export default withWrapper
