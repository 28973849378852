const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#fcfcfc'
  },
  content: {
    position: 'relative',
    overflow: 'hidden'
    // transition: 'height .3s'
  },
  collapseButton: {
    transform: 'rotate(90deg)',
    color: '#d8d8d8',
    marginRight: 20
  },
  rotate: {
    transform: 'rotate(180deg)'
  }
})

export default jssStyles
