import { SET_SCROLL_VALUES } from 'constants/actionTypes'

export function setScrollValues(values) {
  return async (dispatch, getState) => {
    const { view } = getState()

    const direction = view.scrollTop < values.scrollTop ? 'down' : 'up'

    dispatch({
      type: SET_SCROLL_VALUES,
      payload: {
        ...view,
        ...values,
        direction,
      },
    })
  }
}
