const jssStyles = {
  container: {
    background: '#fff',
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.06)',
    width: '100%',
    height: 130,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  },
  imageContainer: {
    height: '100%',
    padding: '18px 38px',

    '& img': {
      height: '100%',
    },
  },
  labelContainer: {
    fontSize: 24,
    color: '#5E5E5E',
    lineHeight: '30px',
    fontFamily: 'SairaSemiCondensed-Regular',
  },
}

export default jssStyles
