const HeaderSizePx = 54

const LightTheme =
  'linear-gradient(transparent, transparent 2px, rgb(255, 255, 255) 2px, rgb(255, 255, 255) 20px), linear-gradient(to right, rgb(200, 195, 199), rgb(200, 195, 199) 2px, rgb(255, 255, 255) 2px, rgb(255, 255, 255) 20px)'
const DarkTheme =
  'linear-gradient(transparent, transparent 2px, rgb(33, 33, 33) 2px, rgb(33, 33, 33) 20px), linear-gradient(to right, rgb(200, 195, 199), rgb(200, 195, 199) 2px, rgb(33, 33, 33) 2px, rgb(33, 33, 33) 20px)'

const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    backgroundImage: LightTheme,
    backgroundPosition: '219.5px -2px',
    backgroundSize: '20px 20px',
    fontFamily: 'Open Sans'
  },
  darkContainer: {
    backgroundImage: DarkTheme
  },
  header: {
    position: 'relative',
    height: HeaderSizePx,
    width: '100%',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 200,
    boxShadow: '0 1px 0 0 rgba(193, 190, 193, 0.4)',
    backgroundColor: '#fbfbfb',
    color: 'rgb(85, 77, 86)',
    overflow: 'hidden'
  },
  imageNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  imageName: {
    height: 20,
    fontSize: 18,
    minWidth: 114,
    maxWidth: '60vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
  imageCounter: {
    position: 'absolute',
    fontSize: 11,
    bottom: 6
  },
  headerControls: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  flexStart: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start'
  },
  flexEnd: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  selectControl: {
    width: 90,
    height: '100%'
  },
  themeControl: {
    width: 120,
    height: '100%'
  },
  canvas: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: `calc(100vh - ${HeaderSizePx}px)`,
    padding: '59px 120px'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  themeItem: {
    display: 'flex',

    '& span': {
      paddingLeft: 12
    }
  },
  lightCircle: {
    width: 16,
    height: 16,
    backgroundImage: LightTheme,
    backgroundPosition: '219.5px -2px',
    backgroundSize: '4px 4px',
    borderRadius: '100%'
  },
  darkCircle: {
    width: 16,
    height: 16,
    backgroundImage: DarkTheme,
    backgroundPosition: '219.5px -2px',
    backgroundSize: '4px 4px',
    borderRadius: '100%'
  },
  selectMenuItem: {
    display: 'flex'
  }
})

export default jssStyles
