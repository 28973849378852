import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId } from '../utils'
import { Properties } from '../constants'

const ProjectId = getId('D50EED3894A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: state => {
        return [
          {
            property: 'disableScroll',
            value: true,
          },
          {
            property: Properties.OnboardingLibraryStep,
            value: 0,
          },
          {
            property: 'libraryDataFeed',
            value: async () => {
              const airtableData = await AirtableAPI.fetch(
                'appjUuIGIQZwVsNYc',
                '[C] Library 2.0',
                'Grid view'
              )

              return groupBy(airtableData, 'category')
            },
          },
        ]
      },
    },
  },
  '7FFBB6D5-0AD9-479E-9DB7-014BD38D3E73': {
    // First message item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )
      return {
        display: onboardingStep === 0 ? 'block' : 'none',
      }
    },
  },
  'A382E24E-4667-4547-8AFE-EDDF93586936': {
    // Second message item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )
      return {
        display: onboardingStep === 1 ? 'block' : 'none',
      }
    },
  },
  '9499E781-FFB1-49D0-92D0-C1B8DF275A15': {
    // Third message item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )

      return {
        display: onboardingStep === 2 ? 'block' : 'none',
      }
    },
  },
  'A6D1C8B3-C5A8-4F26-A9D6-0ACB3EFE315B': {
    // First message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: 1,
        },
      ],
    },
  },
  '8128E3B3-834B-451A-9A80-6F56A99D983D': {
    // Second message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: 2,
        },
      ],
    },
  },
  '71EBD82E-FC49-41B0-96B5-24E7A702835A': {
    // Third message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: null,
        },
        {
          property: 'disableScroll',
          value: false,
        },
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: ProjectId,
        },
      ],
    },
  },
  '5364E9FE-DB28-4DD1-88A0-A78ED0FFA4FD': {
    // First body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[0].text') ||
        'Welcome to the Library! Here you can explore trainings, breathing, meditations, and videos all in one place.',
    },
  },
  '52D3AB90-C6F3-4408-9241-1508B2F6ED4D': {
    // Second body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[1].text') ||
        '“Topics” are collections of activities designed to help you reach a certain goal. Check them out here.',
    },
  },
  '6AA524EB-E5C6-4630-BCC2-EFB8293C5C69': {
    // Third body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[2].text') ||
        'Want to train something specific? You can filter the Library by capacities here.',
    },
  },
}

export default ProjectId
