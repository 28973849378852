import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './QrCatchItemStyles'

const QrCatchItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange,
  ...props
}) => {
  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  return <div className={classes.container} style={containerStyles} />
}

QrCatchItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(QrCatchItem)
