import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Ripple from 'common/Ripple'
import Node from 'components/Node'

import styles from './LayoutItemStyles'

const LayoutItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange,
  ...props
}) => {
  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  const findNodeByName = name => find(values(node.nodes), n => n.name === name)

  const handleClick = e => {
    const nameNode = findNodeByName('name')

    onProjectStateChange(nameNode.value.toLowerCase())
  }

  return (
    <div
      className={classes.container}
      style={containerStyles}
      onClick={handleClick}
    >
      {values(node.nodes).map(childNode => (
        <Node
          key={childNode.id}
          node={childNode}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          {...props}
        />
      ))}
      <Ripple color="#b3bbc8" />
    </div>
  )
}

LayoutItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(LayoutItem)
