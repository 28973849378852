const jssStyles = theme => ({
  container: {
    backgroundColor: '#eee',
    height: 33,
    borderRadius: 16.5,
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px 0 8px',
    position: 'absolute',
    whiteSpace: 'nowrap',
  },
  label: {
    fontSize: 15,
    fontFamily: 'SairaSemiCondensed-Regular',
    color: '#212121',
    padding: '0 8px',
  },
  openFilterButton: {
    backgroundImage:
      'url("https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F581EEC9F94A%2FCB6EEA7E-5A89-4FE9-AF5C-AB927D1781C3%402x.png?alt=media")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 15,
    height: 9,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',

    '&:active': {
      opacity: 0.7,
    },
  },
})

export default jssStyles
