const jssStyles = theme => ({
  container: {
    display: 'flex',
    position: 'relative',
    backgroundColor: '#000',
    width: '100%',
    height: '100%',
  },
  linkedContainer: {
    backgroundColor: 'transparent',
  },
})

export default jssStyles
