const jssStyles = theme => ({
  container: {},
  story: {
    position: 'absolute',
    top: 52,
  },
  collapsedStory: {
    borderRadius: '100%',
    width: 72,
    height: 72,

    '&:nth-child(1)': {
      left: 36,
    },
    '&:nth-child(2)': {
      left: 152,
    },
    '&:nth-child(3)': {
      left: 268,
    },
  },
  expandedStory: {
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  closeButton: {
    color: '#fff',
    position: 'absolute',
    right: 23,
    top: 23,
  },
})

export default jssStyles
