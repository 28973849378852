const jssStyles = theme => ({
  container: {
    position: 'absolute',
  },
  day: {
    position: 'absolute',
    '&:before': {
      content: '""',
      position: 'absolute',
      marginLeft: -7,
      width: 54,
      height: 54,
      top: -15,
      left: -15,
      backgroundColor: 'transparent',
      overflow: 'hidden',
      borderRadius: '100%',
    },
  },
  currentDay: {
    '&:before': {
      backgroundColor: '#30BFCA',
      width: 24,
      height: 24,
      top: 0,
      left: 0,
    },
  },
})

export default jssStyles
