import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import { compose } from 'redux'
import { withTheme, withStyles } from '@material-ui/core/styles'

import { LiveStateProperties } from 'constants/general'
import { processNodeFrame, parseNodeName, getId } from 'utils'

import ViewContainer from 'components/ViewContainer'

import styles from './LinkedProjectStyles'

const LinkedProject = ({
  classes,
  theme,
  node: containerNode,
  scaleRatio,
  views,
  viewRect,
  style,
  projectId,
  projectState,
  linkedProjects,
  onHotspotChange,
  onProjectStateChange,
  ...props
}) => {
  const [linkedProject, setLinkedProject] = useState(null)
  const [activeView, setActiveView] = useState(null)

  const getLinkedProjectId = () => {
    const { modifierName } = parseNodeName(containerNode)
    return getId(modifierName)
  }

  useEffect(() => {
    const linkedViewProps = get(projectState, `data.${containerNode.id}.props`)

    if (!linkedViewProps || !linkedViewProps.onInitialState) return

    onProjectStateChange &&
      onProjectStateChange({
        stateValues: linkedViewProps.onInitialState,
      })
  }, [views])

  useEffect(() => {
    const { activeLinkedProjects } = projectState
    const linkedProjectId = getLinkedProjectId()

    if (!linkedProjectId || !activeLinkedProjects.includes(linkedProjectId)) {
      setLinkedProject(null)
      return
    }

    setLinkedProject(linkedProjects[linkedProjectId])
  }, [projectState])

  useEffect(() => {
    if (!linkedProject) return
    setActiveView(linkedProject.views[linkedProject.id])
  }, [linkedProject])

  const handleProjectStateChange = (value, nodeId = null, stateProps = {}) => {
    onProjectStateChange &&
      onProjectStateChange(value, nodeId || containerNode.id, {
        ...stateProps,
        linkedProjectId: linkedProject.id,
      })
  }

  const handleHotspotChange = nodeId =>
    onHotspotChange && onHotspotChange(nodeId, linkedProject.id)

  if (!activeView) return null

  const isFloatNode =
    containerNode.name && containerNode.name.includes('--float')

  let containerStyles = {
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    ...containerNode.style,
    ...style,
  }

  if (isFloatNode) {
    containerStyles = {
      ...containerStyles,
      zIndex: theme.zIndex.layerElevated,
    }
  }

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.fixedContainer]: isFloatNode,
  })

  return (
    linkedProject && (
      <div className={containerClassNames} style={containerStyles}>
        <ViewContainer
          views={linkedProject.views}
          linkedProjects={linkedProjects}
          projectId={linkedProject.id}
          projectState={projectState}
          onHotspotChange={handleHotspotChange}
          onProjectStateChange={handleProjectStateChange}
          isLinkedProject
          {...props}
        />
      </div>
    )
  )
}

LinkedProject.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  views: PropTypes.object,
  viewRect: PropTypes.object,
  projectId: PropTypes.string,
  projectState: PropTypes.object,
  linkedProjects: PropTypes.object,
  onHotspotChange: PropTypes.func,
  onProjectStateChange: PropTypes.func,
  style: PropTypes.object,
}

export default compose(withTheme, withStyles(styles))(LinkedProject)
