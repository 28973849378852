import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './BottomNavigationStyles'

const BottomNavigation = ({
  classes,
  items,
  activeItemId,
  isDesktopDevice,
  onChange,
  style,
}) => {
  const [activeId, setActiveId] = useState(activeItemId)

  useEffect(() => setActiveId(activeItemId), [activeItemId])

  useEffect(() => setActiveId(activeId || get(items, '[0].id')), [items])

  const handleItemClick = item => {
    setActiveId(item.id)
    onChange && onChange(item.id)
  }

  const renderItem = item => {
    const isActive = item.id === activeId

    const itemContainerClassNames = classNames({
      [classes.itemContainer]: true,
      [classes.activeContainer]: isActive,
    })

    return (
      <div
        className={itemContainerClassNames}
        onClick={() => handleItemClick(item)}
        key={item.id}
      >
        <div className={classes.imageContainer}>
          <img src={item.outlineSvg} alt="Outline" />
          <img src={item.activeSvg} alt="Active" />
        </div>

        {item.label}
      </div>
    )
  }

  const fixedStyles = !isDesktopDevice
    ? { position: 'fixed', bottom: 0, top: 'unset' }
    : {}

  const containerStyles = {
    ...style,
    ...fixedStyles,
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {items && items.map(item => renderItem(item))}
    </div>
  )
}

BottomNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  activeItemId: PropTypes.string,
  isDesktopDevice: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(BottomNavigation)
