import get from 'lodash/get'
import findLast from 'lodash/findLast'

import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B780432')

const Properties = {
  ActiveLabelSection: 'activeLabelSection',
}

const labels = {
  Pregame: 'Pre-game',
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
  Score: 'Score',
  Postgame: 'Post-game',
  Comments: 'Comments (24)',
}

const ScrollToOptions = {
  [labels.Pregame]: 1034,
  [labels.Q1]: 1532,
  [labels.Q2]: 2728,
  [labels.Q3]: 3842,
  [labels.Q4]: 4954,
  [labels.Score]: 6108,
  [labels.Postgame]: 6464,
  [labels.Comments]: 7462,
}

const optionsData = [
  {
    label: labels.Pregame,
  },
  {
    label: labels.Q1,
    trending: 0.6,
  },
  {
    label: labels.Q2,
    trending: 0.9,
    highlightQuote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing',
  },
  {
    label: labels.Q3,
    trending: 0.4,
    highlightQuote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing',
  },
  {
    label: labels.Q4,
    trending: 1,
    highlightQuote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing',
  },
  {
    label: labels.Score,
  },
  {
    label: labels.Postgame,
  },
  {
    label: labels.Comments,
  },
]

const componentProps = {
  value: state => get(state, `liveState.${Properties.ActiveLabelSection}`),
  storyOptions: optionsData,
  onChange: state => value => {
    const activeOption = optionsData.find(d => d.label === value)

    return [
      {
        property: LiveStateProperties.ForceViewScrollTo,
        value: {
          viewId: ProjectId,
          scrollProps: {
            scrollTo: ScrollToOptions[activeOption.label],
            duration: 400,
          },
        },
      },
      {
        property: Properties.ActiveLabelSection,
        value,
      },
    ]
  },
}

const MagicNodeIds = {
  root: '104-19',
  container: '104-18',
}

const MagicNodeIdProperty = `${LiveStateProperties.MagicExpandedItem}_${MagicNodeIds.root}`

export const data = {
  [ProjectId]: {
    eventProps: {
      onScroll: scrollValue => {
        const activeLabelSection = findLast(
          Object.keys(ScrollToOptions),
          scrollOptionKey => scrollValue >= ScrollToOptions[scrollOptionKey]
        )

        return [
          {
            property: MagicNodeIdProperty,
            value: scrollValue > 0,
          },
          {
            property: Properties.ActiveLabelSection,
            value: activeLabelSection,
          },
        ]
      },
    },
    props: {
      onInitialState: [
        {
          property: MagicNodeIdProperty,
          value: false,
        },
      ],
    },
  },
  [MagicNodeIds.container]: {
    styleProps: {
      position: 'relative',
      zIndex: 9999,
    },
  },
  '104-36': {
    // Anchorpoint `initial`
    componentProps: {
      ...componentProps,
      initialState: true,
    },
  },
  '113-18': {
    componentProps: {
      // Anchorpoint `expanded`
      ...componentProps,
      initialState: false,
    },
  },
}

export default ProjectId
