import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import { LiveStateProperties } from 'constants/general'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId, fetchFeedState, getGameDataFeedComponentProps } from '../utils'
import { StyleMixins, Properties } from '../constants'

import HomeFeed2ProjectId from './HomeFeed2'

const ProjectId = getId('51289EBD94A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[C] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.OnboardingHomeFeed,
          value: 1,
        },
      ],
    },
  },
  '2F79D2FE-DEA1-44BF-82DE-F80AEB286796': {
    // First `Onboarding` item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: onboardingStep && onboardingStep === 1 ? 'block' : 'none',
      }
    },
  },
  'EBC43702-5965-4721-82E0-84AE25CAF70C': {
    // First `Next button` HOTSPOT in Onboarding dialog
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingHomeFeed,
          value: 2,
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: [ProjectId, HomeFeed2ProjectId],
            scrollProps: {
              scrollTo: 400,
              duration: 400,
            },
          },
        },
        {
          property: 'openCardItemId',
          value: '22ED1EA0-3652-42DB-B0B1-0D808C13B80A',
        },
      ],
    },
  },
  '22ED1EA0-3652-42DB-B0B1-0D808C13B80A': {
    // Card component
    componentProps: {
      ...getGameDataFeedComponentProps('firstCardName'),
      open: async state => get(state, 'liveState.openCardItem'),
    },
  },
  '6E68FA14-6E89-47AE-837B-3371E774CE96': {
    // Program Card `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '16EC56A9-F279-4C82-8A11-49F15FA09C9A': {
    // Program Card `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '52FB7524-D067-4415-A663-19D204EECF3C': {
    // Second `Onboarding` item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: onboardingStep && onboardingStep === 2 ? 'block' : 'none',
      }
    },
  },
  'EBF8020B-78A7-4F1F-A049-1A8B90B7DC9E': {
    // Second `Done` HOTSPOT in Onboarding dialog
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingHomeFeed,
          value: null,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: 'AC72B18A94A',
        },
      ],
    },
  },
  'EBF3E3B1-7652-4E92-AD5C-17F1092D7559': {
    // First body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Onboarding[0].text') ||
        'We created a personalized program based on your assessment. Learn more here.',
    },
  },
  'D87AE45F-560D-4309-84C6-6A42699EB3D5': {
    // Second body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Onboarding[1].text') ||
        'Swipe to see the science behind your assigned practices or save them to your favorites.',
    },
  },
}

export default ProjectId
