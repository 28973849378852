import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pick from 'lodash/pick'
import { withStyles } from '@material-ui/core/styles'

import ArrowBack from '@material-ui/icons/ArrowBackIos'

import { processNodeFrame, findNodeByName, getContrastColor } from 'utils'

import Node from 'components/Node'

import styles from './CapacityHeaderStyles'

const DefaultDataValue = {
  image:
    'https://dl.airtable.com/.attachments/02d8680e05f912bd5d3eecafa0baae35/304dd562/ScreenShot2018-02-26at3.51.24PM.png',
  title: 'Achieve Sharp Focus',
  description: 'Increase your focus and learn to ignore distractions',
  tagLabel: 'Memory',
  tagColor: '',
}

const ComponentName = {
  BackButton: 'back__button',
  Image: 'image',
  Title: 'title',
  Description: 'description',
  Tag: 'chip__item',
  TagLabel: 'tagLabel',
  TagColor: 'tagColor',
}

const CapacityHeader = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  onChange,
  ...props
}) => {
  const [capacityDataValue, setCapacityDataValue] = useState(DefaultDataValue)

  useEffect(() => {
    const value =
      get(projectState, 'liveState.capacityData') || DefaultDataValue

    setCapacityDataValue(value)
  }, [projectState])

  const renderBackButton = node => {
    const nodeStyles = {
      ...node.style,
      ...processNodeFrame(node, scaleRatio, viewRect),
      height: 24,
      width: 24,
    }

    return <ArrowBack className={classes.iconButton} style={nodeStyles} />
  }

  const renderTag = node => {
    const tagLabelNode = findNodeByName(node, ComponentName.TagLabel)

    const tagLabelValue = capacityDataValue[ComponentName.TagLabel]

    const tagColorValue = capacityDataValue[ComponentName.TagColor]

    const { top, left } = pick(processNodeFrame(node, scaleRatio, viewRect), [
      'top',
      'left',
    ])

    const tagStyles = {
      top,
      left,
      backgroundColor: tagColorValue,
      color: tagColorValue ? getContrastColor(tagColorValue) : '',
    }

    const tagLabelStyles = pick(tagLabelNode.style, ['fontSize', 'fontFamily'])

    return (
      <div className={classes.tag} style={tagStyles}>
        <span style={tagLabelStyles}>{tagLabelValue}</span>
      </div>
    )
  }

  const renderNode = node => {
    const { name } = node

    if (name === ComponentName.BackButton) {
      return renderBackButton(node)
    }

    if (name === ComponentName.Tag) {
      return renderTag(node)
    }

    const prop = name === ComponentName.Image ? 'url' : 'value'
    const value = capacityDataValue[name]

    const nodeValueInjected = {
      ...node,
      [prop]: value,
      forceReload: true,
    }

    return (
      <Node
        key={node.id}
        node={nodeValueInjected}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        {...props}
      />
    )
  }

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {renderNode(findNodeByName(containerNode, ComponentName.BackButton))}
      {renderNode(findNodeByName(containerNode, ComponentName.Image))}
      {renderNode(findNodeByName(containerNode, ComponentName.Title))}
      {renderNode(findNodeByName(containerNode, ComponentName.Description))}
      {renderNode(findNodeByName(containerNode, 'chip__item'))}
    </div>
  )
}

CapacityHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  onChange: PropTypes.func,
}

export default withStyles(styles)(CapacityHeader)
