import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

import instanceConfig from 'config/instances'

const firebaseConfig = instanceConfig.firebase

export const provider = new firebase.auth.GoogleAuthProvider()

export default !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase
