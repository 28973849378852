import ForegroundBackground, {
  data as ForegroundBackgroundData,
} from './ForegroundBackground'
import Home, { data as HomeData } from './Home'
import HomeFlip, { data as HomeFlipData } from './HomeFlip'
import AnchorpointMenu, { data as AnchorpointMenuData } from './AnchorpointMenu'
import StoryPageScrollingHeader, {
  data as StoryPageScrollingHeaderData,
} from './StoryPageScrollingHeader'
import StoryGraphic, { data as StoryGraphicData } from './StoryGraphic'
import StoryGraphicWithTeamColors, {
  data as StoryGraphicWithTeamColorsData,
} from './StoryGraphicWithTeamColors'
import MatchupBox, { data as MatchupBoxData } from './MatchupBox'
import FloatingBox, { data as FloatingBoxData } from './FloatingBox'
import ColorCommentaryBox, {
  data as ColorCommentaryBoxData,
} from './ColorCommentaryBox'
import Experiment, { data as ExperimentData } from './Experiment'

export const ProjectIds = [
  Home,
  HomeFlip,
  ForegroundBackground,
  AnchorpointMenu,
  StoryPageScrollingHeader,
  StoryGraphic,
  StoryGraphicWithTeamColors,
  StoryGraphicWithTeamColors,
  MatchupBox,
  FloatingBox,
  ColorCommentaryBox,
  Experiment,
]

const data = {
  typeExperience: 'mobile',
  ...HomeData,
  ...HomeFlipData,
  ...ForegroundBackgroundData,
  ...AnchorpointMenuData,
  ...StoryPageScrollingHeaderData,
  ...StoryGraphicData,
  ...StoryGraphicWithTeamColorsData,
  ...MatchupBoxData,
  ...FloatingBoxData,
  ...ColorCommentaryBoxData,
  ...ExperimentData,
}

export default ProjectIds.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: data,
  }),
  {}
)
