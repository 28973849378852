import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId } from '../utils'
import { Properties } from '../constants'

const ProjectId = getId('675FFA7E94A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: state => {
        return [
          {
            property: 'disableScroll',
            value: true,
          },
          {
            property: Properties.OnboardingLibraryStep,
            value: 0,
          },
          {
            property: 'libraryDataFeed',
            value: async () => {
              const airtableData = await AirtableAPI.fetch(
                'appjUuIGIQZwVsNYc',
                '[B] Library 2.0',
                'Grid view'
              )

              return groupBy(airtableData, 'category')
            },
          },
        ]
      },
    },
  },
  '235B93AA-4B44-44BC-BC5F-ADAF07A5C5B2': {
    // First message item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )
      return {
        display: onboardingStep === 0 ? 'block' : 'none',
      }
    },
  },
  '2D79CF6E-8B4D-48B5-9886-860FA38F78FB': {
    // Second message item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )
      return {
        display: onboardingStep === 1 ? 'block' : 'none',
      }
    },
  },
  '34B35CC8-ADC1-4621-BE39-6B5ACB42EA0D': {
    // Third message item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )

      return {
        display: onboardingStep === 2 ? 'block' : 'none',
      }
    },
  },
  '0B81F0DF-6E17-4464-A095-8018EECCF076': {
    // First message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: 1,
        },
      ],
    },
  },
  '70CF41EB-18FA-45C8-B586-772E7B8FD492': {
    // Second message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: 2,
        },
      ],
    },
  },
  'F323C91B-C627-4B97-9D6B-7B1C149B9007': {
    // Third message action HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingLibraryStep,
          value: null,
        },
        {
          property: 'disableScroll',
          value: false,
        },
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: ProjectId,
        },
      ],
    },
  },
  '62A3A70B-7350-45BF-A534-C8FCCDED086B': {
    // First body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[0].text') ||
        'Welcome to the Library! Here you can explore trainings, breathing, meditations, and videos all in one place.',
    },
  },
  'A550E1C2-609C-4071-8C80-A24CFB9B68A4': {
    // Second body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[1].text') ||
        '“Topics” are collections of activities designed to help you reach a certain goal. Check them out here.',
    },
  },
  'AE374D61-821D-4A0C-A8CF-ED5755DADAD7': {
    // Third body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.libraryDataFeed.Onboarding[2].text') ||
        'Want to train something specific? You can filter the Library by capacities here.',
    },
  },
}

export default ProjectId
