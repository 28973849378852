import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'

import Ripple from 'common/Ripple'

import styles from './HotspotNodeStyles'

const HotspotNode = ({
  classes,
  node,
  onChange,
  projectState,
  onProjectStateChange,
  style,
  ...props
}) => {
  // TODO: Make this as a separate global node treatment
  const isFloatNode = node.name && node.name.includes('--float')

  const rootStyles = {
    ...node.processedFrame,
    position: isFloatNode ? 'fixed' : 'absolute',
    ...style,
  }

  const isDisabled = get(props, 'disabled')

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.disabled]: isDisabled,
  })

  const handleClick = async () => {
    if (isDisabled) return

    if (onProjectStateChange) {
      await onProjectStateChange(projectState)
    }

    onChange()
  }

  const rippleColor = get(style, 'color')

  return (
    <div
      className={containerClassNames}
      onClick={handleClick}
      style={rootStyles}
    >
      {!isDisabled && <Ripple color={rippleColor} />}
    </div>
  )
}

HotspotNode.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  projectState: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onProjectStateChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(HotspotNode)
