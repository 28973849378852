export default {
  chairs: [
    {
      label: 'Klau Chair',
      description:
        "Vintage, classic styling with a modern-day twist, perfectly describes the Klau chair. It's slender high-back shell has been padded for added comfort and is upholstered in rich tobacco faux leather.",
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fchair-2_2.png?alt=media',
      price: '100'
    },

    {
      label: 'Stov Chair',
      description:
        'Scandinavian styling at its best, our Stov side chair exudes a minimalist quality that has been beloved for generations. Crafted from solid wood stained a warm walnut color.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fchair-3_2.png?alt=media',
      price: '140'
    },
    {
      label: 'Agatha Office Chair',
      description:
        'The beautiful Agatha chair adds a stunning mid-century look to your home office. Its beautiful walnut veneered finish , leatherette upholstery, and  chromed base will add a touch of style to any room.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fchair-1_2.png?alt=media',
      price: '180'
    },
    {
      label: 'Aril Chair',
      description:
        'Repose in a chair that constantly forms and reforms new definitions of reality. Let tensions fade away as you embrace the ability to initiate and innovate. Four wooden legs support the diagonal look of the Aril chair where fresh ideas are spun.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fchair-4_2.png?alt=media',
      price: '200'
    },
    {
      label: 'Ariel Chair',
      description:
        'The Ariel takes the classic wing chair to a whole new level. This chair is a modern twist on a classic design with its broad back, glamorous textured grey velvet upholstery, and fabulous silver stainless steel legs.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fchair-6_2.png?alt=media',
      price: '360'
    },
    {
      label: 'Arvo Chair',
      description:
        'Mid-century design gets a remake with the sculptural Arvo chair. The dark brown Beech conical shaped legs boast gold tipping, while the elegantly curved silhouette provides maximum comfort and the wood frame provides strong support.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fchair-5_2.png?alt=media',
      price: '400'
    }
  ],
  tables: [
    {
      label: 'Twain Desk',
      description:
        "Our industrial Twain desk is a minimalist's dream. With its distressed top finish and black steel legs, it makes it stylish addition to your home office.",
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Ftables-2_2.png?alt=media',
      price: '150'
    },
    {
      label: 'Orwell Desk',
      description:
        'Combining practicality and retro inspiration, the Orwell Desk creates the perfect work setting. Sitting on tapered legs, the desk comes with three drawers for easy storage of essentials and is topped off with a light walnut finish. Whether in a contemporary or traditional space.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Ftables-1_2.png?alt=media',
      price: '280'
    },

    {
      label: 'Asta Dining Table',
      description:
        'This stylish Asta dining table is brilliantly crafted featuring a high quality innovative design. The clear rectangular tempered glass table top (10mm) rests on a stylish angular designed solid beech wood base with a coffee stain finish and aluminum center trim.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Ftables-6_2.png?alt=media',
      price: '400'
    },

    {
      label: 'Cava Formal Dining Table',
      description:
        'Dine in minimalist style with the one-of-a-kind Cava dining table. The durable top is hand rubbed to give it an individual sealed concrete look that combines edgy with clean classic lines to produce naturally beautiful results that will blend perfectly with any decor.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Ftables-4_2.png?alt=media',
      price: '700'
    },
    {
      label: 'Reol Formal Dining Table',
      description:
        'Industrial design merged with nature describes the Reol dining table. Rich dark wood texture and black steel fuse together to give you this unique design that you are sure to love.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Ftables-3_2.png?alt=media',
      price: '800'
    }
  ],
  lamps: [
    {
      label: 'Tosv Desk Lamp',
      description:
        'A modern twist on a classic style, the Tosv desk lamp captures the best of mid-century and industrial task lamps in a clean shape for today. The adjustable matte black metal shade and base are complemented by a black & white fabric cord and antique brass angled arm.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Flamps-1_2.png?alt=media',
      price: '50'
    },
    {
      label: 'Vard Table Lamp',
      description:
        'Dress your home in urban chic style with the Vard table lamp. The neck of this practical lamp easily adjusts from 19" to 25" to suit your preferences by turning the wooden peg. A satin steel base incorporates an industrial element.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Flamps-2_2.png?alt=media',
      price: '65'
    },
    {
      label: 'Hilm Table Lamp',
      description:
        'Form beautifully follows function in the Hilm table lamp with USB port. The gently curved design in black and metallic finishes has a rubberwood foot with an integrated USB port for convenient charging and storage of devices, plus a vintage-inspired textured shade that adds panache.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Flamps-3_2.png?alt=media',
      price: '80'
    },
    {
      label: 'Oska Table Lamp',
      description:
        'An interpretation of Scandinavian design, the Osko table lamp is a simple silhouette that allows natural materials to shine. Natural wood grain is paired with a textured white fabric to create an elegant design that suits all styles.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Flamps-4_2.png?alt=media',
      price: '100'
    },
    {
      label: 'Lian Arc Lamp',
      description:
        'A smart and stylish way to light up a seating area, the Lian arc lamp provides overhead lighting that can be controlled with an adjustable shade. Aside from functional assets, an antique brass finish is paired with a white marble base to display striking style.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Flamps-5_2.png?alt=media',
      price: '200'
    },
    {
      label: 'Tati Floor Lamp',
      description:
        'The Tati floor lamp lends industrial-chic styling to your home. The black tripod legs support two adjustable metal shades that let you direct the light as needed. The shades have a stunning hammered copper interior which adds a warm glow to the light.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Flamps-6_2.png?alt=media',
      price: '240'
    }
  ],
  plants: [
    {
      label: 'Neanthe Bella',
      description:
        'Compact dark green indoor palm with long graceful fronds add tropical flare to your office, school or home.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fplants-1_2.png?alt=media',
      price: '50'
    },
    {
      label: 'Majesty Palm Tree',
      description:
        'Easy to grow, water majesty palm to keep soil consistently moist. Never allow the soil to become saturated.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fplants-2_2.png?alt=media',
      price: '70'
    },
    {
      label: 'Corn Stalk Dracaena',
      description:
        'For those who enjoy touching in addition to visual admiration, we have our four foot high Dracaena Silk Plant. “Real to the touch” it’ll fool both senses to create a very realistic illusion.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fplants-3_2.png?alt=media',
      price: '150'
    },
    {
      label: 'Bird of Paradise Palm Tree',
      description:
        'This artificial palm tree is made of silk. The artificial bird of paradise tree comes in a weighted, durable container that measures 6.5 inches tall, 6 inches across the top, and 6.5 inches across the bottom.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fplants-4_2.png?alt=media',
      price: '200'
    },
    {
      label: 'Ficus Silk Tree',
      description:
        'Unlike the real Ficus tree (which is finicky and practically leafless in almost any home light conditions), our Ficus tree is eternally verdant and deep green with 1008 individual leaves on several sturdy branches.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fplants-5_2.png?alt=media',
      price: '230'
    }
  ]
}
