import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Switch as MaterialSwitch } from '@material-ui/core'

import styles from './SwitchStyles'

const CustomSwitch = withStyles(styles.ui)(MaterialSwitch)

const Switch = ({ classes, checked, style, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => setIsChecked(checked), [checked])

  const handleChange = e => {
    const targetChecked = e.target.checked
    setIsChecked(targetChecked)
    onChange && onChange(targetChecked)
  }

  return (
    <div className={classes.container} style={style}>
      <CustomSwitch
        checked={isChecked}
        onChange={handleChange}
        name="checkedB"
      />
    </div>
  )
}

Switch.propTypes = {
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func,
}

export default withStyles(styles)(Switch)
