import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import MirrorRectNode from 'common/MirrorRectNode'

import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './RepeaterStyles'

const RepeaterNode = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  isLastNode,
  ...props
}) => {
  const [repeaterStyles, setRepeaterStyles] = useState({})

  useEffect(() => {
    // Setting static styles
    const repeaterStyle = {
      width: node.hierarchy.width,
      height: node.hierarchy.height,
      marginBottom: node.hierarchy.y,
      marginLeft: node.hierarchy.x,
    }

    if (isLastNode) repeaterStyle.marginRight = node.hierarchy.x

    setRepeaterStyles(repeaterStyle)
  }, [])

  return (
    <MirrorRectNode className={classes.item} style={repeaterStyles}>
      <Node
        node={node}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        {...props}
      />
    </MirrorRectNode>
  )
}

const Repeater = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  style,
  ...props
}) => {
  if (!node.repeaterProcessed) return null

  const containerStyles = {
    ...processNodeFrame(node, scaleRatio, viewRect),
    height: 'fit-content',
    ...style,
  }

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.fixedContainer]: node.name && node.name.includes('--float'),
  })

  return (
    <div className={containerClassNames} style={containerStyles}>
      {node.repeaterProcessed &&
        values(node.nodes).map((n, idx) => {
          const isLastNode = idx === values(node.nodes).length - 1

          return (
            <RepeaterNode
              key={n.id}
              node={n}
              classes={classes}
              scaleRatio={scaleRatio}
              viewRect={viewRect}
              projectState={projectState}
              isLastNode={isLastNode}
              {...props}
            />
          )
        })}
      {!node.repeaterProcessed &&
        values(node.nodes).map(n => (
          <Node
            key={n.id}
            node={n}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            {...props}
          />
        ))}
    </div>
  )
}

Repeater.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  style: PropTypes.object,
}

RepeaterNode.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  isLastNode: PropTypes.bool,
  style: PropTypes.object,
}

export default withStyles(styles)(Repeater)
