import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const Content = ({ classes, day, disabled }) => (
  <motion.h1
    className={classes.contentTitle}
    magicId="title"
    style={{ opacity: disabled ? 0.2 : 1 }}
  >
    {day}
  </motion.h1>
)

Content.propTypes = {
  classes: PropTypes.object.isRequired,
  day: PropTypes.number,
  disabled: PropTypes.bool,
}

export default Content
