import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

import { Sticky } from 'common/StickyContainer'
import { processNodeFrame } from 'utils'

import MirrorRectNode from 'common/MirrorRectNode'
import Node from 'components/Node'

import styles from './SectionItemStyles'

const SectionItem = ({
  classes,
  node,
  scaleRatio,
  onProjectStateChange,
  viewRect,
  ...props
}) => {
  const ref = React.createRef()
  // const [stickyStyles, setStickyStyles] = useState({})
  const [collapseState, setCollapseState] = useState(false)

  const findNodeByName = (name, optNode) =>
    find(values((optNode || node).nodes), ch => ch.name === name)

  const headerNode = findNodeByName('header__item')
  const contentNode = findNodeByName('content__item')

  const containerFrame = processNodeFrame(node, scaleRatio, viewRect)
  const headerFrame = processNodeFrame(headerNode, scaleRatio, viewRect)
  const contentFrame = processNodeFrame(contentNode, scaleRatio, viewRect)

  const containerStyles = {
    ...node.style,
    ...containerFrame,
    height: 'fit-content'
  }

  const headerStyles = {
    ...headerNode.style,
    ...headerFrame
    // ...stickyStyles
  }

  const contentStyles = {
    ...contentNode.style,
    ...contentFrame,
    top: contentFrame.top - headerFrame.height - headerFrame.top,
    height: 'fit-content'
  }

  const forcedStyle = collapseState
    ? {
        height: 0
      }
    : {}

  const headerIcon = findNodeByName('sectionIcon', headerNode)
  const headerTitle = findNodeByName('sectionTitle', headerNode)

  const collapseButtonClassNames = classNames({
    [classes.collapseButton]: true,
    [classes.rotate]: collapseState
  })

  return (
    <div className={classes.container} style={containerStyles} ref={ref}>
      <>
        {/* <Sticky  viewRect={viewRect}> */}
        {/* {({ style }) => ( */}
        <div
          className={classes.header}
          style={{ ...headerStyles }}
          onClick={() => setCollapseState(!collapseState)}
        >
          {[headerIcon, headerTitle].map(childNode => (
            <Node
              key={childNode.id}
              node={childNode}
              scaleRatio={scaleRatio}
              viewRect={viewRect}
              {...props}
            />
          ))}
          <ArrowForwardIos className={collapseButtonClassNames} />
        </div>
        {/* )} */}
        {/* </Sticky> */}
        <MirrorRectNode
          className={classes.content}
          style={contentStyles}
          forcedStyle={forcedStyle}
        >
          {values(contentNode.nodes).map(childNode => (
            <Node
              key={childNode.id}
              node={childNode}
              scaleRatio={scaleRatio}
              viewRect={viewRect}
              {...props}
            />
          ))}
        </MirrorRectNode>
        <MirrorRectNode
          className={classes.content}
          style={contentStyles}
          forcedStyle={forcedStyle}
        >
          {values(contentNode.nodes).map(childNode => (
            <Node
              key={childNode.id}
              node={childNode}
              scaleRatio={scaleRatio}
              viewRect={viewRect}
              {...props}
            />
          ))}
        </MirrorRectNode>
      </>
    </div>
  )
}

SectionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  viewEvents: PropTypes.object
}

export default withStyles(styles)(SectionItem)
