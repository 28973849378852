import get from 'lodash/get'
import { isProductionInstance } from 'utils'
import * as projectStatesTypes from 'constants/projectStatesTypes'

export const ProjectId = `477A3E8594A${isProductionInstance() ? '0' : ''}`

export default {
  [ProjectId]: {
    /** Views */
    E7A2AAA194A: {
      // 2nd View
      props: {
        onInitialState: [
          {
            property: projectStatesTypes.WAIT,
            value: 500
          },
          {
            property: 'loading',
            value: false
          }
        ]
      }
    },
    FDA8367B94A: {
      // 3rd View
      styleProps: {
        overflowX: 'hidden'
      },
      props: {
        onInitialState: [
          {
            property: projectStatesTypes.WAIT,
            value: 500
          },
          {
            property: 'loading',
            value: false
          }
        ]
      }
    },
    '4E165D3F94A': {
      // 4th View
      styleProps: {
        overflow: 'hidden'
      },
      props: {
        onInitialState: [
          {
            property: projectStatesTypes.WAIT,
            value: 500
          },
          {
            property: 'loading',
            value: false
          }
        ]
      }
    },
    /** Nodes */
    /* 1st View */
    '0E4D1AD0-4C5A-4441-9A94-3F433A7657AE': {
      // Loading bar
      conditionalProps: {
        active: state => state.liveState.loading
      }
    },
    'E9D2070C-4F6E-427D-9FE4-54844B3FBDED': {
      // `Your address` input component
      props: {
        onStateChange: value => ({
          property: 'addressInput',
          value
        })
      }
    },
    '52113119-E113-47AF-B73A-5706837258EC': {
      // `Search Pros` Hotspot
      conditionalProps: {
        disabled: state => !state.liveState.addressInput
      },
      props: {
        onStateChange: () => [
          {
            property: 'loading',
            value: true
          },
          {
            property: projectStatesTypes.WAIT,
            value: 1000
          }
        ]
      }
    },
    '1FDBC18B-E276-4D72-8337-435D36E14B2D': {
      // Time items
      props: {
        onStateChange: value => [
          {
            property: 'professionalData',
            value
          }
        ]
      }
    },
    /* 2nd View */
    '2540048B-E2FB-4ABE-A1C8-3C6BBC0651A4': {
      // Loading bar
      conditionalProps: {
        active: state => state.liveState.loading
      }
    },
    'A4EE97AE-231F-4D62-8F40-6399F97E0ABC': {
      // `Address`
      conditionalProps: {
        value: state => state.liveState.addressInput
      }
    },
    '420D0C50-FA83-4B5C-850B-5DD312474D8E': {
      // `Alex Card` Buttons
      props: {
        onStateChange: value => [
          {
            property: 'professionalData',
            value
          },
          {
            property: 'loading',
            value: true
          },
          {
            property: projectStatesTypes.WAIT,
            value: 1000
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: 'FDA8367B94A'
          }
        ]
      }
    },
    '65B1671C-063D-423F-8716-49B8126FF04D': {
      // `Lisa Card` Buttons
      props: {
        onStateChange: value => [
          {
            property: 'professionalData',
            value
          },
          {
            property: 'loading',
            value: true
          },
          {
            property: projectStatesTypes.WAIT,
            value: 750
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: 'FDA8367B94A'
          }
        ]
      }
    },
    '69A3F54F-D586-46F6-A342-CFC8A0964D70': {
      // `Sarah Card` Buttons
      props: {
        onStateChange: value => [
          {
            property: 'professionalData',
            value
          },
          {
            property: 'loading',
            value: true
          },
          {
            property: projectStatesTypes.WAIT,
            value: 500
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: 'FDA8367B94A'
          }
        ]
      }
    },
    'AB1DD1B0-8D46-4E8B-A6E2-64BEAABEE8E3': {
      // `Pete Card` Buttons
      props: {
        onStateChange: value => [
          {
            property: 'professionalData',
            value
          },
          {
            property: 'loading',
            value: true
          },
          {
            property: projectStatesTypes.WAIT,
            value: 1200
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: 'FDA8367B94A'
          }
        ]
      }
    },
    '82257349-C57A-47DA-B1CE-A209917D6740': {
      // `Tyler Card` Buttons
      props: {
        onStateChange: value => [
          {
            property: 'professionalData',
            value
          },
          {
            property: 'loading',
            value: true
          },
          {
            property: projectStatesTypes.WAIT,
            value: 1300
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: 'FDA8367B94A'
          }
        ]
      }
    },
    'A0738802-2930-4BC5-A0C9-123B70ECE46A': {
      // `Margaret Card` Buttons
      props: {
        onStateChange: value => [
          {
            property: 'professionalData',
            value
          },
          {
            property: 'loading',
            value: true
          },
          {
            property: projectStatesTypes.WAIT,
            value: 400
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: 'FDA8367B94A'
          }
        ]
      }
    },
    /* 3rd View */
    'FD07F6F4-03E2-421D-B836-8EFED45A1C98': {
      // Loading bar
      conditionalProps: {
        active: state => state.liveState.loading
      }
    },
    '08C6D986-EFA0-4CB2-889E-D32E3136B1CC': {
      // Professional Name
      conditionalProps: {
        value: state => get(state, 'liveState.professionalData.name')
      },
      styleProps: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }
    },
    '80C1FEC8-C8AF-404F-825E-E4336BC34952': {
      // Professional Image
      conditionalProps: {
        value: state => get(state, 'liveState.professionalData.imageId')
      }
    },
    '1FDD210F-29CA-4019-A66D-D156D650B240': {
      // `Book <Name>` professional
      conditionalProps: {
        value: state => {
          const name = get(state, 'liveState.professionalData.name')
          if (!name) return 'Alex'
          return `Book ${name.split(' ')[0]}`
        }
      },
      styleProps: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }
    },
    'A2248ACE-77C2-4567-BDC8-D46E0AFABA27': {
      // `Address`
      conditionalProps: {
        value: state => get(state, 'liveState.addressInput')
      }
    },
    'D19F7B62-F044-4912-8097-6E527E170F2D': {
      // Book item `Name` Label
      conditionalProps: {
        value: state => {
          const name = get(state, 'liveState.professionalData.name')
          if (!name) return 'Alex A.'
          return name.substring(0, 5)
        }
      }
    },
    'E9247BC4-81E8-4819-BE28-716F8DD33058': {
      // Book item `Date` Label
      conditionalProps: {
        value: state => {
          const time = get(state, 'liveState.professionalData.time')
          if (!time) return '8:20am'
          return `Thur, January 12 at ${time}`
        }
      }
    },
    '21CE99E0-FC5D-4A18-803C-0ABBC56E5F09': {
      // `Select and continue` Hotspot to 4th view
      props: {
        onStateChange: () => [
          {
            property: 'loading',
            value: true
          },
          {
            property: projectStatesTypes.WAIT,
            value: 1000
          }
        ]
      }
    },
    '16E4803B-149B-47D0-98B7-6F7AC80956A5': {
      // Customer review
      conditionalProps: {
        value: state => {
          const name = get(state, 'liveState.professionalData.name') || 'Alex'
          return `${
            name.split(' ')[0]
          } is dope. Gave me an awesome cut, and it was super quick! Highly recommend.`
        }
      }
    },
    /* 4th view */
    '46BCC92D-99CE-44C2-9BBE-3D9FD5B452DB': {
      // Loading bar
      conditionalProps: {
        active: state => state.liveState.loading
      }
    },
    '49C59667-3DB9-46F3-8907-B6B9CDD70218': {
      // Back to `Name` Profile
      conditionalProps: {
        value: state => {
          const name = get(state, 'liveState.professionalData.name') || 'Alex'
          return `Back to ${name.substring(0, 5)}'s Profile`
        }
      }
    },
    'B2DBBDD0-216D-4F7D-9CA3-C69CB7F48C9E': {
      // Profile picture: 1st item
      conditionalProps: {
        value: state => get(state, 'liveState.professionalData.imageId')
      }
    },
    '24575934-5CED-4CE2-A7D8-5193F02C07ED': {
      // Profile picture: 2nd item
      conditionalProps: {
        value: state => get(state, 'liveState.professionalData.imageId')
      }
    },
    '1F8FA4B7-FF63-4620-B122-C6245719583B': {
      // Profile picture: 3rd item
      conditionalProps: {
        value: state => get(state, 'liveState.professionalData.imageId')
      }
    },
    '2F5374B3-411B-4DD8-914B-941215114326': {
      // Book `Name`: 1st item
      conditionalProps: {
        value: state => {
          const name = get(state, 'liveState.professionalData.name') || 'Alex'
          return `Book ${name.split(' ')[0]}`
        }
      }
    },
    '72F753D7-CE75-48C3-B326-F72BB049CBD6': {
      // Book `Name`: 2nd item
      conditionalProps: {
        value: state => {
          const name = get(state, 'liveState.professionalData.name') || 'Alex'
          return `Book ${name.split(' ')[0]}`
        }
      }
    },
    '5405A20E-884C-4D91-988D-C6CE33D2E780': {
      // Haircut information: 1st item
      conditionalProps: {
        value: state => {
          const time = get(state, 'liveState.professionalData.time') || '9:45am'
          return `1 haircut at ${time} on Thursday January 12, 2020`
        }
      }
    },
    '808BC6A4-B822-4197-9F0F-1FB3AAB3D3E8': {
      // Haircut information: 2nd item
      conditionalProps: {
        value: state => {
          const time = get(state, 'liveState.professionalData.time') || '9:45am'
          return `1 haircut at ${time} on Thursday January 12, 2020`
        }
      }
    },
    '1E7987BD-3465-43E1-8299-8D665B9234CC': {
      // Haircut information: 3rd item
      conditionalProps: {
        value: state => {
          const time = get(state, 'liveState.professionalData.time') || '9:45am'
          return `1 haircut at ${time} on Thursday January 12, 2020`
        }
      }
    },
    '95E63101-A587-4E9D-BD55-D60AC70BE5B2': {
      // Appointment confirmation
      conditionalProps: {
        value: state => {
          const name =
            get(state, 'liveState.professionalData.name') || 'Alex A.'
          return `Your appointment with ${name} is confirmed!`
        }
      }
    },
    '452B3B94-D23F-41C7-9504-C9404AE7C18B': {
      styleProps: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      },
      // Address information
      conditionalProps: {
        value: state => get(state, 'liveState.addressInput')
      }
    },
    '8FABCE7D-B036-43A7-824F-1A511A842997': {
      // Go to 5th view
      props: {
        onStateChange: () => [
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '65229B7994A'
          }
        ]
      }
    },
    // 5th View
    'B9C977A0-10DB-422C-91FA-17F6AE07C609': {
      // Professional Name
      styleProps: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      },
      conditionalProps: {
        value: state => {
          const name =
            get(state, 'liveState.professionalData.name') || 'Alex A.'
          return name.substring(0, 7)
        }
      }
    },
    'F0755379-8E8C-43E2-B9FF-46F1CA62D059': {
      // Professional Image
      conditionalProps: {
        value: state => get(state, 'liveState.professionalData.imageId')
      }
    },
    '88B53FCD-A587-45CF-9514-0C51A104CA4C': {
      conditionalProps: {
        value: state => {
          const time = get(state, 'liveState.professionalData.time') || '9:45am'
          return `1 haircut at ${time} on Thursday January 12, 2020`
        }
      }
    },
    '874A237E-A8AB-473B-AC18-538CD8345B60': {
      conditionalProps: {
        value: state => state.liveState.addressInput
      }
    },
    'E274B845-73AD-4831-ABA4-B45CCF844CBB': {
      conditionalProps: {
        value: state => state.liveState.addressInput
      }
    },
    '18E36F1D-14A4-42A2-85E3-10F4BBC3B6B6': {
      conditionalProps: {
        value: state => {
          const name = get(state, 'liveState.professionalData.name') || 'Alex'
          return `Contact ${name.split(' ')[0]}`
        }
      }
    },
    '38488AA2-B386-46EF-99AB-7B540B2830F5': {
      conditionalProps: {
        value: state => {
          const name = get(state, 'liveState.professionalData.name') || 'Alex'
          return `Helpful Tips from ${name.split(' ')[0]}`
        }
      }
    }
  }
}
