import ShorcutData from './shortcut'
import CraftyData from './crafty'
import TotalBrainData from './totalbrain'
import TaboolaData from './taboola'
import SanJoseData from './sanjose'
import MealPalData from './mealpal'
import ExperimentsData from './experiments'
import BstEvrData from './bstevr'

export default {
  ...ShorcutData,
  ...CraftyData,
  ...TotalBrainData,
  ...TaboolaData,
  ...SanJoseData,
  ...MealPalData,
  ...ExperimentsData,
  ...BstEvrData,
}
