import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactProgressiveImage from 'react-progressive-image'

import { getFirebaseUrl } from 'utils'

const ImageFormat = 'png'
const DefaultScales = ['0.1', '1', '2']
const IntervalTestImageLoadedMs = 5000

let ImagesReady = [false, false, false]

const ProgressiveImage = ({ node, projectId }) => {
  const [imagesReady, setImagesReady] = useState(ImagesReady)

  const getImageByScale = scale =>
    getFirebaseUrl(`project/${projectId}/${node.id}@${scale}x.${ImageFormat}`)

  function loadImage(scale, scaleIdx) {
    const img = new Image()
    img.src = getImageByScale(scale)

    img.onload = () => {
      ImagesReady = [...ImagesReady]
      ImagesReady[scaleIdx] = scale
      setImagesReady(ImagesReady)
    }

    img.onerror = () => {
      setTimeout(() => loadImage(scale), IntervalTestImageLoadedMs)
    }
  }

  useEffect(() => {
    const scales = node.scales ? node.scales : DefaultScales

    scales.forEach((scale, scaleIdx) => loadImage(scale, scaleIdx))
  }, [])

  const renderImageWithScale = scale => {
    const rootStyles = {
      width: '100%',
      height: 'auto'
    }

    return (
      <img src={getImageByScale(scale)} alt={node.name} style={rootStyles} />
    )
  }

  let nextImageIdxToRender = imagesReady.findIndex(
    imageIndex => imageIndex === false
  )

  // If all images are ready, next image to render the one with highest scale.
  nextImageIdxToRender =
    nextImageIdxToRender === -1 ? imagesReady.length - 1 : nextImageIdxToRender

  if (nextImageIdxToRender <= 0) return null

  const scaleA = imagesReady[nextImageIdxToRender - 1]
  const scaleB = imagesReady[nextImageIdxToRender]

  if (!scaleB) return renderImageWithScale(scaleA)

  return (
    <ReactProgressiveImage
      placeholder={getImageByScale(scaleA)}
      src={getImageByScale(scaleB)}
    >
      {(src, loading) => {
        return loading
          ? renderImageWithScale(scaleA)
          : renderImageWithScale(scaleB)
      }}
    </ReactProgressiveImage>
  )
}

ProgressiveImage.propTypes = {
  node: PropTypes.object.isRequired,
  style: PropTypes.object,
  projectId: PropTypes.string
}

export default ProgressiveImage
