import get from 'lodash/get'
import { getId } from '../utils'
import {
  Properties,
  DayPoints,
  DayPointsCategories,
  TodayPointsDate,
} from '../constants'

const ProjectId = getId('FT4IODZ5FB30')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: Properties.ProgramPoints,
          value: async state => {
            const currentDate =
              get(state, `liveState.${Properties.ProgramPoints}.currentDate`) ||
              TodayPointsDate

            return {
              currentDate,
              todayDate: TodayPointsDate,
              dayPoints: DayPoints,
              categories: DayPointsCategories,
            }
          },
        },
      ],
    },
    styleProps: {
      overflowX: 'hidden',
    },
  },
  '3-19': {
    // Program List
    componentProps: {
      day: state =>
        get(state, `liveState.${Properties.ProgramPoints}.currentDate`) ||
        TodayPointsDate,
      pointsData: state => {
        const dayPoints =
          get(state, `liveState.${Properties.ProgramPoints}.dayPoints`) ||
          DayPoints

        return dayPoints[
          get(state, `liveState.${Properties.ProgramPoints}.currentDate`) ||
            TodayPointsDate
        ]
      },
      categoriesData: state =>
        get(state, `liveState.${Properties.ProgramPoints}.categories`),
      showPracticeFreq: state =>
        get(state, `liveState.${Properties.ShowPracticeFreq}`),
      activeCategories: null,
      showDescription: true,
    },
  },
  '5-31': {
    // Switch
    componentProps: {
      onChange: state => value => ({
        property: Properties.ShowPracticeFreq,
        value,
      }),
    },
  },
  '4-3': {
    // `DaysItem` in Calendar
    props: {
      onStateChange: value => ({
        property: Properties.ProgramPoints,
        value: {
          todayDate: TodayPointsDate,
          currentDate: value,
          dayPoints: DayPoints,
          categories: DayPointsCategories,
        },
      }),
    },
  },
  '5-19': {
    // `points__item`
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShowPracticeFreq}`)
        ? 'none'
        : 'block',
    }),
  },
  '6-0': {
    // `practices__item`
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShowPracticeFreq}`)
        ? 'block'
        : 'none',
    }),
  },
}

export default ProjectId
