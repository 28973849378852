const jssStyles = theme => ({
  container: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    fontFamily: 'SairaSemiCondensed-Regular',
    color: '#4A4A4A',
  },
  containerActive: {
    '& $bottomContainer': {
      height: '90%',
      paddingTop: 82,
    },
  },
  bottomContainer: {
    borderRadius: '20px 20px 0 0',
    backgroundColor: '#FCFCFC',
    position: 'absolute',
    width: '100%',
    height: 0,
    bottom: 0,
    left: 0,
    transition:
      'height 200ms cubic-bezier(0.4, 0, 0.6, 1), padding-top 200ms cubic-bezier(0.4, 0, 0.6, 1)',
    paddingTop: 0,
    paddingLeft: 100,
  },
  category: {
    marginTop: 32,
  },
  categoryTitle: {
    fontFamily: 'SairaSemiCondensed-SemiBold',
    textTransform: 'upperCase',
  },
  item: {
    position: 'relative',
    fontSize: 15,
    marginTop: 12,
    letterSpacing: 0.19,
    '&:active': {
      opacity: 0.7,
    },
  },
  collapseButton: {
    position: 'absolute',
    width: 24,
    height: 32,
    backgroundImage:
      'url("https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2FA206C4C194A%2Farrow-down.svg?alt=media")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    left: 0,
    right: 0,
    top: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  activeItem: {
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 14,
      height: 11,
      backgroundImage:
        'url("https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2FA206C4C194A%2Fcheck-icon.svg?alt=media")',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      left: -24,
      top: 2,
    },
  },
})

export default jssStyles
