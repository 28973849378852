import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import { motion } from 'framer-motion'

import { animateScrollTo } from 'utils/general'

const DefaultThreshold = 0.15

const browserTest = {
  isIE: window.navigator.userAgent.toLowerCase().indexOf('msie') > -1,
  isFirefox: window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
}

const ElementListener =
  browserTest.isIE || browserTest.isFirefox
    ? document.documentElement
    : document.body

const DefaultPrivateState = {
  isMouseDown: false,
  lastMousePosition: null,
  startTime: null,
}

const eventProperties = {
  scrollAttr: 'scrollTop',
  eventMove: 'clientY',
  scrollLength: 'scrollHeight',
  scrollOffset: 'offsetHeight',
  threshold: null,
  clearListeners: [],
  animation: null,
  privateState: DefaultPrivateState,
  rafHandle: null,
}

const PanScrollProvider = ({
  horizontal,
  threshold,
  disabled,
  forceScrollTo,
  onUpdateForceScrollTo,
  scrollDistance,
  children,
}) => {
  let refElement = null

  const removeListenerFactory = (eventName, listener) => () =>
    ElementListener.removeEventListener(eventName, listener)

  const addEventListenerWithClear = (type, func) => {
    ElementListener.addEventListener(type, func)
    const clear = removeListenerFactory(type, func)
    eventProperties.clearListeners.push(clear)
  }

  const onPanStart = event => {
    if (disabled) return null

    if (eventProperties.animation) {
      clearTimeout(eventProperties.animation)
    }
    eventProperties.privateState = {
      ...eventProperties.privateState,
      isMouseDown: true,
      lastMousePosition: event[eventProperties.eventMove],
      startTime: new Date(),
      positionStart: refElement && refElement[eventProperties.scrollAttr],
    }
  }

  const onPan = event => {
    if (disabled) return null

    const { isMouseDown, lastMousePosition } = eventProperties.privateState

    if (!isMouseDown) {
      return null
    }

    if (refElement === null) {
      return null
    }

    if (lastMousePosition === null) {
      return null
    }
    refElement[eventProperties.scrollAttr] +=
      lastMousePosition - event[eventProperties.eventMove]

    eventProperties.privateState = {
      ...eventProperties.privateState,
      lastMousePosition: event[eventProperties.eventMove],
    }

    document.body.classList.add('dragScroll')
  }

  const onPanEnd = () => {
    if (disabled) return null

    const { startTime } = eventProperties.privateState
    const time = (new Date() - startTime) / 1000

    eventProperties.privateState = {
      ...eventProperties.privateState,
      isMouseDown: false,
      lastMousePosition: null,
      time,
    }

    setTimeout(() => {
      document.body.classList.remove('dragScroll')
    }, 500)
  }

  useEffect(() => {
    if (horizontal) {
      eventProperties.scrollAttr = 'scrollLeft'
      eventProperties.eventMove = 'clientX'
      eventProperties.scrollLength = 'scrollWidth'
      eventProperties.scrollOffset = 'offsetWidth'
    }
    eventProperties.threshold = threshold || DefaultThreshold
    addEventListenerWithClear('mouseup', onPanEnd)
    addEventListenerWithClear('mousemove', onPan)

    return () => {
      eventProperties.clearListeners.forEach(clear => clear())
    }
  }, [])

  useEffect(() => {
    if (isNil(forceScrollTo)) return

    const { scrollTo: scrollingTo, duration } = forceScrollTo

    const scrollToPositon = scrollingTo || forceScrollTo
    const durationMs = duration || 0

    animateScrollTo(refElement, scrollToPositon, durationMs)
    onUpdateForceScrollTo && onUpdateForceScrollTo()
  }, [forceScrollTo])

  const provisionRef = element => {
    refElement = element
  }

  const scrollRight = () => {
    refElement.classList.add('dragScroll')
    refElement.scrollLeft += scrollDistance
    refElement.classList.remove('dragScroll')
  }

  const scrollLeft = () => {
    refElement.classList.add('dragScroll')
    refElement.scrollLeft -= scrollDistance
    refElement.classList.remove('dragScroll')
  }

  const scrollTo = position => {
    if (!refElement) {
      return null
    }
    refElement.classList.add('dragScroll')
    refElement.scrollLeft = position
    refElement.classList.remove('dragScroll')
  }

  const clickItem = callback => {
    const { time } = eventProperties.privateState
    if (!time || time > threshold) {
      return null
    }
    return callback()
  }

  return (
    <motion.div onPanStart={onPanStart} onPan={onPan} onPanEnd={onPanEnd}>
      {children({
        clickItem,
        ref: provisionRef,
        scrollRight,
        scrollLeft,
        scrollTo,
      })}
    </motion.div>
  )
}

PanScrollProvider.propTypes = {
  horizontal: PropTypes.string,
  threshold: PropTypes.number,
  scrollDistance: PropTypes.number,
  disabled: PropTypes.bool,
  forceScrollTo: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onUpdateForceScrollTo: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export default PanScrollProvider
