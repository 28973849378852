import React from 'react'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import camelCase from 'lodash/camelCase'
import AirtableAPI from 'api/AirtableAPI'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import CapacityCard from '@totalbrain-components/CapacityCard'
import { getId, getCategoryData, getExerciseData } from '../utils'
import { Properties } from '../constants'

import GameExerciseProjectId from './GameExercise'
import OnboardingLibraryProjectId from './OnboardingLibrary'
import LibraryCapacityDetailsId from './LibraryCapacityDetails'

const ProjectId = getId('FT4IODZ5FB70')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: OnboardingLibraryProjectId,
        },
        {
          property: 'capacityData',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[A] Capacities 2.0',
              'Grid view'
            )

            return airtableData
          },
        },
        {
          property: Properties.ExercisesData,
          value: async state => getExerciseData(state),
        },
        {
          property: 'capacityCategoryData',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[A] Capacity Categories',
              'Grid view'
            )

            return airtableData.map(({ category, label, level }) => ({
              id: camelCase(label),
              category,
              label,
              level,
            }))
          },
        },
      ],
    },
  },
  '7-8': {
    // `Tabs`
    componentProps: {
      index: state => get(state, 'liveState.libraryTabIndex'),
      onChange: state => value => ({
        property: 'libraryTabIndex',
        value,
      }),
    },
  },
  '7-36': {
    // `Capacity list` Stack
    componentProps: {
      items: state => {
        const capacityData = get(state, 'liveState.capacityData')

        if (!capacityData) return null

        return capacityData.map(({ title, image }) => (
          <CapacityCard title={title} imageSrc={image} />
        ))
      },
      onItemClick: state => value => {
        const capacityData = get(state, 'liveState.capacityData')
        return [
          {
            property: LiveStateProperties.ForceViewScrollTo,
            value: {
              viewId: ProjectId,
              value: 0,
            },
          },
          {
            property: Properties.ActiveCapacityDetails,
            value: capacityData[value],
          },
          {
            property: projectStatesTypes.ENABLE_LINKED_PROJECT,
            value: LibraryCapacityDetailsId,
          },
        ]
      },
      itemStyle: {
        marginBottom: 16,
      },
      style: {
        height: 'fit-content',
      },
    },
    styleProps: state => ({
      marginBottom: 20,
    }),
  },
  '7-18': {
    // Search Item
    componentProps: {
      onActive: state => value => {
        return {
          property: 'searchItemActive',
          value,
        }
      },
      onChange: state => value => ({
        property: 'searchText',
        value,
      }),
    },
    styleProps: state => ({
      display: get(state, 'liveState.activeFilterId') ? 'none' : 'flex',
    }),
  },
  '7-30': {
    // Recent searches
    styleProps: state => ({
      display:
        get(state, 'liveState.searchItemActive') &&
        !get(state, 'liveState.searchText')
          ? 'block'
          : 'none',
    }),
  },
  '7-27': {
    // `Games Sticky` Item (Explore)
    componentProps: {
      categoriesData: state => {
        const catData = getCategoryData(state)
        const searchText = get(state, 'liveState.searchText')

        if (!searchText) return catData

        const filteredData = catData.reduce((acc, curr) => {
          const games = curr.games.filter(
            ({ title, description }) =>
              title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
              description.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          )

          acc.push({
            ...curr,
            games,
          })
          return acc
        }, [])

        return filteredData
      },
      onChange: state => ({ e, id, value }) => {
        if (e === Properties.GameCardEvents.FavChange) {
          return {
            property: `${Properties.Favorited}${id}`,
            value,
          }
        }

        if (e === Properties.GameCardEvents.CardClicked) {
          return [
            {
              property: LiveStateProperties.ForceViewScrollTo,
              value: {
                viewId: ProjectId,
                value: 0,
              },
            },
            {
              property: LiveStateProperties.DisableScroll,
              value: true,
            },
            {
              property: Properties.ActiveGameId,
              value: id,
            },
            {
              property: projectStatesTypes.ENABLE_LINKED_PROJECT,
              value: GameExerciseProjectId,
            },
          ]
        }
      },
    },
    styleProps: state => ({
      display:
        get(state, 'liveState.searchItemActive') &&
        !get(state, 'liveState.searchText')
          ? 'none'
          : 'block',
    }),
    frameProps: state => ({
      y: get(state, 'liveState.activeFilterId') ? 52 : 112,
    }),
  },
  '25-31': {
    // `FilterSheet Item` bottom nav
    componentProps: {
      capacityCategoryData: state => {
        const capacityCategoryData = get(
          state,
          'liveState.capacityCategoryData'
        )

        return groupBy(capacityCategoryData, 'category')
      },
      active: state => get(state, 'liveState.filterSheetItemActive'),
      onChange: state => value => ({
        property: 'activeFilterId',
        value,
      }),
      onClose: state => () => [
        {
          property: 'filterSheetItemActive',
          value: false,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
    styleProps: state => ({
      display: get(state, 'liveState.filterSheetItemActive') ? 'block' : 'none',
    }),
  },
  '26-0': {
    // `Filter` HOTSPOT button
    props: {
      onStateChange: () => [
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: ProjectId,
            value: 0,
          },
        },
        {
          property: 'filterSheetItemActive',
          value: true,
        },
      ],
    },
    styleProps: state => ({
      display:
        get(state, 'liveState.searchItemActive') ||
        get(state, 'liveState.activeFilterId')
          ? 'none'
          : 'block',
    }),
  },
  '7-24': {
    // `filter__button` component
    styleProps: state => ({
      display:
        get(state, 'liveState.searchItemActive') ||
        get(state, 'liveState.activeFilterId')
          ? 'none'
          : 'block',
    }),
  },
  '25-24': {
    // `Filter Chip`
    componentProps: {
      label: state => {
        const capacityCategoryData = get(
          state,
          'liveState.capacityCategoryData'
        )
        const activeFilterId = get(state, 'liveState.activeFilterId')

        if (!capacityCategoryData || !activeFilterId) return
        const capacity = capacityCategoryData.find(d => d.id === activeFilterId)
        return capacity.label
      },
      onOpen: state => value => ({
        property: 'filterSheetItemActive',
        value: true,
      }),
      onClose: state => () => [
        {
          property: 'activeFilterId',
          value: null,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
  },
}

export default ProjectId
