import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import find from 'lodash/find'
import values from 'lodash/values'
import { lighten, withStyles } from '@material-ui/core/styles'

import MaterialLinearProgress from '@material-ui/core/LinearProgress'

import { processNodeFrame } from 'utils'

import styles from './LinearProgressStyles'

const getLinearProgress = color =>
  withStyles({
    root: {
      backgroundColor: lighten(color, 0.5)
    },
    bar: {
      backgroundColor: color
    }
  })(MaterialLinearProgress)

const LinearProgress = ({ classes, node, scaleRatio, viewRect, ...props }) => {
  const rectangleNode = find(
    values(node.nodes),
    n => n.type === 'shape' && n.shapeType === 'rectangle'
  )

  const CustomLinearProgress = getLinearProgress(
    get(rectangleNode, 'style.background') || '#000'
  )

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.active]: get(props, 'active')
  })

  const processedFrame = processNodeFrame(node, scaleRatio, viewRect)

  const containerStyles = {
    ...processedFrame,
    top: viewRect.top + processedFrame.top,
    left: 'auto'
    // left: viewRect.left + processedFrame.left
  }

  return (
    <CustomLinearProgress
      className={containerClassNames}
      style={containerStyles}
    />
  )
}

LinearProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object
}

export default withStyles(styles)(LinearProgress)
