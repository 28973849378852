import AirtableAPI from 'api/AirtableAPI'
import { isProductionInstance } from 'utils'
import { fetchSectionsData } from './utils'
import { StyleMixins } from './constants'

const ProjectId = `1F37D8BD94A${isProductionInstance() ? '0' : ''}`

export const data = {
  'C5995F3B-EE69-4C42-909D-A8FBBEBE7C57': {
    // Section repeater list
    dataProps: {
      data: async state => {
        const sectionsData = await fetchSectionsData(
          'C5995F3B-EE69-4C42-909D-A8FBBEBE7C57',
          '7DE42E21-0C0A-49B6-B979-D26D5FE7D345'
        )
        return sectionsData
      },
    },
  },
  'BC643A78-41CA-4262-AA0A-85ED8D3CEF28': {
    // Title card image
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '2DDDDD69-F6D0-4944-8F06-04AF3D4CDB46': {
    // Repeater list with Capacities
    dataProps: {
      data: async () => ({
        data: await AirtableAPI.fetch(
          'appjUuIGIQZwVsNYc',
          'Capacities',
          'Grid view'
        ),
        repeaterId: '2DDDDD69-F6D0-4944-8F06-04AF3D4CDB46',
      }),
    },
  },
  '5D56BD7B-9366-4843-80E2-EC88BA2EC2E0': {
    // Search input
    props: {
      onStateChange: value => ({
        property: 'searchCapacityInput',
        value,
      }),
    },
  },
  '3DDEFE47-2192-4306-B1B0-83678B7579D8': {
    // Capacity image
    props: {
      isBackgroundImage: true,
    },
  },
  '9FE68F98-2DE6-4DBB-A489-2A6AFC9A0D94': {
    // Hotspot to `Capacity Details`
    props: {
      onStateChange: value => [
        // {
        //   property: projectStatesTypes.ACTIVE_LINKED_VIEW_ID,
        //   value: 'CFE65EFA94A',
        // },
        {
          property: 'capacityData',
          value,
        },
      ],
    },
  },
  '59751CA8-85FF-4788-9DBA-37218384774E': {
    // Card image
    props: {
      isBackgroundImage: true,
    },
  },
  '06A8A7D3-2DC3-4705-B63D-D47A06ACDCD6': {
    // Title card image
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
}

export default ProjectId
