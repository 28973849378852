import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import DateButton from './DateButton'

import styles from './MagicMotionStyles'

const MagicMotion = ({ classes, style }) => {
  const [expandedDay, setCollapsedDay] = useState()

  const days = [25, 26, 27, 28, 29]

  return (
    <div className={classes.container}>
      <div className={classes.dates}>
        {days.map(day => (
          <DateButton
            key={day}
            classes={classes}
            day={day}
            disabled={expandedDay !== day && expandedDay !== undefined}
            onExpand={() => setCollapsedDay(day)}
            onCollapse={() => setCollapsedDay()}
          />
        ))}
      </div>
    </div>
  )
}

MagicMotion.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default withStyles(styles)(MagicMotion)
