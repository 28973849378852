import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import head from 'lodash/head'
import { ResizeObserver } from '@juggle/resize-observer'

const MirrorRectNode = ({ className, style, forcedStyle, children }) => {
  const ref = React.createRef()
  const [dynamicStyles, setDynamicStyles] = useState({})

  useEffect(() => {
    // Setting dynamic styles
    const resizeObserver = new ResizeObserver(entries => {
      const entry = head(entries)
      if (!entry) return

      const {
        target: { offsetWidth: width, offsetHeight: height },
      } = entry

      setDynamicStyles({ width, height })
    })

    const el = get(ref, 'current.firstElementChild')
    if (el) resizeObserver.observe(el)
  }, [])

  const containerStyles = {
    ...style,
    ...dynamicStyles,
    ...forcedStyle,
  }

  return (
    <div className={className} style={containerStyles} ref={ref}>
      {children}
    </div>
  )
}

MirrorRectNode.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  forcedStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export default MirrorRectNode
