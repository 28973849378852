import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import GameItem from '@totalbrain-components/GameItem'

import styles from './GamesListItemStyles'

const GamesListItem = ({
  classes,
  gamesData,
  projectState,
  onChange,
  style,
}) => {
  const handleChange = (e, id, value) => onChange && onChange({ e, id, value })

  return (
    <ul className={classes.container} style={style}>
      {gamesData &&
        gamesData.map(game => {
          return (
            <li className={classes.gameContainer} key={game.id}>
              <GameItem
                id={game.id}
                projectState={projectState}
                onChange={handleChange}
                isDraggable={false}
              />
            </li>
          )
        })}
    </ul>
  )
}

GamesListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  gamesData: PropTypes.arrayOf(PropTypes.object),
  projectState: PropTypes.object,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(GamesListItem)
