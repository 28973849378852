const jssStyles = theme => ({
  container: {
    position: 'fixed',
    zIndex: theme.zIndex.layerElevated,
    display: 'none'
  },
  active: {
    display: 'block'
  }
})

export default jssStyles
