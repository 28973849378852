import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import { LiveStateProperties } from 'constants/general'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId, fetchFeedState, getGameDataFeedComponentProps } from '../utils'
import { StyleMixins, Properties } from '../constants'

import HomeFeed2ProjectId from './HomeFeed2'

const ProjectId = getId('FT4IODZ5FB432')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[A] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.OnboardingHomeFeed,
          value: 1,
        },
      ],
    },
  },
  '43-30': {
    // First `Onboarding` item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: onboardingStep && onboardingStep === 1 ? 'block' : 'none',
      }
    },
  },
  '43-25': {
    // First `Next button` HOTSPOT in Onboarding dialog
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingHomeFeed,
          value: 2,
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: [ProjectId, HomeFeed2ProjectId],
            scrollProps: {
              scrollTo: 400,
              duration: 400,
            },
          },
        },
      ],
    },
  },
  '43-21': {
    // Card component
    componentProps: {
      ...getGameDataFeedComponentProps('firstCardName'),
      open: async state => get(state, 'liveState.openCardItem'),
    },
  },
  '43-8': {
    // Program Card `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '43-9': {
    // Program Card `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '43-31': {
    // Second `Onboarding` item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: onboardingStep && onboardingStep === 2 ? 'block' : 'none',
      }
    },
  },
  '43-27': {
    // Second `Done` HOTSPOT in Onboarding dialog
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingHomeFeed,
          value: null,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: 'AC72B18A94A',
        },
      ],
    },
  },
  '43-22': {
    // First body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Onboarding[0].text') ||
        'We created a personalized program based on your assessment. Learn more here.',
    },
  },
  '43-23': {
    // Second body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Onboarding[1].text') ||
        'Swipe to see the science behind your assigned practices or save them to your favorites.',
    },
  },
}

export default ProjectId
