const jssStyles = theme => ({
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 64,
  },
  sheetContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#c4c4c4',
  },
})

export default jssStyles
