import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import raf from 'raf'

const _ = {
  node: null,
  framePending: null,
  rafHandle: null,
  subscribers: [],
  events: [
    'resize',
    'scroll',
    'touchstart',
    'touchmove',
    'touchend',
    'pageshow',
    'load',
  ],
}

const Container = ({ ...props }) => {
  const subscribe = handler => {
    _.subscribers = _.subscribers.concat(handler)
  }

  const unsubscribe = handler => {
    _.subscribers = _.subscribers.filter(current => current !== handler)
  }

  const getParent = () => _.node

  const getChildContext = () => ({
    subscribe,
    unsubscribe,
    getParent,
  })

  const notifySubscribers = e => {
    if (_.framePending) return null

    const { currentTarget } = e

    _.rafHandle = raf(() => {
      _.framePending = false
      const { top, bottom } = _.node.getBoundingClientRect()

      _.subscribers.forEach(handler =>
        handler({
          distanceFromTop: top,
          distanceFromBottom: bottom,
          eventSource: currentTarget === window ? document.body : _.node,
        })
      )
    })
    _.framePending = true
  }

  useEffect(() => {
    _.events.forEach(event => window.addEventListener(event, notifySubscribers))

    return () => {
      _.events.forEach(event =>
        window.removeEventListener(event, notifySubscribers)
      )
    }
  }, [])

  return (
    <div
      style={{ height: 'fit-content' }}
      ref={ref => {
        _.node = ref
      }}
      onScroll={notifySubscribers}
      onTouchStart={notifySubscribers}
      onTouchMove={notifySubscribers}
      onTouchEnd={notifySubscribers}
      {...props}
    />
  )
}

Container.propTypes = {}

export default Container
