import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'
import theme from 'styles/muiTheme'

const ProjectId = getId('FDINX7UJYA722')

const InfoMotionProps = {
  variants: {
    visible: {
      opacity: 1,
      transition: {
        delay: 0.2,
      },
    },
    hidden: {
      opacity: 0,
    },
  },
  initial: 'hidden',
  animate: 'visible',
}

const ExpandScrollMs = 300

const MagicNodeIds = {
  First: '74-1',
  Second: '82-0',
  Third: '82-29',
}

const handleExpansion = (state, nodeId) => [
  {
    property: LiveStateProperties.DisableScroll,
    value: true,
  },
  {
    property: `${LiveStateProperties.MagicExpandedItem}_${nodeId}`,
    value: true,
  },
  {
    property: LiveStateProperties.ForceViewScrollTo,
    value: {
      viewId: state.projectId,
      scrollProps: {
        scrollTo: 0,
        duration: ExpandScrollMs * 2,
      },
    },
  },
]

const handleContraction = (state, nodeId) => [
  {
    property: LiveStateProperties.DisableScroll,
    value: false,
  },
  {
    property: `${LiveStateProperties.MagicExpandedItem}_${nodeId}`,
    value: false,
  },
]

export const data = {
  '73-4': {
    // `item-1 default`
    eventProps: {
      onClick: state => handleExpansion(state, MagicNodeIds.First),
    },
  },
  '82-10': {
    // `item-2 default`
    eventProps: {
      onClick: state => handleExpansion(state, MagicNodeIds.Second),
    },
  },
  '82-32': {
    // `item-3 default`
    eventProps: {
      onClick: state => handleExpansion(state, MagicNodeIds.Third),
    },
  },
  '120-9': {
    // `item-1 expanded` back
    eventProps: {
      onClick: state => handleContraction(state, MagicNodeIds.First),
    },
  },
  '120-10': {
    // `item-2 expanded` back
    eventProps: {
      onClick: state => handleContraction(state, MagicNodeIds.Second),
    },
  },
  '120-11': {
    // `item-3 expanded` back
    eventProps: {
      onClick: state => handleContraction(state, MagicNodeIds.Third),
    },
  },
  '73-3': {
    // `item-1 expanded`
    styleProps: {
      zIndex: theme.zIndex.layerElevated,
    },
  },
  '93-1': {
    // `item-1` info
    motionProps: InfoMotionProps,
  },
  '94-5': {
    // `item-2` info
    motionProps: InfoMotionProps,
  },
  '94-16': {
    // `item-3` info
    motionProps: InfoMotionProps,
  },
  '93-6': {
    // `item-1` expanded rectangle
    styleProps: {
      willChange: 'transform',
    },
  },
  '94-10': {
    // `item-2` expanded rectangle
    styleProps: {
      willChange: 'transform',
    },
  },
  '94-21': {
    // `item-3` expanded rectangle
    styleProps: {
      willChange: 'transform',
    },
  },
}

export default ProjectId
