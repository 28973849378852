import get from 'lodash/get'
import random from 'lodash/random'
import isEmpty from 'lodash/isEmpty'

import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

import AirtableAPI from 'api/AirtableAPI'

import { useTransform, useSpring, useMotionValue } from 'framer-motion'

const ProjectId = getId('FHFIT3R6B71634185')

const Properties = {
  Stories: 'stories',
}

const CardProps = {
  story: {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta aliquet est eget condimentum. Suspendisse condimentum',
    imageSrc: '',
    tags: ['tag 1', 'tag 2'],
    user: {
      username: 'Waynes_Colts',
      avatarSrc:
        'https://s3-us-west-2.amazonaws.com/figma-alpha-api/img/a791/fcec/2e03a1086552a3cd3b85586210d15f8c',
    },
  },
  readText: '6 min read',
  commentText: '27 comments',
  rectType: 'lg',
}

const TrendingStoryProps = {
  assets: [
    {
      resource: {
        url: '',
      },
    },
  ],
  headline:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  author: {
    username: 'username',
  },
}

function getTrendingStoryProps(state, storyIndex) {
  const stories = get(state, `liveState.${Properties.Stories}`)

  if (!stories) {
    return TrendingStoryProps
  }

  return {
    ...TrendingStoryProps,
    assets: [
      {
        resource: {
          url: stories[storyIndex].graphic,
        },
      },
    ],
  }
}

const colorsPropsMap = {
  leftPrimaryColor: 'leftPrimaryBgColor',
  leftSecondaryColor: 'leftSecondaryBgColor',
  leftDetail: 'leftDetailBgColor',
  rightPrimaryColor: 'rightPrimaryBgColor',
  rightSecondaryColor: 'rightSecondaryBgColor',
  rightDetail: 'rightDetailBgColor',
}

function getStoryCardProps(state, index) {
  const stories = get(state, `liveState.${Properties.Stories}`)

  if (!index) return {}

  if (isEmpty(stories)) {
    return CardProps.story
  }

  return {
    ...CardProps.story,
    imageSrc: stories[index].graphic,
  }
}

function getStoryColorsProps(state, index, colorKey) {
  const stories = get(state, `liveState.${Properties.Stories}`)

  if (isEmpty(stories) || !colorKey) {
    return null
  }

  return stories[index][colorKey]
}

const reduceStoryComponentProps = (arrayIds, props) =>
  arrayIds.reduce(
    (acc, id, index) => ({
      [`${id}`]:
        id === '165-15'
          ? {
              componentProps: {
                ...props,
              },
            }
          : {
              componentProps: {
                ...props,
                story: state => getStoryCardProps(state, index),
                leftPrimaryBgColor: state =>
                  getStoryColorsProps(state, index, 'leftPrimaryColor'),
                leftSecondaryBgColor: state =>
                  getStoryColorsProps(state, index, 'leftSecondaryColor'),
                leftDetailBgColor: state =>
                  getStoryColorsProps(state, index, 'leftDetail'),
                rightPrimaryBgColor: state =>
                  getStoryColorsProps(state, index, 'rightPrimaryColor'),
                rightSecondaryBgColor: state =>
                  getStoryColorsProps(state, index, 'rightSecondaryColor'),
                rightDetailBgColor: state =>
                  getStoryColorsProps(state, index, 'rightDetail'),
              },
            },
      ...acc,
    }),
    {}
  )

const storyXLIds = [
  '165-15',
  '165-41',
  '165-0',
  '163-5622',
  '163-5773',
  '163-5807',
  '163-5877',
  // '163-6367', Skeleton
  // '163-6382', Skeleton
  // '163-6401', Skeleton
  // '163-6420', Skeleton
]

const storySMIds = ['165-38', '163-5654', '163-5839']

const storyXLCards = reduceStoryComponentProps(storyXLIds, {
  ...CardProps,
  rectType: 'xl',
})

const storySMCards = reduceStoryComponentProps(storySMIds, {
  ...CardProps,
  // story: state => getStoryProps(state),
  rectType: 'sm',
})

const MultipleMagicNodeIds = {
  root: '176-273',
  container: '176-272',
  itemDefault: '176-263',
  itemExpanded: '177-1806',
}

const MultipleMagicExpandedProperty = `${LiveStateProperties.MagicExpandedItem}_${MultipleMagicNodeIds.container}`

export const data = {
  [ProjectId]: {
    typeExperience: 'desktop',
    props: {
      onInitialState: [
        {
          property: MultipleMagicExpandedProperty,
          value: false,
        },
        {
          property: Properties.Stories,
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appNXZQxfLfRHyfvI',
              'Story Graphic with Colors',
              'Grid view'
            )

            return airtableData
          },
        },
      ],
    },
  },
  [MultipleMagicNodeIds.root]: {
    styleProps: {
      position: 'absolute',
      zIndex: 9999,
    },
  },
  [MultipleMagicNodeIds.itemDefault]: {
    eventProps: {
      onClick: state => [
        {
          property: MultipleMagicExpandedProperty,
          value: true,
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: state.projectId,
            scrollProps: {
              scrollTo: 0,
            },
          },
        },
      ],
    },
  },
  [MultipleMagicNodeIds.itemExpanded]: {
    eventProps: {
      onClick: state => [
        {
          property: MultipleMagicExpandedProperty,
          value: false,
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: state.projectId,
            scrollProps: {
              scrollTo: 0,
            },
          },
        },
      ],
    },
  },
  '163-5706': {
    componentProps: {
      title: 'Trending',
      stories: state => {
        return [
          getTrendingStoryProps(state, 0),
          getTrendingStoryProps(state, 1),
          getTrendingStoryProps(state, 5),
          getTrendingStoryProps(state, 3),
          getTrendingStoryProps(state, 8),
        ]
      },
    },
  },
  ...storyXLCards,
  ...storySMCards,
  // '163-4233': {
  //   // Trending Stories
  //   componentProps: {
  //     placeholderA: 'Baltimore Ravens',
  //     placeholderB: 'Kansas City Chiefs',
  //     options: [
  //       { type: 'player', id: 574, label: 'Alfred Morris', sport: 'NFL' },
  //       { type: 'player', id: 1050, label: 'Travis Frederick', sport: 'NFL' },
  //       { type: 'player', id: 588, label: 'Haason Reddick', sport: 'NFL' },
  //       { type: 'player', id: 890, label: 'Jared Goff', sport: 'NFL' },
  //       { type: 'player', id: 1792, label: 'Fred Brown', sport: 'NFL' },
  //       { type: 'player', id: 1848, label: 'Fred Warner', sport: 'NFL' },
  //       { type: 'player', id: 407, label: 'Jared Cook', sport: 'NFL' },
  //       { type: 'player', id: 1913, label: 'Will Redmond', sport: 'NFL' },
  //       { type: 'player', id: 1127, label: 'Kasey Redfern', sport: 'NFL' },
  //       { type: 'player', id: 412, label: 'Deadrin Senat', sport: 'NFL' },
  //       { type: 'player', id: 415, label: 'Seth Roberts', sport: 'NFL' },
  //       { type: 'team', id: 431, label: 'Kansas City Chiefs', sport: 'NFL' },
  //       { type: 'team', id: 756, label: 'Seattle Seahawks', sport: 'NFL' },
  //       { type: 'team', id: 823, label: 'Tennessee Titans', sport: 'NFL' },
  //       {
  //         type: 'team',
  //         id: 942,
  //         label: 'Washington Football Team',
  //         sport: 'NFL',
  //       },
  //       { type: 'team', id: 942, label: 'Minnesota Vikings', sport: 'NFL' },
  //       { type: 'team', id: 731, label: 'New England Patriots', sport: 'NFL' },
  //       { type: 'team', id: 731, label: 'New York Giants', sport: 'NFL' },
  //       { type: 'team', id: 742, label: 'Philadelphia Eagles', sport: 'NFL' },
  //       { type: 'team', id: 742, label: 'San Francisco 49ers', sport: 'NFL' },
  //       { type: 'team', id: 790, label: 'Tampa Bay Buccaneers', sport: 'NFL' },
  //     ],
  //   },
  // },
}

export default ProjectId
