const jssStyles = theme => ({
  container: {
    position: 'absolute',
    fontFamily: 'sans-serif',
  },
  dates: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '3rem',
  },
  contentTitle: {
    color: 'navy',
    fontWeight: 800,
    margin: 0,
  },
  cardContainer: {
    width: '3rem',
    height: '3rem',
    position: 'relative',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  card: {
    overflow: 'hidden',
    boxShadow: '0 0 10px -2px rgba(0, 0, 0, 0.2)',
    padding: '2rem',
    borderRadius: '1rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  expandedCard: {
    width: '10rem',
    height: '10rem',
    backgroundColor: 'navy',
    position: 'relative',
    left: '-100%',
    top: '150%',
    '& $contentTitle': {
      fontSize: '5em',
      color: 'white',
    },
  },
  compactCard: {
    width: '3rem',
    height: '3rem',
    padding: '0.5rem',
    borderRadius: '1.5rem',
    '& $contentTitle': {
      fontSize: '1.5em',
    },
  },
  secondaryCard: {
    backgroundColor: 'white',
    height: '2.5rem',
    padding: '0.5rem',
    justifyContent: 'center',
    width: '8rem',
    marginLeft: '1rem',
  },
})

export default jssStyles
