const jssStyles = theme => ({
  container: {
    cursor: 'default',
    position: 'absolute'
  },
  inputContainer: {
    position: 'absolute',
    borderColor: '#d1d1d1'
  },
  inputContainerFocused: {
    '& $icon, & $input, & span, & p': {
      color: '#0c1d48'
    },
    '& $closeIcon': {
      display: 'block'
    }
  },
  input: {
    position: 'absolute',
    background: 'transparent',
    border: 'none',
    height: '100%',
    width: '90%',
    margin: 0,
    padding: 0
  },
  icon: {
    position: 'absolute'
  },
  closeIcon: {
    display: 'none',
    cursor: 'pointer',
    position: 'absolute',
    height: '100%',
    right: '1.5%',
    opacity: 0.4,

    '&:hover': {
      opacity: 1
    }
  },
  autocompleteContainer: {
    cursor: 'pointer',
    position: 'absolute',
    background: 'white',
    width: '100%',
    top: '100%',
    overflow: 'hidden',
    marginTop: 4,
    userSelect: 'none',

    '& span, & p': {
      whiteSpace: 'nowrap',
      paddingTop: 2
    },
    '& p': {
      paddingTop: 10
    }
  },
  autocompleteItem: {
    display: 'block',
    position: 'relative',

    '&:hover': {
      backgroundColor: '#ebebeb'
    }
  }
})

export default jssStyles
