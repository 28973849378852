import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import { AnimateSharedLayout } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import useNodeProps from 'hooks/useNodeProps'
import { LiveStateProperties } from 'constants/general'
import { processNodeFrame, findNodeByName, injectMagicToNodes } from 'utils'

import ItemNode from 'components/Node/nodes/ComponentNode/Item'

import styles from './ToriiMagicMotionStyles'

const MagicNode = ({
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  projectId,
  style,
}) => {
  const { node: processedNode, nodeProps, nodeStyles } = useNodeProps(
    containerNode,
    projectState,
    onProjectStateChange,
    style
  )

  return (
    <ItemNode
      node={containerNode}
      scaleRatio={scaleRatio}
      viewRect={viewRect}
      projectState={projectState}
      onProjectStateChange={onProjectStateChange}
      projectId={projectId}
      style={nodeStyles}
      {...nodeProps}
    />
  )
}

MagicNode.propTypes = {
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  style: PropTypes.object,
}

const ToriiMagicMotion = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  projectId,
}) => {
  const [magicNodes, setMagicNodes] = useState(null)
  const [isExpanded, setIsExpanded] = useState(null)

  useEffect(() => {
    const defaultNode = findNodeByName(containerNode, 'item')
    const expandedNode = findNodeByName(containerNode, 'item--expanded')
    const processedNodes = injectMagicToNodes(defaultNode, expandedNode)

    setMagicNodes(processedNodes)
  }, [])

  useEffect(() => {
    const expandedItemValue = get(
      projectState,
      `liveState.${LiveStateProperties.MagicExpandedItem}_${containerNode.id}`
    )

    setTimeout(() => setIsExpanded(expandedItemValue))
  }, [projectState])

  if (!magicNodes) return null

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.isExpanded]: isExpanded,
  })
  const containerStyles = processNodeFrame(
    isExpanded ? magicNodes.expandedNode : magicNodes.defaultNode,
    scaleRatio,
    viewRect
  )

  const defaultNodeProcessed = {
    ...magicNodes.defaultNode,
    frame: {
      ...magicNodes.defaultNode.frame,
      x: 0,
      y: 0,
    },
  }

  return (
    <div className={containerClassNames} style={containerStyles}>
      <AnimateSharedLayout>
        {isExpanded ? (
          <>
            <MagicNode
              node={magicNodes.expandedNode}
              scaleRatio={scaleRatio}
              viewRect={viewRect}
              projectState={projectState}
              onProjectStateChange={onProjectStateChange}
              projectId={projectId}
              style={{ zIndex: 999 }}
            />
          </>
        ) : (
          <MagicNode
            node={defaultNodeProcessed}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            projectId={projectId}
          />
        )}
      </AnimateSharedLayout>
    </div>
  )
}

ToriiMagicMotion.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  projectId: PropTypes.string.isRequired,
}

export default withStyles(styles)(ToriiMagicMotion)
