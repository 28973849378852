const jssStyles = theme => ({
  container: {
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid #E8E8E8',
    borderRadius: 3,
    boxShadow: '0 0 10px rgba(228, 228, 228, 0.5)',
    '&:hover': {
      boxShadow: '0 0 20px rgba(175, 175, 175, 0.5)'
    }
  },
  button: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    border: '1px solid #E1E3E5',
    background: 'transparent',
    borderRadius: 2,
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#081C37',
      boxShadow: '0 0 10px rgba(228, 228, 228, 0.5)'
    }
  },
  faceContainer: {
    position: 'absolute',
    borderRadius: 1,
    border: '1px solid #E1E3E5',
    background: '#D8D8D8',
    overflow: 'hidden'
  }
})

export default jssStyles
