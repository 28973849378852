import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'

import instanceConfig from '../config/instances'

/**
 * A set of paths that represent what portions of the redux store should be persisted to local
 * storage. These paths will later be fed to lodash pick(): https://lodash.com/docs/4.17.4#pick
 * @type {Array<String>}
 */
const StoreSubsetForLocalStorage = []

/**
 * The name of string key under which all persisted state will be stored in local storage.
 * @type {String}
 */
const LocalStorageKey = instanceConfig.localStorage.reduxStateKey

/**
 * Uses the keys from `STORE_SUBSET_FOR_LOCAL_STORAGE` to get a subset of the current redux store
 * state and then saves that stringified object to localStorage.
 * @param {Object} state - The redux store state.
 */
export function saveStoreToLocalStorage(state) {
  try {
    const persistedState = cloneDeep(pick(state, StoreSubsetForLocalStorage))
    localStorage.setItem(LocalStorageKey, JSON.stringify(persistedState))
  } catch (err) {
    console.error('Error writing to localStorage')
  }
}

/**
 * Loads the persisted redux store object from localStorage.
 * @return {Object} A subset of the redux store state.
 */
export function loadStoreFromLocalStorage() {
  try {
    const persistedState = localStorage.getItem(LocalStorageKey)
    if (persistedState === null) {
      return undefined
    }
    return JSON.parse(persistedState)
  } catch (err) {
    console.error('Error reading from localStorage')
    return undefined
  }
}
