import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B72261867')

export const data = {
  [ProjectId]: {
    props: {
      // onInitialState: [
      //   {
      //     property: LiveStateProperties.DisableScroll,
      //     value: true,
      //   },
      // ],
    },
  },
  '226-1868': {
    componentProps: {
      multiline: true,
      label: 'Multiline Placeholder',
      placeholder: 'Placeholder',
      variant: 'outlined',
    },
  },
}

export default ProjectId
