import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, findNodeByName, formatNumberWithComma } from 'utils'

import Node from 'components/Node'

import styles from './ProgressItemStyles'

const NodeNames = {
  Label: 'label',
  Color: 'color',
  Description: 'description',
  Icon: 'icon',
  Points: 'points',
  BarItem: 'bar__item',
}

const ProgressItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  projectState,
  onProjectStateChange,
  viewRect,
  ...props
}) => {
  const { currentDate, dayPoints } =
    get(projectState, 'liveState.programPoints') || {}

  const renderBarNode = node => {
    const labelNode = findNodeByName(containerNode, NodeNames.Label)

    const currentDayData = dayPoints[currentDate]
    const currentDataForNode =
      currentDayData &&
      currentDayData.data.find(c => c.label === labelNode.value)

    const colorNode = findNodeByName(node, NodeNames.Color)

    const barContainerStyles = {
      ...processNodeFrame(node, scaleRatio, viewRect),
      ...node.style,
    }

    const barItemStyles = {
      backgroundColor: colorNode.url,
      width: `${currentDataForNode.progress}%`,
    }

    return (
      <div
        key={node.id}
        className={classes.barContainer}
        style={barContainerStyles}
      >
        <div className={classes.barItem} style={barItemStyles} />
      </div>
    )
  }

  const renderPointsNode = node => {
    const processedNode = {
      ...node,
      value: formatNumberWithComma(node.value),
    }

    const pointsClassNames = classNames({
      [classes.points]: true,
      [classes.disabled]: !Number(node.value),
    })

    return (
      <div className={pointsClassNames} key={processedNode.id}>
        <Node
          node={processedNode}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          {...props}
        />
      </div>
    )
  }

  const renderNode = node => {
    return (
      <Node
        key={node.id}
        node={node}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        {...props}
      />
    )
  }

  const containerFrame = processNodeFrame(containerNode, scaleRatio, viewRect)

  const containerStyles = {
    ...containerNode.style,
    ...containerFrame,
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(containerNode.nodes).map(node => {
        if (node.name === NodeNames.BarItem) return renderBarNode(node)

        if (node.name === NodeNames.Points) return renderPointsNode(node)

        if (!values(NodeNames).includes(node.name)) return null

        return renderNode(node)
      })}
    </div>
  )
}

ProgressItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  viewEvents: PropTypes.object,
}

export default withStyles(styles)(ProgressItem)
