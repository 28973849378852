import { combineReducers } from 'redux'

// Import reducers
import { routerReducer as routing } from 'react-router-redux'

import user from './userReducer'
import page from './pageReducer'
import view from './viewReducer'
import projectState from './projectStateReducer'

export default combineReducers({
  // Library reducers
  routing,
  // App reducers
  user,
  page,
  view,
  projectState,
})
