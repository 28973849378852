import update from 'immutability-helper'

import {
  FETCH_PROJECT_PAGE,
  FETCH_LINKED_PROJECTS,
} from 'constants/actionTypes'

import initialState from './initialState'

export default (state = initialState.page, action) => {
  switch (action.type) {
    case FETCH_PROJECT_PAGE:
      return update(state, {
        project: {
          $set: {
            ...initialState.page.project,
            ...action.payload,
          },
        },
      })
    case FETCH_LINKED_PROJECTS:
      return update(state, {
        linkedProjects: {
          $set: action.payload,
        },
      })
    default:
      return state
  }
}
