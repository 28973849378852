import get from 'lodash/get'
import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

import AirtableAPI from 'api/AirtableAPI'

import { useTransform, useSpring, useMotionValue } from 'framer-motion'

const ProjectId = getId('FHFIT3R6B71342043')

const CardProps = {
  story: {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta aliquet est eget condimentum. Suspendisse condimentum',
    imageSrc: '',
    tags: ['tag 1', 'tag 2'],
    user: {
      username: 'Waynes_Colts',
      avatarSrc:
        'https://s3-us-west-2.amazonaws.com/figma-alpha-api/img/a791/fcec/2e03a1086552a3cd3b85586210d15f8c',
    },
  },
  readText: '6 min read',
  commentText: '27 comments',
  rectType: 'lg',
}

const TrendingStoryProps = {
  assets: [
    {
      resource: {
        url: '',
      },
    },
  ],
  headline:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  author: {
    username: 'username',
  },
}

function getStoryProps(state, storyIndex) {
  const stories = get(state, `liveState.${Properties.Stories}`)

  if (!stories) {
    return CardProps.story
  }

  return {
    ...CardProps.story,
    imageSrc: storyIndex ? stories[storyIndex].graphic : '',
  }
}

function getTrendingStoryProps(state, storyIndex) {
  const stories = get(state, `liveState.${Properties.Stories}`)

  if (!stories) {
    return TrendingStoryProps
  }

  return {
    ...TrendingStoryProps,
    assets: [
      {
        resource: {
          url: stories[storyIndex].graphic,
        },
      },
    ],
  }
}

const Properties = {
  Stories: 'stories',
  IsScrolled: 'isScrolled',
}

const MultipleMagicNodeIds = {
  root: '151-3',
  container: '151-2',
  imageDefault: '151-0',
  imageExpanded: '150-81',
}

const Globals = {
  scrollY: null,
}

const getParallaxStyles = () => {
  Globals.scrollY = useMotionValue(0)

  const scale = useSpring(
    useTransform(Globals.scrollY, [0, 500], ['1', '1.05'], {
      clamp: false,
    })
  )

  return {
    scale,
  }
}

const MultipleMagicExpandedProperty = `${LiveStateProperties.MagicExpandedItem}_${MultipleMagicNodeIds.container}`

const DefaultTransition = 'transform .3s linear'

export const data = {
  [ProjectId]: {
    typeExperience: 'desktop',
    props: {
      onInitialState: [
        {
          property: MultipleMagicExpandedProperty,
          value: false,
        },
        {
          property: Properties.Stories,
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appNXZQxfLfRHyfvI',
              'Story Graphic 0.5',
              'Grid view'
            )

            return airtableData
          },
        },
      ],
    },
    eventProps: {
      onScroll: scrollValue => {
        Globals.scrollY && Globals.scrollY.set(scrollValue)

        return {
          property: LiveStateProperties.ScrollPosition,
          value: scrollValue,
        }
      },
    },
  },
  [MultipleMagicNodeIds.root]: {
    styleProps: {
      position: 'absolute',
      zIndex: 9999,
    },
  },
  [MultipleMagicNodeIds.imageDefault]: {
    conditionalProps: {
      url: state =>
        get(state, 'liveState.swapImage')
          ? 'https://dl.airtable.com/.attachments/0309185dacb19f0480c1d869af6eec65/6e3873d0/Single-16x9.svg'
          : 'https://dl.airtable.com/.attachments/8775f52eef56124e89e3e25297c39c3e/65bee105/Double-16x9.svg',
    },
    eventProps: {
      onClick: state => [
        {
          property: MultipleMagicExpandedProperty,
          value: true,
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: state.projectId,
            scrollProps: {
              scrollTo: 0,
            },
          },
        },
      ],
    },
    styleProps: () => getParallaxStyles(),
  },
  [MultipleMagicNodeIds.imageExpanded]: {
    conditionalProps: {
      url: state =>
        get(state, 'liveState.swapImage')
          ? 'https://dl.airtable.com/.attachments/0309185dacb19f0480c1d869af6eec65/6e3873d0/Single-16x9.svg'
          : 'https://dl.airtable.com/.attachments/8775f52eef56124e89e3e25297c39c3e/65bee105/Double-16x9.svg',
    },
    eventProps: {
      onClick: state => [
        {
          property: Properties.IsScrolled,
          value: () => {
            const isExpanded = get(
              state,
              `liveState.${MultipleMagicExpandedProperty}`
            )
            const isScrolled = get(state, `liveState.${Properties.IsScrolled}`)

            return isExpanded && !isScrolled ? true : false
          },
        },
        {
          property: MultipleMagicExpandedProperty,
          value: () => {
            const isExpanded = get(
              state,
              `liveState.${MultipleMagicExpandedProperty}`
            )
            const isScrolled = get(state, `liveState.${Properties.IsScrolled}`)

            return isExpanded && isScrolled ? false : true
          },
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: state.projectId,
            scrollProps: {
              scrollTo: 0,
            },
          },
        },
      ],
    },
    styleProps: state => {
      const isScrolled = get(state, `liveState.${Properties.IsScrolled}`)

      return {
        height: isScrolled ? 120 : 814,
      }
    },
  },
  '150-83': {
    // Header for expanded image
    styleProps: state => {
      const isScrolled = get(state, `liveState.${Properties.IsScrolled}`)

      return {
        height: isScrolled ? 120 : 475,
        backgroundColor: isScrolled ? '#013ea4' : '#002c71',
      }
    },
  },
  '150-86': {
    // Rectangle Mask for expanded image
    styleProps: state => {
      const isScrolled = get(state, `liveState.${Properties.IsScrolled}`)

      return {
        top: isScrolled ? 120 : 475,
        zIndex: 9999,
      }
    },
  },
  '134-3318': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state),
      rectType: 'xl',
    },
  },
  '140-59': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 1),
    },
  },
  '140-177': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 2),
    },
  },
  '142-2': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 3),
    },
  },
  '142-176': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 4),
    },
  },
  '142-208': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 5),
    },
  },
  '142-267': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 6),
    },
  },
  '142-395': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 7),
      rectType: 'sm',
    },
  },
  '142-433': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 8),
      rectType: 'sm',
      isRTL: true,
    },
  },
  '142-630': {
    componentProps: {
      ...CardProps,
      story: state => getStoryProps(state, 8),
    },
  },
  '144-38': {
    componentProps: {
      title: 'Trending',
      stories: state => {
        return [
          getTrendingStoryProps(state, 0),
          getTrendingStoryProps(state, 1),
          getTrendingStoryProps(state, 5),
          getTrendingStoryProps(state, 3),
          getTrendingStoryProps(state, 8),
        ]
      },
    },
  },
  '142-2': {
    // Merch box click
    eventProps: {
      onClick: state => [
        {
          property: 'swapImage',
          value: true,
        },
      ],
    },
  },
  '151-1': {
    styleProps: {
      overflow: 'hidden',
    },
  },
}

export default ProjectId
