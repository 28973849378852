import get from 'lodash/get'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import { getId } from '../utils'
import { Properties } from '../constants'

const ProjectId = getId('71E7DFD894A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [],
    },
  },
  '4E71E9D6-4509-4F1F-BC4E-42DD8015B89F': {
    // `Game Exercise` Component
    componentProps: {
      exerciseId: state => get(state, `liveState.${Properties.ActiveGameId}`),
      onChange: state => value => {
        if (value === 'practice_clicked') {
          const activeGameId = get(
            state,
            `liveState.${Properties.ActiveGameId}`
          )

          return [
            {
              property: Properties.ActiveGameId,
              value: null,
            },
            {
              property: `${activeGameId}Checked`,
              value: true,
            },
            {
              property: projectStatesTypes.DISABLE_LINKED_PROJECT,
              value: ProjectId,
            },
            {
              property: LiveStateProperties.DisableScroll,
              value: false,
            },
          ]
        }
      },
      onClose: state => () => [
        {
          property: Properties.ActiveGameId,
          value: null,
        },
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: ProjectId,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
  },
}

export default ProjectId
