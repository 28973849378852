const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  item: {
    position: 'relative',
    backgroundColor: '#fff',
    borderBottom: '0.5px solid #D2DAE6'
  }
})

export default jssStyles
