import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from '@material-ui/core/SvgIcon'

const LocationIcon = ({ className, style }) => (
  <SvgIcon className={className} style={style}>
    <svg width="19px" height="22px" viewBox="0 0 19 22" version="1.1">
      <title>Group 3</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.5"
      >
        <g
          id="Intro"
          transform="translate(-809.000000, -430.000000)"
          stroke="currentColor"
        >
          <g id="Group-4" transform="translate(794.000000, 252.000000)">
            <g id="Group-3" transform="translate(16.000000, 179.000000)">
              <path
                d="M14.1086957,2.42937518 L14.1086957,2.42937518 C17.3695652,5.69024475 17.3695652,10.9076361 14.1521739,14.1250274 L8.2826087,19.9945926 L2.41304348,14.1250274 C-0.804347826,10.9076361 -0.804347826,5.69024475 2.41304348,2.47285344 L2.45652174,2.42937518 C5.67391304,-0.788016123 10.8478261,-0.831494383 14.1086957,2.42937518 C14.0652174,2.38589692 14.0652174,2.38589692 14.1086957,2.42937518 Z"
                id="Shape"
              />
              <circle id="Oval" cx="8.2826087" cy="8.25546214" r="2.17391304" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
)

LocationIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
}

export default LocationIcon
