const jssStyles = theme => ({
  container: {
    position: 'absolute',
  },
  isExpanded: {
    zIndex: theme.zIndex.layerElevated,
  },
  backButton: {
    position: 'absolute',
    color: 'white',
    top: 16,
    left: 16,
    zIndex: theme.zIndex.layerElevated,
  },
})

export default jssStyles
