const jssStyles = theme => ({
  container: {
    backgroundImage:
      'url("https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2FA206C4C194A%2Fclose-btn.svg?alt=media")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 24,
    height: 24,

    '&:active': {
      opacity: 0.7,
    },
  },
  darkContainer: {
    backgroundImage:
      'url("https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F581EEC9F94A%2F356CF6C2-8730-44EA-AA05-4CB4D2682F29%402x.png?alt=media")',
    width: 23,
    height: 23,
  },
})

export default jssStyles
