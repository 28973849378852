import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'

import MaterialCircularProgress from '@material-ui/core/CircularProgress'

import { processNodeFrame } from 'utils'

import styles from './CircularProgressStyles'

const getCircularProgress = color =>
  withStyles({
    root: {
      color
    }
  })(MaterialCircularProgress)

const CircularProgress = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  style,
  ...props
}) => {
  const color = get(style, 'color') || '#000'

  const CustomCircularProgress = getCircularProgress(color)

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.active]: get(props, 'active')
  })

  const processedFrame = processNodeFrame(node, scaleRatio, viewRect)

  const containerStyles = {
    ...processedFrame
  }

  return (
    <CustomCircularProgress
      className={containerClassNames}
      style={containerStyles}
    />
  )
}

CircularProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  style: PropTypes.object
}

export default withStyles(styles)(CircularProgress)
