import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import camelCase from 'lodash/camelCase'
import AirtableAPI from 'api/AirtableAPI'
import { LiveStateProperties } from 'constants/general'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId, getExerciseData } from '../utils'
import { Properties } from '../constants'

import HomeFeed2ProjectId from './HomeFeed2'
import Program2ProjectId from './Program2'
import AssessPageProjectId from './AssessPage'
import LibraryPage2ProjectId from './LibraryPage2'
import OnboardingHomeFeed2ProjectId from './OnboardingHomeFeed2'

const ProjectId = getId('CF0CAE9694A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: OnboardingHomeFeed2ProjectId,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
        {
          property: Properties.OnboardingHomeFeed,
          value: 1,
        },
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[C] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.BottomNavItemsData,
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[C] Bottom Nav',
              'Grid view'
            )
            return airtableData.map(({ label, outlineSvg, activeSvg }) => ({
              id: camelCase(label),
              label,
              outlineSvg,
              activeSvg,
            }))
          },
        },
        {
          property: Properties.ExercisesData,
          value: async state => getExerciseData(state),
        },
      ],
    },
  },
  'A4596126-6FB6-4D1F-AC6C-881A6CDED13F': {
    // `BottomNavigation` Component
    componentProps: {
      items: state => get(state, `liveState.${Properties.BottomNavItemsData}`),
      activeItemId: state => get(state, 'liveState.bottomNavActiveId'),
      onChange: state => value => ({
        property: 'bottomNavActiveId',
        value,
      }),
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ActiveGameId}`) ? 'none' : '',
    }),
  },
  '4BEA0623-7832-4217-A256-FE0CD1FCBAC7': {
    // `linkedProject` OnboardingHomeFeed
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: !onboardingStep ? 'none' : 'block',
      }
    },
  },
  '7DB4C4A5-9BC0-4FA3-A099-6075FDED097F': {
    // `linkedProject` OnboardingLibrary
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )
      return {
        display: onboardingStep === null ? 'none' : 'block',
      }
    },
  },
  '1A215D6E-B576-4EB9-9334-E0C29BB6FB78': {
    // `linkedProject` Home Feed view
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: HomeFeed2ProjectId,
        },
      ],
    },
    frameProps: state => ({
      height: get(state, `liveState.${Properties.ActiveGameId}`) ? 812 : 736,
    }),
  },
  '1EBBDB65-3D93-450A-834E-7ABA0DADC6D9': {
    // `linkedProject` Program view
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: Program2ProjectId,
        },
      ],
    },
  },
  '62CB305D-6354-4094-B972-957E859CA6BE': {
    // `linkedProject` Assess view
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: AssessPageProjectId,
        },
      ],
    },
  },
  '2560E0AB-05C6-42F9-B2B7-6BAF5DB3BFF9': {
    // `linkedProject` Library view
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: LibraryPage2ProjectId,
        },
      ],
    },
    frameProps: state => ({
      height: get(state, `liveState.${Properties.ActiveGameId}`) ? 812 : 736,
    }),
  },
}

export default ProjectId
