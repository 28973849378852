import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader'

import * as routes from 'constants/routes'

// import TestPage from 'pages/TestPage'
import LivePage from 'pages/LivePage'
import StoriesPage from 'pages/StoriesPage'
import ImageViewerPage from 'pages/ImageViewerPage'

const App = () => (
  <Switch>
    {/* <Route exact path="/test" component={TestPage} /> */}
    <Route exact path={routes.StoriesPage} component={StoriesPage} />
    <Route exact path={routes.ImageViewerPage} component={ImageViewerPage} />
    <Route exact path={routes.LivePage} component={LivePage} />
    <Route
      path={routes.LandingPage}
      component={() => {
        window.location.href = 'https://torii.studio'
        return null
      }}
    />
  </Switch>
)

App.propTypes = {
  location: PropTypes.object.isRequired,
}

export default compose(withRouter, hot(module))(App)
