import { isProductionInstance } from 'utils'

export const ProjectIds = [
  '53166FF494A' // Share - Sticky Unit
]

const data = {
  typeExperience: 'mobile',
  /** "53166FF494A" `Share - Sticky Unit` artboard  */
  '53166FF494A': {
    styleProps: {
      overflowX: 'hidden'
    }
  },
  '2F6900DF-0503-46F2-AFC3-A9728E8E64EA': {
    // `Scroll Breakpoint` component
    props: {
      onStateChange: value => ({
        property: 'scrollState',
        value
      })
    }
  },
  'C14FD1D6-22DB-43A2-9487-EBFE26F2C95D': {
    // `Sticky Unit` component
    frameProps: {
      bottomY: 0
    }
  },
  '2A863843-4DAB-4CD4-B026-87C3949AB184': {
    // `Image in feed`
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F53166FF494A%2FImage.png?alt=media'
    }
  },
  '426AC395-C956-46A8-A00B-8C00EF1A058F': {
    // `Peanuts Image` in feed
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F53166FF494A%2FImage2.png?alt=media'
    }
  },
  '4EF8B4B8-A601-46C1-9212-53B8C6195FAF': {
    // `Search icon` in header
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F53166FF494A%2Fsearch-icon.svg?alt=media'
    }
  }
}

export default ProjectIds.reduce((acc, curr) => {
  const projectId = `${curr}${isProductionInstance() ? '0' : ''}`
  acc[projectId] = data
  return acc
}, {})
