import React, { createRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './RippleStyles'

const DefaultColor = '#FFF'

const Ripple = ({ classes, color }) => {
  const containerRef = createRef()
  const rippleRef = createRef()

  const getRippleSize = () => {
    const { offsetWidth, offsetHeight } = containerRef.current
    return offsetWidth >= offsetHeight ? offsetWidth : offsetHeight
  }

  const renderRipple = style => {
    ReactDOM.unmountComponentAtNode(rippleRef.current)
    ReactDOM.render(
      <div className={classes.ripple} style={style} />,
      rippleRef.current
    )
  }

  const handleClick = e => {
    const containerRect = containerRef.current.getBoundingClientRect()

    const rippleSize = getRippleSize()

    const left = e.clientX - containerRect.left - rippleSize / 2
    const top = e.clientY - containerRect.top - rippleSize / 2

    const rippleColor = color || DefaultColor

    renderRipple({
      left,
      top,
      width: rippleSize,
      height: rippleSize,
      backgroundColor: rippleColor
    })
  }

  return (
    <div className={classes.container} onClick={handleClick} ref={containerRef}>
      <div ref={rippleRef} />
    </div>
  )
}

Ripple.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string
}

export default withStyles(styles)(Ripple)
