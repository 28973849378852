export default {
  page: {
    project: {},
    linkedProjects: {},
  },
  projectState: {
    data: {},
    liveState: {},
    projectId: null,
    activeViews: {},
    prevActiveViews: {},
    activeLinkedProjects: [],
  },
  view: {
    rect: null,
    scrollTop: null,
    offsetTop: null,
    scrollHeight: null,
  },
  user: null,
}
