import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { motionValue, motion } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import useNodeProps from 'hooks/useNodeProps'
import { processNodeFrame, findNodeByName } from 'utils'
import { ProjectStateContext } from 'contexts/ProjectStateContextProvider'

import Node from 'components/Node'

import styles from './ScrollItemStyles'

const NodeNames = {
  ContentItem: 'content__item',
}

const ScrollContainerStyles = {
  overflow: 'hidden',
  transform: 'translateZ(0)',
  cursor: 'grab',
}

const ScrollItemKey = 'sticky-scroll-item'

const ScrollItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  style,
  ...otherProps
}) => {
  const y = motionValue(0)
  const projectStateContext = useContext(ProjectStateContext)

  useEffect(() => {
    y.onChange(() => {
      projectStateContext.setData({ key: ScrollItemKey, value: y })
    })
  }, [y])

  const contentItemNode = findNodeByName(containerNode, NodeNames.ContentItem)

  const { node: contentItemProcessedNode } = useNodeProps(
    contentItemNode,
    projectState,
    onProjectStateChange,
    style
  )

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    ...style,
    ...ScrollContainerStyles,
  }

  const contentItemStyles = processNodeFrame(
    contentItemProcessedNode,
    scaleRatio,
    viewRect
  )

  return (
    <motion.div
      className={classes.container}
      style={containerStyles}
      whileTap={{ cursor: 'grabbing' }}
    >
      <motion.div
        style={{
          width: containerStyles.width,
          height: contentItemStyles.height,
        }}
        y={y}
        drag="y"
        dragConstraints={{
          top:
            -contentItemStyles.height +
            containerStyles.height -
            contentItemStyles.top,
          bottom: 0,
        }}
      >
        {values(containerNode.nodes).map(node => (
          <Node
            key={node.id}
            node={node}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...otherProps}
          />
        ))}
      </motion.div>
    </motion.div>
  )
}

ScrollItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(ScrollItem)
