import { isProductionInstance } from 'utils'

export const ProjectIds = [
  'B8B4A6D594A' // San Jose Day 2020
]

const data = {
  typeExperience: 'mobile',
  /** "B8B4A6D594A" `San Jose Day 2020` artboard  */
  [`B8B4A6D594A${isProductionInstance() ? '0' : ''}`]: {
    styleProps: {
      overflow: 'hidden'
    }
  },
  '340327C9-4BBF-4954-B9A3-64CEA1058327': {
    // `Feliz Dia` Text
    styleProps: {
      pointerEvents: 'none'
    }
  },
  '30E424D4-FC72-48BF-A8FD-CF6A170E6182': {
    // `San Jose` Text
    styleProps: {
      pointerEvents: 'none'
    }
  },
  'DD831931-3933-4CA5-B284-96F083C9F300': {
    // `2020` Text
    styleProps: {
      pointerEvents: 'none'
    }
  }
}

export default ProjectIds.reduce((acc, curr) => {
  const projectId = `${curr}${isProductionInstance() ? '0' : ''}`
  acc[projectId] = data
  return acc
}, {})
