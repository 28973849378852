import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B715100')

export const data = {
  [ProjectId]: {
    props: {
      // onInitialState: [
      //   {
      //     property: LiveStateProperties.DisableScroll,
      //     value: true,
      //   },
      // ],
    },
  },
}

export default ProjectId
