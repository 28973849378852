import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './InputStyles'

const getInputType = string => {
  const formatString = string.toLowerCase().trim()

  if (formatString.includes('password')) {
    return 'password'
  }
  if (formatString.includes('email')) {
    return 'email'
  }

  return 'text'
}

const Input = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')

  const inputRef = React.createRef()

  const handleClick = () => inputRef.current.focus()

  useEffect(() => {
    onProjectStateChange && onProjectStateChange(inputValue)
  }, [inputValue])

  const handleTextChange = e => setInputValue(e.target.value)

  const containerStyles = {
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  return (
    <div
      className={classes.container}
      style={containerStyles}
      onClick={handleClick}
    >
      {values(node.nodes).map(childNode => {
        if (childNode.type === 'text') {
          const inputStyles = {
            ...childNode.style,
            ...processNodeFrame(childNode, scaleRatio, viewRect)
          }

          return (
            <input
              key={childNode.id}
              value={inputValue}
              onChange={handleTextChange}
              className={classes.input}
              type={getInputType(childNode.value)}
              placeholder={childNode.value}
              style={inputStyles}
              ref={inputRef}
              spellCheck="false"
            />
          )
        }

        return (
          <Node
            key={childNode.id}
            node={childNode}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            onProjectStateChange={onProjectStateChange}
            {...props}
          />
        )
      })}
    </div>
  )
}

Input.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  viewRect: PropTypes.object,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func
}

export default withStyles(styles)(Input)
