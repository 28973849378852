import update from 'immutability-helper'

import { SET_SCROLL_VALUES } from 'constants/actionTypes'

import initialState from './initialState'

export default (state = initialState.view, action) => {
  switch (action.type) {
    case SET_SCROLL_VALUES:
      return update(state, {
        $set: {
          ...action.payload
        }
      })
    default:
      return state
  }
}
