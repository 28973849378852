const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  item: {
    position: 'relative',
    height: 75,
    backgroundColor: '#fff',
    marginBottom: 12
  }
})

export default jssStyles
