const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  barContainer: {
    position: 'absolute',
    borderRadius: '8px 0 0 8px',
    boxShadow: '2px 2px 6px 0 #949494',
    overflow: 'hidden'
  },
  barItem: {
    position: 'absolute',
    width: 300,
    height: '100%',
    transition: 'width .3s ease'
  },
  points: {
    position: 'absolute'
  },
  disabled: {
    opacity: 0.3
  }
})

export default jssStyles
