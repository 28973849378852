import { getId } from 'utils'

const ProjectId = getId('FDINX7UJYA472')

export const data = {
  [ProjectId]: {
    styleProps: {
      overflowX: 'hidden',
    },
  },
  '58-17': {
    eventProps: {
      onClick: state => ({
        property: 'closeProductDrag',
        value: true,
      }),
    },
  },
  '47-37': {
    eventProps: {
      onClick: state => ({
        property: 'closeProductDrag',
        value: false,
      }),
    },
  },
}

export default ProjectId
