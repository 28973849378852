const SpinnerBorderPx = 8

const SpinnerAnimation = {
  animationIterationCount: 1 /* Only run once */,
  animationFillMode: 'forwards' /* Hold the last keyframe */,
  animationTimingFunction: 'linear'
}

const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  isPracticing: {
    zIndex: theme.zIndex.layerElevated
  },
  imageContainer: {
    position: 'absolute',
    animation: '$breath 10s ease-in-out alternate infinite',
    width: '100%',
    height: '100%'
  },
  containerBottomItem: {
    position: 'absolute'
  },
  spinnerContainer: {
    position: 'absolute',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: `calc(100% - ${SpinnerBorderPx * 2}px)`,
      height: `calc(100% - ${SpinnerBorderPx * 2}px)`,
      border: `${SpinnerBorderPx}px solid #fff`,
      borderRadius: '100%'
    }
  },
  spinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    animationName: '$spinnerWrapper',
    animationDuration: '0.01s',
    ...SpinnerAnimation
  },
  spinnerCircleLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: `${SpinnerBorderPx}px solid #00bbc6`,
    borderRadius: '100%',
    animationName: '$leftSpin',
    ...SpinnerAnimation
  },
  spinnerCircleRight: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: `${SpinnerBorderPx}px solid #00bbc6`,
    borderRadius: '100%',
    animationName: '$rightSpin',
    ...SpinnerAnimation
  },
  '@keyframes breath': {
    from: {
      transform: 'scale(1)'
    },
    to: {
      transform: 'scale(1.05)'
    }
  },
  '@keyframes spinnerWrapper': {
    to: {
      clip: 'rect(auto, auto, auto, auto)'
    }
  },
  '@keyframes leftSpin': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  '@keyframes rightSpin': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(180deg)'
    }
  }
})

export default jssStyles
