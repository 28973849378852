import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import find from 'lodash/find'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Ripple from 'common/Ripple'
import Node from 'components/Node'

import styles from './BookItemStyles'

const BookItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  ...props
}) => {
  const data = get(projectState, 'liveState.professionalData')

  const processedFrame = processNodeFrame(node, scaleRatio, viewRect)

  const containerStyles = {
    ...node.style,
    ...processedFrame
  }

  const renderTimeButtons = () => {
    const timeButtonsItemNode = find(
      values(node.nodes),
      n => n.type === 'component' && n.name === 'items'
    )

    if (!timeButtonsItemNode || !timeButtonsItemNode.nodes) return

    const processedItemFrame = processNodeFrame(timeButtonsItemNode, scaleRatio)

    return values(timeButtonsItemNode.nodes).map(buttonNode => {
      const processedButtonFrame = processNodeFrame(buttonNode, scaleRatio)

      const buttonStyles = {
        ...processedButtonFrame,
        ...buttonNode.style,
        top: processedItemFrame.top + processedButtonFrame.top,
        left: processedItemFrame.left + processedButtonFrame.left
      }

      const buttonNodeText = find(
        values(buttonNode.nodes),
        n => n.type === 'text'
      )

      const dataTime = get(data, 'time')
      const isButtonActive = dataTime ? buttonNodeText.value === dataTime : null

      const buttonNodeTextColor = get(buttonNodeText, 'style.color')

      const buttonClassNames = classNames({
        [classes.button]: true,
        [classes.activeButton]: isButtonActive
      })

      const handleButtonClick = e => {
        onProjectStateChange({
          ...data,
          time: buttonNodeText.value
        })
      }

      return (
        <button
          key={buttonNode.id}
          className={buttonClassNames}
          style={buttonStyles}
          onClick={handleButtonClick}
          type="button"
        >
          <Node
            node={buttonNodeText}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            viewRect={viewRect}
            scaleRatio={scaleRatio}
            {...props}
          />
          <Ripple color={buttonNodeTextColor} />
        </button>
      )
    })
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(node.nodes).map(childNode => (
        <Node
          key={childNode.id}
          node={childNode}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          viewRect={viewRect}
          scaleRatio={scaleRatio}
          {...props}
        />
      ))}
      {renderTimeButtons()}
    </div>
  )
}

BookItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(BookItem)
