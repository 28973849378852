import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import firebase from 'api/firebase'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import { initUser, signOutUser, loginWithGoogle } from 'actions/userActions'
import { getEmailDomain } from 'utils'

import styles from './GoogleAuthPageStyles'

const GoogleAuthPage = ({
  classes,
  user,
  validateDomain,
  actions,
  children,
}) => {
  // TODO: [Issue] User can't sign in twice in Safari/Firefox => Solution: Clear the cache/localstorage
  const [isAuthorized, setIsAuthorized] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(firebaseUser => {
      setLoading(!!firebaseUser)
      if (!firebaseUser) return
      actions.initUser(firebaseUser)
    })
  }, [])

  useEffect(() => {
    const email = get(user, 'email')

    if (!email) return

    const emailDomain = getEmailDomain(email)

    const accessGranted = emailDomain === validateDomain

    if (!accessGranted) {
      actions.signOutUser(user)
    }

    setShowError(!accessGranted)
    setIsAuthorized(accessGranted)
  }, [user])

  const handleLogin = () => actions.loginWithGoogle()

  return !isAuthorized ? (
    <div className={classes.container}>
      {loading && <LinearProgress className={classes.linearProgress} />}
      <div className={classes.buttonContainer} onClick={handleLogin}>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          alt="logo"
        />
        <Button>Login With Google</Button>
      </div>
      {showError && (
        <div className={classes.errorMessage}>
          Sorry you have to sign in using your
          <span onClick={handleLogin}> {validateDomain} </span>
          account
        </div>
      )}
    </div>
  ) : (
    children
  )
}

const mapStateToProps = state => ({ user: state.user })

GoogleAuthPage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  validateDomain: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  actions: PropTypes.shape({
    initUser: PropTypes.func.isRequired,
    signOutUser: PropTypes.func.isRequired,
    loginWithGoogle: PropTypes.func.isRequired,
  }),
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { initUser, signOutUser, loginWithGoogle },
      dispatch
    ),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(GoogleAuthPage)
