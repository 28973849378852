import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'

import { SvgLoader, SvgProxy } from 'react-svgmt'

import { processNodeFrame } from 'utils'

import styles from './ColorItemStyles'

const DefaultPalette = {
  primary: '#089aff',
  secondary: '#9cd7ff',
}

const ColorItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  svgUrl,
  projectState,
  style,
}) => {
  const [paletteValue, setPaletteValue] = useState(DefaultPalette)

  useEffect(() => {
    setPaletteValue(get(projectState, 'liveState.palette') || DefaultPalette)
  }, [projectState])

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect),
    ...style,
  }

  const svgStyles = {
    ...processNodeFrame(node, scaleRatio, viewRect),
  }

  const color = {
    dark: paletteValue.primary,
    light: paletteValue.secondary,
  }

  return (
    <div className={classes.container} style={containerStyles}>
      <SvgLoader path={svgUrl} style={svgStyles}>
        <SvgProxy selector="#dark" fill={color.dark} stroke={color.dark} />
        <SvgProxy selector="#light" fill={color.light} stroke={color.light} />
        <SvgProxy selector="#dark-fill" fill={color.dark} />
        <SvgProxy selector="#light-fill" fill={color.light} />
        <SvgProxy selector="#dark-stroke" stroke={color.dark} />
        <SvgProxy selector="#light-stroke" stroke={color.light} />
      </SvgLoader>
    </div>
  )
}

ColorItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  viewRect: PropTypes.object,
  viewEvents: PropTypes.object,
  svgUrl: PropTypes.string,
  style: PropTypes.object,
}

export default withStyles(styles)(ColorItem)
