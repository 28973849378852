import AirtableAPI from 'api/AirtableAPI'
import { isProductionInstance } from 'utils'
import * as projectStatesTypes from 'constants/projectStatesTypes'

const ProjectId = `67F2DEDC94A${isProductionInstance() ? '0' : ''}`

export const data = {
  'AAD06840-3DFB-45B4-A011-003D018795C5': {
    // Repeater list with Capacities
    dataProps: {
      data: async () => ({
        data: await AirtableAPI.fetch(
          'appjUuIGIQZwVsNYc',
          'Capacities',
          'Grid view'
        ),
        repeaterId: 'AAD06840-3DFB-45B4-A011-003D018795C5',
      }),
    },
  },
  '4F32CC3C-5BA7-4F6A-B7CE-8AC0122F2037': {
    // Card image
    props: {
      isBackgroundImage: true,
    },
  },
  'E25B3FFB-5AB2-4D0D-906C-9A6279D0981B': {
    // `Close button` in Science Behind Meditation
    props: {
      onStateChange: () => [
        {
          property: projectStatesTypes.HISTORY_BACK,
        },
      ],
    },
  },
}

export default ProjectId
