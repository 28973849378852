import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { motion, AnimateSharedLayout } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import styles from './InstagramStoriesStyles'

const LayoutIds = {
  ExpandableStory: 'expandable-story',
}

const InstagramStory = ({ classes, backgroundColor }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const collapse = () => setIsExpanded(false)

  const expand = () => setIsExpanded(true)

  return (
    <AnimateSharedLayout>
      {isExpanded ? (
        <motion.div
          className={classNames(classes.story, classes.expandedStory)}
          layoutId={LayoutIds.ExpandableStory}
          style={{ backgroundColor }}
        >
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={collapse}
          >
            <CloseIcon />
          </IconButton>
        </motion.div>
      ) : (
        <motion.div
          className={classNames(classes.story, classes.collapsedStory)}
          onClick={expand}
          layoutId={LayoutIds.ExpandableStory}
          style={{ backgroundColor }}
        />
      )}
    </AnimateSharedLayout>
  )
}

InstagramStory.propTypes = {
  classes: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
}

export default withStyles(styles)(InstagramStory)
