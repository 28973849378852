/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import shortid from 'shortid'
import get from 'lodash/get'
import head from 'lodash/head'
import tail from 'lodash/tail'
import filter from 'lodash/filter'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import intersection from 'lodash/intersection'
import * as instances from 'constants/instances'
import {
  ComponentDelimeter,
  ModifierDelimeter,
  ImageFormatDefault,
  DynamicNodeTypes,
} from 'constants/general'
import instanceConfig from '../config/instances'

export const isProductionInstance = () =>
  process.env.NODE_ENV === instances.Production

export const getId = str => `${str}${isProductionInstance() ? '0' : ''}`

export const log = (message, ...otherMessages) => {
  if (isProductionInstance()) return
  console.log('⛩', message, ...otherMessages)
}

export const getEmailDomain = email => email.substring(email.indexOf('@'))

export function encodeUrl(url) {
  return encodeURIComponent(url)
}

export function getFirebaseUrl(value) {
  const FirebaseStorageUrl = `https://firebasestorage.googleapis.com/v0/b/${instanceConfig.firebase.storageBucket}/o`
  return `${FirebaseStorageUrl}/${encodeUrl(value)}?alt=media`
}

export function getFirebaseImage(projectId, node, scale = undefined) {
  const scaleValue = scale || tail(node.scales)
  return getFirebaseUrl(
    `project/${projectId}/${node.id}@${scaleValue}x.${ImageFormatDefault}`
  )
}

export const getImageUrl = (projectId, nodeId, scale, imageFormat = 'png') =>
  getFirebaseUrl(`project/${projectId}/${nodeId}@${scale}x.${imageFormat}`)

export function loadScript({ src, id, position, body }) {
  const script = document.createElement('script')
  const elPosition = position || document.querySelector('head')
  script.setAttribute('async', '')
  if (id) script.setAttribute('id', id)
  if (src) script.src = src
  if (body) script.text = body
  elPosition.appendChild(script)
}

const HotJarLoaded = {}
export function loadHotjarScript(id) {
  if (HotJarLoaded[id]) return

  HotJarLoaded[id] = id

  const body = `(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:${id},hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`

  loadScript({ body })
}

export function getDataPropsFromNode(node, projectState) {
  return new Promise(async (resolve, reject) => {
    try {
      // TODO: DRY. Process all props here from Node.js
      const dataProps = get(projectState, `data.${node.id}.dataProps`)

      if (!dataProps) {
        resolve({})
        return
      }

      const dataPropsProcessed = await Promise.all(
        Object.keys(dataProps).map(async nodePropKey => {
          const dataProp = dataProps[nodePropKey]

          if (!dataProp) return

          const value = await dataProp(projectState)

          return value
        })
      )

      const nodeProps = dataPropsProcessed.reduce(
        (acc, curr) => ({
          ...acc,
          ...curr,
        }),
        {}
      )

      resolve(nodeProps)
    } catch (err) {
      console.error(err)
      resolve({})
    }
  })
}

export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// ========== NODES ==================================================================

export const findNodeByName = (node, name) => {
  if (!node) return null

  const result = filter(values(node.nodes), ch => ch.name === name)

  if (!result || isEmpty(result)) return null

  if (result.length === 1) return head(result)

  return result
}

export const sortNodesByZIndex = node => {
  const array = node.nodes ? node.nodes : node
  return values(array).sort((a, b) => b.style.zIndex - a.style.zIndex)
}

function processFloatNodeFrame(node, scaleRatio = 1, viewRect) {
  const {
    frame: { x, y, width, height, bottomY },
    absoluteFrame,
    style,
    type,
  } = node

  const { artboardFrame, windowRect } = viewRect

  const isFloatFromBottom = y > artboardFrame.height / 2

  const propertyY = isFloatFromBottom ? 'bottom' : 'top'

  const offsetBottomWindow = windowRect.height - viewRect.top - viewRect.height

  const xProcessed = (absoluteFrame && absoluteFrame.x) || x
  const yProcessed = (absoluteFrame && absoluteFrame.y) || y

  let valueY
  if (isFloatFromBottom && bottomY !== null) {
    valueY = offsetBottomWindow + (artboardFrame.height - yProcessed - height)
  } else {
    valueY = isFloatFromBottom
      ? artboardFrame.height - yProcessed - height + offsetBottomWindow
      : viewRect.top + yProcessed
  }

  let styles = {
    [propertyY]: valueY * scaleRatio,
    left: viewRect.left + xProcessed * scaleRatio,
    width: width * scaleRatio,
    height: height * scaleRatio,
    position: 'fixed',
  }

  if (type === 'text') {
    styles = {
      ...styles,
      letterSpacing: style.letterSpacing * scaleRatio,
      fontSize: style.fontSize * scaleRatio,
      lineHeight: `${style.lineHeight * scaleRatio}px`,
    }
  }

  return styles
}

export function processNodeFrame(
  node,
  scaleRatio = 1,
  viewRect,
  isFloat = false,
  scrollY
) {
  const {
    name,
    frame: { x, y, width, height },
    style,
    type,
  } = node

  const stickyProps = node?.customProps?.sticky
  const isFloatNode = (name && name.includes('--float')) || isFloat

  if (isFloatNode) return processFloatNodeFrame(node, scaleRatio, viewRect)

  let styles = {
    top: y * scaleRatio,
    left: x * scaleRatio,
    width: width * scaleRatio,
    height: height * scaleRatio,
  }

  if (type === 'text') {
    styles = {
      ...styles,
      letterSpacing: style.letterSpacing * scaleRatio,
      fontSize: style.fontSize * scaleRatio,
      lineHeight: `${style.lineHeight * scaleRatio}px`,
    }
  }

  if (Boolean(stickyProps) && scrollY + stickyProps.top > styles.top) {
    return {
      ...styles,
      position: 'sticky',
      top: stickyProps.top * scaleRatio,
    }
  }

  return styles
}

export function processNodeStyleProps(state, node) {
  const styleProps = get(state, `data.${node.id}.styleProps`)
  const processedStyleProps =
    styleProps && typeof styleProps === 'function'
      ? styleProps(state)
      : styleProps

  return processedStyleProps
}

export function parseStringByType(string, type) {
  const regex = `([^${ComponentDelimeter}+${ModifierDelimeter}]*)?(${ComponentDelimeter}([a-zA-Z0-9]+(_[a-zA-Z0-9]+)?))?((${ModifierDelimeter})([a-zA-Z0-9]+(-[a-zA-Z0-9]+)?)+)?`
  const matchProps = string.match(regex)

  switch (type) {
    case 'name': {
      return get(matchProps, '[1]')
    }
    case 'componentName': {
      return get(matchProps, '[3]')
    }
    case 'modifierName': {
      return get(matchProps, '[7]')
    }
    default: {
      return false
    }
  }
}

export function parseNodeName(node) {
  const { name } = node
  const regexComponent = `${ComponentDelimeter}[^]*`
  const regexModifier = `${ModifierDelimeter}[^]*`

  const matchComponent = get(name.match(regexComponent), '[0]')
  const matchModifier = get(name.match(regexModifier), '[0]')

  return {
    name: parseStringByType(name, 'name'),
    componentName: matchComponent
      ? parseStringByType(matchComponent, 'componentName')
      : undefined,
    modifierName: matchModifier
      ? parseStringByType(matchModifier, 'modifierName')
      : undefined,
  }
}

// Order the list of nodes based on position of the items using a left-right, top-down approach
export function getNodesArraySortedByPosition(nodes) {
  const nodesArray = isArray(nodes) ? nodes : values(nodes)
  return nodesArray.sort(
    (a, b) => a.frame.y - b.frame.y || a.frame.x - b.frame.x
  )
}

export function getNodesArrayHierarchy(nodesArray) {
  const getItemFrameFromListOrdered = index => ({
    x: nodesArray[index].frame.x,
    y: nodesArray[index].frame.y,
    width: nodesArray[index].frame.width,
    height: nodesArray[index].frame.height,
  })

  let hierarchy = {
    width: head(nodesArray).frame.width,
    height: head(nodesArray).frame.height,
  }

  nodesArray.forEach((item, itemIndex) => {
    if (itemIndex === 0) return

    const currentItemFrame = getItemFrameFromListOrdered(itemIndex)
    const prevItemFrame = getItemFrameFromListOrdered(itemIndex - 1)

    hierarchy = {
      ...currentItemFrame,
      x:
        currentItemFrame.x !== prevItemFrame.x
          ? currentItemFrame.x - (prevItemFrame.x + prevItemFrame.width)
          : currentItemFrame.x,
      y:
        currentItemFrame.y !== prevItemFrame.y
          ? currentItemFrame.y - (prevItemFrame.y + prevItemFrame.height)
          : currentItemFrame.y,
    }
  })

  return {
    ...hierarchy,
    x: hierarchy.x >= 0 ? hierarchy.x : 0,
    y: hierarchy.y >= 0 ? hierarchy.y : 0,
  }
}

function extractAllChildNames(containerNode) {
  const extractNamesRecursively = node => {
    if (!node.nodes) {
      return {
        [node.name]: true,
      }
    }

    return values(node.nodes).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: true,
        ...extractNamesRecursively(curr),
      }),
      {}
    )
  }

  return Object.keys(
    values(containerNode.nodes).reduce(
      (acc, curr) => ({
        ...acc,
        ...extractNamesRecursively(curr),
      }),
      {}
    )
  )
}

// ========== DYNAMIC NODES ==================================================================

export function injectMagicToNodes(defaultNode, expandedNode) {
  const injectLayoutIdsRecursively = (node, layoutIds) => {
    const processedNode = { ...node }

    if (layoutIds.includes(node.name)) {
      processedNode.layoutId = node.name
    }

    return {
      ...processedNode,
      nodes: values(node.nodes).reduce(
        (acc, currNode) => ({
          ...acc,
          [currNode.id]: injectLayoutIdsRecursively(currNode, layoutIds),
        }),
        {}
      ),
    }
  }

  const commonChildNames = intersection(
    extractAllChildNames(defaultNode),
    extractAllChildNames(expandedNode)
  )

  return {
    defaultNode: injectLayoutIdsRecursively(defaultNode, commonChildNames),
    expandedNode: injectLayoutIdsRecursively(expandedNode, commonChildNames),
  }
}

function injectDataToRepeaterNode(node, data) {
  const processNode = n => {
    const value = data[n.name]

    if (!value) return n

    const prop =
      n.type === DynamicNodeTypes.Image.prop
        ? DynamicNodeTypes.Image.dynamicProp
        : DynamicNodeTypes.Default.dynamicProp

    return {
      ...n,
      [prop]: value,
    }
  }

  if (!node.nodes) return processNode(node)

  return {
    ...node,
    nodes: values(node.nodes).reduce((acc, currNode) => {
      if (currNode.nodes) {
        return {
          ...acc,
          [currNode.id]: injectDataToRepeaterNode(currNode, data),
        }
      }

      return {
        ...acc,
        [currNode.id]: processNode(currNode),
      }
    }, {}),
  }
}

export function processRepeaterNodes(node, data) {
  // Finds the repeater node name by looking for the one that is more repeated
  const nodeNamesCount = values(
    values(node.nodes).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: {
          name: curr.name,
          value:
            acc[curr.name] && acc[curr.name].value
              ? acc[curr.name].value + 1
              : 1,
        },
      }),
      {}
    )
  )

  const repeaterNodeName = head(
    nodeNamesCount.sort((a, b) => b.value - a.value)
  ).name

  // Sorts repeater nodes by position in the canvas
  const repeaterNodesSorted = getNodesArraySortedByPosition(
    filter(values(node.nodes), a => a.name === repeaterNodeName)
  )

  // Get node hierarchy values
  const nodesHierarchy = getNodesArrayHierarchy(repeaterNodesSorted)

  // Get the first repeater node
  const headRepeaterNode = head(repeaterNodesSorted)

  // Dynamic rendering of all the nodes by using the first repeater node and the node hierarchy
  return data.reduce((acc, curr, index) => {
    const id = shortid.generate()

    acc[id] = {
      ...headRepeaterNode,
      id,
      frame: {
        x: 0,
        y: 0,
        width: nodesHierarchy.width,
        height: nodesHierarchy.height,
      },
      hierarchy: nodesHierarchy,
      nodes: values(headRepeaterNode.nodes).reduce((accNode, currNode) => {
        return {
          ...accNode,
          [currNode.id]: injectDataToRepeaterNode(currNode, data[index]),
        }
      }, {}),
    }

    return acc
  }, {})
}

export function recursiveProcess(node, { data, repeaterId }) {
  if (node.id === repeaterId) {
    return {
      ...node,
      repeaterProcessed: true,
      nodes: processRepeaterNodes(node, data),
    }
  }

  return {
    ...node,
    nodes: values(node.nodes).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: recursiveProcess(curr, { data, repeaterId }),
      }),
      {}
    ),
  }
}

export function processNodeDataProps(node, { data, repeaterId, nestedData }) {
  const processedNode = recursiveProcess(node, {
    data,
    repeaterId,
  })

  if (!nestedData) return processedNode

  return {
    ...processedNode,
    nodes: values(processedNode.nodes).reduce(
      (accNode, currNode, currNodeIndex) => ({
        ...accNode,
        [currNode.id]: recursiveProcess(currNode, {
          data: nestedData.data[currNodeIndex],
          repeaterId: nestedData.repeaterId,
        }),
      }),
      {}
    ),
  }
}

// ========== FONTS ==================================================================

export function loadFont(fontName, type = 'otf') {
  try {
    const fontUrl = getFirebaseUrl(`font/${fontName}.${type}`)
    const newStyle = document.createElement('style')
    newStyle.appendChild(
      document.createTextNode(
        `@font-face { font-family: '${fontName}'; src: url('${fontUrl}'); font-weight: normal; font-style: normal;}`
      )
    )
    log('font loaded', fontName)
    document.head.appendChild(newStyle)
    return true
  } catch (err) {
    log(err)
    return false
  }
}

function extractProjectIds(node, loadedProjectIds, projectIds = {}) {
  if (!node.nodes) return

  values(node.nodes).forEach(n => {
    const { name, modifierName: projectId } = parseNodeName(n)

    if (name === 'view' && !loadedProjectIds[projectId]) {
      projectIds[getId(projectId)] = true
    }

    n.nodes && extractProjectIds(n, loadedProjectIds, projectIds)
  })
}

export function extractLinkedProjectsIds(project) {
  if (!project) return []

  const projectIds = {}
  const loadedProjectIds = Object.keys(project.views)
  values(project.views).map(view =>
    extractProjectIds(view, loadedProjectIds, projectIds)
  )

  return Object.keys(projectIds)
}

function extractAllFontsFromNodes(nodes, fontFamilies) {
  values(nodes).forEach(({ nodes: childNodes }) => {
    childNodes && extractAllFontsFromNodes(childNodes, fontFamilies)
  })

  values(nodes)
    .filter(node => node.type === 'text')
    .forEach(node => {
      const fontFamily = get(node, 'style.fontFamily')
      // eslint-disable-next-line no-param-reassign
      fontFamilies[fontFamily] = true
    })
}

export function loadFontsFromNodes(
  nodes,
  injectedFonts = [],
  loadedFonts = {}
) {
  const fontFamilies = injectedFonts.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {}
  )

  extractAllFontsFromNodes(nodes, fontFamilies)

  const fontTypes = ['ttf', 'otf']
  const fontsLoaded = {}
  fontTypes.forEach(ftype => {
    Object.keys(fontFamilies).forEach(fontFamilyName => {
      if (!fontFamilyName || loadedFonts[fontFamilyName]) return
      loadFont(fontFamilyName, ftype)
      fontsLoaded[fontFamilyName] = true
    })
  })

  return fontsLoaded
}

export function loadFontsFromViews(
  views,
  injectedFonts = [],
  loadedFonts = {}
) {
  const topLevelNodeViews = values(views)
    .map(view => view.nodes)
    .reduce((acc, currentNode) => {
      Object.keys(currentNode).forEach(currentNodeKey => {
        acc[currentNodeKey] = currentNode[currentNodeKey]
      })
      return acc
    }, {})

  return loadFontsFromNodes(topLevelNodeViews, injectedFonts, loadedFonts)
}

// ========== MISC ==================================================================

export function IsSafari() {
  const userAgentString = navigator.userAgent.toLowerCase()
  const chromeAgent = userAgentString.indexOf('chrome') > -1
  const safariAgent = !chromeAgent && userAgentString.indexOf('safari') > -1
  return safariAgent
}

export function checkIsDesktopDevice() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const isIos = /iphone|ipod|ipad/.test(userAgent)
  const isAndroid = /android/.test(userAgent)
  const isIpadPro =
    /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document

  return !isIos && !isAndroid && !isIpadPro
}

export function checkIsSmartDevice() {
  // Check if is actually a smart device and not devtools with mobile opened
  const isSmartDevice =
    /* mobile if android or iOS and not emulated in mac or win pc (for dev) */
    (navigator.userAgent.match(/(android|ip(hone|ad|od))/i) &&
      (!navigator.platform ||
        !navigator.platform.match(
          /(win|mac)/i
        ))) /* or if windows phone or blackberry (no dev in windows) */ ||
    navigator.userAgent.match(/(windows phone|iemobile|wpdesktop|blackberry)/i)
  return isSmartDevice
}

export function getContrastColor(hexColor) {
  const checkColor = +`0x${hexColor
    .slice(1)
    .replace(hexColor.length < 5 && /./g, '$&$&')}`

  const rgbColor = {
    r: checkColor >> 16,
    g: (checkColor >> 8) & 255,
    b: checkColor & 255,
  }

  const hsp = Math.sqrt(
    0.299 * (rgbColor.r * rgbColor.r) +
      0.587 * (rgbColor.g * rgbColor.g) +
      0.114 * (rgbColor.b * rgbColor.b)
  )

  return hsp > 135 ? '#000' : '#fff'
}

export function getOrdinal(number) {
  const n = Number(number) % 10
  const output =
    ~~((Number(number) % 100) / 10) === 1
      ? 'th'
      : n === 1
      ? 'st'
      : n === 2
      ? 'nd'
      : n === 3
      ? 'rd'
      : 'th'
  return `${number}${output}`
}

export function formatNumberWithComma(number) {
  if (!number) return null
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function easeInOutQuad(t, b, c, d) {
  t /= d / 2
  if (t < 1) {
    return (c / 2) * t * t + b
  }
  t -= 1
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export function animateScrollTo(element, to, duration) {
  const start = element.scrollTop
  const change = to - start
  let currentTime = 0
  const increment = 20

  if (!duration) {
    element.scrollTop = to
    return
  }

  const animateScroll = () => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    element.scrollTop = val
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

export const sleep = (delay = 0) =>
  new Promise(resolve => {
    setTimeout(resolve, delay)
  })
