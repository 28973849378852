import { isProductionInstance } from 'utils'

const ProjectId = `2348A50E94A${isProductionInstance() ? '0' : ''}`

export const data = {
  'E89CA660-B857-4CFE-AC1A-093C206BCA56': {
    // Controls
    props: {
      onStateChange: ({ property, targetId, value }) => ({
        property,
        value: {
          [targetId]: value,
        },
      }),
    },
  },
}

export default ProjectId
