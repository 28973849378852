const jssStyles = theme => ({
  container: {
    position: 'absolute',
  },
  dragHeader: {
    zIndex: theme.zIndex.layerElevatedL,
  },
  dragProgress: {
    position: 'absolute',
    zIndex: theme.zIndex.layerElevatedL,
  },
  dragImage: {
    zIndex: theme.zIndex.layerElevated,
  },
  background: {
    position: 'absolute',
    zIndex: theme.zIndex.layer,
  },
})

export default jssStyles
