import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import TotalBrain from '@totalbrain-components'

import styles from './SearchItemStyles'

const SearchItem = ({ classes, onChange, onActive, style }) => {
  const [value, setValue] = useState(null)
  const [isActive, setIsActive] = useState(null)

  useEffect(() => onActive && onActive(isActive), [isActive])

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.activeContainer]: isActive,
    [classes.textContainer]: value && value.length > 0,
  })

  const clearText = () => {
    setValue('')
    onChange && onChange('')
  }

  const handleInputChange = e => {
    setValue(e.target.value)
    onChange && onChange(e.target.value)
  }

  const closeButtonClassNames = {
    [classes.closeButton]: true,
    [classes.hide]: !value || value.length === 0,
  }

  return (
    <div className={containerClassNames} style={style}>
      <div className={classes.inputContainer}>
        <input
          className={classes.input}
          type="text"
          placeholder="Search the library"
          spellCheck="false"
          onClick={() => setIsActive(true)}
          onChange={handleInputChange}
          value={value || ''}
        />
        <TotalBrain.CloseButton
          className={closeButtonClassNames}
          onClick={e => clearText()}
        />
      </div>
      <button
        type="button"
        className={classes.cancelButton}
        onClick={() => {
          clearText()
          setIsActive(false)
        }}
      >
        Cancel
      </button>
    </div>
  )
}

SearchItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onActive: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(SearchItem)
