import { isProductionInstance } from 'utils'
import { fetchSectionsData } from './utils'

const ProjectId = `498AA54894A${isProductionInstance() ? '0' : ''}`

export const data = {
  '802D85C1-8877-459E-A545-277114234858': {
    // Section repeater list
    dataProps: {
      data: async state => {
        const sectionsData = await fetchSectionsData(
          '802D85C1-8877-459E-A545-277114234858',
          '9C8179D6-64D0-42D2-B5D4-15795F06668C'
        )
        return sectionsData
      },
    },
  },
}

export default ProjectId
