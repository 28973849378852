import React from 'react'
import PropTypes from 'prop-types'

const TabPanel = ({ children, value, index, className }) => (
  <div
    className={className}
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
  >
    {children}
  </div>
)

TabPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default TabPanel
