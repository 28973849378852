import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B712')

const CardProps = {
  story: {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta aliquet est eget condimentum. Suspendisse condimentum',
    imageSrc: '',
    tag: 'tag',
    user: {
      username: 'Waynes_Colts',
      avatarSrc:
        'https://s3-us-west-2.amazonaws.com/figma-alpha-api/img/a791/fcec/2e03a1086552a3cd3b85586210d15f8c',
    },
  },
}

const ExpandScrollMs = 300

const handleExpansion = (state, nodeId) => [
  {
    property: LiveStateProperties.DisableScroll,
    value: true,
  },
  {
    property: `${LiveStateProperties.MagicExpandedItem}_${nodeId}`,
    value: true,
  },
  {
    property: LiveStateProperties.ForceViewScrollTo,
    value: {
      viewId: state.projectId,
      scrollProps: {
        scrollTo: 0,
        duration: ExpandScrollMs * 2,
      },
    },
  },
]

const handleContraction = (state, nodeId) => [
  {
    property: LiveStateProperties.DisableScroll,
    value: false,
  },
  {
    property: `${LiveStateProperties.MagicExpandedItem}_${nodeId}`,
    value: false,
  },
]

const MagicNodeIds = {
  First: '38-768',
}

export const data = {
  [ProjectId]: {
    fontFamilies: ['Roboto', 'Overpass-Regular'],
    props: {
      onInitialState: [
        {
          property: LiveStateProperties.MagicExpandedItem,
          value: false,
        },
      ],
    },
  },
  '29-245': {
    // 1st story card
    eventProps: {
      onClick: state => handleExpansion(state, MagicNodeIds.First),
    },
  },
  '29-214': {
    // 2nd story card
    componentProps: {
      ...CardProps,
    },
  },
  '29-276': {
    // 3rd story card
    componentProps: {
      ...CardProps,
      story: {
        ...CardProps.story,
        description: null,
      },
    },
  },
  '42-2': {
    // Logo Text to go back
    frameProps: {
      x: 68,
      y: 18,
    },
    eventProps: {
      onClick: state => handleContraction(state, MagicNodeIds.First),
    },
  },
}

export default ProjectId
