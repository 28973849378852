import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { parseNodeName } from 'utils'

import Button from './Button'
import Input from './Input'
import LinearProgress from './LinearProgress'
import CircularProgress from './CircularProgress'
import Slideshow from './Slideshow'
import Item from './Item'
import Repeater from './Repeater'
import LinkedProject from './LinkedProject'
import CustomComponentNode from '../CustomComponentNode'

const ComponentNames = {
  button: Button,
  input: Input,
  linearprogress: LinearProgress,
  circularprogress: CircularProgress,
  slideshow: Slideshow,
  item: Item,
  repeater: Repeater,
  view: LinkedProject,
}

const ComponentNode = ({ node, ...props }) => {
  const { name, componentName, modifierName } = parseNodeName(node)

  const customComponentName = modifierName
    ? node.name.replace(`--${modifierName}`, '')
    : node.name

  const CustomComponent = get(
    CustomComponentNode,
    `${props.projectId}[${customComponentName}]`
  )

  if (Boolean(CustomComponent)) {
    return <CustomComponent node={node} {...props} />
  }

  // If no custom, render the component itself
  const ComponentName = ComponentNames[componentName] || ComponentNames[name]

  if (!ComponentName) return null

  return <ComponentName node={node} {...props} />
}

ComponentNode.propTypes = {
  node: PropTypes.object.isRequired,
  projectId: PropTypes.string,
}

export default ComponentNode
