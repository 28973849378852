import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import find from 'lodash/find'
import filter from 'lodash/filter'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Ripple from 'common/Ripple'
import Node from 'components/Node'

import styles from './CardItemStyles'

const CardItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange,
  ...props
}) => {
  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  const findNodeByName = name => find(values(node.nodes), n => n.name === name)

  const renderFace = () => {
    let faceNode = findNodeByName('face')

    if (!faceNode) return

    const faceContainerStyles = {
      ...processNodeFrame(faceNode, scaleRatio, viewRect)
    }

    // Make the image relative to its parent `faceContainer`
    faceNode = {
      ...faceNode,
      frame: {
        ...faceNode.frame,
        x: 0,
        y: 0
      }
    }

    return (
      <div className={classes.faceContainer} style={faceContainerStyles}>
        <Node node={faceNode} scaleRatio={scaleRatio} {...props} />
      </div>
    )
  }

  const renderButtons = () => {
    const buttonNodes = filter(values(node.nodes), n => n.type === 'component')

    if (!buttonNodes) return

    return buttonNodes.map(buttonNode => {
      const buttonStyles = {
        ...processNodeFrame(buttonNode, scaleRatio, viewRect),
        ...buttonNode.style
      }

      const buttonNodeText = find(
        values(buttonNode.nodes),
        n => n.type === 'text'
      )

      const buttonNodeTextColor = get(buttonNodeText, 'style.color')

      const handleButtonClick = e => {
        const nameNode = findNodeByName('name')
        const faceNode = findNodeByName('face')

        onProjectStateChange({
          name: nameNode.value,
          time: buttonNodeText.value,
          imageId: faceNode.id
        })
      }

      return (
        <button
          key={buttonNode.id}
          className={classes.button}
          style={buttonStyles}
          onClick={handleButtonClick}
          type="button"
        >
          <Node
            node={buttonNodeText}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            {...props}
          />
          <Ripple color={buttonNodeTextColor} />
        </button>
      )
    })
  }

  const renderBasicNodeByName = name => {
    const nameNode = findNodeByName(name)

    if (!nameNode) return

    return <Node node={nameNode} scaleRatio={scaleRatio} {...props} />
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {renderFace()}
      {renderButtons()}
      {renderBasicNodeByName('name')}
      {renderBasicNodeByName('stars')}
      {renderBasicNodeByName('reviews')}
      {renderBasicNodeByName('pin')}
      {renderBasicNodeByName('miles')}
    </div>
  )
}

CardItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(CardItem)
