import get from 'lodash/get'
import { isProductionInstance, formatNumberWithComma } from 'utils'
import { DayPoints, TodayPointsDate } from './constants'

const ProjectId = `4EC270A794A${isProductionInstance() ? '0' : ''}`

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: 'programPoints',
          value: async state => {
            const currentDate =
              get(state, 'liveState.programPoints.currentDate') ||
              TodayPointsDate

            return {
              currentDate,
              todayDate: TodayPointsDate,
              dayPoints: DayPoints,
            }
          },
        },
      ],
    },
    styleProps: {
      overflowX: 'hidden',
    },
  },
  '9B14266B-0F86-4F33-A661-9137B552640F': {
    // `Program Points` repeater
    dataProps: {
      data: async state => ({
        data: DayPoints[TodayPointsDate].data,
        repeaterId: '9B14266B-0F86-4F33-A661-9137B552640F',
      }),
    },
  },
  '2E8D63BB-017E-4870-ACA6-8A6A2AEC9910': {
    // `Forward` icon
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F16741A1794A%2Fforward-icon.svg?alt=media',
    },
  },
  '4A3FE65E-D0AE-468E-88E8-EBC296B7A545': {
    // `TOTAL Points`
    conditionalProps: {
      value: state => {
        const currentDate =
          get(state, 'liveState.programPoints.currentDate') || TodayPointsDate

        let totalPoints = 0
        DayPoints[currentDate].data.forEach(p => {
          totalPoints = (p.progress * p.points) / 100 + totalPoints
        })
        return formatNumberWithComma(totalPoints)
      },
    },
  },
  'D9CB6335-EEB5-4D0E-BE06-A7B3459F6018': {
    // `GOAL Points`
    conditionalProps: {
      value: state => {
        const currentDate =
          get(state, 'liveState.programPoints.currentDate') || TodayPointsDate

        const goalPoints = DayPoints[currentDate].goal

        return formatNumberWithComma(goalPoints)
      },
    },
  },
  '7A96AFF5-03AE-41DB-AE1A-3E5A864BE5B2': {
    // `DaysItem` in Calendar
    props: {
      onStateChange: value => ({
        property: 'programPoints',
        value: {
          todayDate: TodayPointsDate,
          currentDate: value,
          dayPoints: DayPoints,
        },
      }),
    },
  },
  /** */
}

export default ProjectId
