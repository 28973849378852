const PseudoStyles = {
  content: '""',
  position: 'absolute',
  backgroundRepeat: 'no-repeat',
}

const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  itemContainer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: 69,
    paddingLeft: 16,
    marginTop: 4,
    alignItems: 'center',
    fontFamily: 'SairaSemiCondensed-SemiBold',
    fontSize: 17,

    '&:after': {
      ...PseudoStyles,
      right: 16,
      top: 'calc(50% - 10px)',
      width: 11,
      height: 20,
      backgroundImage:
        'url("https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2FA206C4C194A%2Fprogram-arrow.svg?alt=media")',
    },
  },
  label: {
    justifyContent: 'flex-start',
    letterSpacing: 0,
    lineHeight: '19px',
  },
  description: {
    fontFamily: 'SairaSemiCondensed-Regular',
    fontSize: 13,
    marginTop: 4,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressBarContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.06)',
    borderRadius: '8px 0 0 8px',
    overflow: 'hidden',
  },
  progressBarItem: {
    position: 'absolute',
    height: '100%',
    transition: 'width .3s ease',
  },
  pointsContainer: {
    position: 'relative',
    paddingRight: 42,
    textAlign: 'right',
  },
  highlight: {
    // '&:before': {
    //   ...PseudoStyles,
    //   left: -10,
    //   top: 'calc(50% - 7px)',
    //   width: 8,
    //   height: 14,
    //   backgroundImage:
    //     'url("https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2FA206C4C194A%2Fprogram-highlight.svg?alt=media")',
    // },
  },
  completed: {
    '&:before': {
      ...PseudoStyles,
      left: -20,
      top: 'calc(50% - 7.5px)',
      width: 15,
      height: 15,
      backgroundImage:
        'url("https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2FA206C4C194A%2Fprogram-check.svg?alt=media")',
    },
  },
  practicesContainer: {
    fontSize: 19,
    paddingRight: 42,
    textAlign: 'right',
    '& span': {
      fontSize: 13,
      padding: '0 2px',
      fontFamily: 'SairaSemiCondensed-Regular',
    },
  },
  tagLabel: {
    fontSize: 13,
    fontFamily: 'SairaSemiCondensed-Medium',
  },
  overallPointsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    fontFamily: 'SairaSemiCondensed-Regular',
    fontSize: 24,
    marginTop: 12,
    marginRight: 20,
    textAlign: 'right',
    '& span': {
      padding: '0 4px',
    },
  },
  tagLabelPointsContainer: {
    fontFamily: 'SairaSemiCondensed-SemiBold',
    fontSize: 13,
  },
  overallPracticesContainer: {
    display: 'flex',
    fontFamily: 'SairaSemiCondensed-Regular',
    fontSize: 24,
    marginTop: 12,
    marginRight: 20,
    textAlign: 'right',
    '& > *:first-child': {
      textAlign: 'left',
      opacity: 0.3,
    },
  },
  disabled: {
    color: '#4A4A4A',
    opacity: 0.3,
  },
})

export default jssStyles
