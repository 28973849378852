import AirtableAPI from 'api/AirtableAPI'
import get from 'lodash/get'
import pick from 'lodash/pick'
import values from 'lodash/values'
import random from 'lodash/random'
import flatMap from 'lodash/flatMap'
import isEmpty from 'lodash/isEmpty'
import camelCase from 'lodash/camelCase'

import { Properties } from './constants'
import { isProductionInstance } from 'utils'

export const getId = str => `${str}${isProductionInstance() ? '0' : ''}`

export const getRandomProgress = () => random(0, 100)

export const getGameItemDynamicY = (state, propArray, defaultY) => {
  const offsetY = 115

  let count = 0
  propArray.forEach(prop => {
    count = get(state, `liveState.${prop}`) ? count + 1 : count
  })

  return defaultY - count * offsetY
}

export const fetchFeedState = (state, path, nameCol, type) => {
  const pathData = get(state, path)

  if (!pathData) return

  const colData = pathData.find(c => c.name === nameCol)

  if (!colData) return

  return colData[type]
}

export const getCardNameFromDataFeedState = (state, fieldName) =>
  fetchFeedState(state, 'liveState.dataFeed.Cards', fieldName, 'text')

export const getGameIdFromDataFeedState = (state, fieldName) =>
  camelCase(getCardNameFromDataFeedState(state, fieldName))

export const getGameDataFeedComponentProps = (
  fieldName,
  openExerciseActions = {}
) => ({
  id: state => getGameIdFromDataFeedState(state, fieldName),
  onCardClick: state => () => [
    {
      property: Properties.ActiveGameId,
      value: getGameIdFromDataFeedState(state, fieldName),
    },
    ...openExerciseActions,
  ],
  isFavorited: state =>
    get(
      state,
      `liveState.${Properties.Favorited}${getGameIdFromDataFeedState(
        state,
        fieldName
      )}`
    ),
  onFavChange: state => value => ({
    property: `${Properties.Favorited}${getGameIdFromDataFeedState(
      state,
      fieldName
    )}`,
    value,
  }),
})

let SectionsAirtableData = {}

export const fetchSectionsData = async (currRepeaterId, nestedRepeaterId) => {
  const airtableData = isEmpty(SectionsAirtableData)
    ? await AirtableAPI.fetch('appjUuIGIQZwVsNYc', 'Exercises', 'Grid view')
    : [...SectionsAirtableData]

  SectionsAirtableData = [...airtableData]

  const dataProcessed = values(
    airtableData.reduce((acc, curr) => {
      if (!acc[curr.sectionTitle]) {
        acc[curr.sectionTitle] = []
      }
      acc[curr.sectionTitle].push(curr)
      return acc
    }, {})
  )

  const sectionData = flatMap(
    dataProcessed.map(s =>
      values(
        s.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.sectionTitle]: pick(curr, ['sectionTitle', 'sectionIcon']),
          }),
          {}
        )
      )
    )
  )

  const childData = dataProcessed.map(s =>
    s.map(({ title, subtitle, description, image, tagLabel }) => ({
      id: camelCase(title),
      title,
      subtitle,
      description,
      image,
      tagLabel,
    }))
  )

  return {
    data: sectionData,
    repeaterId: currRepeaterId,
    nestedData: {
      // TODO: Process multiple nestedData
      repeaterId: nestedRepeaterId,
      data: childData,
    },
  }
}

export async function getExerciseData(state) {
  const exercisesData = get(state, `liveState.${Properties.ExercisesData}`)

  if (exercisesData) return exercisesData

  const airtableData = await AirtableAPI.fetch(
    'appjUuIGIQZwVsNYc',
    'Exercises',
    'Grid view'
  )
  return airtableData.map(
    ({
      sectionTitle,
      sectionIcon,
      title,
      subtitle,
      description,
      image,
      tagLabel,
    }) => ({
      sectionTitle,
      sectionIcon,
      id: camelCase(title),
      title,
      subtitle,
      description,
      image,
      tagLabel,
    })
  )
}

export function getCategoryData(state) {
  const exercisesData = get(state, `liveState.${Properties.ExercisesData}`)

  return (
    exercisesData &&
    values(
      exercisesData.reduce((acc, curr) => {
        const {
          sectionTitle,
          sectionIcon,
          title,
          subtitle,
          description,
          image,
          tagLabel,
        } = curr
        if (!acc[sectionTitle]) {
          acc[sectionTitle] = {
            title: sectionTitle,
            icon: sectionIcon,
            games: [],
          }
        }
        acc[sectionTitle].games.push({
          id: camelCase(title),
          title,
          subtitle,
          description,
          image,
          tagLabel,
        })
        return acc
      }, {})
    )
  )
}
