import ProjectIds from 'data/projects/experiments'

import MagicMotion from './MagicMotion'
import ToriiMagicMotion from './ToriiMagicMotion'
import ExpandingCard from './ExpandingCard'
import GoogleMapsSheet from './GoogleMapsSheet'
import InstagramStories from './InstagramStories'
import HiFiveItem from './HiFiveItem'
import SwipeSlider from './SwipeSlider'
import FlipCards from './FlipCards'
import FramerScroll from './FramerScroll'
import ScrollItem from './ScrollItem'
import StickyHeader from './StickyHeader'

export default {
  ProjectIds,
  ExpandingCard,
  MagicMotion,
  ToriiMagicMotion,
  GoogleMapsSheet,
  InstagramStories,
  HiFiveItem,
  SwipeSlider,
  FlipCards,
  FramerScroll,
  ScrollItem,
  StickyHeader,
}
