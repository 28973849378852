import DraggableCardSheet, {
  data as DraggableCardSheetData,
} from './DraggableCardSheet'
import ExpandingCard, { data as ExpandingCardData } from './ExpandingCard'
import GoogleMapsSheet, { data as GoogleMapsSheetData } from './GoogleMapsSheet'
import MagicMotion, { data as MagicMotionData } from './MagicMotion'
import ToriiMagicMotion, {
  data as ToriiMagicMotionData,
} from './ToriiMagicMotion'
import InstagramStories, {
  data as InstagramStoriesData,
} from './InstagramStories'
import HiFive, { data as HiFiveData } from './HiFive'
import SwipeSlider, { data as SwipeSliderData } from './SwipeSlider'
import Flip, { data as FlipData } from './FLIP'
import FramerScroll, { data as FramerScrollData } from './FramerScroll'
import StickyHeader, { data as StickyHeaderData } from './StickyHeader'

export const ProjectIds = [
  DraggableCardSheet,
  ExpandingCard,
  GoogleMapsSheet,
  MagicMotion,
  ToriiMagicMotion,
  InstagramStories,
  HiFive,
  SwipeSlider,
  Flip,
  FramerScroll,
  StickyHeader,
]

const data = {
  typeExperience: 'mobile',
  ...DraggableCardSheetData,
  ...ExpandingCardData,
  ...GoogleMapsSheetData,
  ...MagicMotionData,
  ...ToriiMagicMotionData,
  ...InstagramStoriesData,
  ...HiFiveData,
  ...SwipeSliderData,
  ...FlipData,
  ...FramerScrollData,
  ...StickyHeaderData,
}

export default ProjectIds.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: data,
  }),
  {}
)
