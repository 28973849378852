export default {
  totalbrain: {
    domain: '@totalbrain.com',
    url: 'https://total-brain-b5748a.web.app',
  },
  spotify: {
    domain: '@spotify.com',
    url: '',
  },
  google: {
    domain: '@google.com',
    url: '',
  },
  invitae: {
    domain: '@invitae.com',
    url: '',
  },
  taboola: {
    domain: '@taboola.com',
    url: '',
  },
}
