import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './SearchInputStyles'

const SearchInput = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange,
  onChange,
  ...props
}) => {
  const inputRef = React.createRef()
  const [inputValue, setInputValue] = useState('')

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  useEffect(() => {
    onProjectStateChange && onProjectStateChange(inputValue)
  }, [inputValue])

  const handleTextChange = e => setInputValue(e.target.value)

  return (
    <div className={classes.container} style={containerStyles}>
      {values(node.nodes).map(n => {
        if (n.type === 'text') {
          const inputStyles = {
            ...n.style,
            ...processNodeFrame(n, scaleRatio, viewRect)
          }

          return (
            <input
              onChange={handleTextChange}
              key={n.id}
              className={classes.input}
              type="text"
              placeholder={n.value}
              style={inputStyles}
              ref={inputRef}
              value={inputValue}
              spellCheck="false"
            />
          )
        }

        return (
          <Node
            key={n.id}
            node={n}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            {...props}
          />
        )
      })}
    </div>
  )
}

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  onChange: PropTypes.func
}

export default withStyles(styles)(SearchInput)
