/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

const Sticky = props => {
  const { view, disabled } = props

  const _ = {
    content: null,
    placeholder: null
  }

  const [placeholderClientRect, setPlaceholderClientRect] = useState(null)

  const [state, setState] = useState({
    isSticky: false,
    wasSticky: false,
    style: {}
  })

  useEffect(() => {
    if (view.scrollTop === 0) {
      const placeholderRect = _.placeholder.getBoundingClientRect()
      setPlaceholderClientRect(placeholderRect)
    }
  }, [view])

  const calculateBounds = () => {
    if (!placeholderClientRect || disabled) return

    const contentClientRect = _.content.getBoundingClientRect()

    const currDistanceFromTop = contentClientRect.top - view.offsetTop

    const currWasSticky = !!state.isSticky
    const currIsSticky =
      view.scrollTop >= placeholderClientRect.top - view.offsetTop

    const calculatedHeight = contentClientRect.height

    const style = !currIsSticky
      ? {}
      : {
          position: 'fixed',
          top: view.offsetTop,
          left: placeholderClientRect.left,
          width: placeholderClientRect.width
        }

    if (!props.disableHardwareAcceleration) {
      style.transform = 'translateZ(0)'
    }

    setState({
      isSticky: currIsSticky,
      wasSticky: currWasSticky,
      distanceFromTop: currDistanceFromTop,
      calculatedHeight,
      style
    })
  }

  useEffect(() => {
    setTimeout(() => {
      const placeholderRect = _.placeholder.getBoundingClientRect()
      setPlaceholderClientRect(placeholderRect)
    })
  }, [])

  useEffect(() => {
    calculateBounds()
  }, [view.scrollTop])

  useEffect(() => {
    if (!disabled) return

    setState({
      ...state,
      style: {}
    })
  }, [disabled])

  const {
    isSticky,
    wasSticky,
    distanceFromTop,
    distanceFromBottom,
    calculatedHeight,
    style
  } = state

  const element = React.cloneElement(
    props.children({
      isSticky,
      wasSticky,
      distanceFromTop,
      distanceFromBottom,
      calculatedHeight,
      style
    }),
    {
      ref: content => {
        _.content = ReactDOM.findDOMNode(content)
      }
    }
  )

  return (
    <div>
      <div
        ref={placeholder => {
          _.placeholder = placeholder
        }}
      />
      {element}
    </div>
  )
}

Sticky.propTypes = {
  topOffset: PropTypes.number,
  bottomOffset: PropTypes.number,
  viewRect: PropTypes.object,
  relative: PropTypes.bool,
  disableCompensation: PropTypes.bool,
  disableHardwareAcceleration: PropTypes.bool,
  view: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}

const mapStateToProps = (state, ownProps) => {
  const { view } = state
  return {
    view
  }
}

export default compose(connect(mapStateToProps, null))(Sticky)
