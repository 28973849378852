const jssStyles = theme => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.layerElevatedL,
  },
  closeButton: {
    position: 'absolute',
  },
})

export default jssStyles
