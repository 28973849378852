import get from 'lodash/get'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'

import FirebaseAPI from 'api/FirebaseAPI'
import {
  FETCH_PROJECT_PAGE,
  FETCH_LINKED_PROJECTS,
} from 'constants/actionTypes'
import { extractLinkedProjectsIds, getId } from 'utils'

export function fetchProjectPage(projectId, screenId) {
  return async dispatch => {
    const project = await FirebaseAPI.getProject(projectId)
    dispatch({
      type: FETCH_PROJECT_PAGE,
      payload: project,
    })

    dispatch({
      type: FETCH_LINKED_PROJECTS,
      payload: {
        [projectId]: {
          id: projectId,
          views: get(project, 'views'),
        },
      },
    })

    return project
  }
}

export function fetchLinkedProjects() {
  return async (dispatch, getState) => {
    const {
      page: { linkedProjects },
    } = getState()

    if (isEmpty(linkedProjects)) return

    const linkedProjectIds = Array.from(
      new Set(
        flatten(
          values(
            values(linkedProjects).reduce(
              (acc, curr) => ({
                [curr.id]: extractLinkedProjectsIds(curr),
                ...acc,
              }),
              {}
            )
          )
        )
      )
    )

    const unloadedProjectIds = linkedProjectIds.filter(
      pId => !linkedProjects[pId]
    )

    if (isEmpty(unloadedProjectIds)) return

    const newLinkedProjects = {
      ...linkedProjects,
    }
    await Promise.all(
      unloadedProjectIds.map(async projectId => {
        const { id, views } = await FirebaseAPI.getProject(projectId)

        newLinkedProjects[id] = {
          id,
          views,
        }
      })
    )

    dispatch({
      type: FETCH_LINKED_PROJECTS,
      payload: newLinkedProjects,
    })
  }
}
