import { GlobalIds } from './constants'
import FigmaExperience from './_figma_'
import ExperienceA from './A'
import ExperienceB from './B'
import ExperienceC from './C'

import GameCard1, { data as GameCard1Data } from './GameCard1'
import GameCard2, { data as GameCard2Data } from './GameCard2'
import GameCardList, { data as GameCardListData } from './GameCardList'
import GameCardListWithStickyHeader, {
  data as GameCardListWithStickyHeaderData,
} from './GameCardListWithStickyHeader'
import CapacityCardItem, {
  data as CapacityCardItemData,
} from './CapacityCardItem'
import CapacityCardList, {
  data as CapacityCardListData,
} from './CapacityCardList'
import CapacityDetails, { data as CapacityDetailsData } from './CapacityDetails'
import LibraryPage1, { data as LibraryPage1Data } from './LibraryPage1'
import Program1, { data as Program1Data } from './Program1'
import HomeFeed1, { data as HomeFeed1Data } from './HomeFeed1'
import Test, { data as TestData } from './Test'

export const ProjectIds = [
  ...FigmaExperience.ProjectIds,
  ...ExperienceA.ProjectIds,
  ...ExperienceB.ProjectIds,
  ...ExperienceC.ProjectIds,
  GameCard1,
  GameCard2,
  GameCardList,
  GameCardListWithStickyHeader,
  CapacityCardItem,
  CapacityCardList,
  CapacityDetails,
  LibraryPage1,
  Program1,
  HomeFeed1,
  Test,
]

const data = {
  typeExperience: 'mobile',
  globalNodeIds: {
    ...GlobalIds,
  },
  ...FigmaExperience.data,
  ...ExperienceA.data,
  ...ExperienceB.data,
  ...ExperienceC.data,
  ...GameCard1Data,
  ...GameCard2Data,
  ...GameCardListData,
  ...GameCardListWithStickyHeaderData,
  ...CapacityCardItemData,
  ...CapacityCardListData,
  ...CapacityDetailsData,
  ...LibraryPage1Data,
  ...Program1Data,
  ...HomeFeed1Data,
  ...TestData,
}

export default ProjectIds.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: data,
  }),
  {}
)
