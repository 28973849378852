import withWrapper from 'common/withWrapper'
import ProjectIds from 'data/projects/totalbrain'

import * as StoryComponents from 'totalbrain-stories/dist'

import ControlsItem from './ControlsItem'
import CardItem from './CardItem'
import ToggleItem from './ToggleItem'
import SectionItem from './SectionItem'
import CapacityItem from './CapacityItem'
import SearchInput from './SearchInput'
import TabsItem from './TabsItem'
import CapacityHeader from './CapacityHeader'
import ColorItem from './ColorItem'
import FavChipItem from './FavChipItem'
import FavModalItem from './FavModalItem'
import PointsChipItem from './PointsChipItem'
import ExerciseBackgroundItem from './ExerciseBackgroundItem'
import ProgressItem from './ProgressItem'
import ProgramList from './ProgramList'
import DaysItem from './DaysItem'
import BottomNav from './BottomNav'
import BottomNavigation from './BottomNavigation'
import Switch from './Switch'
import Stack from './Stack'
import CapacityCard from './CapacityCard'
import GamesListItem from './GamesListItem'
import GamesStickyItem from './GamesStickyItem'
import SearchItem from './SearchItem'
import CloseButton from './CloseButton'
import FilterChip from './FilterChip'
import FilterSheetItem from './FilterSheetItem'
import Tabs from './Tabs'

const ExternalComponents = Object.keys(StoryComponents).reduce(
  (acc, currKey) => ({
    ...acc,
    [currKey]: withWrapper(StoryComponents[currKey]),
  }),
  {}
)

export default {
  ControlsItem,
  ProjectIds,
  CardItem,
  ToggleItem,
  SectionItem,
  CapacityItem,
  SearchInput,
  TabsItem,
  CapacityHeader,
  ColorItem,
  FavChipItem,
  FavModalItem,
  PointsChipItem,
  ExerciseBackgroundItem,
  ProgressItem,
  ProgramList: withWrapper(ProgramList),
  DaysItem,
  BottomNav,
  BottomNavigation: withWrapper(BottomNavigation),
  Switch: withWrapper(Switch),
  Stack: withWrapper(Stack),
  CapacityCard: withWrapper(CapacityCard),
  GamesListItem: withWrapper(GamesListItem),
  GamesStickyItem: withWrapper(GamesStickyItem),
  SearchItem: withWrapper(SearchItem),
  CloseButton: withWrapper(CloseButton),
  FilterChip: withWrapper(FilterChip),
  FilterSheetItem: withWrapper(FilterSheetItem),
  Tabs: withWrapper(Tabs),
  ...ExternalComponents,
}
