import React, { useState, useEffect } from 'react'
import isNil from 'lodash/isNil'

export default function useRect() {
  const ref = React.useRef()
  const [rect, setRect] = useState({})
  const [winRect, setWinRect] = useState({})

  const calcRect = () =>
    setRect(ref && ref.current ? ref.current.getBoundingClientRect() : null)

  const calcWinRect = () => {
    setWinRect({
      x: 0,
      y: 0,
      width: window.innerWidth,
      height: window.innerHeight,
    })

    calcRect()
  }

  useEffect(() => {
    calcRect()
    window.addEventListener('resize', calcWinRect)

    return () => {
      window.removeEventListener('resize', calcWinRect)
    }
  }, [])

  useEffect(() => {
    if (isNil(ref?.current)) return

    let resizeObserver = new ResizeObserver(entries => calcRect())
    resizeObserver.observe(ref.current)

    return () => {
      resizeObserver.disconnect()
      resizeObserver = null
    }
  }, [ref])

  return { rect, ref, winRect }
}
