const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  iconButton: {
    position: 'absolute',
    color: '#4A4A4A'
  },
  tag: {
    position: 'absolute',
    borderRadius: 9,
    background: 'yellow',
    width: 'fit-content',
    padding: '2px 8px'
  }
})

export default jssStyles
