import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { compose } from 'redux'
import { useSpring, useTransform, AnimatePresence } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import withWrapper from 'common/withWrapper'

import { findNodeByName, processNodeFrame } from 'utils'

import MotionNode from './MotionNode'

import styles from './HiFiveItemStyles'

const NodeNames = {
  ContentItem: 'content__item',
  DragImageItem: 'drag-image__item',
  DragAffordanceItem: 'drag-affordance__item',
  FooterItem: 'footer__item',
  BackgroundItem: 'background__item',
  DragHeaderItem: 'drag-header__item--float',
  DragProgressItem: 'drag-progress__item',
  DragProgressBgItem: 'drag-progress-bg__item',
  Line: 'line',
}

const HiFiveItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  style,
  ...props
}) => {
  const [isDraggingActive, setIsDraggingActive] = useState(false)

  const ease = [0.6, 0.05, -0.01, 0.99]

  const x = useSpring(0, { stiffness: 300, damping: 200, ease })
  const width = useTransform(x, [-1060 * scaleRatio, 0], [350 * scaleRatio, 0])
  const scale = useTransform(x, [-100 * scaleRatio, 0], [1.25 * scaleRatio, 1])
  const fadeIn = useTransform(x, [-100 * scaleRatio, 0], [1 * scaleRatio, 0])
  const fadeOut = useTransform(x, [-60 * scaleRatio, 0], [0, 1])
  const up = useTransform(x, [-100 * scaleRatio, 0], [-100 * scaleRatio, 0])
  const down = useTransform(x, [-100 * scaleRatio, 0], [100 * scaleRatio, 0])

  const closeProductDrag = () => {
    x.stop()
    x.set(0)
  }

  useEffect(() => {
    onProjectStateChange(
      {
        property: 'disableScroll',
        value: isDraggingActive,
      },
      null,
      {
        updateWithValue: true,
      }
    )
  }, [isDraggingActive])

  useEffect(() => {
    // change the state dependent if my x has passed -100px on the x-axis
    x.onChange(() => {
      x.get() > -100 * scaleRatio
        ? setIsDraggingActive(false)
        : setIsDraggingActive(true)
    })
  }, [x])

  useEffect(() => {
    const closeProductDragValue = get(
      projectState,
      'liveState.closeProductDrag'
    )

    if (!closeProductDragValue) return

    closeProductDrag()
  }, [projectState])

  const dragImageNode = findNodeByName(containerNode, NodeNames.DragImageItem)
  const contentNode = findNodeByName(containerNode, NodeNames.ContentItem)
  const dragAffordanceItem = findNodeByName(
    containerNode,
    NodeNames.DragAffordanceItem
  )
  const footerItem = findNodeByName(containerNode, NodeNames.FooterItem)
  const backgroundNode = findNodeByName(containerNode, NodeNames.BackgroundItem)
  const dragHeaderNode = findNodeByName(containerNode, NodeNames.DragHeaderItem)
  const dragProgressContainerNode = findNodeByName(
    containerNode,
    NodeNames.DragProgressItem
  )

  const dragProgressBgNode = findNodeByName(
    containerNode,
    NodeNames.DragProgressBgItem
  )

  const dragProgressNode = findNodeByName(
    dragProgressContainerNode,
    NodeNames.Line
  )

  const dragProgressContainerStyles = {
    ...processNodeFrame(dragProgressContainerNode, scaleRatio, viewRect),
    height: 1,
  }

  return (
    <>
      <MotionNode
        node={contentNode}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        style={{ translateY: up }}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        {...props}
      />
      {isDraggingActive ? (
        <AnimatePresence>
          <MotionNode
            className={classes.dragHeader}
            node={dragHeaderNode}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            motionProps={{
              initial: { opacity: 0, y: -30 },
              animate: { opacity: 1, y: 0 },
              exit: { opacity: 0, y: -30 },
              transition: { ease },
            }}
            {...props}
          />
        </AnimatePresence>
      ) : (
        <AnimatePresence />
      )}
      <MotionNode
        className={classes.dragImage}
        node={dragImageNode}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        motionProps={{
          drag: 'x',
          dragConstraints: { left: -1060, right: 0 },
          dragElastic: 0.05,
        }}
        style={{ x, scale }}
        {...props}
      />
      <MotionNode
        node={dragAffordanceItem}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        style={{ opacity: fadeOut, x }}
        {...props}
      />
      <MotionNode
        className={classes.background}
        node={backgroundNode}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        style={{ opacity: fadeIn }}
        {...props}
      />
      <MotionNode
        node={dragProgressBgNode}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        {...props}
      />
      <div style={dragProgressContainerStyles} className={classes.dragProgress}>
        <MotionNode
          node={dragProgressNode}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          style={{
            width,
            height: 1,
            backgroundColor: 'white',
            right: 0,
            left: 'auto',
          }}
          {...props}
        />
      </div>
      <MotionNode
        node={footerItem}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        style={{ translateY: down }}
        {...props}
      />
    </>
  )
}

HiFiveItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  style: PropTypes.object,
}

export default compose(withStyles(styles), withWrapper)(HiFiveItem)
