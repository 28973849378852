const jssStyles = theme => ({
  container: {
    position: 'absolute',
    overflow: 'auto',
    transform: 'translate3d(0,0,0)',
  },
  fixedContainer: {
    position: 'fixed',
  },
})

export default jssStyles
