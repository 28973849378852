import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import GameItem from '@totalbrain-components/GameItem'

import styles from './GamesStickyItemStyles'

const GamesStickyItem = ({
  classes,
  categoriesData,
  projectState,
  onChange,
  style,
}) => {
  const handleChange = (e, id, value) => onChange && onChange({ e, id, value })

  return (
    <div className={classes.container} style={style}>
      {categoriesData &&
        categoriesData.map(category => {
          const iconStyles = {
            backgroundImage: `url(${category.icon})`,
          }

          return (
            <ul className={classes.categoryContainer} key={category.title}>
              <div className={classes.categoryHeader}>
                <div className={classes.icon} style={iconStyles} />
                <p>{category.title}</p>
              </div>
              {category.games.map(game => (
                <li
                  className={classes.gameContainer}
                  key={`${category.title}-${game.id}`}
                >
                  <GameItem
                    id={game.id}
                    projectState={projectState}
                    onChange={handleChange}
                  />
                </li>
              ))}
            </ul>
          )
        })}
    </div>
  )
}

GamesStickyItem.propTypes = {
  classes: PropTypes.object.isRequired,
  categoriesData: PropTypes.arrayOf(PropTypes.object),
  projectState: PropTypes.object,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(GamesStickyItem)
