import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import get from 'lodash/get'
import values from 'lodash/values'
import { withTheme, withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './FavModalItemStyles'

const IconButtonName = 'close__icon'

const FavModalItem = ({
  classes,
  theme,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  onChange,
  ...props
}) => {
  const isFavModalActive = get(projectState, 'liveState.isFavModalActive')

  if (!isFavModalActive) return null

  const deactivateModal = () => onProjectStateChange && onProjectStateChange()

  const renderButton = node => (
    <div key={node.id} onClick={deactivateModal}>
      <Node
        node={node}
        className={classes.icon}
        viewRect={viewRect}
        scaleRatio={scaleRatio}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        {...props}
      />
    </div>
  )

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect, true),
    zIndex: theme.zIndex.layerElevatedL,
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(containerNode.nodes).map(node => {
        if (node.name === IconButtonName) return renderButton(node)

        return (
          <Node
            key={node.id}
            node={node}
            viewRect={viewRect}
            scaleRatio={scaleRatio}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...props}
          />
        )
      })}
    </div>
  )
}

FavModalItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  onChange: PropTypes.func,
}

export default compose(withTheme, withStyles(styles))(FavModalItem)
