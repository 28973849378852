import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import theme from 'styles/muiTheme'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import {
  getId,
  fetchFeedState,
  getGameItemDynamicY,
  getExerciseData,
  getGameDataFeedComponentProps,
  getGameIdFromDataFeedState,
  getCardNameFromDataFeedState,
} from '../utils'
import { StyleMixins, Properties } from '../constants'

import GameExerciseProjectId from './GameExercise'

const ProjectId = getId('FT4IODZ5FB250')

const Actions = {
  OpenExerciseView: [
    {
      property: LiveStateProperties.ForceViewScrollTo,
      value: {
        viewId: ProjectId,
        value: 0,
      },
    },
    {
      property: LiveStateProperties.DisableScroll,
      value: true,
    },
    {
      property: projectStatesTypes.ENABLE_LINKED_PROJECT,
      value: GameExerciseProjectId,
    },
  ],
}

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[A] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.ExercisesData,
          value: async state => getExerciseData(state),
        },
      ],
    },
  },
  '27-1': {
    // `hero` image
    conditionalProps: {
      url: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'image',
          'attachment'
        ) ||
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fhero.svg?alt=media',
    },
  },
  '27-2': {
    // Welcome hero `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
      textTransform: 'uppercase',
    },
  },
  '27-3': {
    // WelcomeHero `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
      textTransform: 'uppercase',
    },
  },
  '25-10': {
    // `FavChip` chip
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: true,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
      ],
    },
    styleProps: state => {
      const { liveState } = state

      const isFavorited = Object.keys(liveState).find(
        key => key.includes(Properties.Favorited) && liveState[key]
      )

      return {
        display: isFavorited ? 'block' : 'none',
      }
    },
  },
  '35-5': {
    // `Favorites Modal`
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: false,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
  },
  '35-7': {
    // `Fav Modal close` icon
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: false,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fmodal-close.svg?alt=media',
    },
  },
  '27-10': {
    // Program Card
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
  '27-10-H': {
    // Program Card Hotspot
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
  '27-8': {
    // Program Card `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '27-9': {
    // Program Card `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '35-3': {
    // Program Card `successMessage`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'successMessage',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '27-16': {
    // ProgramView `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '27-15': {
    // ProgramView `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '27-29': {
    // ProgramView `OKAY button`
    styleProps: {
      zIndex: 50,
    },
  },
  '27-29-H': {
    // `Personalized Program` START button HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '27-31-H': {
    // `Program close` icon HOTSPOT
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '27-31': {
    // `Program close` icon
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fprogram-close.svg?alt=media',
    },
  },
  '32-4': {
    // First motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[0].text') ||
        'This method suggests meditation helps us bounce back from stress. Give it a try.',
    },
  },
  '32-22': {
    // Second motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[1].text') ||
        'You think around 70,000 thoughts a day! Reframing the unhelpful ones can lower stress. Try it out.',
    },
  },
  '32-34': {
    // Third motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[2].text') ||
        'You think around 70,000 thoughts a day! Reframing the unhelpful ones can lower stress. Try it out.',
    },
  },
  '35-14': {
    // `games-list__item` in Fav Modal
    componentProps: {
      gamesData: state => {
        const { liveState } = state

        const exercisesData = get(liveState, Properties.ExercisesData)

        const favs = Object.keys(liveState).filter(
          key => key.includes(Properties.Favorited) && liveState[key]
        )

        const favorites = favs.map(f => f.split(Properties.Favorited)[1])

        if (!favorites || !exercisesData) return null

        return favorites.reduce((acc, currId) => {
          const exercise = exercisesData.find(e => e.id === currId)

          if (!exercise) return acc

          acc.push(exercise)

          return acc
        }, [])
      },
      onChange: state => ({ e, id, value }) => {
        if (e === Properties.GameCardEvents.FavChange) {
          return {
            property: `${Properties.Favorited}${id}`,
            value,
          }
        }

        if (e === Properties.GameCardEvents.CardClicked) {
          return [
            {
              property: 'isFavModalActive',
              value: false,
            },
            {
              property: LiveStateProperties.ForceViewScrollTo,
              value: {
                viewId: ProjectId,
                value: 0,
              },
            },
            {
              property: LiveStateProperties.DisableScroll,
              value: true,
            },
            {
              property: Properties.ActiveGameId,
              value: id,
            },
            {
              property: projectStatesTypes.ENABLE_LINKED_PROJECT,
              value: GameExerciseProjectId,
            },
          ]
        }
      },
    },
  },
  '32-10': {
    // `game__item` First Card Unchecked container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`
      )
        ? 'none'
        : '',
    }),
  },
  '32-16': {
    // `game__item` First Card Checked container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`
      )
        ? ''
        : 'none',
    }),
  },
  '32-9': {
    // `game-card__item` First Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'firstCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  '32-15': {
    // `game__item--checked` First Card Text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'firstCardName'),
    },
  },
  '32-21': {
    // `game__item` Second Card Unchecked container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(
          state,
          'secondCardName'
        )}Checked`
      )
        ? 'none'
        : '',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [`${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`],
        204
      ),
    }),
  },
  '32-17': {
    // `game__item` Second Card Checked container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(
          state,
          'secondCardName'
        )}Checked`
      )
        ? ''
        : 'none',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [`${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`],
        199
      ),
    }),
  },
  '32-24': {
    // `game-card__item` Second Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'secondCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  '32-20': {
    // `game__item--checked` Second Card text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'secondCardName'),
    },
  },
  '32-33': {
    // `game__item` Third Unchecked card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`
      )
        ? 'none'
        : '',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
        ],
        398
      ),
    }),
  },
  '32-29': {
    // `game__item` Third Checked card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`
      )
        ? ''
        : 'none',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
        ],
        396
      ),
    }),
  },
  '32-36': {
    // `game-card__item` Third Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'thirdCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  '32-32': {
    // `game__item--checked` Third Card text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'thirdCardName'),
    },
  },
  '33-9': {
    // Game Exercise Linked View
    styleProps: {
      zIndex: theme.zIndex.layerElevatedL,
    },
  },
  '32-11': {
    // `content__item` 1
    frameProps: state => ({
      y: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 520
        : 630,
      height: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        1430 //  1354 + 76 (Home Feed Height)
      ),
    }),
  },
  '32-40': {
    // `content__item` 2
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        554
      ),
    }),
  },
  '32-1': {
    // `house tree` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fhouse-tree.svg?alt=media',
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
}

export default ProjectId
