import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import TotalBrain from '@totalbrain-components'

import styles from './FilterChipStyles'

const FilterChip = ({
  classes,
  label,
  onOpen,
  onClose,
  className,
  style,
  ...props
}) => {
  if (!label) return null

  const containerStyles = {
    ...style,
    width: 'fit-content',
    top: 8,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  }

  const closeButtonStyles = {
    width: 23,
    height: 23,
  }

  const containerClassNames = classNames({
    [classes.container]: true,
    ...className,
  })

  return (
    <div className={containerClassNames} style={containerStyles}>
      <TotalBrain.CloseButton
        isDark
        style={closeButtonStyles}
        onClick={() => onClose && onClose()}
      />
      <div
        className={classes.actionContainer}
        onClick={() => onOpen && onOpen()}
      >
        <p className={classes.label}>{label}</p>
        <div style={{ flex: 1 }} />
        <div className={classes.openFilterButton} />
      </div>
    </div>
  )
}

FilterChip.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default withStyles(styles)(FilterChip)
