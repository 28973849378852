import React, { useState, useEffect, useMemo, useContext } from 'react'
import isEmpty from 'lodash/isEmpty'

import useRect from 'hooks/useRect'
import { ProjectStateContext } from 'contexts/ProjectStateContextProvider'
import {
  processNodeFrame,
  processNodeStyleProps,
  getDataPropsFromNode,
  processNodeDataProps,
} from 'utils'
import { LiveStateProperties } from 'constants/general'

export default function useNodeProps(
  node,
  projectState,
  onProjectStateChange,
  style,
  otherProps
) {
  const projectStateCtx = useContext(ProjectStateContext)
  const scrollY = projectStateCtx.getData(
    LiveStateProperties.ViewScrollPosition
  )

  const [preProcessedNode, setPreProcessedNode] = useState(node)

  const getProjectStateData = propName =>
    projectState?.data?.[node.id]?.[propName]

  const {
    nodeProps,
    frameProps,
    conditionalProps,
    eventProps,
    customProps,
    motionProps,
    observedRef,
    onRectChange,
  } = useMemo(
    () => ({
      nodeProps: getProjectStateData('props'),
      frameProps: getProjectStateData('frameProps'),
      conditionalProps: getProjectStateData('conditionalProps'),
      eventProps: getProjectStateData('eventProps'),
      customProps: getProjectStateData('customProps'),
      motionProps:
        getProjectStateData('motionProps') || otherProps?.motionProps,
      observedRef: getProjectStateData('observedRef'),
      onRectChange: getProjectStateData('onRectChange'),
    }),
    [projectState]
  )

  const fetchingDataIfNecessary = async () => {
    const dataProps = await getDataPropsFromNode(node, projectState)

    if (!dataProps || isEmpty(dataProps)) return

    setPreProcessedNode(processNodeDataProps(node, dataProps))
  }

  useEffect(() => {
    const initialState = nodeProps?.onInitialState

    if (initialState && onProjectStateChange) {
      onProjectStateChange({ stateValues: initialState, nodeId: node.id })
    }

    ;(async function fetchingData() {
      fetchingDataIfNecessary()
    })()
  }, [])

  useEffect(() => {
    const { forceReload } = node

    if (!forceReload) return

    setPreProcessedNode({ ...node, forceReload: false })
  }, [node])

  const { processedNode, processedNodeProps, nodeStyles } = useMemo(() => {
    const processNodeProps = () => {
      let valueProps = { ...nodeProps }

      if (conditionalProps) {
        Object.keys(conditionalProps).forEach(nodePropKey => {
          const conditionalProp = conditionalProps[nodePropKey]

          if (!conditionalProp) return

          const value = conditionalProp(projectState)

          valueProps = {
            ...valueProps,
            [conditionalProp.name]: value,
          }
        })
      }

      if (eventProps) {
        Object.keys(eventProps).forEach(nodePropKey => {
          valueProps = {
            ...valueProps,
            [nodePropKey]: () => {
              const value = eventProps[nodePropKey](projectState)
              onProjectStateChange &&
                onProjectStateChange(value, null, {
                  updateWithValue: true,
                })
            },
          }
        })
      }

      return valueProps
    }

    const processNode = () => {
      let valueProps = {
        ...preProcessedNode,
      }

      // Processing `frame props`
      if (frameProps) {
        const frameProcessed =
          frameProps && typeof frameProps === 'function'
            ? frameProps(projectState)
            : frameProps

        valueProps = {
          ...valueProps,
          frame: {
            ...valueProps.frame,
            ...frameProcessed,
          },
        }
      }

      if (customProps) {
        valueProps = {
          ...valueProps,
          customProps,
        }
      }

      return {
        ...valueProps,
      }
    }

    return {
      processedNode: processNode(),
      processedNodeProps: processNodeProps(),
      nodeStyles: {
        ...style,
        ...processNodeStyleProps(projectState, node),
      },
    }
  }, [projectState])

  const { rect, ref } = useRect()

  const processedNodeRef = useMemo(() => {
    if (!onRectChange) return null
    return { ref, rect }
  }, [rect])

  useMemo(() => {
    if (!processedNodeRef) return

    const value = onRectChange(processedNodeRef?.rect)

    onProjectStateChange &&
      onProjectStateChange(value, null, {
        updateWithValue: true,
      })
  }, [projectState, processedNodeRef])

  const processedFrameAndNode = useMemo(
    () => ({
      ...processedNode,
      nodeRef: {
        [processedNodeRef && observedRef
          ? observedRef
          : processedNodeRef
          ? 'ref'
          : null]: processedNodeRef?.ref,
      },
      processedFrame: processNodeFrame(
        processedNode,
        otherProps?.scaleRatio,
        otherProps?.viewRect,
        false,
        scrollY
      ),
    }),
    [projectState, scrollY]
  )

  return {
    node: processedFrameAndNode,
    nodeProps: processedNodeProps,
    nodeStyles,
    nodeMotionProps: motionProps,
    onProjectStateChange,
  }
}
