import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import find from 'lodash/find'
import values from 'lodash/values'
import { motion } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import JsonData from './data'

import styles from './SearchItemsStyles'

const DefaultProductCatValue = 'chairs'

const SearchItemHeight = 108

const SearchItems = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  style,
  ...props
}) => {
  const productCatValue =
    get(projectState, 'liveState.productCategory') || DefaultProductCatValue

  const searchInputValue = get(projectState, 'liveState.searchInput')

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  const findNodeByName = (name, optNode) =>
    find(values((optNode || node).nodes), ch => ch.name === name)

  const renderBasicNodeByName = (name, basicNode, value, otherNodeProps) => {
    const nameNode = findNodeByName(name, basicNode)

    if (!nameNode) return

    const nodeValueInjected = {
      ...nameNode,
      value
    }

    return (
      <Node
        node={nodeValueInjected}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        {...props}
        {...otherNodeProps}
      />
    )
  }

  const renderButton = (itemNode, productItem) => {
    const buttonNode = findNodeByName('button', itemNode)

    const handleButtonClick = () => onProjectStateChange(productItem)

    return (
      <div onClick={handleButtonClick}>
        <Node
          node={buttonNode}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          projectState={projectState}
          {...props}
        />
      </div>
    )
  }

  const itemNode = findNodeByName('item')

  const dataItems = JsonData[productCatValue]

  const searchItemStyles = {
    height: SearchItemHeight * scaleRatio
  }

  const itemOverrides = {
    variants: {
      open: {
        opacity: 1,
        x: 0
      },
      closed: {
        opacity: 0,
        x: -8
      }
    }
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {dataItems &&
        dataItems.map(({ label, description, imageUrl, price }, itemIndex) => {
          const productItem = JsonData[productCatValue][itemIndex]

          if (
            searchInputValue &&
            label.toLowerCase().indexOf(searchInputValue.toLowerCase()) === -1
          ) {
            return null
          }

          return (
            <motion.div
              className={classes.item}
              key={label}
              style={searchItemStyles}
              {...itemOverrides}
            >
              {renderBasicNodeByName('title', itemNode, label)}
              {renderBasicNodeByName('description', itemNode, description)}
              {renderBasicNodeByName('price', itemNode, `$${price}`)}
              {renderBasicNodeByName('tile', itemNode, null, { url: imageUrl })}
              {renderButton(itemNode, productItem)}
            </motion.div>
          )
        })}
    </div>
  )
}

SearchItems.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(SearchItems)
