// User & Auth
export const INIT_USER = 'INIT_USER'

// Project
export const FETCH_PROJECT_PAGE = 'FETCH_PROJECT_PAGE'
export const FETCH_LINKED_PROJECTS = 'FETCH_LINKED_PROJECTS'

// Project State
export const INIT_PROJECT_STATE = 'INIT_PROJECT_STATE'
export const UPDATE_LIVE_STATE = 'UPDATE_LIVE_STATE'
export const UPDATE_ACTIVE_VIEWS = 'UPDATE_ACTIVE_VIEWS'
export const UPDATE_DATA_STYLE_PROPS = 'UPDATE_DATA_STYLE_PROPS'
export const UPDATE_ACTIVE_LINKED_VIEW = 'UPDATE_ACTIVE_LINKED_VIEW'

// View
export const SET_SCROLL_VALUES = 'SET_SCROLL_VALUES'

// Admin
export const ADD_LOADED_PROJECT_ID = 'ADD_LOADED_PROJECT_ID'
