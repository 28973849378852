import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import camelCase from 'lodash/camelCase'
import AirtableAPI from 'api/AirtableAPI'
import { LiveStateProperties } from 'constants/general'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId, getExerciseData } from '../utils'
import { Properties } from '../constants'

import HomeFeed2ProjectId from './HomeFeed2'
import Program2ProjectId from './Program2'
import AssessPageProjectId from './AssessPage'
import LibraryPage2ProjectId from './LibraryPage2'
import OnboardingHomeFeed2ProjectId from './OnboardingHomeFeed2'

const ProjectId = getId('FT4IODZ5FB410')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: OnboardingHomeFeed2ProjectId,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
        {
          property: Properties.OnboardingHomeFeed,
          value: 1,
        },
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[A] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.BottomNavItemsData,
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[A] Bottom Nav',
              'Grid view'
            )
            return airtableData.map(({ label, outlineSvg, activeSvg }) => ({
              id: camelCase(label),
              label,
              outlineSvg,
              activeSvg,
            }))
          },
        },
        {
          property: Properties.ExercisesData,
          value: async state => getExerciseData(state),
        },
      ],
    },
  },
  '42-4': {
    // `BottomNavigation` Component
    componentProps: {
      items: state => get(state, `liveState.${Properties.BottomNavItemsData}`),
      activeItemId: state => get(state, 'liveState.bottomNavActiveId'),
      onChange: state => value => ({
        property: 'bottomNavActiveId',
        value,
      }),
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ActiveGameId}`) ? 'none' : '',
    }),
  },
  '44-0': {
    // `linkedProject` OnboardingHomeFeed
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: !onboardingStep ? 'none' : 'block',
      }
    },
  },
  '42-5': {
    // `linkedProject` Home Feed view
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: HomeFeed2ProjectId,
        },
      ],
    },
    frameProps: state => ({
      height: get(state, `liveState.${Properties.ActiveGameId}`) ? 812 : 736,
    }),
  },
  '42-10': {
    // `linkedProject` Program view
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: Program2ProjectId,
        },
      ],
    },
  },
  '42-14': {
    // `linkedProject` Assess view
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: AssessPageProjectId,
        },
      ],
    },
  },
  '42-12': {
    // `linkedProject` Library view
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: LibraryPage2ProjectId,
        },
      ],
    },
    frameProps: state => ({
      height: get(state, `liveState.${Properties.ActiveGameId}`) ? 812 : 736,
    }),
  },
  '46-0': {
    // `linkedProject` Onboarding Library
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingLibraryStep}`
      )
      return {
        display: onboardingStep === null ? 'none' : 'block',
      }
    },
  },
}

export default ProjectId
