import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import createDebounce from 'redux-debounced'
import throttle from 'lodash/throttle'
import merge from 'lodash/merge'

import { saveStoreToLocalStorage, loadStoreFromLocalStorage } from 'utils/localStorage'
import initialState from './reducers/initialState'
import rootReducer from './reducers'

export const history = createBrowserHistory()

function updateFirebaseConnections(store) {}

function configureStore() {
  // Give the router middleware a history to attach itself to
  const attachedRouterMiddleware = routerMiddleware(history)

  const debounceMiddleware = createDebounce()
  // Load subset of state from localStorage. This will override the initialState in reducers.
  // @see http://redux.js.org/docs/recipes/reducers/InitializingState.html
  // Note: Since preloaded state overrides initialState when provided via createStore, we need to
  // first deep merge these two together. This allows values in local storage to overwrite the
  // equivalent values in initialState if necessary.
  const preloadedState = merge({}, initialState, loadStoreFromLocalStorage())

  // Allow the store to work with the Redux DevTools Chrome Extension:
  // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      // Debounce middleware must be applied before thunkMiddleware in order to work in thunks.
      applyMiddleware(debounceMiddleware, thunkMiddleware, attachedRouterMiddleware)
    )
  )

  // Listen for store updates and save a subset of redux store to localStorage. Throttled to reduce
  // the performance overhead from consecutive store updates.
  // @see https://stackoverflow.com/a/35675304/2275300
  store.subscribe(throttle(_ => saveStoreToLocalStorage(store.getState()), 5000))
  store.subscribe(_ => updateFirebaseConnections(store))
  return store
}

export default configureStore()
