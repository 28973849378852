import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { motion } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import Node from 'components/Node'

import { processNodeFrame } from 'utils'

import styles from './HiFiveItemStyles'

const MotionNode = ({
  classes,
  className,
  node: containerNode,
  scaleRatio,
  viewRect,
  motionProps,
  style,
  ...props
}) => {
  const containerClassNames = classNames({
    [classes.container]: true,
    [className]: !!className,
  })

  const containerStyles = {
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    ...style,
  }

  return (
    <motion.div
      className={containerClassNames}
      style={containerStyles}
      {...motionProps}
    >
      {values(containerNode.nodes).map(n => (
        <Node node={n} scaleRatio={scaleRatio} viewRect={viewRect} {...props} />
      ))}
    </motion.div>
  )
}

MotionNode.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  motionProps: PropTypes.object,
  style: PropTypes.object,
}

export default withStyles(styles)(MotionNode)
