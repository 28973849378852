import firebase from './firebase'

export const ConnectionTypes = {
  Projects: 'Projects',
}

const FirebaseDB = firebase.database()
const ProjectRef = FirebaseDB.ref('project')

const DefaultConnextionsConfig = {
  [ConnectionTypes.Project]: {
    ref: ProjectRef,
  },
}

class FirebaseAPI {
  connections = { ...DefaultConnextionsConfig }

  getConnectionRef = (ConnectionType, parentRef = null) => {
    return parentRef
      ? this.connections[ConnectionType][parentRef].ref
      : this.connections[ConnectionType].ref
  }

  getProject = async projectId => {
    if (!this.connections[ConnectionTypes.Project].ref) return

    const snapshot = await this.connections[ConnectionTypes.Project].ref
      .child(projectId)
      .once('value')
    return snapshot.val()
  }

  /**
   * Bind any changes made in firebase to redux.
   */
  bindStoreToFirebaseConnection = (store, connectionType) => {}

  /**
   * Initialize a connection to firebase.
   * @param {Object} store Reference to the redux store.
   * @param {ConnectionType} connectionType The type of connection to create.
   * @param {Object}
   *    {String} projectId - The project id.
   *    {String} screenId - The screen id.
   */
  connect = (store, connectionType, { projectId, screenId }) => {}

  /**
   * Disconnect from firebase and unsubscribe from the redux store.
   */
  disconnect = connectionType => {}
}

export default new FirebaseAPI()
