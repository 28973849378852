import get from 'lodash/get'
import set from 'lodash/set'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import {
  INIT_PROJECT_STATE,
  UPDATE_LIVE_STATE,
  UPDATE_ACTIVE_VIEWS,
  UPDATE_DATA_STYLE_PROPS,
} from 'constants/actionTypes'
import { isProductionInstance, loadHotjarScript } from 'utils'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import ProjectsData from 'data/projects'
import HotJarProjects from 'config/hotjar'

function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function initHotJarIfNecessary(projectId) {
  if (!isProductionInstance()) return
  const hotProject = HotJarProjects[projectId]
  if (!hotProject || !hotProject.on) return
  loadHotjarScript(hotProject.siteId)
}

export function updateActiveViews(viewId, projectId = null) {
  return async (dispatch, getState) => {
    const {
      page: { project },
    } = getState()

    dispatch({
      type: UPDATE_ACTIVE_VIEWS,
      payload: {
        [projectId || project.id]: viewId,
      },
    })
  }
}

export function initProjectState(projectId) {
  return async dispatch => {
    const data = get(ProjectsData, projectId)

    dispatch({
      type: INIT_PROJECT_STATE,
      payload: {
        data,
        projectId,
      },
    })

    dispatch(updateActiveViews(projectId, projectId))

    initHotJarIfNecessary(projectId)
  }
}

export function updateLiveState(prop, nodeId = null, linkedProjectId = null) {
  return async (dispatch, getState) => {
    const { projectState } = getState()

    if (isArray(prop)) {
      prop.forEach(p => dispatch(updateLiveState(p, nodeId, linkedProjectId)))
      return
    }

    if (!prop || !prop.property) return

    if (prop.property === projectStatesTypes.WAIT) {
      await wait(prop.value)
      return
    }

    if (prop.property === projectStatesTypes.ACTIVE_VIEW_ID) {
      dispatch(updateActiveViews(prop.value))
      return
    }

    if (prop.property === projectStatesTypes.ENABLE_LINKED_PROJECT) {
      const { value: projectId } = prop

      dispatch({
        type: projectStatesTypes.ENABLE_LINKED_PROJECT,
        payload: projectId,
      })

      dispatch(updateActiveViews(projectId, projectId))
      return
    }

    if (prop.property === projectStatesTypes.DISABLE_LINKED_PROJECT) {
      const { value: projectId } = prop
      dispatch({
        type: projectStatesTypes.DISABLE_LINKED_PROJECT,
        payload: projectId,
      })
      return
    }

    if (prop.property === projectStatesTypes.STYLE_PROPS) {
      const nodeStyleProps = get(projectState, `data.${nodeId}.styleProps`)

      const styleProps = {
        ...nodeStyleProps,
        ...prop.value,
      }

      dispatch({
        type: UPDATE_DATA_STYLE_PROPS,
        styleProps,
        nodeId,
      })
      return
    }

    if (prop.property === projectStatesTypes.HISTORY_BACK) {
      const { projectId, prevActiveViews } = projectState
      const prevActiveView = get(prevActiveViews, projectId)
      if (prevActiveView) dispatch(updateActiveViews(prevActiveView))
      return
    }

    // This doesn't enable the deep update of nested properties for liveState
    // e.g. `favorites.shortMeditation = true`
    const newLiveStateValue = set(
      {},
      prop.property,
      isFunction(prop.value) ? await prop.value() : prop.value
    )

    dispatch({
      type: UPDATE_LIVE_STATE,
      payload: {
        ...newLiveStateValue,
      },
    })
  }
}
