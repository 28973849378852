import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import MaterialTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { findNodeByName, sortNodesByZIndex } from 'utils'

import Node from 'components/Node'
import TabPanel from './TabPanel'

import styles, { UI } from './TabsStyles'

/** Utils */
const getLabelItems = node => {
  const headerItemNode = findNodeByName(node, 'header__item')
  const headerItemNodes = findNodeByName(headerItemNode, 'item')

  return sortNodesByZIndex(headerItemNodes).map(n => {
    const labelNode = findNodeByName(n, 'label')
    return labelNode.value
  })
}

const getContentNodes = node => {
  const headerItemNode = findNodeByName(node, 'content__item')
  const headerItemNodes = findNodeByName(headerItemNode, 'item')

  return sortNodesByZIndex(headerItemNodes)
}
//

const CustomTabs = withStyles(UI.tabs)(props => (
  <MaterialTabs {...props} TabIndicatorProps={{ children: <div /> }} />
))

const CustomTab = withStyles(UI.tab)(Tab)

const Tabs = ({
  classes,
  index,
  node: containerNode,
  onChange,
  style,
  ...otherProps
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [labels, setLabels] = useState(null)
  const [contentNodes, setContentNodes] = useState(null)

  useEffect(() => {
    const labelItems = getLabelItems(containerNode)

    const contentItems = getContentNodes(containerNode)

    setLabels(labelItems)
    setContentNodes(contentItems)
  }, [containerNode])

  useEffect(() => setActiveIndex(index || 0), [index])

  const handleChange = (e, newValue) => {
    setActiveIndex(newValue)
    onChange && onChange(newValue)
  }

  return (
    <div className={classes.container} style={style}>
      <CustomTabs value={activeIndex} onChange={handleChange} centered>
        {labels && labels.map(label => <CustomTab key={label} label={label} />)}
      </CustomTabs>
      {contentNodes &&
        contentNodes.map((n, nIndex) => (
          <TabPanel
            className={classes.tabPanel}
            value={activeIndex}
            index={nIndex}
            key={nIndex}
          >
            {values(n.nodes).map(ch => (
              <Node key={ch.id} node={ch} {...otherProps} />
            ))}
          </TabPanel>
        ))}
    </div>
  )
}

Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  node: PropTypes.object,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(Tabs)
