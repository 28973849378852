import AirtableAPI from 'api/AirtableAPI'
import { isProductionInstance } from 'utils'

const ProjectId = `95E6B9D794A${isProductionInstance() ? '0' : ''}`

export const data = {
  'EEB073E9-FBDA-4584-AEB4-5FF85CB93392': {
    // Repeater list
    dataProps: {
      data: async () => ({
        data: await AirtableAPI.fetch(
          'appjUuIGIQZwVsNYc',
          'Table 1',
          'Grid view'
        ),
        repeaterId: 'EEB073E9-FBDA-4584-AEB4-5FF85CB93392',
      }),
    },
  },
  '2826E4A9-2F34-4A70-A0F8-DABA9227308F': {
    // Card image
    props: {
      isBackgroundImage: true,
    },
  },
}

export default ProjectId
