const jssStyles = theme => ({
  container: {},
  categoryContainer: {
    marginBottom: 48,
  },
  categoryHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,

    '& img': {
      width: 24,
      height: 24,
    },
    '& p': {
      color: 'rgb(33, 33, 33)',
      fontFamily: 'Saira-Regular',
      fontSize: 19,
      letterSpacing: -0.22,
      lineHeight: '23px',
      marginLeft: 8,
    },
  },
  gameContainer: {
    marginBottom: 16,
    '& > *': {
      width: '100% !important',
    },
  },
  icon: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 24,
    width: 24,
  },
})

export default jssStyles
