import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { css } from 'emotion'
import ReactDOMServer from 'react-dom/server'
import { withStyles } from '@material-ui/core/styles'

import Swiper from 'react-id-swiper'

import {
  findNodeByName,
  processNodeFrame,
  sortNodesByZIndex,
  getNodesArrayHierarchy,
  getNodesArraySortedByPosition,
} from 'utils'

import Node from 'components/Node'

import styles from './SlideshowStyles'

const SlideshowType = {
  container: 'slideshow',
  slides: 'slides__item',
  handlers: 'handlers__item',
}

const Slideshow = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  style,
  id,
  reload,
  ...props
}) => {
  const [init, setInit] = useState(null)

  useEffect(() => setInit(true), [])

  if (!init) return null

  const renderParams = () => {
    const handlersContainer = findNodeByName(
      containerNode,
      SlideshowType.handlers
    )

    const handlersArray = getNodesArraySortedByPosition(
      values(handlersContainer.nodes)
    )

    const handlersHierarchy = getNodesArrayHierarchy(handlersArray)

    const handlerNode = handlersArray.find(n => !n.name.includes('--active'))

    const handlerActiveNode = handlersArray.find(n =>
      n.name.includes('--active')
    )

    let handlerActiveFrame = {}
    let handlerActiveStyles = {}
    let bulletActiveClassName = null
    if (handlerActiveNode) {
      const activeFrame = processNodeFrame(
        handlerActiveNode,
        scaleRatio,
        viewRect
      )

      handlerActiveFrame = Object.keys(activeFrame).reduce((acc, keyValue) => {
        acc[keyValue] = `${activeFrame[keyValue]}px !important`
        return acc
      }, {})

      handlerActiveStyles = Object.keys(handlerActiveNode.style).reduce(
        (acc, keyValue) => {
          acc[keyValue] = `${handlerActiveNode.style[keyValue]} !important`
          return acc
        },
        {}
      )

      bulletActiveClassName = css`
        ${{
          ...handlerActiveFrame,
          ...handlerActiveStyles,
        }}
      `
    }

    const { width, height } = processNodeFrame(
      handlerNode,
      scaleRatio,
      viewRect
    )

    const handlerStyles = {
      width,
      height,
      opacity: 1,
      ...handlerNode.style,
      margin: `0 ${(handlersHierarchy.x / 2) * scaleRatio}px `,
    }

    return {
      slidesPerView: 'auto',
      spaceBetween: 0,
      mousewheel: true,
      freeMode: false,
      pagination: {
        el: '.swiper-pagination',
        bulletActiveClass: bulletActiveClassName,
        clickable: true,
        renderBullet: (index, className) => {
          const bulletElement = (
            <span style={handlerStyles} className={className} />
          )
          return ReactDOMServer.renderToString(bulletElement)
        },
      },
    }
  }

  const containerStyles = {
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
  }

  const slidesNodes = sortNodesByZIndex(
    findNodeByName(containerNode, SlideshowType.slides)
  )

  return (
    <div className={classes.container} style={containerStyles}>
      <Swiper {...renderParams()}>
        {slidesNodes.map(slideNode => (
          <div key={slideNode.id}>
            <Node
              node={slideNode}
              scaleRatio={scaleRatio}
              viewRect={viewRect}
              {...props}
            />
          </div>
        ))}
      </Swiper>
    </div>
  )
}

Slideshow.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  style: PropTypes.object,
  id: PropTypes.string,
  reload: PropTypes.bool,
}

export default withStyles(styles)(Slideshow)
