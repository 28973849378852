import React, { useState } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { compose } from 'redux'
import { motion, useMotionValue, useAnimation } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import withWrapper from 'common/withWrapper'

import styles from './GoogleMapsSheetStyles'

const GoogleMapsSheet = ({ classes, onChange, style }) => {
  const [sheetState, setSheetState] = useState(null)

  const toggleSheetState = () => setSheetState(!sheetState)

  const sheetContainerStyles = {
    borderRadius: sheetState ? 0 : '10px 10px 0 0',
    height: sheetState ? 190 : 64,
  }

  return (
    <div className={classes.container} onClick={toggleSheetState}>
      <div className={classes.sheetContainer} style={sheetContainerStyles} />
      <div className={classes.imageContainer} />
    </div>
  )
}

GoogleMapsSheet.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  onChange: PropTypes.func,
  viewRect: PropTypes.object,
  style: PropTypes.object,
}

export default compose(withStyles(styles), withWrapper)(GoogleMapsSheet)
