/* eslint-disable no-eval */
import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import styles from './TextNodeStyles'

const TextNode = ({ classes, node, value, onClick, style }) => {
  const { style: nodeStyle, value: nodeValue, layoutId } = node

  const rootStyles = {
    ...nodeStyle,
    ...node.processedFrame,
    ...style,
  }

  // Make sure lineHeight is in pixels. Otherwise the style would be off.
  // Limitation of Sketch, when it exports it properties, they come as a plain number
  if (typeof rootStyles.lineHeight === 'number') {
    rootStyles.lineHeight = `${rootStyles.lineHeight}px`
  }

  const otherProps = {}

  if (layoutId) {
    otherProps.layoutId = layoutId
  }

  return (
    <motion.div
      className={classes.container}
      onClick={onClick}
      style={rootStyles}
      dangerouslySetInnerHTML={{ __html: value || nodeValue }}
      {...otherProps}
    />
  )
}

TextNode.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  projectId: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

export default withStyles(styles)(TextNode)
