const jssStyles = theme => ({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: 15
    }
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'center',
    justifyContent: 'center'
  }
})

export default jssStyles
