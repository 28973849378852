import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { getImageUrl } from 'utils'
import { fetchProjectPage } from 'actions/pageActions'

import styles from './ImageViewerPageStyles'

function extractAllImagesFromNodes(nodes, images = {}) {
  values(nodes).forEach(({ nodes: childNodes }) => {
    childNodes && extractAllImagesFromNodes(childNodes, images)
  })

  values(nodes)
    .filter(node => node.type === 'image')
    .forEach(node => {
      // eslint-disable-next-line no-param-reassign
      images[node.id] = node
    })
}

const ImageViewerPage = ({ classes, page, routeParams, actions }) => {
  const [imageNodes, setImageNodes] = useState(null)
  const [viewerIndex, setViewerIndex] = useState(null)
  const [currentScale, setCurrentScale] = useState(null)
  const [theme, setTheme] = useState('light')

  useEffect(() => {
    const projectId = get(routeParams, 'projectId')
    if (!projectId) return
    actions.fetchProjectPage(projectId)
  }, [])

  useEffect(() => {
    const views = get(page, 'project.views')
    if (!views) return

    const images = {}
    extractAllImagesFromNodes(views, images)
    setImageNodes(images)

    const currentImageIndex = 0
    const currentImage = values(images)[currentImageIndex]

    if (!currentImage) return

    setViewerIndex(0)

    const currScale = currentImage.scales[currentImage.scales.length - 1]

    setCurrentScale(currScale)
  }, [page])

  const handlePrevImage = () => {
    setViewerIndex(Math.max(viewerIndex - 1, 0))
  }

  const handleNextImage = () => {
    setViewerIndex(Math.min(values(imageNodes).length - 1, viewerIndex + 1))
  }

  const project = get(page, 'project')

  const currentImageNode = get(values(imageNodes), `[${viewerIndex}]`)

  if (!currentImageNode) return null

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.darkContainer]: theme === 'dark'
  })

  const imageContainerStyles = {
    width: currentImageNode.frame.width,
    height: currentImageNode.frame.height
  }

  return (
    <div className={containerClassNames}>
      <div className={classes.header}>
        <div className={classes.flexStart}>
          <FormControl variant="filled" className={classes.themeControl}>
            <InputLabel>Theme</InputLabel>
            <Select value={theme} onChange={e => setTheme(e.target.value)}>
              <MenuItem value="light" className={classes.selectMenuItem}>
                <div className={classes.themeItem}>
                  <div className={classes.lightCircle} />
                  <span>Light</span>
                </div>
              </MenuItem>
              <MenuItem value="dark">
                <div className={classes.themeItem}>
                  <div className={classes.darkCircle} />
                  <span>Dark</span>
                </div>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.headerControls}>
          <IconButton
            aria-label="Previous image"
            onClick={handlePrevImage}
            disabled={viewerIndex === 0}
          >
            <KeyboardArrowLeft />
          </IconButton>

          <span className={classes.imageNameContainer}>
            <span className={classes.imageName}>{currentImageNode.name}</span>
            <span className={classes.imageCounter}>
              {`${viewerIndex + 1}/${values(imageNodes).length}`}
            </span>
          </span>

          <IconButton
            aria-label="Next image"
            onClick={handleNextImage}
            disabled={viewerIndex === values(imageNodes).length - 1}
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
        <div className={classes.flexEnd}>
          <FormControl variant="filled" className={classes.selectControl}>
            <InputLabel>Scale</InputLabel>
            <Select
              value={currentScale}
              onChange={e => setCurrentScale(e.target.value)}
            >
              {currentImageNode.scales.map(scale => (
                <MenuItem value={scale} key={scale}>
                  {scale}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.canvas}>
        <div className={classes.imageContainer} style={imageContainerStyles}>
          <img
            src={getImageUrl(project.id, currentImageNode.id, currentScale)}
            alt={currentImageNode.id}
          />
        </div>
      </div>
    </div>
  )
}

ImageViewerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  projectState: PropTypes.object,
  routeParams: PropTypes.object,
  history: PropTypes.object,
  actions: PropTypes.shape({
    fetchProjectPage: PropTypes.func.isRequired
  })
}

const mapStateToProps = (state, ownProps) => {
  const { page } = state
  return {
    page,
    routeParams: ownProps.match.params
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        fetchProjectPage
      },
      dispatch
    )
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ImageViewerPage)
