import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './CloseButtonStyles'

const CloseButton = ({ classes, className, isDark, onClick, style }) => {
  const newClassNames =
    typeof className === 'string' ? { className } : className

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.darkContainer]: isDark,
    ...newClassNames,
  })

  return (
    <div
      className={containerClassNames}
      onClick={e => onClick && onClick(e)}
      style={style}
    />
  )
}

CloseButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isDark: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(CloseButton)
