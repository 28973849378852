import get from 'lodash/get'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import { getId, getCategoryData } from '../utils'
import { Properties } from '../constants'

import LibraryPageProjectId from './LibraryPage2'
import GameExerciseProjectId from './GameExercise'

const ProjectId = getId('9C66BD0894A')

export const data = {
  'C04CBD3B-406F-4DA5-833C-7E0438807D3F': {
    // `Capacity Details` Benefits
    conditionalProps: {
      value: state => {
        const activeCapacityBenefits = get(
          state,
          `liveState.${Properties.ActiveCapacityDetails}.benefits`
        )
        return activeCapacityBenefits
      },
    },
  },
  '06D90901-39FD-4387-8BA2-E75F5169641E': {
    // `Capacity Details` Science
    conditionalProps: {
      value: state => {
        const activeCapacityScience = get(
          state,
          `liveState.${Properties.ActiveCapacityDetails}.science`
        )
        return activeCapacityScience
      },
    },
  },
  '6776F138-E944-4BF7-955E-B90158A2ED74': {
    // `Capacity Details` Image
    conditionalProps: {
      url: state => {
        const activeCapacityImage = get(
          state,
          `liveState.${Properties.ActiveCapacityDetails}.image`
        )
        return activeCapacityImage
      },
    },
    props: {
      isBackgroundImage: true,
    },
    styleProps: {
      opacity: 0.4,
    },
  },
  '7CE69882-1672-4C17-AA86-0273CB85CE19': {
    // `Capacity Details` Exercises list
    componentProps: {
      categoriesData: state => getCategoryData(state),
      projectState: state => state,
      onChange: state => ({ e, id, value }) => {
        if (e === Properties.GameCardEvents.FavChange) {
          return {
            property: `${Properties.Favorited}${id}`,
            value,
          }
        }

        if (e === Properties.GameCardEvents.CardClicked) {
          return [
            {
              property: LiveStateProperties.ForceViewScrollTo,
              value: {
                viewId: ProjectId,
                value: 0,
              },
            },
            {
              property: LiveStateProperties.DisableScroll,
              value: true,
            },
            {
              property: Properties.ActiveGameId,
              value: id,
            },
            {
              property: projectStatesTypes.ENABLE_LINKED_PROJECT,
              value: GameExerciseProjectId,
            },
          ]
        }
      },
    },
  },
  '690700EC-A532-4D1B-B0B0-EA605742FF72': {
    // Back `HOTSPOT` button
    props: {
      onStateChange: () => [
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: ProjectId,
        },
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: LibraryPageProjectId,
        },
      ],
    },
  },
}

export default ProjectId
