const jssStyles = theme => ({
  container: {
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
  },
  collapsedCard: {
    margin: 23,
    width: 329,
    height: 329,
    borderRadius: 20,

    '& $content': {
      fontSize: 64,
    },
  },
  expandedCard: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '& $content': {
      fontSize: 120,
    },
  },
  content: {
    fontWeight: 400,
  },
  backButton: {
    color: '#fff',
    position: 'absolute',
    left: 23,
    top: 23,
  },
})

export default jssStyles
