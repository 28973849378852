import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import { Provider as ReduxProvider } from 'react-redux'
import {
  MuiThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles'

import ProjectStateContextProvider from 'contexts/ProjectStateContextProvider'
import store, { history } from './store'
import theme from './styles/muiTheme'
import 'styles/index.css'
import 'swiper/dist/css/swiper.min.css'

import App from './App'

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: true,
})

ReactDOM.render(
  <ReduxProvider store={store}>
    <MuiThemeProvider theme={theme}>
      <ProjectStateContextProvider>
        <Router history={history}>
          <StylesProvider generateClassName={generateClassName}>
            <App />
          </StylesProvider>
        </Router>
      </ProjectStateContextProvider>
    </MuiThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
)
