const ActiveStyles = {
  zIndex: 5,
  opacity: 1,
}

const HiddenStyles = {
  zIndex: -1,
  opacity: 0,
}

const jssStyles = theme => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
    fontFamily: 'SairaSemiCondensed-Regular',
    fontSize: 10,
    backgroundColor: '#fff',
  },
  itemContainer: {
    flex: 1,
    color: '#8D8E8F',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',

    '& *:first-child': {
      marginBottom: 6,
    },
  },
  activeContainer: {
    color: '#30BFCA',

    '& $imageContainer': {
      '& *:nth-child(1)': {
        ...HiddenStyles,
      },
      '& *:nth-child(2)': {
        ...ActiveStyles,
      },
    },
  },
  imageContainer: {
    position: 'relative',
    alignSelf: 'center',
    height: 23,
    width: '100%',

    '& > *': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    '& *:nth-child(1)': {
      ...ActiveStyles,
    },

    '& *:nth-child(2)': {
      ...HiddenStyles,
    },
  },
})

export default jssStyles
