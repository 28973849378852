import get from 'lodash/get'
import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B7910')

const MagicNodeIds = {
  root: '93-93',
  container: '93-92',
  default: '93-40',
  expanded: '93-41',
}

const MagicNodeIdProperty = `${LiveStateProperties.MagicExpandedItem}_${MagicNodeIds.root}`

export const data = {
  [ProjectId]: {
    eventProps: {
      onScroll: scrollValue => ({
        property: MagicNodeIdProperty,
        value: scrollValue > 0,
      }),
    },
    props: {
      onInitialState: [
        {
          property: MagicNodeIdProperty,
          value: false,
        },
      ],
    },
  },
  [MagicNodeIds.container]: {
    styleProps: {
      position: 'relative',
      zIndex: 9999,
    },
  },
  '94-27': {
    // content__item
    frameProps: state => ({
      y: get(state, `liveState.${MagicNodeIdProperty}`) ? 200 : 490,
    }),
  },
}

export default ProjectId
