import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { Properties } from '@totalbrain-project/constants'
import TotalBrain from '@totalbrain-components'

const GameItem = ({ id, projectState, isDraggable, disabled, onChange }) => {
  const [isFavorited, setIsFavorited] = useState(null)

  useEffect(() => {
    const favorited = get(
      projectState,
      `liveState.${Properties.Favorited}${id}`
    )
    setIsFavorited(favorited)
  }, [projectState])

  const handleChange = (e, value) => {
    setIsFavorited(value)
    onChange && onChange(e, id, value)
  }

  return (
    <TotalBrain.GameCard
      id={id}
      onCardClick={() => handleChange('card_clicked')}
      onFavChange={value => handleChange('fav_change', value)}
      onLabButtonClick={() => handleChange('lab_clicked')}
      isFavorited={isFavorited}
      isDraggable={isDraggable}
      disabled={disabled}
      showDetails
    />
  )
}

GameItem.propTypes = {
  id: PropTypes.string.isRequired,
  projectState: PropTypes.object,
  isDraggable: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default GameItem
