const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  button: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    border: '1px solid #E1E3E5',
    background: 'transparent',
    borderRadius: 2,
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#081C37',
      boxShadow: '0 0 10px rgba(228, 228, 228, 0.5)'
    }
  },
  activeButton: {
    borderColor: '#081C37',
    boxShadow: '0 0 10px rgba(228, 228, 228, 0.5)',
    fontWeight: '600'
  }
})

export default jssStyles
