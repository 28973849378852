import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import { LiveStateProperties } from 'constants/general'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { getId, fetchFeedState, getGameDataFeedComponentProps } from '../utils'
import { StyleMixins, Properties } from '../constants'

import HomeFeed2ProjectId from './HomeFeed2'

const ProjectId = getId('A04BEC7294A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[B] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.OnboardingHomeFeed,
          value: 1,
        },
      ],
    },
  },
  '66F7D529-1C3D-46C3-A4B4-84A2F68B052A': {
    // First `Onboarding` item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: onboardingStep && onboardingStep === 1 ? 'block' : 'none',
      }
    },
  },
  '1C32E854-E4DF-4A4B-B5A1-58E86ED2185F': {
    // First `Next button` HOTSPOT in Onboarding dialog
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingHomeFeed,
          value: 2,
        },
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: [ProjectId, HomeFeed2ProjectId],
            scrollProps: {
              scrollTo: 400,
              duration: 400,
            },
          },
        },
      ],
    },
  },
  'C48A3EF4-F256-482E-9C3A-2D10FF2CB71C': {
    // Card component
    componentProps: {
      ...getGameDataFeedComponentProps('firstCardName'),
      open: async state => get(state, 'liveState.openCardItem'),
    },
  },
  '0B483FA6-EB82-425C-8801-AEB08576FE60': {
    // Program Card `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  'D4193B13-3451-4B29-B192-7E4585E18520': {
    // Program Card `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  'AB993FE8-B72D-4639-AD65-FBBE61825177': {
    // Second `Onboarding` item
    styleProps: state => {
      const onboardingStep = get(
        state,
        `liveState.${Properties.OnboardingHomeFeed}`
      )
      return {
        display: onboardingStep && onboardingStep === 2 ? 'block' : 'none',
      }
    },
  },
  'AF3F70A8-6957-4044-88AE-8FEA194ED9CA': {
    // Second `Done` HOTSPOT in Onboarding dialog
    props: {
      onStateChange: () => [
        {
          property: Properties.OnboardingHomeFeed,
          value: null,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: 'AC72B18A94A',
        },
      ],
    },
  },
  'BE7B7FF7-6E24-4170-86D5-428A77FCA2A8': {
    // First body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Onboarding[0].text') ||
        'We created a personalized program based on your assessment. Learn more here.',
    },
  },
  '7287EB74-FC53-4751-8093-968C16CF3378': {
    // Second body message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Onboarding[1].text') ||
        'Swipe to see the science behind your assigned practices or save them to your favorites.',
    },
  },
}

export default ProjectId
