import get from 'lodash/get'
import findLast from 'lodash/findLast'

import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B71811793')

export const data = {
  [ProjectId]: {
    fontFamilies: ['Roboto', 'Overpass-Regular'],
    styleProps: {
      overflowX: 'hidden',
    },
  },
  // '198-0': {
  //   // MatchupBox
  //   componentProps: {
  //     initialState: true,
  //   },
  // },
}

export default ProjectId
