import get from 'lodash/get'
import values from 'lodash/values'
import { isProductionInstance } from 'utils'
import * as projectStatesTypes from 'constants/projectStatesTypes'

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const ProjectId = `327F0D4B94A${isProductionInstance() ? '0' : ''}`

export default {
  [ProjectId]: {
    /** Views */
    [ProjectId]: {
      props: {
        onInitialState: [
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 500,
          },
          {
            property: 'loading',
            value: false,
          },
        ],
      },
    },
    '18CE12AC94A': {
      // 4th View
      props: {
        onInitialState: [
          {
            property: projectStatesTypes.WAIT,
            value: 500,
          },
          {
            property: 'loading',
            value: false,
          },
        ],
      },
    },
    '2E52D8B494A': {
      // 5th View
      props: {
        onInitialState: [
          {
            property: 'loading',
            value: false,
          },
        ],
      },
    },
    /** Nodes */
    /* 1st View */
    '979CB144-9DAE-46C5-AA57-192EEF873A29': {
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.5,
            },
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    /* 2nd View */
    '8ASFJAS9-FDBE-4029-B16D-ASSA8ASA8': {
      // Linear progress loading bar
      conditionalProps: {
        active: state => state.liveState.loading,
      },
    },
    '387ADF7B-1B6E-41DD-882C-61037964433A': {
      // Email input
      props: {
        onStateChange: value => ({
          property: 'userEmail',
          value,
        }),
      },
    },
    'BD5EDBC8-4B5D-4A0D-8D66-625A762D043F': {
      // Password input
      props: {
        onStateChange: value => ({
          property: 'userPassword',
          value,
        }),
      },
    },
    '8A171E9C-A932-4BE7-A0B6-6F2A811B75C2': {
      // Sign in button
      conditionalProps: {
        disabled: state => {
          const userEmail = get(state, 'liveState.userEmail')
          const userPassword = get(state, 'liveState.userPassword')
          const isEmailValid = validateEmail(userEmail)
          return !userEmail || !userPassword || !isEmailValid
        },
      },
    },
    /* 3rd View */
    'CD5D5425-0D80-4889-B7B6-BB2A0A549CCE': {
      // Linear progress loading bar
      conditionalProps: {
        active: state => state.liveState.loading,
      },
    },
    'EE2056ED-E178-4A11-97BC-984EFAB27469': {
      // Shopping cart
      props: {
        onStateChange: () => [
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 200,
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '5BA3210A94A',
          },
        ],
      },
    },
    // Slideshow
    '7509F5BA-9998-4B75-8AD8-B7C1FC8F9C9E': {
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.5,
            },
          },
          hidden: {
            y: -8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    '45F4C35E-10BF-41D5-972C-F987786529F0': {
      // Layout item Hotspot to 4th view
      styleProps: {
        color: '#b3bbc8',
      },
      props: {
        onStateChange: () => [
          {
            property: 'productCategory',
            value: 'chairs',
          },
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 1000,
          },
        ],
      },
    },
    '35423BD4-B1F7-4290-B67B-3190BD09A0D6': {
      // `Chairs` layout item
      props: {
        onStateChange: value => [
          {
            property: 'productCategory',
            value,
          },
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 750,
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '18CE12AC94A',
          },
        ],
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.5,
            },
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    'CDFA507E-895F-4FEF-A287-072D70B09CD8': {
      // `Tables` layout item
      props: {
        onStateChange: value => [
          {
            property: 'productCategory',
            value,
          },
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 750,
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '18CE12AC94A',
          },
        ],
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.5,
            },
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    '893F5D65-A061-4E14-98E6-6EAF16E00429': {
      // `Lamps` layout item
      props: {
        onStateChange: value => [
          {
            property: 'productCategory',
            value,
          },
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 750,
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '18CE12AC94A',
          },
        ],
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.5,
            },
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    '3D93776B-DCD4-45D0-ACD9-73C06E0A2ABA': {
      // `Plants` layout item
      props: {
        onStateChange: value => [
          {
            property: 'productCategory',
            value,
          },
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 750,
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '18CE12AC94A',
          },
        ],
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.5,
            },
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    /* 4th View */
    'C6A90EAF-AB4C-4A5E-ADF4-6B7B034917E6': {
      // Shopping cart
      props: {
        onStateChange: () => [
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 200,
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '5BA3210A94A',
          },
        ],
      },
    },
    '0F42E492-FDBE-4029-B16D-FCA7B055F0B8': {
      // Linear progress loading bar
      conditionalProps: {
        active: state => state.liveState.loading,
      },
    },
    '34E32125-9799-408A-AD98-1CFAA28AA416': {
      // Header `title`
      conditionalProps: {
        value: state => {
          const productCategory =
            get(state, 'liveState.productCategory') || 'Chairs'
          return `"${productCategory.charAt(0).toUpperCase() +
            productCategory.slice(1)}"`
        },
      },
    },
    'C0EEA26A-A4EE-49EE-8759-09330FCB8334': {
      // `Search` input component
      props: {
        onStateChange: value => ({
          property: 'searchInput',
          value,
        }),
      },
    },
    '6C909268-4210-420F-B946-91462FE67039': {
      // Search item `description`
      styleProps: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    '247052C6-A6D2-4223-BC18-F698C1990F8D': {
      // `Buy now` button
      props: {
        onStateChange: value => [
          {
            property: 'productSelected',
            value,
          },
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 400,
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '2E52D8B494A',
          },
        ],
      },
      motionProps: {
        variants: {
          open: {
            transition: {
              delay: 1,
              delayChildren: 0,
              staggerChildren: 0.15,
            },
          },
          closed: {},
        },
        initial: 'closed',
        animate: 'open',
      },
    },
    /* 5th View */
    'FF3E375C-C5EB-4658-8CC5-7B2814CBAE99': {
      // Linear progress loading bar
      conditionalProps: {
        active: state => state.liveState.loading,
      },
    },
    '802CED16-8B10-42D1-961A-0EAAA2B3D530': {
      // Shopping cart
      props: {
        onStateChange: () => [
          {
            property: 'loading',
            value: true,
          },
          {
            property: projectStatesTypes.WAIT,
            value: 200,
          },
          {
            property: projectStatesTypes.ACTIVE_VIEW_ID,
            value: '5BA3210A94A',
          },
        ],
      },
    },
    '5277CF3D-5769-4FF9-B753-0827485D3C7A': {
      // Product header title
      conditionalProps: {
        value: state => {
          const productSelected =
            get(state, 'liveState.productSelected') || 'Agatha Chair'

          return productSelected.label
        },
      },
    },
    'F6588038-F3E8-4C12-B0CD-B9891B332656': {
      // Product Image
      conditionalProps: {
        url: state => get(state, 'liveState.productSelected.imageUrl'),
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    'E20D7FD3-69C5-4271-A2F3-5FF394A4DB47': {
      // Product price button
      conditionalProps: {
        value: state => {
          const productSelected =
            get(state, 'liveState.productSelected') || '180'

          return `$${productSelected.price} Buy Now`
        },
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    'A4E07550-412F-4C32-9667-B2AE79C4610F': {
      // Product title
      conditionalProps: {
        value: state => {
          const productSelected =
            get(state, 'liveState.productSelected') || 'Agatha Chair'

          return productSelected.label
        },
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    '0BB7FC08-18D9-4BA4-ACF4-432C23C4FC13': {
      // Product description
      conditionalProps: {
        value: state => {
          const productSelected =
            get(state, 'liveState.productSelected') ||
            'The beautiful Agatha chair adds a stunning mid-century look to your home office. Its beautiful walnut veneered finish , leatherette upholstery, and  chromed base will add a touch of style to any room.'

          return productSelected.description
        },
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    '35AB28C7-166B-4C78-9221-621349D4C926': {
      // Hotspot BUY button
      props: {
        onStateChange: stateValue => [
          {
            property: 'loading',
            value: true,
          },
          {
            property: 'shoppingCart',
            value: () => {
              const shoppingCartValues =
                get(stateValue, 'liveState.shoppingCart') || {}

              const product = get(stateValue, 'liveState.productSelected')

              const productId = product.label

              if (!shoppingCartValues[productId]) {
                shoppingCartValues[productId] = {
                  amount: 1,
                  ...product,
                }
              } else {
                shoppingCartValues[productId].amount += 1
              }

              return shoppingCartValues
            },
          },
          {
            property: projectStatesTypes.WAIT,
            value: 200,
          },
          {
            property: 'loading',
            value: false,
          },
        ],
      },
    },
    /* 6th View */
    '917527DE-0A64-4DA8-B38C-5D67BE715748': {
      // Back button
      props: {
        onStateChange: () => [
          {
            property: projectStatesTypes.HISTORY_BACK,
          },
        ],
      },
    },
    // Shopping items
    'FDFDEC02-F9AE-491C-9B16-6896B5256C0E': {
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
          },
          hidden: {
            y: -8,
            opacity: 1,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    'CA7CC65E-6195-4E0E-9792-AC3BF4BEF9CB': {
      // Buy button
      conditionalProps: {
        value: state => {
          const shoppingCart = get(state, 'liveState.shoppingCart') || {}

          let totalPrice = 0
          let totalItems = 0

          values(shoppingCart).forEach(product => {
            totalPrice += Number(product.price) * Number(product.amount)
            totalItems += product.amount
          })

          return `$${totalPrice} Buy Now (${totalItems} items)`
        },
      },
      motionProps: {
        variants: {
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.75,
            },
          },
          hidden: {
            y: 8,
            opacity: 0,
          },
        },
        initial: 'hidden',
        animate: 'visible',
      },
    },
    'D2B955FA-5BE5-4486-A9B8-3C6264639F27': {
      // Description item
      styleProps: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    /* 7th View */
    '6E281977-8D7A-454F-8FA7-808BC71F4A29': {
      // Success gif
      styleProps: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'opacity 0.4s 0.6s',
      },
      props: {
        onInitialState: [
          {
            property: projectStatesTypes.WAIT,
            value: 200,
          },
          {
            property: projectStatesTypes.STYLE_PROPS,
            value: {
              visibility: 'visible',
              opacity: 1,
            },
          },
        ],
      },
      conditionalProps: {
        url: state =>
          'https://firebasestorage.googleapis.com/v0/b/torii-live-prod.appspot.com/o/project%2F327F0D4B94A0%2Fanimation-noloop.gif?alt=media',
      },
    },
  },
}
