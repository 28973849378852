import { isProductionInstance } from 'utils'

const ProjectId = `9CD1E0C294A${isProductionInstance() ? '0' : ''}`

export const data = {
  '91E2BD6A-D34F-49E8-8E62-24EF2971A172': {
    conditionalProps: {
      animateOnInit: () => true,
    },
  },
}

export default ProjectId
