import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import values from 'lodash/values'
import random from 'lodash/random'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './ConfirmBookingItemStyles'

const ItemNames = ['first__items', 'second__items', 'third__items']

const WaitToFinalScreenRangeMs = [1500, 4500]

const ConfirmBookingItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  ...props
}) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)

  useEffect(() => {
    if (currentItemIndex === 1) {
      const waitToFinalMs = random(
        WaitToFinalScreenRangeMs[0],
        WaitToFinalScreenRangeMs[1]
      )
      setTimeout(() => {
        setCurrentItemIndex(2)
      }, waitToFinalMs)
    }
  }, [currentItemIndex])

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  const renderItems = () => {
    const itemNode = find(
      values(node.nodes),
      n => n.type === 'component' && n.name === ItemNames[currentItemIndex]
    )

    if (!itemNode) return null

    return values(itemNode.nodes).map(childNode => {
      const firstButton =
        currentItemIndex === 0 &&
        childNode.type === 'component' &&
        childNode.name === 'button'

      const circularProgress =
        currentItemIndex === 1 &&
        childNode.type === 'component' &&
        childNode.name === 'circularprogress'

      let wrapperProps = {}
      let nodeProps = {}

      if (firstButton) {
        wrapperProps = {
          onClick: () => setCurrentItemIndex(1)
        }
      }

      if (circularProgress) {
        nodeProps = {
          active: true,
          style: {
            color: '#cb3341'
          }
        }
      }

      return (
        <div {...wrapperProps} key={childNode.id}>
          <Node
            node={childNode}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            {...props}
            {...nodeProps}
          />
        </div>
      )
    })
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(node.nodes).map(childNode => (
        <Node
          key={childNode.id}
          node={childNode}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          scaleRatio={scaleRatio}
          {...props}
        />
      ))}
      {renderItems()}
    </div>
  )
}

ConfirmBookingItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(ConfirmBookingItem)
