import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { findNodeByName, processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './ExerciseBackgroundItemStyles'

const SpinnerTimeMs = 4000

const NodeNames = {
  CloseIcon: 'close__item',
  TopItem: 'top__item',
  BottomItem: 'bottom__item',
  Image: 'image',
  Backdrop: 'backdrop',
  Spinner: 'spinner',
  Button: 'button',
  Content: 'content__item',
  ContentInfo: 'info__item',
  ContentFooter: 'footer__item',
  SuccessItem: 'success__item'
}

const _ = {
  spinnerTimeout: null
}

const ExerciseBackgroundItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  ...props
}) => {
  const [isPracticing, setIsPracticing] = useState(null)
  const [resumeSpinner, setResumeSpinner] = useState(null)

  useEffect(() => {
    if (!resumeSpinner) return

    _.spinnerTimeout = setTimeout(() => {
      onProjectStateChange && onProjectStateChange()
    }, SpinnerTimeMs)
  }, [resumeSpinner])

  const renderNode = node => (
    <Node
      node={node}
      viewRect={viewRect}
      scaleRatio={scaleRatio}
      projectState={projectState}
      onProjectStateChange={onProjectStateChange}
      {...props}
    />
  )

  const renderTopItem = node => {
    const contentNode = findNodeByName(node, NodeNames.Content)

    const backdropNode = findNodeByName(node, NodeNames.Backdrop)
    const practiceButtonNode = findNodeByName(contentNode, NodeNames.Button)
    const contentInfoNode = findNodeByName(contentNode, NodeNames.ContentInfo)
    const contentFooterNode = findNodeByName(
      contentNode,
      NodeNames.ContentFooter
    )

    const contentNodeStyles = {
      ...contentNode.style,
      ...processNodeFrame(contentNode, scaleRatio, viewRect)
    }

    const processedBackdropNode = {
      ...backdropNode,
      frame: {
        ...backdropNode.frame,
        x: 0,
        y: 0
      }
    }

    return (
      <div className={classes.containerTopItem}>
        <div className={classes.container} style={contentNodeStyles}>
          {renderNode(contentInfoNode)}
          <div
            onClick={() => {
              setIsPracticing(true)
              setResumeSpinner(true)
            }}
          >
            {renderNode(practiceButtonNode)}
          </div>
          {renderNode(contentFooterNode)}
        </div>
        {renderNode(processedBackdropNode)}
      </div>
    )
  }

  const renderBottomItem = node => {
    const spinnerNode = findNodeByName(node, NodeNames.Spinner)
    const pauseButtonNode = findNodeByName(node, NodeNames.Button)

    const bottomItemStyles = {
      ...node.style,
      ...processNodeFrame(node, scaleRatio, viewRect)
    }

    const spinnerFrame = processNodeFrame(spinnerNode, scaleRatio, viewRect)

    // const animationPlayState = resumeSpinner ? 'running' : 'paused'

    const spinnerContainerStyles = {
      ...spinnerNode.style,
      ...spinnerFrame
    }

    const spinnerNodeStyles = {
      clip: `rect(0px, ${spinnerFrame.width}px, ${
        spinnerFrame.width
      }px, ${spinnerFrame.width / 2}px)`,
      animationDelay: `${SpinnerTimeMs / 2}ms`
      // animationPlayState
    }

    const spinnerChildStyles = {
      clip: `rect(0px, ${spinnerFrame.width / 2}px, ${
        spinnerFrame.width
      }px, 0px)`
      // animationPlayState
    }

    const spinnerLeftStyles = {
      ...spinnerChildStyles,
      animationDuration: `${SpinnerTimeMs}ms`
    }

    const spinnerRightStyles = {
      ...spinnerChildStyles,
      animationDuration: `${SpinnerTimeMs / 2}ms`
    }

    return (
      <div className={classes.containerBottomItem} style={bottomItemStyles}>
        <div
          className={classes.spinnerContainer}
          style={spinnerContainerStyles}
        >
          <div className={classes.spinner} style={spinnerNodeStyles}>
            <div
              className={classes.spinnerCircleLeft}
              style={spinnerLeftStyles}
            />
            <div
              className={classes.spinnerCircleRight}
              style={spinnerRightStyles}
            />
          </div>
        </div>
        <div onClick={() => setResumeSpinner(!resumeSpinner)}>
          {renderNode(pauseButtonNode)}
        </div>
      </div>
    )
  }

  const closeIconNode = findNodeByName(containerNode, NodeNames.CloseIcon)
  const topItemNode = findNodeByName(containerNode, NodeNames.TopItem)
  const bottomItemNode = findNodeByName(containerNode, NodeNames.BottomItem)
  const imageNode = findNodeByName(containerNode, NodeNames.Image)

  const handleClose = () => {
    _.spinnerTimeout && clearTimeout(_.spinnerTimeout)
    onProjectStateChange && onProjectStateChange(null, closeIconNode.id)
  }

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.isPracticing]: isPracticing
  })

  return (
    <div className={containerClassNames} style={containerStyles}>
      <div onClick={handleClose}>{renderNode(closeIconNode)}</div>
      <div className={classes.imageContainer}>{renderNode(imageNode)}</div>
      {isPracticing && renderBottomItem(bottomItemNode)}
      {!isPracticing && renderTopItem(topItemNode)}
    </div>
  )
}

ExerciseBackgroundItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(ExerciseBackgroundItem)
