import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { motion, useAnimation, motionValue } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import {
  processNodeFrame,
  getNodesArrayHierarchy,
  getNodesArraySortedByPosition,
} from 'utils'

import Node from 'components/Node'

import styles from './SwipeSliderStyles'

const SwipeSlider = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  ...props
}) => {
  const [activeX, setActiveX] = useState(0)
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [contentOffsetX] = useState(motionValue(0))
  const [controls] = useState(useAnimation())
  const [nodesHierarchy, setNodesHierarchy] = useState({ x: 0 })
  const [nodesLength, setNodesLength] = useState(0)

  useEffect(() => {
    // Sorts repeater nodes by position in the canvas
    const nodesSorted = getNodesArraySortedByPosition(containerNode.nodes)

    // Get node hierarchy values
    const nodesArrayhierarchy = getNodesArrayHierarchy(nodesSorted)
    setNodesHierarchy({
      x: nodesArrayhierarchy.x * scaleRatio,
      y: nodesArrayhierarchy.y * scaleRatio,
      width: nodesArrayhierarchy.width * scaleRatio,
      height: nodesArrayhierarchy.height * scaleRatio,
    })
    setNodesLength(nodesSorted.length)
  }, [])

  const containerStyles = {
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
  }

  const leftEdge =
    containerStyles.left +
    (containerStyles.width - viewRect.width) +
    nodesHierarchy.x

  return (
    <motion.div
      className={classes.container}
      style={containerStyles}
      drag="x"
      x={contentOffsetX}
      dragConstraints={{ left: -leftEdge, right: 0 }}
      animate={controls}
      onDragEnd={e => {
        const itemIndex =
          activeX > contentOffsetX.get()
            ? currentItemIndex + 1
            : currentItemIndex - 1

        const newActiveX =
          -(nodesHierarchy.width + nodesHierarchy.x) *
          Math.max(Math.min(itemIndex, nodesLength - 1), 0)

        controls.start({
          x: newActiveX,
        })

        setActiveX(newActiveX)
        setCurrentItemIndex(itemIndex)
      }}
    >
      {values(containerNode.nodes).map(n => (
        <Node
          key={n.id}
          node={n}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          {...props}
        />
      ))}
    </motion.div>
  )
}

SwipeSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
}

export default withStyles(styles)(SwipeSlider)
