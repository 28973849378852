import React from 'react'
import get from 'lodash/get'
import camelCase from 'lodash/camelCase'
import AirtableAPI from 'api/AirtableAPI'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import { getId } from './utils'
import { Properties } from './constants'

const ProjectId = getId('8121619F94A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: Properties.BottomNavItemsData,
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              'Bottom Nav',
              'Grid view'
            )
            return airtableData.map(({ label, outlineSvg, activeSvg }) => ({
              id: camelCase(label),
              label,
              outlineSvg,
              activeSvg,
            }))
          },
        },
      ],
    },
  },
  'F7F4FD56-C3D6-4063-913B-F269B91DDE45': {
    // `Home Feed 2.0` Linked View
    props: {
      onInitialState: [
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: getId('627141BE94A'),
        },
      ],
    },
    frameProps: state => ({
      height: get(state, `liveState.${Properties.ActiveGameId}`) ? 812 : 736,
    }),
  },
  'F6BB547E-5BE5-4502-A7C0-45AEE316CD0D': {
    // `BottomNavigation` Component
    componentProps: {
      items: state => get(state, `liveState.${Properties.BottomNavItemsData}`),
      activeItemId: state => get(state, 'liveState.bottomNavActiveId'),
      onChange: state => value => ({
        property: 'bottomNavActiveId',
        value,
      }),
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ActiveGameId}`) ? 'none' : '',
    }),
  },
  '4D4FF62E-9048-4878-A2F4-96A92D5EBD0F': {
    componentProps: {
      id: 'happySeeker',
      onCardClick: state => () => [
        {
          property: Properties.ActiveGameId,
          value: 'happySeeker',
        },
      ],
      isFavorited: state =>
        get(state, `liveState.${Properties.Favorited}happySeeker`),
      onFavChange: state => value => ({
        property: `${Properties.Favorited}happySeeker`,
        value,
      }),
    },
  },
  'DFFDB68F-B779-4CC6-8F34-0BB2114FC6FB': {
    componentProps: {
      id: 'shortMeditation',
      onCardClick: state => () => [
        {
          property: Properties.ActiveGameId,
          value: 'shortMeditation',
        },
      ],
      isFavorited: state =>
        get(state, `liveState.${Properties.Favorited}shortMeditation`),
      onFavChange: state => value => ({
        property: `${Properties.Favorited}shortMeditation`,
        value,
      }),
    },
  },
}

export default ProjectId
