import React from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './ButtonStyles'

const Button = ({ classes, node, scaleRatio, viewRect, style, ...props }) => {
  const processedFrame = processNodeFrame(node, scaleRatio, viewRect)

  const containerStyles = {
    ...processedFrame,
    ...style,
  }

  return (
    <button className={classes.container} style={containerStyles} type="button">
      <>
        <div className={classes.overlay} />
        {values(node.nodes).map(childNode => (
          <Node
            key={childNode.id}
            node={childNode}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            {...props}
          />
        ))}
      </>
    </button>
  )
}

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  style: PropTypes.object,
  viewRect: PropTypes.object,
}

export default withStyles(styles)(Button)
