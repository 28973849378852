/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, checkIsDesktopDevice } from 'utils'

import styles from './CameraItemStyles'

const videoPros = checkIsDesktopDevice()
  ? { facingMode: 'user' }
  : {
      facingMode: {
        exact: 'environment'
      }
    }

// Set constraints for the video stream
const Constraints = { video: { ...videoPros }, audio: false }

const CameraItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange
}) => {
  const cameraView = React.createRef()
  const cameraSensor = React.createRef()

  useEffect(() => {
    // Access the device camera and stream to cameraView
    navigator.mediaDevices
      .getUserMedia(Constraints)
      .then(stream => {
        cameraView.current.srcObject = stream
      })
      .catch(error => {
        console.error('Oops. Something is broken.', error)
      })
  }, [])

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  return (
    <div className={classes.container} style={containerStyles}>
      <main className={classes.camera}>
        <canvas ref={cameraSensor} className={classes.cameraSensor} />
        <video
          ref={cameraView}
          className={classes.cameraView}
          playsInline
          autoPlay
        />
      </main>
    </div>
  )
}

CameraItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(CameraItem)
