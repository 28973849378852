import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './ScrollBreakpointStyles'

const StickyUnitHeightPx = 64

const ScrollStateValues = {
  SocialMedia: 'SocialMedia',
  NextNews: 'NextNews'
}

const ScrollBreakpoint = ({
  classes,
  node: nodeContainer,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  view,
  ...props
}) => {
  const [scrollState, setScrollState] = useState(null)

  useEffect(() => {
    const { scrollTop, direction } = view

    const isScrollLowerBreakpoint =
      scrollTop + viewRect.height - StickyUnitHeightPx * 2 * scaleRatio >
      nodeContainer.frame.y * scaleRatio

    let newScrollStateValue = null

    if (isScrollLowerBreakpoint) {
      newScrollStateValue = ScrollStateValues.SocialMedia
    } else if (scrollState && direction === 'up') {
      newScrollStateValue = ScrollStateValues.NextNews
    }

    if (!newScrollStateValue) return

    setScrollState(newScrollStateValue)

    onProjectStateChange && onProjectStateChange(newScrollStateValue)
  }, [view])

  const containerStyles = {
    ...nodeContainer.style,
    ...processNodeFrame(nodeContainer, scaleRatio, viewRect)
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(nodeContainer.nodes).map(childNode => (
        <Node
          key={childNode.id}
          node={childNode}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          {...props}
        />
      ))}
    </div>
  )
}

ScrollBreakpoint.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  view: PropTypes.object
}

const mapStateToProps = ({ view }) => ({ view })

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles)
)(ScrollBreakpoint)
