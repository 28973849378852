import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { motion } from 'framer-motion'

import styles from './RectangleNodeStyles'

const RectangleNode = ({ classes, node, style, onClick }) => {
  const { layoutId, style: nodeStyle } = node

  const rootStyles = {
    ...node.processedFrame,
    ...nodeStyle,
    ...style,
  }

  if (node.shapeType === 'oval') rootStyles.borderRadius = '100%'

  const otherProps = {}

  if (layoutId) {
    otherProps.layoutId = layoutId
  }

  return (
    <motion.div
      className={classes.container}
      style={rootStyles}
      onClick={onClick}
      {...otherProps}
    />
  )
}

RectangleNode.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

export default withStyles(styles)(RectangleNode)
