const jssStyles = theme => ({
  container: {
    position: 'absolute'
  },
  selection: {
    transition: 'transform .3s, width .3s',
    position: 'absolute',
    left: 0
  },
  content: {
    position: 'absolute',
    visibility: 'hidden',
    display: 'none',
    opacity: 0,
    zIndex: -1
  },
  active: {
    display: 'block',
    visibility: 'visible',
    opacity: 1,
    zIndex: 10
  },
  header: {
    position: 'fixed',
    backgroundColor: '#fcfcfc'
  }
})

export default jssStyles
