import React from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './NavItemStyles'

const NavItem = ({ classes, node, scaleRatio, viewRect, ...props }) => {
  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect),
    top: viewRect.top,
    height: viewRect.height
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {values(node.nodes).map(childNode => (
        <Node
          key={childNode.id}
          node={childNode}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          {...props}
        />
      ))}
    </div>
  )
}

NavItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object
}

export default withStyles(styles)(NavItem)
