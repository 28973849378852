import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const LayoutIds = {
  ExpandableCard: 'expandable-card',
}

const ExpandedCard = ({ classes, onCollapse, children }) => (
  <>
    <motion.div
      className={classNames(classes.card, classes.expandedCard)}
      layoutId={LayoutIds.ExpandableCard}
      onClick={onCollapse}
    >
      {children}
    </motion.div>
    <motion.p
      className={classNames(
        classes.card,
        classes.expandedCard,
        classes.secondaryCard
      )}
      onClick={onCollapse}
      transition={{ delay: 0.3 }}
      initial={{ opacity: 0, top: '6rem' }}
      animate={{ opacity: 1, top: '3rem' }}
    >
      Today is clear
    </motion.p>
  </>
)

ExpandedCard.propTypes = {
  classes: PropTypes.object.isRequired,
  onCollapse: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export default ExpandedCard
