import * as Shortcut from './projects/Shortcut'
import * as Crafty from './projects/Crafty'
import * as Taboola from './projects/Taboola'
// import * as SanJose from './projects/SanJose'
import * as MealPal from './projects/MealPal'
import Experiments from './projects/Experiments'
import TotalBrain from './projects/TotalBrain'
import Bstevr from './projects/Bstevr'

import TextField from '@material-ui/core/TextField'

const processProjects = (instance, components) =>
  Object.keys(instance.ProjectIds).reduce((acc, curr) => {
    acc[curr] = components
    return acc
  }, {})

export default {
  [Shortcut.ProjectId]: {
    address__input: Shortcut.AddressInput,
    card__item: Shortcut.CardItem,
    nav__item: Shortcut.NavItem,
    book__item: Shortcut.BookItem,
    confirmbooking__item: Shortcut.ConfirmBookingItem,
  },
  [Crafty.ProjectId]: {
    layout__item: Crafty.LayoutItem,
    search__input: Crafty.SearchInput,
    search__items: Crafty.SearchItems,
    shopping__items: Crafty.ShoppingItems,
    shoppingcart__item: Crafty.ShoppingCart,
  },
  ...processProjects(Taboola, {
    stickyunit__item: Taboola.StickyUnit,
    scrollbreakpoint__item: Taboola.ScrollBreakpoint,
  }),
  // ...processProjects(SanJose, {
  //   game__item: SanJose.GameItem,
  // }),
  ...processProjects(MealPal, {
    qrcatch__item: MealPal.QrCatchItem,
    camera__item: MealPal.CameraItem,
    data__item: MealPal.DataItem,
  }),
  ...processProjects(Experiments, {
    'magic-motion__item': Experiments.MagicMotion,
    'torii-magic__item': Experiments.ToriiMagicMotion,
    'expanding-card__item': Experiments.ExpandingCard,
    'googlemaps-sheet__item': Experiments.GoogleMapsSheet,
    'instagram-stories__item': Experiments.InstagramStories,
    hifive__item: Experiments.HiFiveItem,
    'swipe-slider__item': Experiments.SwipeSlider,
    'flip-cards__item': Experiments.FlipCards,
    'framer-scroll__item': Experiments.FramerScroll,
    scroll__item: Experiments.ScrollItem,
    'sticky-header__item': Experiments.StickyHeader,
    'trending-stories__item': Experiments.TrendingStories,
  }),
  ...processProjects(TotalBrain, {
    controls__item: TotalBrain.ControlsItem,
    card__item: TotalBrain.CardItem,
    toggle__item: TotalBrain.ToggleItem,
    section__item: TotalBrain.SectionItem,
    capacity__item: TotalBrain.CapacityItem,
    search__input: TotalBrain.SearchInput,
    tabs__item: TotalBrain.TabsItem,
    capacitydetails__item: TotalBrain.CapacityHeader,
    color__item: TotalBrain.ColorItem,
    'fav-chip__item': TotalBrain.FavChipItem,
    'fav-modal__item': TotalBrain.FavModalItem,
    'points-chip__item': TotalBrain.PointsChipItem,
    'exercise-background__item': TotalBrain.ExerciseBackgroundItem,
    progress__item: TotalBrain.ProgressItem,
    days__item: TotalBrain.DaysItem,
    dynamic__item: TotalBrain.HomeFeedDynamicItem,
    'bottom-nav__item': TotalBrain.BottomNav,
    'program-list__item': TotalBrain.ProgramList,
    switch__button: TotalBrain.Switch,
    stack__item: TotalBrain.Stack,
    'capacity-card__item': TotalBrain.CapacityCard,
    'bottom-navigation__item': TotalBrain.BottomNavigation,
    'games-list__item': TotalBrain.GamesListItem,
    'games-sticky__item': TotalBrain.GamesStickyItem,
    search__item: TotalBrain.SearchItem,
    'filter-sheet__item': TotalBrain.FilterSheetItem,
    'filter-chip__item': TotalBrain.FilterChip,
    tabs: TotalBrain.Tabs,
    // External components
    'game-card__item': TotalBrain.GameCard,
    'lab-button__item': TotalBrain.LabButtonLink,
    'fav-toggle__button': TotalBrain.FavToggleButton,
    'game-exercise__item': TotalBrain.GameExercise,
  }),
  ...processProjects(Bstevr, {
    'foreground-background__item': Bstevr.ForegroundBackgroundScroll,
    'flip-cards__item': Experiments.FlipCards,
    // External components
    'app-bar__item': Bstevr.AppBar,
    'story-card__item': Bstevr.StoryCard,
    'trending-stories__item': Bstevr.TrendingStories,
    fab__button: Bstevr.Fab,
    'anchorpoint-menu__item': Bstevr.AnchorpointMenu,
    'framer-scroll__item': Bstevr.FramerScrollItem,
    'bstevr-bar__item': Bstevr.BstevrBar,
    'matchup-box__item': Bstevr.MatchupBox,
    'floating-box__item': Bstevr.FloatingStatusBox,
    review__input: Bstevr.TextField,
    button: Bstevr.Button,
    tabs__item: Bstevr.Tabs,
    addcolorcommentary__item: Bstevr.ColorCommentBox,
  }),
}
