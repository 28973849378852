import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { compose } from 'redux'
import values from 'lodash/values'
import { withTheme, withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './ItemStyles'

const Item = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  style,
  onClick,
  ...props
}) => {
  const isFloatNode = node.name && node.name.includes('--float')

  let containerStyles = {
    ...processNodeFrame(node, scaleRatio, viewRect),
    ...node.style,
    ...style,
  }

  if (isFloatNode) {
    containerStyles = {
      ...containerStyles,
      zIndex: 9999,
    }
  }

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.fixedContainer]: isFloatNode,
  })

  return (
    <div
      className={containerClassNames}
      style={containerStyles}
      onClick={onClick}
    >
      {values(node.nodes).map(childNode => (
        <Node
          key={childNode.id}
          node={childNode}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          {...props}
        />
      ))}
    </div>
  )
}

Item.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

export default compose(withTheme, withStyles(styles))(Item)
