const jssStyles = theme => ({
  container: {
    position: 'absolute'
    // overflow: 'auto'
  },
  fixedContainer: {
    position: 'fixed',
    zIndex: theme.zIndex.layerElevated
  },
  item: {
    position: 'relative'
  }
})

export default jssStyles
