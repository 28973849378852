import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { motionValue, motion } from 'framer-motion'

import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, findNodeByName } from 'utils'
import { LiveStateProperties } from 'constants/general'

import Node from 'components/Node'

import styles from './FramerScrollItemStyles'

const NodeNames = {
  ScrollItem: 'scroll__item',
  ContentItem: 'content__item',
}

const ScrollContainerStyles = {
  overflow: 'hidden',
  transform: 'translateZ(0)',
  cursor: 'grab',
}

const FramerScrollItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  style,
  ...otherProps
}) => {
  const [contentNode, setContentNode] = useState(null)
  const [scrollNodeItem, setScrollNodeItem] = useState(null)
  const [otherNodes, setOtherNodes] = useState([])

  const y = motionValue(0)

  useEffect(() => {
    const scrollItem = findNodeByName(containerNode, NodeNames.ScrollItem)

    const contentItem = findNodeByName(scrollItem, NodeNames.ContentItem)

    const restNodes = values(scrollItem.nodes).filter(
      n => n.name !== NodeNames.ContentItem
    )

    setContentNode(contentItem)
    setScrollNodeItem(scrollItem)
    setOtherNodes(restNodes)
  }, [])

  useEffect(() => {
    y.onChange(() => {
      // console.log('y.get()', y.get())
      // onProjectStateChange(
      //   {
      //     property: `${LiveStateProperties.FramerScrollPosition}_${containerNode.id}`,
      //     value: { y: y.get() },
      //   },
      //   null,
      //   {
      //     updateWithValue: true,
      //   }
      // )
    })
  }, [y])

  if (!scrollNodeItem) return null

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    ...style,
    ...ScrollContainerStyles,
  }

  return (
    <>
      {otherNodes.map(n => (
        <Node
          node={n}
          key={n.id}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          {...otherProps}
        />
      ))}
      <motion.div
        className={classes.container}
        style={containerStyles}
        whileTap={{ cursor: 'grabbing' }}
      >
        <motion.div
          style={{
            width: containerStyles.width,
            height: scrollNodeItem.frame.height,
          }}
          y={y}
          drag="y"
          dragConstraints={{
            top: 0,
            bottom: 0,
          }}
        >
          <Node
            node={contentNode}
            scaleRatio={scaleRatio}
            viewRect={viewRect}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...otherProps}
          />
        </motion.div>
      </motion.div>
    </>
  )
}

FramerScrollItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(FramerScrollItem)
