import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import find from 'lodash/find'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import Badge from '@material-ui/core/Badge'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import { processNodeFrame } from 'utils'

import Ripple from 'common/Ripple'

import styles from './ShoppingCartStyles'

const ShoppingCart = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  ...props
}) => {
  const shoppingCart = get(projectState, 'liveState.shoppingCart') || {}

  const findNodeByName = (name, optNode) =>
    find(values((optNode || node).nodes), ch => ch.name === name)

  const getTotalItems = () => {
    let nItems = 0
    values(shoppingCart).forEach(product => {
      nItems += product.amount
    })
    return nItems
  }

  const handleShoppingClick = () => {
    const totalItems = getTotalItems()

    if (!totalItems) return

    onProjectStateChange && onProjectStateChange()
  }

  const badgeNode = findNodeByName('badge')
  const shoppingIconNode = findNodeByName('icon')
  const numberBadgeNode = findNodeByName('number', badgeNode)
  const circleBadgeNode = findNodeByName('circle', badgeNode)

  const textColor = get(numberBadgeNode, 'style.color')
  const textSize = get(numberBadgeNode, 'style.fontSize')
  const circleColor = get(circleBadgeNode, 'style.background')

  const badgeFrame = processNodeFrame(circleBadgeNode, scaleRatio, viewRect)

  const CustomBadge = withStyles(theme => ({
    badge: {
      color: textColor,
      backgroundColor: circleColor,
      width: badgeFrame.width,
      minWidth: badgeFrame.width,
      height: badgeFrame.height,
      minHeight: badgeFrame.height,
      fontSize: textSize * scaleRatio,
      borderRadius: '100%'
    }
  }))(Badge)

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect),
    paddingTop: badgeFrame.height / 2
  }

  const iconStyles = {
    ...processNodeFrame(shoppingIconNode, scaleRatio, viewRect),
    color: '#8190a5'
  }

  const nItems = getTotalItems()

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.disabled]: !nItems
  })

  return (
    <div
      className={containerClassNames}
      style={containerStyles}
      onClick={handleShoppingClick}
    >
      <CustomBadge
        className={classes.margin}
        badgeContent={nItems}
        disabled={!nItems}
      >
        <ShoppingCartIcon style={iconStyles} />
      </CustomBadge>
      {!!nItems && <Ripple color="#f2f4f8" />}
    </div>
  )
}

ShoppingCart.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object
}

export default withStyles(styles)(ShoppingCart)
