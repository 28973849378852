import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import values from 'lodash/values'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import { motion, motionValue, useAnimation, useTransform } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame, findNodeByName } from 'utils'

import Node from 'components/Node'

import styles from './CardItemStyles'

const MinDragXPx = -130
const MaxDragXPx = 0

const NodeNames = {
  TopStackItem: 'top-stack__item',
  BottomStackItem: 'bottom-stack__item',
  IconItem: 'icon__item',
  ToggleItem: 'toggle__item',
  ScienceHotspot: 'ScienceHotspot',
}

const Events = {
  dragging: false,
}

const DelayAnimationMs = 1200

const CardItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  onChange,
  animateOnInit,
  style,
  ...props
}) => {
  const [idExercise, setIdExercise] = useState(null)
  const [disabled, setDisabled] = useState(null)
  const [contentOffsetX] = useState(motionValue(0))
  const [controls] = useState(useAnimation())

  useEffect(() => {
    if (!animateOnInit) return
    // Slide animation to the left when card is loaded
    setTimeout(async () => {
      await controls.start({ x: -50 })
      await controls.start({ x: 0 })
    }, DelayAnimationMs)
  }, [])

  useEffect(() => {
    const cardItemId = get(projectState, 'liveState.openCardItemId')

    if (containerNode.id === cardItemId) {
      setTimeout(async () => {
        controls.start({ x: MinDragXPx })
      }, DelayAnimationMs)
    }
  }, [projectState])

  useEffect(() => {
    const stackItem = findNodeByName(containerNode, NodeNames.TopStackItem)

    if (!stackItem) return

    const title = findNodeByName(stackItem, 'title').value

    const id = camelCase(title)

    setIdExercise(id)

    const searchValue = get(projectState, 'liveState.searchCapacityInput')

    if (!searchValue) {
      setDisabled(false)
      return
    }

    const titleL = title.toLowerCase()

    const subtitleL = findNodeByName(stackItem, 'subtitle').value.toLowerCase()
    const descriptionL = findNodeByName(
      stackItem,
      'description'
    ).value.toLowerCase()
    const lSearchValue = searchValue.toLowerCase()

    if (
      !titleL.includes(lSearchValue) &&
      !subtitleL.includes(lSearchValue) &&
      !descriptionL.includes(lSearchValue)
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [projectState])

  // Setup dragging interaction values
  const dragWidth = MinDragXPx * scaleRatio
  const dragFull = { x: dragWidth }
  const dragReset = { x: MaxDragXPx }

  const renderNode = node => (
    <Node
      node={node}
      scaleRatio={scaleRatio}
      viewRect={viewRect}
      projectState={projectState}
      onProjectStateChange={onProjectStateChange}
      {...props}
    />
  )

  const renderIcon = (iconNode, otherProps = {}) => {
    const relativeChildNode = {
      ...iconNode,
      frame: {
        ...iconNode.frame,
        x: 0,
        y: 0,
      },
    }

    return (
      <motion.div
        key={iconNode.id}
        className={classes.icon}
        rotate={useTransform(
          contentOffsetX,
          [dragWidth / 2, dragWidth],
          [25, 0]
        )}
        scale={useTransform(
          contentOffsetX,
          [dragWidth / 2, dragWidth],
          [0.5, 1]
        )}
        whileTap={{ opacity: 0.3 }}
      >
        <Node
          node={relativeChildNode}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          {...props}
          {...otherProps}
        />
      </motion.div>
    )
  }

  const topStackClassNames = classNames({
    [classes.stack]: true,
    [classes.topStack]: true,
  })

  const bottomStackClassNames = classNames({
    [classes.stack]: true,
    [classes.bottomStack]: true,
  })

  const topStackItem = findNodeByName(containerNode, NodeNames.TopStackItem)
  const bottomStackItem = findNodeByName(
    containerNode,
    NodeNames.BottomStackItem
  )

  const iconItemNode = findNodeByName(bottomStackItem, NodeNames.IconItem)
  const toggleItemNode = findNodeByName(bottomStackItem, NodeNames.ToggleItem)
  const scienceHotspotNode = findNodeByName(
    bottomStackItem,
    NodeNames.ScienceHotspot
  )

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.disabled]: disabled,
  })

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    ...style,
  }

  const topStackNodes = values(topStackItem.nodes).map(childNode => (
    <Node
      key={childNode.id}
      node={childNode}
      scaleRatio={scaleRatio}
      viewRect={viewRect}
      projectState={projectState}
      onProjectStateChange={onProjectStateChange}
      {...props}
    />
  ))

  return (
    <div className={containerClassNames} style={containerStyles}>
      {(bottomStackItem && (
        <motion.div
          className={topStackClassNames}
          drag="x"
          x={contentOffsetX}
          dragConstraints={{ left: dragWidth, right: 0 }}
          dragDirectionLock="true"
          animate={controls}
          onClick={e => {
            if (Events.dragging) return
            onProjectStateChange && onProjectStateChange()
          }}
          onDragStart={e => {
            Events.dragging = true
          }}
          onDragEnd={e => {
            // This snaps back or all the way to the left based on drag position
            if (contentOffsetX.get() > dragWidth / 2) {
              controls.start(dragReset)
            } else if (contentOffsetX.get() < dragWidth / 2) {
              controls.start(dragFull)
            }

            setTimeout(() => {
              Events.dragging = false
            })
          }}
        >
          {topStackNodes}
        </motion.div>
      )) || <div className={topStackClassNames}>{topStackNodes}</div>}
      {bottomStackItem && (
        <motion.div className={bottomStackClassNames}>
          {renderIcon(iconItemNode)}
          {renderIcon(toggleItemNode, { idExercise })}
          {scienceHotspotNode && renderNode(scienceHotspotNode)}
        </motion.div>
      )}
    </div>
  )
}

CardItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  onChange: PropTypes.func,
  animateOnInit: PropTypes.bool,
  style: PropTypes.object,
}

export default withStyles(styles)(CardItem)
