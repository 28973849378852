import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import pick from 'lodash/pick'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import {
  processNodeFrame,
  findNodeByName,
  parseNodeName,
  getNodesArraySortedByPosition,
  getContrastColor,
} from 'utils'

import Node from 'components/Node'

import styles from './CapacityItemStyles'

const ExpandHeightPx = 293

const CapacityItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  onChange,
  ...props
}) => {
  const [expanded, setExpanded] = useState(null)
  const [capacityData, setCapacityData] = useState(null)

  useEffect(() => {
    const parsedName = parseNodeName(node)
    const expandedValue = parsedName.modifierName === 'expanded'

    setExpanded(expandedValue)
  }, [])

  useEffect(() => {
    const expandedValue =
      get(projectState, `liveState.capacityItemExpand[${node.id}]`) || false

    setExpanded(expandedValue)
  }, [projectState])

  useEffect(() => {
    const imageNode = findNodeByName(node, 'image')
    const contentNode = findNodeByName(node, 'content__item')
    const titleNode = findNodeByName(contentNode, 'title')
    const descriptionNode = findNodeByName(contentNode, 'description')

    const chipNode = findNodeByName(contentNode, 'chip__item')
    const tagLabelNode = findNodeByName(chipNode, 'tagLabel')
    const tagColorNode = findNodeByName(chipNode, 'tagColor')

    setCapacityData({
      title: titleNode.value,
      description: descriptionNode.value,
      image: imageNode.url,
      tagLabel: get(tagLabelNode, 'value'),
      tagColor: get(tagColorNode, 'value'),
    })
  }, [node])

  const renderImage = () => {
    const imageNode = findNodeByName(node, 'image')

    if (!imageNode) return null

    return (
      <Node
        node={imageNode}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        projectState={projectState}
        onProjectStateChange={onProjectStateChange}
        {...props}
      />
    )
  }

  const renderChipNode = chipNode => {
    const tagLabelNode = findNodeByName(chipNode, 'tagLabel')
    const tagColorNode = findNodeByName(chipNode, 'tagColor')

    const tagLabelStyles = pick(tagLabelNode.style, ['fontSize', 'fontFamily'])

    const tagStyles = {
      display: 'none', // Note: This has been an add-on for Home Feed 2.0
      backgroundColor:
        tagColorNode && tagColorNode.value ? tagColorNode.value : '#fcb82f',
      color:
        tagColorNode && tagColorNode.value
          ? getContrastColor(tagColorNode.value)
          : '',
    }

    return (
      <div className={classes.tag} style={tagStyles} key={chipNode.id}>
        <span style={tagLabelStyles}>{tagLabelNode.value}</span>
      </div>
    )
  }

  const renderHotspot = () => {
    const hotspotNode = findNodeByName(node, 'Hotspot')

    if (!hotspotNode) return null

    const nodeValueInjected = {
      ...hotspotNode,
      frame: {
        ...hotspotNode.frame,
        x: 0,
        y: 0,
      },
      forceReload: true,
    }

    const handleClick = async () =>
      onProjectStateChange &&
      onProjectStateChange({ ...capacityData }, hotspotNode.id)

    return (
      <div className={classes.hotspot} onClick={() => handleClick()}>
        <Node
          node={nodeValueInjected}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          {...props}
        />
      </div>
    )
  }

  const renderContent = () => {
    const contentNode = findNodeByName(node, 'content__item')

    const contentStyles = {
      ...contentNode.style,
      ...processNodeFrame(contentNode, scaleRatio, viewRect),
      position: 'relative',
      left: 0,
      top: 0,
      height: 'fit-content',
      marginRight: 14,
    }

    return (
      <div className={classes.content} style={contentStyles}>
        {getNodesArraySortedByPosition(values(contentNode.nodes)).map(n => {
          if (n.name === 'description' && !expanded) return null

          if (n.name === 'chip__item') return renderChipNode(n)

          const processedNode = {
            ...n,
            style: {
              ...n.style,
              position: 'relative',
              top: 0,
              left: 0,
            },
          }

          return (
            <Node
              key={processedNode.id}
              node={processedNode}
              scaleRatio={scaleRatio}
              viewRect={viewRect}
              projectState={projectState}
              onProjectStateChange={onProjectStateChange}
              {...props}
            />
          )
        })}
      </div>
    )
  }

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.expanded]: expanded,
  })

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect),
  }

  if (expanded) containerStyles.height = ExpandHeightPx

  return (
    <div className={containerClassNames} style={containerStyles}>
      {renderHotspot()}
      {renderImage()}
      {renderContent()}
    </div>
  )
}

CapacityItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  onChange: PropTypes.func,
}

export default withStyles(styles)(CapacityItem)
