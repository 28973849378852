import React, { useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import SwipeableViews from 'react-swipeable-views'
import Box from '@material-ui/core/Box'
import MuiTabs from '@material-ui/core/Tabs'
import MuiTab from '@material-ui/core/Tab'

import { processNodeFrame, findNodeByName, sortNodesByZIndex } from 'utils'

import Node from 'components/Node'

import styles from './TabsStyles'

const TabPanel = ({ classes, children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box className={classes.tabPanel} p={3}>
        {children}
      </Box>
    )}
  </div>
)

const NodeNames = {
  ContentItem: 'content__item',
}

const Tabs = ({
  classes,
  index,
  node: containerNode,
  onChange,
  scaleRatio,
  viewRect,
  style,
  ...otherProps
}) => {
  const [value, setValue] = React.useState(0)
  const [tabsContentNodes, setTabsContentNodes] = useState(null)

  useEffect(() => {
    const contentItem = findNodeByName(containerNode, NodeNames.ContentItem)
    const tabs = sortNodesByZIndex(contentItem)
    setTabsContentNodes(tabs)
  }, [])

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const containerStyles = useMemo(
    () => ({
      ...containerNode.style,
      ...processNodeFrame(containerNode, scaleRatio, viewRect),
      ...style,
    }),
    [scaleRatio, viewRect]
  )

  return (
    <div className={classes.container} style={containerStyles}>
      <Box>
        <MuiTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
        >
          <MuiTab label="Teams" id="simple-tab-1" />
          <MuiTab label="Players" id="simple-tab-2" />
          <MuiTab label="Sim" id="simple-tab-3" />
        </MuiTabs>
      </Box>
      {tabsContentNodes && (
        <SwipeableViews
          axis={'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          className={classes.tabPanelContainer}
        >
          {tabsContentNodes?.map((tabNode, index) => (
            <TabPanel
              classes={classes}
              value={value}
              index={index}
              key={tabNode.id}
            >
              <Node
                node={tabNode}
                scaleRatio={scaleRatio}
                viewRect={viewRect}
                {...otherProps}
              />
            </TabPanel>
          ))}
        </SwipeableViews>
      )}
    </div>
  )
}

Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  node: PropTypes.object,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(Tabs)
