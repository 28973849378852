/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import sumBy from 'lodash/sumBy'
import { lighten, withStyles } from '@material-ui/core/styles'

import { formatNumberWithComma } from 'utils'

import styles from './ProgramListStyles'

const ProgramList = ({
  classes,
  pointsData,
  categoriesData,
  activeCategories,
  showDescription,
  showPracticeFreq,
  style,
}) => {
  const renderPoints = ({ points, totalPoints }) => {
    const pointsContainerClassNames = classNames({
      [classes.pointsContainer]: true,
      // [classes.disabled]: !Number(points),
      [classes.completed]: Number(points) === totalPoints,
      [classes.highlight]: Number(points) > 0 && Number(points) < totalPoints,
    })

    return (
      <div className={pointsContainerClassNames}>
        {formatNumberWithComma(points) || 0} of{' '}
        {formatNumberWithComma(totalPoints)}
      </div>
    )
  }

  const renderPractices = ({ practices, points, totalPoints }) => {
    const progressPerc = (points * 100) / totalPoints

    const currPractices = Math.ceil((progressPerc / 100) * practices)

    const practicesContainerClassNames = classNames({
      [classes.practicesContainer]: true,
      // [classes.disabled]: !currPractices,
    })

    return (
      <div className={practicesContainerClassNames}>
        <div>
          {currPractices || 0}
          <span>of</span>
          {practices}
        </div>
        {(currPractices && <div className={classes.tagLabel}>PRACTICES</div>) ||
          null}
      </div>
    )
  }

  const renderProgressBar = ({ points, totalPoints, category }) => {
    const { color } = find(categoriesData, c => c.id === category)

    const progressPerc = (points * 100) / totalPoints

    const barItemStyle = {
      background: `linear-gradient(90deg, ${lighten(
        color,
        0.5
      )} 0%, ${color} 98%)`,
      width: `${progressPerc}%`,
    }

    return (
      <div className={classes.progressBarContainer}>
        <div className={classes.progressBarItem} style={barItemStyle} />
      </div>
    )
  }

  const renderOverallPoints = () => {
    const totalPoints = formatNumberWithComma(
      sumBy(pointsData.data, d => Number(d.totalPoints))
    )
    const currTotalPoints = formatNumberWithComma(
      sumBy(pointsData.data, d => Number(d.points))
    )

    return (
      <div className={classes.overallPointsContainer}>
        <div>
          {currTotalPoints}
          <span>/</span>
          {totalPoints}
        </div>
        <div className={classes.tagLabelPointsContainer}>TOTAL</div>
      </div>
    )
  }

  const renderOverallPractices = () => {
    const totalPractices = formatNumberWithComma(
      sumBy(pointsData.data, d => Math.round(Number(d.practices)))
    )

    const currPractices = formatNumberWithComma(
      sumBy(pointsData.data, d => {
        const progressPerc = (d.points * 100) / d.totalPoints
        return Math.round((progressPerc / 100) * Number(d.practices))
      })
    )

    return (
      <div className={classes.overallPracticesContainer}>
        <div className={classes.totalOverallPractices}>
          {totalPractices}
          <div className={classes.tagLabelPointsContainer}>GOAL</div>
        </div>
        <div style={{ flex: 1 }} />
        <div className={classes.currOverallPractices}>
          {currPractices}
          <div className={classes.tagLabelPointsContainer}>TOTAL</div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container} style={style}>
      {pointsData &&
        categoriesData &&
        pointsData.data.map(itemData => {
          const { label } =
            categoriesData &&
            find(categoriesData, c => c.id === itemData.category)

          if (activeCategories && !activeCategories.includes(itemData.category))
            return null

          return (
            <div className={classes.itemContainer} key={itemData.label}>
              <div className={classes.textContainer}>
                <div className={classes.label}>{itemData.label}</div>
                {showDescription && (
                  <div className={classes.description}>for{` ${label}`}</div>
                )}
              </div>
              <div style={{ flex: 1 }} />
              {!showPracticeFreq && renderPoints(itemData)}
              {showPracticeFreq && renderPractices(itemData)}
              {renderProgressBar(itemData)}
            </div>
          )
        })}
      {/* {pointsData && !showPracticeFreq && renderOverallPoints()}
      {pointsData && showPracticeFreq && renderOverallPractices()} */}
    </div>
  )
}

ProgramList.propTypes = {
  classes: PropTypes.object.isRequired,
  day: PropTypes.number,
  pointsData: PropTypes.object,
  categoriesData: PropTypes.arrayOf(PropTypes.object),
  activeCategories: PropTypes.arrayOf(PropTypes.string),
  showDescription: PropTypes.bool,
  showPracticeFreq: PropTypes.bool,
  style: PropTypes.object,
}

export default withStyles(styles)(ProgramList)
