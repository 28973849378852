import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import find from 'lodash/find'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CloseIcon from '@material-ui/icons/Close'
import values from 'lodash/values'
import throttle from 'lodash/throttle'
import parse from 'autosuggest-highlight/parse'
import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import SvgIcon from '@material-ui/core/SvgIcon'

import { processNodeFrame, loadScript } from 'utils'

import LocationIcon from './LocationIcon'

import styles from './AddressInputStyles'

const GoogleMapsId = 'google-maps'

const autocompleteService = { current: null }

const parsePartsInText = parts => parts.map(part => part.text).join('')

const DefaultAddress = '723 7th Ave'

const ThrottleMs = 200

const AddressInput = ({
  classes,
  node,
  scaleRatio,
  onProjectStateChange,
  viewRect,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(null)
  const [openAutocomplete, setOpenAutocomplete] = useState(null)
  const [options, setOptions] = useState([])
  const loaded = React.useRef(false)
  const containerInputRef = React.createRef()
  const inputRef = React.createRef()

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector(`#${GoogleMapsId}`)) {
      loadScript({
        src:
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyB6Zr419hrW9x6g8Gli8Sk-zmWZdIPUO4g&libraries=places',
        id: GoogleMapsId,
      })
    }

    loaded.current = true
  }

  const focus = () => inputRef.current.focus()

  const blur = useMemo(
    () =>
      throttle(
        input => {
          input.blur()
        },
        ThrottleMs,
        { leading: false }
      ),
    []
  )

  const fetch = useMemo(
    () =>
      throttle((input, callback) => {
        autocompleteService.current.getPlacePredictions(input, callback)
      }, ThrottleMs),
    []
  )

  const initFetchService = () => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }
  }

  useEffect(() => {
    let active = true

    initFetchService()

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    fetch({ input: inputValue }, results => {
      if (active) setOptions(results || [])
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  useEffect(() => {
    onProjectStateChange && onProjectStateChange(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (!isFocused) {
      setOpenAutocomplete(false)
      return
    }

    initFetchService()

    fetch({ input: inputValue || DefaultAddress }, results => {
      setOptions(results || [])
    })

    setOpenAutocomplete(true)
  }, [isFocused])

  const handleTextChange = e => {
    setInputValue(e.target.value)
    setOpenAutocomplete(true)
  }

  const resetWithText = text => {
    setOptions([])
    setInputValue(text)
    setOpenAutocomplete(false)
  }

  const processedFrame = processNodeFrame(node, scaleRatio, viewRect)

  const containerStyles = {
    ...processedFrame,
  }

  const renderInput = (inputNode, imageNode, rectangleNode) => {
    const processInputFrame = processNodeFrame(inputNode, scaleRatio, viewRect)
    const processImageFrame = processNodeFrame(imageNode, scaleRatio, viewRect)
    const processRectangleFrame = processNodeFrame(
      rectangleNode,
      scaleRatio,
      viewRect
    )

    const fontSize = get(processInputFrame, 'fontSize')
    const height = get(processInputFrame, 'height')

    const inputColor = inputNode.style.color

    const borderColor = isFocused && inputColor

    const inputContainerStyles = {
      ...rectangleNode.style,
      ...processRectangleFrame,
    }

    if (borderColor) {
      inputContainerStyles.borderColor = borderColor
    }

    const inputStyles = {
      color: inputColor,
      fontFamily: inputNode.style.fontFamily,
      paddingLeft: processInputFrame.left,
      fontSize,
    }

    const autocompleteItemStyles = {
      paddingLeft: processInputFrame.left,
      fontFamily: inputNode.style.fontFamily,
      fontSize: fontSize - 4,
      paddingTop: height / 2,
      paddingBottom: height / 2,
    }

    const iconStyles = {
      ...processImageFrame,
      width: processImageFrame.width * 1.2,
    }

    const closeIconStyles = {
      fontSize: fontSize * 1.5,
    }

    const inputContainerClassNames = classNames({
      [classes.inputContainer]: true,
      [classes.inputContainerFocused]: isFocused,
    })

    return (
      <div
        className={inputContainerClassNames}
        style={inputContainerStyles}
        ref={containerInputRef}
      >
        <LocationIcon className={classes.icon} style={iconStyles} />
        <input
          value={inputValue}
          onChange={handleTextChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
          className={classes.input}
          placeholder={inputNode.value}
          ref={inputRef}
          style={inputStyles}
          spellCheck="false"
        />
        <CloseIcon
          className={classes.closeIcon}
          style={closeIconStyles}
          onClick={() => resetWithText('')}
        />
        <Paper className={classes.autocompleteContainer}>
          {openAutocomplete &&
            options &&
            options.map((option, optionIndex) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map(match => [
                  match.offset,
                  match.offset + match.length,
                ])
              )
              const text = option.structured_formatting.secondary_text

              return (
                <div
                  className={classes.autocompleteItem}
                  key={optionIndex}
                  onClick={() => {
                    resetWithText(`${parsePartsInText(parts)}, ${text}`)
                    blur(inputRef.current)
                  }}
                >
                  <LocationIcon className={classes.icon} style={iconStyles} />
                  <div style={autocompleteItemStyles}>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <p className={classes.autocompleteSecondaryText}>{text}</p>
                  </div>
                </div>
              )
            })}
        </Paper>
      </div>
    )
  }

  const textNode = find(values(node.nodes), n => n.type === 'text')
  const imageNode = find(values(node.nodes), n => n.type === 'image')
  const rectangleNode = find(
    values(node.nodes),
    n => n.type === 'shape' && n.shapeType === 'rectangle'
  )

  return (
    <div className={classes.container} style={containerStyles} onClick={focus}>
      {renderInput(textNode, imageNode, rectangleNode)}
    </div>
  )
}

AddressInput.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
}

export default withStyles(styles)(AddressInput)
