import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FDINX7UJYA879')

export const data = {
  '87-20': {
    styleProps: {
      border: '1px solid',
    },
  },
  '87-8': {
    frameProps: {
      height: 700,
    },
  },
}

export default ProjectId
