import React, { useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { loadFontsFromViews } from 'utils'

import ScaleContainer from './ScaleContainer'

import styles from './ViewContainerStyles'

let FontsLoaded = {}

const ViewContainer = ({
  classes,
  views,
  projectId,
  projectState,
  isLinkedProject,
  ...props
}) => {
  useEffect(() => {
    const injectedFontFamilies = values(views).reduce((acc, view) => {
      const fontFamilies = get(projectState, `data.${view.id}.fontFamilies`)

      if (!fontFamilies) return acc

      return acc.concat([...fontFamilies])
    }, [])

    FontsLoaded = loadFontsFromViews(views, injectedFontFamilies, FontsLoaded)
  }, [views])

  const { activeViews } = projectState

  const activeViewId = activeViews[projectId]

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.linkedContainer]: isLinkedProject,
  })

  return (
    <div className={containerClassNames}>
      {activeViewId &&
        values(views).map(view => {
          if (view.id !== activeViewId) return null
          return (
            <ScaleContainer
              view={view}
              key={view.id}
              views={views}
              projectId={projectId}
              projectState={projectState}
              isLinkedProject={isLinkedProject}
              {...props}
            />
          )
        })}
    </div>
  )
}

ViewContainer.propTypes = {
  classes: PropTypes.object,
  views: PropTypes.object,
  projectId: PropTypes.string,
  projectState: PropTypes.object,
  isLinkedProject: PropTypes.bool,
}

export default withStyles(styles)(ViewContainer)
