import get from 'lodash/get'
import { getId } from '../utils'
import {
  Properties,
  DayPoints,
  DayPointsCategories,
  TodayPointsDate,
} from '../constants'

const ProjectId = getId('1CD6024D94A')

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: Properties.ProgramPoints,
          value: async state => {
            const currentDate =
              get(state, `liveState.${Properties.ProgramPoints}.currentDate`) ||
              TodayPointsDate

            return {
              currentDate,
              todayDate: TodayPointsDate,
              dayPoints: DayPoints,
              categories: DayPointsCategories,
            }
          },
        },
      ],
    },
    styleProps: {
      overflowX: 'hidden',
    },
  },
  '3E841884-5F3C-4ABE-A5A0-A745EDE616F5': {
    // Program List
    componentProps: {
      day: state =>
        get(state, `liveState.${Properties.ProgramPoints}.currentDate`) ||
        TodayPointsDate,
      pointsData: state => {
        const dayPoints =
          get(state, `liveState.${Properties.ProgramPoints}.dayPoints`) ||
          DayPoints

        return dayPoints[
          get(state, `liveState.${Properties.ProgramPoints}.currentDate`) ||
            TodayPointsDate
        ]
      },
      categoriesData: state =>
        get(state, `liveState.${Properties.ProgramPoints}.categories`),
      showPracticeFreq: state =>
        get(state, `liveState.${Properties.ShowPracticeFreq}`),
      activeCategories: null,
      showDescription: true,
    },
  },
  '2FABB3C7-3B35-4C3B-84D9-2B6979F8E327': {
    // Switch
    componentProps: {
      onChange: state => value => ({
        property: Properties.ShowPracticeFreq,
        value,
      }),
    },
  },
  'DC646C39-43B1-4703-AFED-E947EF9B8C65': {
    // `DaysItem` in Calendar
    props: {
      onStateChange: value => ({
        property: Properties.ProgramPoints,
        value: {
          todayDate: TodayPointsDate,
          currentDate: value,
          dayPoints: DayPoints,
          categories: DayPointsCategories,
        },
      }),
    },
  },
  'BFFD7EB9-38AF-49A2-8CC3-3CF4621DB4E5': {
    // `points__item`
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShowPracticeFreq}`)
        ? 'none'
        : 'block',
    }),
  },
  '1869CCC9-5AC4-4FA3-95FC-B648BB71521C': {
    // `practices__item`
    styleProps: state => ({
      display: get(state, `liveState.${Properties.ShowPracticeFreq}`)
        ? 'block'
        : 'none',
    }),
  },
}

export default ProjectId
