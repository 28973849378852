const jssStyles = theme => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    transform: 'translateZ(0)',
  },
  closeButton: {
    position: 'absolute',
  },
})

export default jssStyles
