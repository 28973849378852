const jssStyles = theme => ({
  container: {
    position: 'absolute',
    zIndex: theme.zIndex.layerElevated
  },
  stickyUnit: {
    position: 'absolute',
    opacity: 0,
    zIndex: -1
  },
  active: {
    opacity: 1,
    zIndex: theme.zIndex.layerElevated
  }
})

export default jssStyles
