import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import InstagramStory from './InstagramStory'

import styles from './InstagramStoriesStyles'

const InstagramStories = ({ classes }) => {
  const nodes = [
    {
      backgroundColor: '#aa88fe',
    },
    {
      backgroundColor: '#733eff',
    },
    {
      backgroundColor: '#642afe',
    },
  ]

  return (
    <div className={classes.container}>
      {nodes.map(({ backgroundColor }) => (
        <InstagramStory
          key={backgroundColor}
          backgroundColor={backgroundColor}
        />
      ))}
    </div>
  )
}

InstagramStories.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(InstagramStories)
