import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { motion, AnimateSharedLayout } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import styles from './ExpandingCardStyles'

const LayoutIds = {
  ExpandableCard: 'expandable-card',
  CardTitle: 'cardTitle',
}

const ExpandingNode = ({ classes, cardTitle, backgroundColor }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const collapse = () => setIsExpanded(false)

  const expand = () => setIsExpanded(true)

  return (
    <div className={classes.container}>
      <AnimateSharedLayout>
        {isExpanded ? (
          <motion.div
            className={classNames(classes.card, classes.expandedCard)}
            layoutId={LayoutIds.ExpandableCard}
            style={{ backgroundColor }}
          >
            <IconButton
              className={classes.backButton}
              aria-label="back"
              onClick={collapse}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <motion.h1
              className={classes.content}
              layoutId={LayoutIds.CardTitle}
            >
              {cardTitle}
            </motion.h1>
          </motion.div>
        ) : (
          <motion.div
            className={classNames(classes.card, classes.collapsedCard)}
            onClick={expand}
            layoutId={LayoutIds.ExpandableCard}
            style={{ backgroundColor }}
          >
            <motion.h1
              className={classes.content}
              layoutId={LayoutIds.CardTitle}
            >
              {cardTitle}
            </motion.h1>
          </motion.div>
        )}
      </AnimateSharedLayout>
    </div>
  )
}

ExpandingNode.propTypes = {
  classes: PropTypes.object.isRequired,
  cardTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default withStyles(styles)(ExpandingNode)
