export const VoidNode = '__'

export const ComponentDelimeter = '__'

export const ModifierDelimeter = '--'

export const ImageFormatDefault = 'png'

export const DynamicNodeTypes = {
  Default: {
    dynamicProp: 'value',
  },
  Image: {
    prop: 'image',
    dynamicProp: 'url',
  },
}

// State Props
export const LiveStateProperties = {
  DisableScroll: 'disableScroll',
  ForceViewScrollTo: 'forceViewScrollTo',
  PropsInitialized: 'propsInitialized',
  MagicExpandedItem: 'magicExpandedItem',
  ViewScrollPosition: 'viewScrollPosition',
  FramerScrollPosition: 'framerScrollPosition',
  ScrollPosition: 'scrollPosition',
  ViewScrollPosition: 'viewScrollPosition',
}
