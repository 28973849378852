import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PhotoSizeSelectLargeIcon from '@material-ui/icons/PhotoSizeSelectLarge'
import PhotoSizeSelectActual from '@material-ui/icons/PhotoSizeSelectActual'
import ToggleButton from '@material-ui/lab/ToggleButton'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import Node from 'components/Node'

import styles from './ControlsItemStyles'

const ControlNames = {
  CapacityItem: 'CapacityItem'
}

const Controls = {
  'E89CA660-B857-4CFE-AC1A-093C206BCA56': {
    name: ControlNames.CapacityItem,
    property: 'capacityItemExpand',
    targetId: '27B87B36-7870-44A6-9536-DCB270263457'
  }
}

const ControlsItem = ({
  classes,
  node,
  scaleRatio,
  viewRect,
  onProjectStateChange,
  onChange,
  ...props
}) => {
  const [capacityItemExpanded, setCapacityItemExpanded] = React.useState(false)

  const containerStyles = {
    ...node.style,
    ...processNodeFrame(node, scaleRatio, viewRect)
  }

  useEffect(() => {
    onProjectStateChange &&
      onProjectStateChange({
        ...Controls[node.id],
        value: capacityItemExpanded
      })
  }, [capacityItemExpanded])

  const renderControl = () => {
    const control = Controls[node.id]

    if (!control) return null

    if (control.name === ControlNames.CapacityItem) {
      return (
        <ToggleButton
          value="check"
          selected={capacityItemExpanded}
          onChange={() => {
            setCapacityItemExpanded(!capacityItemExpanded)
          }}
        >
          {capacityItemExpanded && <PhotoSizeSelectActual />}
          {!capacityItemExpanded && <PhotoSizeSelectLargeIcon />}
        </ToggleButton>
      )
    }

    return values(node.nodes).map(childNode => (
      <Node
        key={childNode.id}
        node={childNode}
        scaleRatio={scaleRatio}
        viewRect={viewRect}
        {...props}
      />
    ))
  }

  return (
    <div className={classes.container} style={containerStyles}>
      {renderControl()}
    </div>
  )
}

ControlsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  onChange: PropTypes.func
}

export default withStyles(styles)(ControlsItem)
