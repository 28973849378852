const jssStyles = theme => ({
  container: {
    position: 'absolute'
    // overflow: 'auto'
  },
  fixedContainer: {
    position: 'fixed'
  }
})

export default jssStyles
