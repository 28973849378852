import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import find from 'lodash/find'
import raf from 'raf'
import { withStyles } from '@material-ui/core/styles'

import DatGui, {
  DatBoolean,
  DatColor,
  DatNumber,
  DatString
} from 'react-dat-gui'

import styles from './GUIControlsStyles'

const ControlType = {
  String: 'string',
  Number: 'number',
  Boolean: 'boolean',
  Color: 'color'
}

const _ = {
  rafHandle: null
}

const GUIControls = ({
  classes,
  projectState,
  onProjectStateChange,
  ...props
}) => {
  const [guiControls, setGUIControls] = useState(null)
  const [controlState, setControlState] = useState(null)

  useEffect(() => {
    const { projectId, data } = projectState
    const guiControlsData = get(data, `${projectId}.guiControls`)
    if (!guiControlsData) return

    setControlState(guiControlsData)

    setGUIControls(
      guiControlsData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.prop]: curr.value
        }),
        {}
      )
    )
  }, [])

  const handleUpdate = newData => {
    const newControlState = Object.keys(newData).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: {
          ...find(controlState, c => c.prop === curr),
          value: newData[curr]
        }
      }),
      {}
    )

    _.rafHandle = raf(() => {
      onProjectStateChange &&
        onProjectStateChange({
          stateValues: Object.keys(newControlState).reduce(
            (acc, controlId) => [
              ...acc,
              {
                property: newControlState[controlId].liveStateProp,
                value: newControlState[controlId].value
              }
            ],
            []
          )
        })
    })

    setControlState(newControlState)
    setGUIControls({ ...guiControls, ...newData })
  }

  return (
    <div className={classes.container}>
      <DatGui data={guiControls} onUpdate={handleUpdate}>
        {guiControls &&
          Object.keys(guiControls).map(controlId => {
            const control = find(controlState, c => c.prop === controlId)

            if (control.type === ControlType.String) {
              return (
                <DatString
                  key={controlId}
                  path={controlId}
                  label={control.label}
                />
              )
            }

            if (control.type === ControlType.Number) {
              return (
                <DatNumber
                  key={controlId}
                  path={controlId}
                  label={control.label}
                  min={control.min}
                  max={control.max}
                  step={control.step || 1}
                />
              )
            }

            if (control.type === ControlType.Boolean) {
              return (
                <DatBoolean
                  key={controlId}
                  path={controlId}
                  label={control.label}
                />
              )
            }

            if (control.type === ControlType.Color) {
              return (
                <DatColor
                  key={controlId}
                  path={controlId}
                  label={control.label}
                />
              )
            }

            return null
          })}
      </DatGui>
    </div>
  )
}

GUIControls.propTypes = {
  classes: PropTypes.object.isRequired,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func
}

export default withStyles(styles)(GUIControls)
