import withWrapper from 'common/withWrapper'
import ProjectIds from 'data/projects/bstevr'

import * as StoryComponents from 'bstevr-component-library/dist'
import FramerScrollItem from './FramerScrollItem'
import ForegroundBackgroundScroll from './ForegroundBackgroundScroll'
import Tabs from './Tabs'

const ExternalComponents = Object.keys(StoryComponents).reduce(
  (acc, currKey) => ({
    ...acc,
    [currKey]: withWrapper(StoryComponents[currKey]),
  }),
  {}
)

export default {
  ProjectIds,
  FramerScrollItem,
  ForegroundBackgroundScroll,
  Tabs,
  ...ExternalComponents,
}
