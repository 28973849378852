import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const LayoutIds = {
  ExpandableCard: 'expandable-card',
}

const CompactCard = ({ classes, disabled, onExpand, children }) => (
  <motion.div
    className={classNames(classes.card, classes.compactCard)}
    layoutId={LayoutIds.ExpandableCard}
    onClick={disabled ? undefined : onExpand}
  >
    {children}
  </motion.div>
)

CompactCard.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onExpand: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export default CompactCard
