import { getId } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FDINX7UJYA303')

export const data = {
  [ProjectId]: {},
}

export default ProjectId
