import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import values from 'lodash/values'
import { Animated } from 'react-animated-css'
import { withStyles } from '@material-ui/core/styles'

import { processNodeFrame } from 'utils'

import { Properties } from '@totalbrain-project/constants'
import Node from 'components/Node'

import styles from './FavChipItemStyles'

const IconName = 'icon'

const AnimationDurationMs = 800

const FavChipItem = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  onChange,
  ...props
}) => {
  const [animating, setAnimating] = useState(true)

  const animate = () => {
    setAnimating(false)

    setTimeout(() => {
      setAnimating(true)
    })
  }

  useEffect(() => {
    const { liveState } = projectState
    const isFavorited = Object.keys(liveState).find(
      key => key.includes(Properties.Favorited) && liveState[key]
    )
    if (!isFavorited) return
    animate()
  }, [projectState])

  const activateModal = () => onProjectStateChange && onProjectStateChange()

  const renderIcon = node => {
    const iconStyles = {
      ...node.style,
      ...processNodeFrame(node, scaleRatio, viewRect),
    }

    const processedNode = {
      ...node,
      frame: {
        ...node.frame,
        x: 0,
        y: 0,
      },
    }

    return (
      <Animated
        key={node.id}
        animationIn="pulse"
        animationInDuration={AnimationDurationMs}
        className={classes.icon}
        isVisible={animating}
        style={iconStyles}
      >
        <Node
          node={processedNode}
          viewRect={viewRect}
          scaleRatio={scaleRatio}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          {...props}
        />
      </Animated>
    )
  }

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
  }

  return (
    <div
      className={classes.container}
      style={containerStyles}
      onClick={activateModal}
    >
      {values(containerNode.nodes).map(node => {
        if (node.name === IconName) return renderIcon(node)

        return (
          <Node
            key={node.id}
            node={node}
            viewRect={viewRect}
            scaleRatio={scaleRatio}
            projectState={projectState}
            onProjectStateChange={onProjectStateChange}
            {...props}
          />
        )
      })}
    </div>
  )
}

FavChipItem.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  viewRect: PropTypes.object,
  onChange: PropTypes.func,
}

export default withStyles(styles)(FavChipItem)
