const jssStyles = theme => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
  },
  buttonContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > button': {
      fontSize: '.8em',
      marginTop: 20,
    },

    '&:hover': {
      opacity: 0.7,
    },
  },
  errorMessage: {
    backgroundColor: 'black',
    fontSize: 16,
    color: 'white',
    position: 'absolute',
    top: 0,
    textAlign: 'center',
    lineHeight: '26px',
    width: '100%',
    padding: '8px 0',

    '& span': {
      cursor: 'pointer',
      color: '#fcbc05',

      '&:hover': {
        opacity: 0.7,
      },
    },
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
})

export default jssStyles
