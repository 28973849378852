import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { motion, useTransform } from 'framer-motion'
import { withStyles } from '@material-ui/core/styles'

import { ProjectStateContext } from 'contexts/ProjectStateContextProvider'
import { processNodeFrame, findNodeByName } from 'utils'

import Node from 'components/Node'

import styles from './StickyHeaderStyles'

const ScrollItemKey = 'sticky-scroll-item'

const NodeNames = {
  StickyHeader: 'sticky-header',
}

const StickyHeader = ({
  classes,
  node: containerNode,
  scaleRatio,
  viewRect,
  projectState,
  onProjectStateChange,
  style,
  ...otherProps
}) => {
  const projectStateContext = useContext(ProjectStateContext)
  const scrollY = projectStateContext.getData(ScrollItemKey)

  const stickyHeaderNode = findNodeByName(containerNode, NodeNames.StickyHeader)

  const stickyHeaderFrame = processNodeFrame(
    stickyHeaderNode,
    scaleRatio,
    viewRect
  )

  const containerStyles = {
    ...containerNode.style,
    ...processNodeFrame(containerNode, scaleRatio, viewRect),
    ...style,
    y:
      scrollY && stickyHeaderFrame
        ? useTransform(
            scrollY,
            [0, -stickyHeaderFrame.top + 1, -stickyHeaderFrame.top],
            [0, 0, 1],
            { clamp: false }
          )
        : 0,
  }

  return (
    <motion.div className={classes.container} style={containerStyles}>
      {values(containerNode.nodes).map(node => (
        <Node
          key={node.id}
          node={node}
          scaleRatio={scaleRatio}
          viewRect={viewRect}
          projectState={projectState}
          onProjectStateChange={onProjectStateChange}
          {...otherProps}
        />
      ))}
    </motion.div>
  )
}

StickyHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object,
  scaleRatio: PropTypes.number,
  viewRect: PropTypes.object,
  projectState: PropTypes.object,
  onProjectStateChange: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(StickyHeader)
