import Filter from 'bad-words'

import { getId, IsSafari } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B72380')

const Properties = {
  ColorCommentaryBoxInitialState: 'ColorCommentaryBoxInitialState',
}

export const data = {
  [ProjectId]: {
    fontFamilies: ['Overpass-Regular', 'Overpass-Black'],
    styleProps: {
      overflowX: 'hidden',
    },
  },
  '238-84': {
    // ColorCommentaryBox
    componentProps: {
      onInitialStateChange: state => value => ({
        property: Properties.ColorCommentaryBoxInitialState,
        value,
      }),
    },
    observedRef: 'expandableRef',
    styleProps: {
      height: 'auto',
      zIndex: 9999,
    },
  },
  '238-34': {
    // `CONTINUE` button
    componentProps: {
      children: 'CONTINUE',
      color: 'secondary',
      variant: 'contained',
    },
    styleProps: {
      backgroundColor: 'auto',
    },
  },
  '238-74': {
    // Content below
    frameProps: state => ({
      y: state?.liveState?.[Properties.ColorCommentaryBoxInitialState]
        ? 2029
        : 2282,
    }),
  },
}

export default ProjectId
