const jssStyles = theme => ({
  container: {
    position: 'absolute',
    cursor: 'pointer',
    borderRadius: '100%'
  },
  disabled: {
    opacity: 0.6,
    cursor: 'default'
  }
})

export default jssStyles
