const jssStyles = theme => ({
  container: {
    cursor: 'pointer',
    position: 'absolute',
    zIndex: theme.zIndex.layerElevated,
    userSelect: 'none'
  },
  disabled: {
    cursor: 'default',
    backgroundColor: 'rgba(255,255,255,.6)'
  }
})

export default jssStyles
