import get from 'lodash/get'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import { getId, getCategoryData } from '../utils'
import { Properties } from '../constants'

import LibraryPageProjectId from './LibraryPage2'
import GameExerciseProjectId from './GameExercise'

const ProjectId = getId('22D07A0494A')

export const data = {
  '608B6253-54B8-4BD3-9328-21AC4F767AA7': {
    // `Capacity Details` Benefits
    conditionalProps: {
      value: state => {
        const activeCapacityBenefits = get(
          state,
          `liveState.${Properties.ActiveCapacityDetails}.benefits`
        )
        return activeCapacityBenefits
      },
    },
  },
  'BBA2A520-6E0A-49CD-AFA5-5B231D98430E': {
    // `Capacity Details` Science
    conditionalProps: {
      value: state => {
        const activeCapacityScience = get(
          state,
          `liveState.${Properties.ActiveCapacityDetails}.science`
        )
        return activeCapacityScience
      },
    },
  },
  '257F5568-E8F4-44FB-9485-78D4D30781FF': {
    // `Capacity Details` Image
    conditionalProps: {
      url: state => {
        const activeCapacityImage = get(
          state,
          `liveState.${Properties.ActiveCapacityDetails}.image`
        )
        return activeCapacityImage
      },
    },
    props: {
      isBackgroundImage: true,
    },
    styleProps: {
      opacity: 0.4,
    },
  },
  'B5BCE73D-4B87-4FC4-8432-D57C1B8CDFEB': {
    // `game-sticky__item` Exercises list
    componentProps: {
      categoriesData: state => getCategoryData(state),
      projectState: state => state,
      onChange: state => ({ e, id, value }) => {
        if (e === Properties.GameCardEvents.FavChange) {
          return {
            property: `${Properties.Favorited}${id}`,
            value,
          }
        }

        if (e === Properties.GameCardEvents.CardClicked) {
          return [
            {
              property: LiveStateProperties.ForceViewScrollTo,
              value: {
                viewId: ProjectId,
                value: 0,
              },
            },
            {
              property: LiveStateProperties.DisableScroll,
              value: true,
            },
            {
              property: Properties.ActiveGameId,
              value: id,
            },
            {
              property: projectStatesTypes.ENABLE_LINKED_PROJECT,
              value: GameExerciseProjectId,
            },
          ]
        }
      },
    },
  },
  '222107F5-8B2C-424C-8274-FF6A6DD16BDD': {
    // Back `HOTSPOT` button
    props: {
      onStateChange: () => [
        {
          property: projectStatesTypes.DISABLE_LINKED_PROJECT,
          value: ProjectId,
        },
        {
          property: projectStatesTypes.ENABLE_LINKED_PROJECT,
          value: LibraryPageProjectId,
        },
      ],
    },
  },
}

export default ProjectId
