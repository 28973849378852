const jssStyles = theme => ({
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.layerElevated
  },
  ripple: {
    position: 'absolute',
    borderRadius: '100%',
    width: 0,
    height: 0,
    transform: 'scale(0)',
    userSelect: 'none',
    pointerEvents: 'none',
    animation: '$rippleAnimation 0.4s linear',
    opacity: 0.1
  },
  '@keyframes rippleAnimation': {
    '60%': {
      transform: 'scale(2)'
    },
    '100%': {
      transform: 'scale(3)'
    }
  }
})

export default jssStyles
