import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './FilterSheetItemStyles'

const PropNames = {
  ShowAll: 'showAll',
  FilterSheetItemActive: 'filterSheetItemActive',
}

const FilterSheetItem = ({
  classes,
  active,
  capacityCategoryData,
  onChange,
  onClose,
  style,
  ...props
}) => {
  const [isActive, setActive] = useState(null)
  const [itemActiveId, setActiveId] = useState(PropNames.ShowAll)

  useEffect(() => setActive(active), [active])

  useEffect(
    () =>
      onChange &&
      onChange(itemActiveId !== PropNames.ShowAll ? itemActiveId : null),
    [itemActiveId]
  )

  const handleClose = () => {
    setActive(false)
    setTimeout(() => onClose && onClose(), 200)
  }

  const handleItemClick = item => {
    const newActiveId = item ? item.id : PropNames.ShowAll
    setActiveId(newActiveId)
    handleClose()
  }

  const containerClassNames = classNames({
    [classes.container]: true,
    [classes.containerActive]: isActive,
  })

  const itemAllClassName = classNames({
    [classes.item]: true,
    [classes.activeItem]: PropNames.ShowAll === itemActiveId,
  })

  return (
    <div className={containerClassNames} style={style}>
      <div className={classes.bottomContainer}>
        <div className={classes.collapseButton} onClick={() => handleClose()} />
        <p className={itemAllClassName} onClick={() => handleItemClick()}>
          Show All Capacities
        </p>
        {capacityCategoryData &&
          Object.keys(capacityCategoryData).map(cKey => (
            <div className={classes.category} key={cKey}>
              <p className={classes.categoryTitle}>{cKey}</p>
              {capacityCategoryData[cKey].map(item => {
                const itemClassName = classNames({
                  [classes.item]: true,
                  [classes.activeItem]: item.id === itemActiveId,
                })
                return (
                  <p
                    className={itemClassName}
                    onClick={() => handleItemClick(item)}
                    key={item.label}
                  >
                    {item.label}
                  </p>
                )
              })}
            </div>
          ))}
      </div>
    </div>
  )
}

FilterSheetItem.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool,
  capacityCategoryData: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(styles)(FilterSheetItem)
