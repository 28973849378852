import Filter from 'bad-words'

import { getId, IsSafari } from 'utils'
import { LiveStateProperties } from 'constants/general'

const ProjectId = getId('FHFIT3R6B72060')

const isProfaneText = text => {
  const filter = new Filter()
  return filter.isProfane(text)
}

const StorySectionStates = {
  empty: 'empty', // `gray` unchecked
  prefilled: 'prefilled', // `blue` checked
  filled: 'filled', // `green` checked
  error: 'error', // `blue` error
  alert: 'alert', // `red` warning
}

const FloatingScrollTopProperty = 'floating-scroll-top'

const HeadlineSectionsScrollTop = {
  0: 825,
  1: 975,
  2: 1750,
  3: 2400,
}

const Properties = {
  Headline: 'input-headline',
  Subhead: 'input-subhead',
  Pregame: 'input-pregame',
  FloatingBoxSuccess: 'floating-box-success',
}

const PregameDefaultText = `Hours before kickoff at Miami Gardens' Hard Rock Stadium in Florida, Fox will air the Super Bowl LIV Pregame Show beginning at 2 p.m. ET. Viewers can watch the show via live stream on Fox's website and app with a cable provider login.`

const getStatusText = (text, defaultText) => {
  if (!text) return StorySectionStates.empty

  if (isProfaneText(text)) {
    return StorySectionStates.alert
  }

  if (text === defaultText) return StorySectionStates.prefilled

  return StorySectionStates.filled
}

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: Properties.Pregame,
          value: PregameDefaultText,
        },
      ],
    },
    fontFamilies: ['Roboto', 'Overpass-Regular'],
    styleProps: {
      overflowX: 'hidden',
    },
    eventProps: {
      onScroll: scrollValue => ({
        property: FloatingScrollTopProperty,
        value: scrollValue > 764,
      }),
    },
  },
  '209-59': {
    // Header
    customProps: {
      sticky: {
        top: 0,
      },
    },
    styleProps: {
      zIndex: 100,
    },
  },
  '228-0': {
    // Tabs
    customProps: {
      sticky: {
        top: 55,
      },
    },
    styleProps: {
      zIndex: 100,
    },
  },
  '219-17': {
    // `headline input
    componentProps: {
      placeholder: 'Give Your Story a Title',
      fullWidth: true,
      charMax: 80,
      onChange: state => value => ({
        property: Properties.Headline,
        value,
      }),
      error: state => isProfaneText(state?.liveState?.[Properties.Headline]),
      helperText: state =>
        isProfaneText(state?.liveState?.[Properties.Headline])
          ? 'Innapropriate language is used'
          : '',
    },
  },
  '219-15': {
    // `subhead` input
    componentProps: {
      placeholder: 'Describe the game in a few sentences',
      fullWidth: true,
      charMax: 250,
      multiline: !IsSafari(), // Bug with multiline and Safari
      rowsMax: 5,
      onChange: state => value => ({
        property: Properties.Subhead,
        value,
      }),
      error: state => isProfaneText(state?.liveState?.[Properties.Subhead]),
      helperText: state =>
        isProfaneText(state?.liveState?.[Properties.Subhead])
          ? 'Innapropriate language is used'
          : '',
    },
  },
  '219-13': {
    // `pre-game` input
    componentProps: {
      placeholder:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum tortor aliquam erat dignissim dignissim',
      value: state => state?.liveState?.[Properties.Pregame],
      fullWidth: true,
      charMax: 250,
      multiline: !IsSafari(), // Bug with multiline and Safari
      rowsMax: 6,
      onChange: state => value => ({
        property: Properties.Pregame,
        value,
      }),
      error: state => isProfaneText(state?.liveState?.[Properties.Pregame]),
      helperText: state =>
        isProfaneText(state?.liveState?.[Properties.Pregame])
          ? 'Innapropriate language is used'
          : '',
    },
  },
  '224-6': {
    // `CONTINUE` button
    componentProps: {
      children: 'CONTINUE',
      color: 'secondary',
      variant: 'contained',
      disabled: state =>
        !Boolean(state?.liveState?.[Properties.Headline]) ||
        !Boolean(state?.liveState?.[Properties.Subhead]) ||
        !Boolean(state?.liveState?.[Properties.Pregame]),
    },
    styleProps: {
      backgroundColor: 'auto',
    },
  },
  '206-181': {
    // Floating box
    customProps: {
      sticky: {
        top: 113,
      },
    },
    componentProps: {
      title: 'SECTIONS TO EDIT',
      fullWidth: true,
      collapsed: state => state?.liveState?.[FloatingScrollTopProperty],
      isActive: state => state?.liveState?.[FloatingScrollTopProperty],
      onClick: state => value => [
        {
          property: LiveStateProperties.ForceViewScrollTo,
          value: {
            viewId: ProjectId,
            scrollProps: {
              scrollTo: HeadlineSectionsScrollTop[value],
              duration: 400,
            },
          },
        },
        {
          property: FloatingScrollTopProperty,
          value: false,
        },
      ],
      onChange: state => value => ({
        property: Properties.FloatingBoxSuccess,
        value: value?.warningState === 'success',
      }),
      options: state => [
        {
          label: 'Headline',
          status: getStatusText(state?.liveState?.[Properties.Headline]),
        },
        {
          label: 'Subhead',
          status: getStatusText(state?.liveState?.[Properties.Subhead]),
        },
        {
          label: 'Pre-game',
          status: getStatusText(
            state?.liveState?.[Properties.Pregame],
            PregameDefaultText
          ),
        },
        {
          label: 'Post-game',
          status: StorySectionStates.prefilled,
        },
      ],
    },
    styleProps: {
      height: 'auto',
      zIndex: 100,
      transform: 'translateZ(0)',
      WebkitTransform: 'translateZ(0)',
      WebkitFontSmoothing: 'antialiased',

      '> *': {
        WebkitTransform: 'translateZ(0)',
      },
    },
  },
}

export default ProjectId
