const jssStyles = theme => ({
  container: {
    position: 'relative',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    backgroundColor: 'white',
  },
  noScroll: {
    overflow: 'hidden',
  },
  linkedContainer: {},
  mobileContainer: {
    cursor: 'url(/images/mobile-cursor.png) 39 39,auto',
    '& *': {
      cursor: 'url(/images/mobile-cursor.png) 39 39,auto',
      '&:active': {
        cursor: 'url(/images/mobile-cursor-tap.png) 39 39,auto !important',
      },
    },
    '& .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet': {
      cursor: 'url(/images/mobile-cursor.png) 39 39,auto',
    },
    '& * *:not(input)': {
      userSelect: 'none',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      display: 'none',
      width: '0 !important',
    },
  },
})

export default jssStyles
