export default {
  /** TotalBrain Projects */

  /** Torii Sample Projects */
  '53166FF494A0': {
    // Torii Live - Taboola
    on: false,
    siteId: '1804562',
  },
  '327F0D4B94A0': {
    // Torii Live - Crafty
    on: false,
    siteId: '1795128',
  },
}
