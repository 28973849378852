import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import AirtableAPI from 'api/AirtableAPI'
import theme from 'styles/muiTheme'
import * as projectStatesTypes from 'constants/projectStatesTypes'
import { LiveStateProperties } from 'constants/general'
import {
  getId,
  fetchFeedState,
  getGameItemDynamicY,
  getExerciseData,
  getGameDataFeedComponentProps,
  getGameIdFromDataFeedState,
  getCardNameFromDataFeedState,
} from '../utils'
import { StyleMixins, Properties } from '../constants'

import GameExerciseProjectId from './GameExercise'

const ProjectId = getId('5F983E5194A')

const Actions = {
  OpenExerciseView: [
    {
      property: LiveStateProperties.ForceViewScrollTo,
      value: {
        viewId: ProjectId,
        value: 0,
      },
    },
    {
      property: LiveStateProperties.DisableScroll,
      value: true,
    },
    {
      property: projectStatesTypes.ENABLE_LINKED_PROJECT,
      value: GameExerciseProjectId,
    },
  ],
}

export const data = {
  [ProjectId]: {
    props: {
      onInitialState: [
        {
          property: 'dataFeed',
          value: async () => {
            const airtableData = await AirtableAPI.fetch(
              'appjUuIGIQZwVsNYc',
              '[C] Home Feed',
              'Grid view'
            )

            return groupBy(airtableData, 'category')
          },
        },
        {
          property: Properties.ExercisesData,
          value: async state => getExerciseData(state),
        },
      ],
    },
  },
  '32BD461F-A780-482E-89C8-292C991627B3': {
    // `hero__image`
    conditionalProps: {
      url: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'image',
          'attachment'
        ),
    },
    frameProps: {
      x: 0,
      y: 0,
      width: 375,
      height: 413,
    },
  },
  '723881AF-77C6-4669-A958-28BC50C279C1': {
    // `HELLO` title
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
      textTransform: 'uppercase',
    },
  },
  '6C9F9E8B-C394-4FF1-AEF7-12F2D0ED60CF': {
    // `KWAMIN` subtitle
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.WelcomeHero',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
      textTransform: 'uppercase',
    },
  },
  'EA60D96E-8226-42AA-BB75-9F469BC5686B': {
    // `fav-chip__item` component
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: true,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: true,
        },
      ],
    },
    styleProps: state => {
      const { liveState } = state

      const isFavorited = Object.keys(liveState).find(
        key => key.includes(Properties.Favorited) && liveState[key]
      )

      return {
        display: isFavorited ? 'block' : 'none',
      }
    },
  },
  '68D96AAE-2E7F-4546-8AD3-E7C652150BA3': {
    // `fav-modal__item`
    props: {
      onStateChange: () => [
        {
          property: 'isFavModalActive',
          value: false,
        },
        {
          property: LiveStateProperties.DisableScroll,
          value: false,
        },
      ],
    },
  },
  '60ADAB73-BC94-477C-9F96-124C99431CF1': {
    // `program__item` initial state
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
  '907B1C40-7F9B-4F79-84F9-8F6909A9BFCB': {
    // `program__item` title
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  'FA9D0590-F433-46F4-8F7E-E9C29356E571': {
    // `program__item` subtitle
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '5C07F1AE-2A06-4391-B17A-446538042E62': {
    // `program__item--checked` message
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramCard',
          'successMessage',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '024FC0A6-CF7D-472F-A047-597CBA76BC19': {
    // ProgramView `title`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'title',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '5717FB2B-F4CE-43F9-98F3-B70FDFBEE956': {
    // ProgramView `subtitle`
    conditionalProps: {
      value: state =>
        fetchFeedState(
          state,
          'liveState.dataFeed.ProgramView',
          'subtitle',
          'text'
        ),
    },
    styleProps: {
      ...StyleMixins.oneLine,
    },
  },
  '8AB3C5C4-A6F3-4D3E-9F8C-451557982990': {
    // ProgramView `START button`
    styleProps: {
      zIndex: 50,
    },
  },
  '4B544A43-EFFF-4EC2-B87A-3689B71DAD36': {
    // ProgramView `START button HOTSPOT`
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  '70831A12-564A-4C57-A771-F90BA0569C0D': {
    // ProgramView `close  icon HOTSPOT`
    props: {
      onStateChange: () => [
        {
          property: Properties.PersonalizedProgramChecked,
          value: true,
        },
      ],
    },
  },
  'C0A82CA4-7F5A-4A4D-A363-CC8AB1B9C54F': {
    // First motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[0].text') ||
        'This method suggests meditation helps us bounce back from stress. Give it a try.',
    },
  },
  'F2E3A839-FDC3-4033-A7A5-22AA62164367': {
    // Second motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[1].text') ||
        'You think around 70,000 thoughts a day! Reframing the unhelpful ones can lower stress. Try it out.',
    },
  },
  '7F115F72-A3D5-42DF-A25B-50E8A5C94BF3': {
    // Third motivational message
    conditionalProps: {
      value: state =>
        get(state, 'liveState.dataFeed.Motivational[2].text') ||
        'You think around 70,000 thoughts a day! Reframing the unhelpful ones can lower stress. Try it out.',
    },
  },
  'D305DCB6-9142-4FB1-B2A3-F616E1E01D5B': {
    // `games-list__item` in Fav Modal
    componentProps: {
      gamesData: state => {
        const { liveState } = state

        const exercisesData = get(liveState, Properties.ExercisesData)

        const favs = Object.keys(liveState).filter(
          key => key.includes(Properties.Favorited) && liveState[key]
        )

        const favorites = favs.map(f => f.split(Properties.Favorited)[1])

        if (!favorites || !exercisesData) return null

        return favorites.reduce((acc, currId) => {
          const exercise = exercisesData.find(e => e.id === currId)

          if (!exercise) return acc

          acc.push(exercise)

          return acc
        }, [])
      },
      onChange: state => ({ e, id, value }) => {
        if (e === Properties.GameCardEvents.FavChange) {
          return {
            property: `${Properties.Favorited}${id}`,
            value,
          }
        }

        if (e === Properties.GameCardEvents.CardClicked) {
          return [
            {
              property: 'isFavModalActive',
              value: false,
            },
            {
              property: LiveStateProperties.ForceViewScrollTo,
              value: {
                viewId: ProjectId,
                value: 0,
              },
            },
            {
              property: LiveStateProperties.DisableScroll,
              value: true,
            },
            {
              property: Properties.ActiveGameId,
              value: id,
            },
            {
              property: projectStatesTypes.ENABLE_LINKED_PROJECT,
              value: GameExerciseProjectId,
            },
          ]
        }
      },
    },
  },
  '25B0679B-908E-4248-928E-D77A9D35F1E2': {
    // `game__item` Unchecked First card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`
      )
        ? 'none'
        : '',
    }),
  },
  '8A65775D-CB5F-4707-B468-84BE2873E969': {
    // `game__item` Checked First card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`
      )
        ? ''
        : 'none',
    }),
  },
  'E70A0747-BC60-42C2-AB02-53AD3F639067': {
    // `game-card__item` First Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'firstCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  '73F871ED-3BF2-4764-906A-335F0DDE6192': {
    // `game__item--checked` First Card Text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'firstCardName'),
    },
  },
  '5508C72A-E209-4A82-AF2B-1A5B1E2DC298': {
    // `game__item` Unchecked Second card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(
          state,
          'secondCardName'
        )}Checked`
      )
        ? 'none'
        : '',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [`${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`],
        204
      ),
    }),
  },
  '4340C24B-17F5-4EF2-BDD4-77687A5BEDBC': {
    // `game__item` Checked Second card container`
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(
          state,
          'secondCardName'
        )}Checked`
      )
        ? ''
        : 'none',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [`${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`],
        199
      ),
    }),
  },
  '91FEDA73-2BE8-498F-A523-6F2F15B25687': {
    // `game-card__item` Second Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'secondCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  'EF845D45-31A5-48ED-BC36-928E2FB6D249': {
    // `game__item--checked` Second Card Text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'secondCardName'),
    },
  },
  'AD77C761-DC6A-4B2B-A3F8-E5E4148B4DC1': {
    // `game__item` Unchecked Third card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`
      )
        ? 'none'
        : '',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        398
      ),
    }),
  },
  '77521CAC-593B-45E5-805E-FC640719F330': {
    // `game__item` Checked Third card container
    styleProps: state => ({
      display: get(
        state,
        `liveState.${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`
      )
        ? ''
        : 'none',
    }),
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
        ],
        396
      ),
    }),
  },
  'DCBAB667-3D69-4731-83AB-233787208B4E': {
    // `game-card__item` Third Card
    componentProps: {
      ...getGameDataFeedComponentProps(
        'thirdCardName',
        Actions.OpenExerciseView
      ),
    },
  },
  '40A82AFD-6613-4073-85AD-A301EB9914DF': {
    // `game__item--checked` Third Card Text
    conditionalProps: {
      value: state => getCardNameFromDataFeedState(state, 'thirdCardName'),
    },
  },
  'EE7C70C8-4E7F-4577-B628-6238F20FA5BB': {
    // Game Exercise Linked View
    styleProps: {
      zIndex: theme.zIndex.layerElevatedL,
    },
  },
  'C5FEB07C-3512-49B9-82D4-78D5CF1E5B65': {
    // `content__item` 1
    frameProps: state => ({
      y: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 520
        : 630,
      height: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        1430 //  1354 + 76 (Home Feed Height)
      ),
    }),
  },
  'A13F9C12-28F5-42A0-BF95-C1FF1AA02E09': {
    // `content__item` 2
    frameProps: state => ({
      y: getGameItemDynamicY(
        state,
        [
          `${getGameIdFromDataFeedState(state, 'firstCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'secondCardName')}Checked`,
          `${getGameIdFromDataFeedState(state, 'thirdCardName')}Checked`,
        ],
        554
      ),
    }),
  },
  '4F1CF997-D2B6-45A3-9083-182B1B5AFB90': {
    // `house-tree` image
    conditionalProps: {
      url: () =>
        'https://firebasestorage.googleapis.com/v0/b/torii-live-dev.appspot.com/o/project%2F0AA7194C94A%2Fhouse-tree.svg?alt=media',
    },
    styleProps: state => ({
      display: get(state, `liveState.${Properties.PersonalizedProgramChecked}`)
        ? 'none'
        : '',
    }),
  },
}

export default ProjectId
